import { useEffect, useState } from 'react';
import { View } from '@views/View';
import {
  WarehouseMatchList,
  Match,
} from '@views/WarehouseDetails/WarehouseDetails';
import moment from 'moment';
import {
  BillingCycle,
  InvoiceAdditionalService,
  ServiceRates,
  VAT_RATE,
} from './invoiceTypes';
import { useAuthHttp } from '../../hooks';
import { APPROVE_INVOICE, MATCH_LIST } from '../../constants';
import { InvoiceTable } from './InvoiceTable';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoicePaymentDetails } from './InvoicePaymentDetails';

export const additionalServicesOptions = [
  {
    label: 'Case Picking and Despatch',
    value: 'casePickingAndDespatchPrice',
  },
  {
    label: 'Container Handling',
    value: 'containerHandlingPrice',
  },
  {
    label: 'Demurrage',
    value: 'demurragePricePerDay',
  },
  {
    label: 'Item Picking and Despatch',
    value: 'itemPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Picking and Despatch',
    value: 'palletPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Receipt and Put Away',
    value: 'palletReceiptAndPutAwayPrice',
  },
  {
    label: 'Pallet Storage',
    value: 'palletStoragePricePerWeek',
  },
  {
    label: 'Pallet Returns',
    value: 'palletReturnsPrice',
  },
  {
    label: 'Case Returns',
    value: 'caseReturnsPrice',
  },
  {
    label: 'Item Returns',
    value: 'itemReturnsPrice',
  },
];

export function LSPInvoice({ billingCycle }: { billingCycle: BillingCycle }) {
  const authHttp = useAuthHttp();
  const [hasConfirmed, setHasConfirmed] = useState(
    !!billingCycle.invoice.approvedAt
  );
  const [invoiceLines, setInvoiceLines] = useState(billingCycle.invoice.lines);
  const [invoiceNotes, setInvoiceNotes] = useState(billingCycle.invoice.notes);
  const [hasPaid, setHasPaid] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [auxiliaryServicesPrice, setAuxiliaryServicesPrice] = useState(0);
  const [manHours, setManHours] = useState(0);
  const [otherAmount, setOtherAmount] = useState(0);
  const [additionalServices, setAdditionalServices] = useState<
    InvoiceAdditionalService[]
  >([]);
  const [match, setMatch] = useState<Match>();
  const [dueDate, setDueDate] = useState(billingCycle.invoice.dueDate);

  let subTotalAmount = billingCycle.invoice.lines.reduce(
    (sum, line) => sum + line.amount,
    0
  );

  let subTotalVatAmount = billingCycle.invoice.lines.reduce(
    (sum, line) => sum + (line.amount * VAT_RATE) / 100,
    0
  );

  subTotalAmount += manHours * auxiliaryServicesPrice;
  subTotalVatAmount += (manHours * auxiliaryServicesPrice * VAT_RATE) / 100;

  subTotalAmount += otherAmount;

  additionalServices.forEach((service) => {
    const acceptedValue =
      match?.serviceRates[service.description as keyof ServiceRates]
        ?.acceptedValue ?? 0;
    const amount = service.amount * acceptedValue;
    const vatAmout = (service.amount * (acceptedValue * VAT_RATE)) / 100;
    subTotalAmount += parseFloat(amount.toFixed(2));
    subTotalVatAmount += parseFloat(vatAmout.toFixed(2));
  });

  const fetchWarehouseMatch = async () => {
    try {
      const { data } = await authHttp.post<WarehouseMatchList>(
        MATCH_LIST,
        {
          warehouseId: billingCycle.warehouseId,
          matchId: billingCycle.warehouseMatchId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.matches.length > 0) {
        setAuxiliaryServicesPrice(
          data.matches[0].match.serviceRates.auxiliaryServicePricePerManHour
            .acceptedValue
        );
        setMatch(data.matches[0].match);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleConfirmInvoice = async () => {
    setIsConfirming(true);
    const otherDescription = (
      document.getElementById('otherDescription') as HTMLInputElement
    ).value;

    try {
      const { data } = await authHttp.post(
        APPROVE_INVOICE,
        {
          billingCycle: {
            billingCycleId: billingCycle.id,
            approvedAt: moment(),
            notes: (document.getElementById('comments') as HTMLInputElement)
              .value,
            auxiliaryServiceManHours:
              (document.getElementById('manHours') as HTMLInputElement)
                ?.value || null,
            additionalServices:
              otherAmount > 0 && otherDescription
                ? [
                    ...additionalServices,
                    {
                      id: crypto.randomUUID(),
                      lineNumber:
                        billingCycle.invoice.lines.length +
                        additionalServices.length +
                        2,
                      description: otherDescription,
                      amount: otherAmount,
                    },
                  ]
                : additionalServices,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.billingCycle) {
        setHasConfirmed(true);
        setInvoiceLines(data.billingCycle.invoice.lines);
        setInvoiceNotes(data.billingCycle.invoice.notes);
        setDueDate(data.billingCycle.invoice.dueDate);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsConfirming(false);
    }
  };

  const handleAdditionalServiceDescriptionChange = (
    value: string,
    id: string
  ) => {
    const updatedServices = additionalServices.map((service) => {
      if (service.id === id) {
        return {
          ...service,
          description: value,
        };
      }

      return service;
    });

    setAdditionalServices(updatedServices);
  };

  const handleDeleteService = (serviceId: string) => {
    const updatedServices = additionalServices.filter(
      (service) => service.id !== serviceId
    );
    setAdditionalServices(updatedServices);
  };

  useEffect(() => {
    fetchWarehouseMatch();
  }, []);

  return (
    <View>
      <InvoiceSummary
        isLsp
        hasConfirmed={hasConfirmed}
        hasPaid={hasPaid}
        match={match}
        billingCycle={billingCycle}
      />
      <InvoiceTable
        billingCycle={billingCycle}
        invoiceLines={invoiceLines}
        match={match}
        subTotalAmount={subTotalAmount}
        subTotalVatAmount={subTotalVatAmount}
        editSectionProps={
          hasConfirmed
            ? null
            : {
                handleAdditionalServiceDescriptionChange,
                additionalServices,
                setAdditionalServices,
                setOtherAmount,
                handleDeleteService,
                manHours,
                setManHours,
                auxiliaryServicesPrice,
                setAuxiliaryServicesPrice,
              }
        }
      />
      <InvoicePaymentDetails
        isLsp
        hasConfirmed={hasConfirmed}
        hasPaid={hasPaid}
        billingCycle={billingCycle}
        invoiceNotes={invoiceNotes}
        dueDate={dueDate}
        isConfirming={isConfirming}
        handleConfirmInvoice={handleConfirmInvoice}
        setHasPaid={setHasPaid}
      />
    </View>
  );
}
