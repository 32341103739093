import Stack from '@mui/material/Stack/Stack';
import { useSetAtom } from 'jotai';
import Button from '@mui/material/Button';
import { UserImage, VehicleSummary as Vehicle } from '@views/FleetMaster/types';
import { Text } from '@components/Text';
import { Box } from '@mui/material';
import { FLEET_IMAGES_URL } from '../../constants';
import { editVehicleIdAtom } from '../../store/jotai';

interface VehicleSummaryProps {
  selectedVehicle: Vehicle;
  setOpen: (isOpen: boolean) => void;
  isTablet: boolean;
  isDesktop: boolean;
}

type Fleet =
  | 'VanLessThan1AndHalft'
  | 'LgvLessThan3AndHalft'
  | 'TwoAxleRigidLessThan7AndHalf5t'
  | 'TwoAxleRigid18t'
  | 'TwoAxleRigidLessThan26t'
  | 'MultiAxleArticMoreThan26t'
  | 'SpecialOrOther';

const fleetMapping = {
  VanLessThan1AndHalft: 'Van < 1.5t',
  LgvLessThan3AndHalft: 'LDVs < 3.5t',
  TwoAxleRigidLessThan7AndHalf5t: 'Rigids < 7.5t',
  TwoAxleRigid18t: 'Rigids 18t',
  TwoAxleRigidLessThan26t: 'Rigids < 26t',
  ThreeAxleRigidLessThan26t: 'Rigids < 26t',
  MultiAxleArticMoreThan26t: 'Artics > 26t',
  SpecialOrOther: 'Special/Other',
};

export function VehicleSummary({
  selectedVehicle,
  setOpen,
  isTablet,
  isDesktop,
}: VehicleSummaryProps) {
  const setEditVehicleId = useSetAtom(editVehicleIdAtom);
  return (
    <>
      <Stack p={6} spacing={32}>
        <Stack spacing={32} direction={isTablet ? 'row' : 'column'}>
          <Stack spacing={16} sx={{ width: '100%' }}>
            <Text as='h3' size='lg' weight='bold'>
              {selectedVehicle?.registrationNumber}
            </Text>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isDesktop
                  ? 'repeat(3, 1fr)'
                  : 'repeat(2, 1fr)',
                gap: 16,
              }}
            >
              {/* Product images */}
              {selectedVehicle?.images.map((image: UserImage) => (
                <img
                  key={image.id}
                  src={`${FLEET_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`}
                  style={{
                    borderRadius: 'var(--radii-radii8)',
                    borderWidth: 'var(--sizes-size1)',
                    borderColor: 'var(--colors-gray7)',
                    borderStyle: 'solid',
                    boxShadow: 'var(--shadows-shadow)',
                  }}
                  alt={image.title}
                />
              ))}
            </Box>
          </Stack>
          <Stack
            sx={{
              width: '100%',
              pt: isTablet ? '2.75rem' : 0,
              flexDirection: isTablet ? 'row' : 'column',
              justifyContent: isTablet ? 'flex-start' : 'space-between',
              gap: isTablet ? 64 : 6,
            }}
          >
            <Stack gap={6}>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Make & Model </strong>
                {selectedVehicle?.model}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Vehicle Type </strong>
                {fleetMapping[selectedVehicle?.vehicleType as Fleet] ||
                  selectedVehicle?.vehicleType}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Vehicle Category</strong>
                {selectedVehicle?.vehicleCategory}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Vehicle Capacity </strong>
                <Stack gap={6} direction='column'>
                  <div>
                    {selectedVehicle?.vehicleCapacity?.maxPallets} pallets
                  </div>
                  <div>{selectedVehicle?.vehicleCapacity?.maxKilograms} kg</div>
                  <div>
                    Width:{' '}
                    {
                      selectedVehicle?.vehicleCapacity?.maxCapacityDimensions
                        ?.width
                    }{' '}
                    {
                      selectedVehicle?.vehicleCapacity.maxCapacityDimensions
                        ?.dimensionsUnitOfMeasure
                    }
                  </div>
                  <div>
                    Height:{' '}
                    {
                      selectedVehicle?.vehicleCapacity?.maxCapacityDimensions
                        ?.height
                    }{' '}
                    {
                      selectedVehicle?.vehicleCapacity.maxCapacityDimensions
                        ?.dimensionsUnitOfMeasure
                    }
                  </div>
                  <div>
                    Length:{' '}
                    {
                      selectedVehicle?.vehicleCapacity?.maxCapacityDimensions
                        ?.length
                    }{' '}
                    {
                      selectedVehicle?.vehicleCapacity.maxCapacityDimensions
                        ?.dimensionsUnitOfMeasure
                    }
                  </div>
                </Stack>
              </Text>
            </Stack>
            <Stack gap={6}>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Special Equipment</strong>
                {selectedVehicle?.specialEquipment}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Fuel Type </strong>
                {selectedVehicle?.fuelType}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Emission Standard </strong>
                {selectedVehicle?.emissionStandard}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Average Annual Mileage</strong>
                {selectedVehicle?.averageAnnualMileage}{' '}
                {selectedVehicle?.averageAnnualMileageUnits}
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='contained'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={() => {
            setEditVehicleId(selectedVehicle.vehicleId);
            setOpen(false);
          }}
          fullWidth={!isDesktop}
        >
          Edit
        </Button>
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={() => setOpen(false)}
          fullWidth={!isDesktop}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
