import { styled } from '@stitchesConfig';

export const StyledToggleTheme = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  overflow: 'visible',
  border: 'none',
  fontFamily: '$primary',
  fontWeight: '$medium',
  color: '$gray12',
  backgroundColor: 'transparent',
  padding: '$space4',
  borderRadius: '$radii4',
  transition:
    'background-color 150ms ease-in-out, box-shadow 150ms ease-in-out',
  cursor: 'pointer',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderWidth: '0.0625rem',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',

  '&:hover': {
    boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
  },

  '&:focus': {
    outlineColor: 'transparent',
  },

  '&:focus-visible': {
    outline: '2px solid $gray12',
    outlineOffset: '0.125rem',
  },
});
