/* c8 ignore start */

import {
  gray,
  grayA,
  grayDark,
  grayDarkA,
  tomato,
  tomatoDark,
  blue,
  blueDark,
  green,
  greenDark,
  amber,
  amberDark,
} from '@radix-ui/colors';

import { createStitches } from '@stitches/react';
import type * as Stitches from '@stitches/react';

export const { styled, createTheme, config, keyframes, globalCss, css } =
  createStitches({
    theme: {
      colors: {
        ...gray,
        ...grayA,
        ...tomato,
        ...blue,
        ...green,
        ...amber,
      },
      space: {
        space0: '0',
        space1: '0.0625rem',
        space2: '0.125rem',
        space4: '0.25rem',
        space6: '0.375rem',
        space8: '0.5rem',
        space10: '0.625rem',
        space12: '0.75rem',
        space14: '0.875rem',
        space16: '1rem',
        space20: '1.25rem',
        space24: '1.5rem',
        space28: '1.75rem',
        space32: '2rem',
        space36: '2.25rem',
        space40: '2.5rem',
        space48: '3rem',
        space56: '3.5rem',
        space64: '4rem',
        space80: '5rem',
        space96: '6rem',
        space112: '7rem',
        space128: '8rem',
        space144: '9rem',
        space160: '10rem',
        space176: '11rem',
        space192: '12rem',
        space208: '13rem',
        space224: '14rem',
        space240: '15rem',
        space256: '16rem',
        space288: '18rem',
        space320: '20rem',
        space384: '24rem',
      },
      fontSizes: {
        text2xs: '0.625rem',
        textXs: '0.75rem',
        textSm: '0.875rem',
        textMd: '1rem',
        textLg: '1.125rem',
        textXl: '1.25rem',
        headingXs: '1.5rem',
        headingSm: '1.875rem',
        headingMd: '2.2563rem',
        headingLg: '3rem',
        headingXl: '3.75rem',
        heading2xl: '4.5rem',
      },
      fonts: {
        primary: 'Poppins, sans-serif',
      },
      fontWeights: {
        regular: '400',
        medium: '500',
        semibold: '600',
        bold: '700',
      },
      lineHeights: {
        text2xs: '1rem',
        textXs: '1.125rem',
        textSm: '1.25rem',
        textMd: '1.5rem',
        textLg: '1.75rem',
        textXl: '1.875rem',
        headingXs: '2rem',
        headingSm: '2.375rem',
        headingMd: '2.75rem',
        headingLg: '3.75rem',
        headingXl: '4.5rem',
        heading2xl: '5.625rem',
      },
      sizes: {
        size0: '0',
        size1: '0.0625rem',
        size2: '0.125rem',
        size4: '0.25rem',
        size6: '0.375rem',
        size8: '0.5rem',
        size10: '0.625rem',
        size12: '0.75rem',
        size14: '0.875rem',
        size16: '1rem',
        size20: '1.25rem',
        size24: '1.5rem',
        size28: '1.75rem',
        size32: '2rem',
        size36: '2.25rem',
        size40: '2.5rem',
        size48: '3rem',
        size56: '3.5rem',
        size64: '4rem',
        size80: '5rem',
        size96: '6rem',
        size112: '7rem',
        size128: '8rem',
        size144: '9rem',
        size160: '10rem',
        size176: '11rem',
        size192: '12rem',
        size208: '13rem',
        size224: '14rem',
        size240: '15rem',
        size256: '16rem',
        size288: '18rem',
        size320: '20rem',
        size384: '24rem',
      },
      radii: {
        radii0: '0',
        radii1: '0.0625rem',
        radii2: '0.125rem',
        radii4: '0.25rem',
        radii6: '0.375rem',
        radii8: '0.5rem',
        radii10: '0.625rem',
        radii12: '0.75rem',
        radii14: '0.875rem',
        radii16: '1rem',
        radii20: '1.25rem',
        radii24: '1.5rem',
        radii28: '1.75rem',
        radii32: '2rem',
        radii36: '2.25rem',
        radii40: '2.5rem',
        radii48: '3rem',
        radii56: '3.5rem',
        radii64: '4rem',
        radii80: '5rem',
        radii96: '6rem',
        radii112: '7rem',
        radii128: '8rem',
        radii144: '9rem',
        radii160: '10rem',
        radii176: '11rem',
        radii192: '12rem',
        radii208: '13rem',
        radii224: '14rem',
        radii240: '15rem',
        radii256: '16rem',
        radii288: '18rem',
        radii320: '20rem',
        radii384: '24rem',
      },
      shadows: {
        sm: '0 0.0625rem 0.125rem 0 rgba(17 24 28 / 0.05)',
        shadow:
          '0 0.0625rem 0.1875rem 0 rgba(17 24 28 / 0.1), 0 0.0625rem 0.125rem -0.0625rem rgba(17 24 28 / 0.1)',
        md: '0 0.25rem 0.375rem -0.0625rem rgba(17 24 28 / 0.1), 0 0.125rem 0.25rem -0.125rem rgba(17 24 28 / 0.1)',
        lg: '0 0.625rem 0.9375rem -0.1875rem rgba(17 24 28 / 0.1), 0 0.25rem 0.375rem -0.25rem rgba(17 24 28 / 0.1)',
        xl: '0 1.25rem 1.5625rem -0.3125rem rgba(17 24 28 / 0.1), 0 0.5rem 0.625rem -0.375rem rgba(17 24 28 / 0.1)',
        '2xl': '0 1.5625rem 3.125rem -0.75rem rgba(17 24 28 / 0.1)',
        inner: 'inset 0 0.125rem 0.25rem 0 rgba(17 24 28 / 0.05)',
      },
      zIndices: {},
      transitions: {
        slow: '700ms ease-in-out',
        medium: '300ms ease-in-out',
        fast: '150ms ease-in-out',
      },
    },
    media: {
      xs: '(min-width: 23.4375em)',
      sm: '(min-width: 36em)',
      md: '(min-width: 48em)',
      lg: '(min-width: 62em)',
      xl: '(min-width: 75em)',
      '2xl': '(min-width: 87.5em)',
      '3xl': '(min-width: 100em)',
    },
    utils: {
      px: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      pt: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
      }),
      pb: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
      }),
      pr: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingRight: value,
      }),
      mx: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
        marginBottom: value,
      }),
      mt: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
      }),
      mb: (value: Stitches.PropertyValue<'margin'>) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
      }),
      mr: (value: Stitches.PropertyValue<'margin'>) => ({
        marginRight: value,
      }),
    },
  });

export const darkTheme = createTheme('dark-theme', {
  colors: {
    ...grayDark,
    ...grayDarkA,
    ...tomatoDark,
    ...blueDark,
    ...greenDark,
    ...amberDark,
  },
});

export const globalStyles = globalCss({
  '*': {
    margin: 0,
    padding: 0,
    fontFamily: 'Poppins, sans-serif',
  },

  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },

  html: {
    lineHeight: 1.5,
  },

  body: {
    overflowX: 'hidden',
    backgroundColor: '$gray1',
    color: '$gray12',
  },

  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },

  button: {
    appearance: 'none',
    '-webkit-appearance': 'none',
    userSelect: 'none',
  },

  'input[type="text"]': {
    appearance: 'none',
    '-webkit-appearance': 'none',
  },

  'input[type="email"]': {
    appearance: 'none',
    '-webkit-appearance': 'none',
  },

  'input[type="password"]': {
    appearance: 'none',
    '-webkit-appearance': 'none',
  },

  textarea: {
    appearance: 'none',
    '-webkit-appearance': 'none',
  },
  select: {
    appearance: 'none',
    '-webkit-appearance': 'none',
  },

  'input:-webkit-autofill': {
    backgroundColor: '$gray1',
    color: '$gray12',
  },

  'input:-webkit-autofill-strong-password': {
    backgroundColor: '$gray1',
    color: '$gray12',
  },

  'input:-webkit-autofill-strong-password-viewable': {
    backgroundColor: '$gray1',
    color: '$gray12',
  },
});

/* c8 ignore stop */
