import { ReactNode, useEffect, useState } from 'react';
import { Heading } from '@components/Heading';
import {
  Board,
  BoardLane,
  BoardLaneHeader,
  BoardLaneTitleContainer,
  BoardLaneTitleButton,
  BoardLaneItemCount,
} from '@components/Board';
import { View } from '@views/View';
import InfiniteScroll from 'react-infinite-scroller';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoading,
  InfiniteScrollErrorMessage,
} from '@components/InfiniteScroll';
import {
  InboundSupplyChainProcessEventList,
  OutboundSupplyChainProcessEventList,
  SupplyChainProcessEventCard,
} from '@components/SupplyChainProcessEventList';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import { CreateDraftGoodsReceivedNoteForm } from '@forms/manager/CreateDraftGoodsReceivedNoteForm';
import { SignOffGoodsReceivedNoteForm } from '@forms/manager/SignOffGoodsReceivedNoteForm';
import { ConfirmPickingNoteForm } from '@forms/manager/ConfirmPickingNoteForm';
import { CreateOutboundShipmentNoteForm } from '@forms/manager/CreateOutboundShipmentNoteForm';
import {
  useInboundProcess,
  useGoodsReceivedNote,
  useOutboundProcess,
  usePickingNote,
  useOutboundShipmentNote,
  useProofOfDelivery,
  useAuthoriseForPicking,
} from '@hooks';
import { useAtom, useSetAtom } from 'jotai';
import { CreateProofOfDeliveryForm } from '@forms/manager/CreateProofOfDeliveryForm';
import { DialogModal } from '@components/DialogModal';
import { AuthoriseForPickingForm } from '@forms/manager/AuthoriseForPickingForm';
import { WarehouseClientsSelector } from '@components/WarehouseClientSelector';
import { WarehouseSelector } from '@components/WarehouseSelector';
import Stack from '@mui/material/Stack';
import { ShipmentNoteDetails } from './ShipmentNoteDetails';
import { WarehouseManagerInboundSideDrawer } from './WarehouseManagerInboundSideDrawer';
import { WarehouseManagerOutboundSideDrawer } from './WarehouseManagerOutboundSideDrawer';
import { DespatchNoteDetails } from './DespatchNoteDetails';
import { GoodsReceivedNoteDetails } from './GoodsReceivedNoteDetails';
import { PickingConfirmationDetails } from './PickingConfirmationDetails';
import { OutboundShipmentNoteDetails } from './OutboundShipmentNoteDetails';
import { ProofOfDeliveryDetails } from './ProofOfDeliveryDetails';
import {
  isInboundSidebarOpenAtom,
  isOutboundSidebarOpenAtom,
  selectedInboundProcessIdAtom,
  selectedOutboundProcessIdAtom,
} from '../../../store/jotai';

export function WarehouseManagerNoticeboard() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const [isInboundSidebarOpen, setIsInboundSidebarOpen] = useAtom(
    isInboundSidebarOpenAtom
  );
  const [isOutboundSidebarOpen, setIsOutboundSidebarOpen] = useAtom(
    isOutboundSidebarOpenAtom
  );
  const setSelectedInboundProcessId = useSetAtom(selectedInboundProcessIdAtom);
  const setSelectedOutboundProcessId = useSetAtom(
    selectedOutboundProcessIdAtom
  );
  const searchParams = new URLSearchParams(window.location.search);

  const supplyChainProcessId = searchParams.get('supplyChainProcessId');
  const processChildType = searchParams.get('processChildType');

  useDocumentTitle('FLOX - Noticeboard');

  const {
    inboundProcesses,
    fetchInboundSupplyChainProcesses,
    hasMoreInboundData,
    isLoadingInboundProcessesError,
    selectedInboundProcess,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    getInboundScrollParent,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    selectedProcessChildType,
    setSelectedProcessChildType,
  } = useInboundProcess();

  const {
    isCreateDraftGoodsReceivedNoteFormOpen,
    setIsCreateDraftGoodsReceivedNoteFormOpen,
    handleCloseCreateDraftGoodsReceivedNoteForm,
    handleSubmitCreateDraftGoodsReceivedNote,
    isSignOffGoodsReceivedNoteFormOpen,
    setIsSignOffGoodsReceivedNoteFormOpen,
    handleCloseSignOffGoodsReceivedNoteForm,
    handleSubmitSignOffGoodsReceivedNote,
  } = useGoodsReceivedNote();

  const {
    outboundProcesses,
    fetchOutboundSupplyChainProcesses,
    hasMoreOutboundData,
    isLoadingOutboundProcessesError,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    getOutboundScrollParent,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  } = useOutboundProcess();

  const {
    isAuthoriseForPickingFormOpen,
    setIsAuthoriseForPickingFormOpen,
    handleCloseAuthoriseForPickingForm,
    handleSubmitAuthoriseForPickingForm,
  } = useAuthoriseForPicking();

  const {
    isConfirmPickingNoteFormOpen,
    setIsConfirmPickingNoteFormOpen,
    handleCloseConfirmPickingNoteForm,
    handleSubmitConfirmPickingNote,
  } = usePickingNote();

  const {
    isCreateOutboundShipmentNoteFormOpen,
    setIsCreateOutboundShipmentNoteFormOpen,
    handleCloseCreateOutboundShipmentNoteForm,
    handleSubmitOutboundShipmentNote,
  } = useOutboundShipmentNote();

  const {
    isCreateProofOfDeliveryFormOpen,
    setIsCreateProofOfDeliveryFormOpen,
    handleCloseCreateProofOfDeliveryForm,
    handleSubmitCreateProofOfDelivery,
  } = useProofOfDelivery();

  const clearProcesses = () => {
    setSelectedInboundProcess(null);
    setSelectedOutboundProcess(null);
    setSelectedInboundProcessId('');
    setSelectedOutboundProcessId('');
    setIsInboundSidebarOpen(false);
    setIsOutboundSidebarOpen(false);
  };

  useEffect(() => {
    clearProcesses();
    if (!inboundProcesses && !outboundProcesses) return;
    if (supplyChainProcessId) {
      const inboundProcess = inboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );
      const outboundProcess = outboundProcesses.find(
        (process) => process.id === supplyChainProcessId
      );

      if (inboundProcess) {
        setSelectedInboundProcess(inboundProcess);
        setSelectedInboundProcessId(inboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsInboundSidebarOpen(true);
          setIsViewShipmentNoteDialogOpen(true);
        }
      } else if (outboundProcess) {
        setSelectedOutboundProcess(outboundProcess);
        setSelectedOutboundProcessId(outboundProcess.id);
        if (processChildType) {
          if (processChildType === 'PickingNoteConfirmed') {
            setIsViewPickingConfirmationDialogOpen(true);
          } else if (processChildType === 'GoodsReceivedNoteFinalised') {
            setIsViewGoodsReceivedNoteDialogOpen(true);
          }
        } else {
          setIsOutboundSidebarOpen(true);
          setIsViewDespatchNoteDialogOpen(true);
        }
      }
    }
  }, [
    supplyChainProcessId,
    processChildType,
    inboundProcesses,
    outboundProcesses,
  ]);

  return (
    <View>
      <Stack
        direction={isTablet ? 'row' : 'column'}
        gap={16}
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Noticeboard
        </Heading>
        <WarehouseSelector />
        <WarehouseClientsSelector />
      </Stack>
      <Board>
        {/* Inbound */}
        <BoardLane>
          <BoardLaneHeader>
            <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
              <BoardLaneTitleButton>Inbound shipments</BoardLaneTitleButton>
            </BoardLaneTitleContainer>
            <BoardLaneItemCount itemCount={inboundProcesses.length} />
          </BoardLaneHeader>
          <InfiniteScroll
            element={InboundSupplyChainProcessEventList as unknown as ReactNode}
            initialLoad
            loadMore={fetchInboundSupplyChainProcesses}
            hasMore={
              hasMoreInboundData === null ||
              (hasMoreInboundData === true && !isLoadingInboundProcessesError)
            }
            useWindow={false}
            getScrollParent={getInboundScrollParent}
            loader={
              <InfiniteScrollLoading
                key='inbound-loader'
                loadingMessage='Loading inbound items...'
              />
            }
          >
            {inboundProcesses.map((inboundProcess) => (
              <SupplyChainProcessEventCard
                key={inboundProcess.id}
                inboundProcess={inboundProcess}
                onClick={() => {
                  setSelectedInboundProcess(inboundProcess);
                  setSelectedInboundProcessId(inboundProcess.id);
                  setIsInboundSidebarOpen(true);
                  if (isOutboundSidebarOpen) {
                    setIsOutboundSidebarOpen(false);
                  }
                }}
              />
            ))}
            {hasMoreInboundData === false && (
              <InfiniteScrollEndMessage
                isVisible={inboundProcesses.length > 25}
                direction='column'
                endMessageText='End of inbound items'
                handleScrollToTop={handleScrollToTopOfInbound}
              />
            )}
            {isLoadingInboundProcessesError && (
              <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your inbound items.' />
            )}
          </InfiniteScroll>
        </BoardLane>
        {/* Outbound */}
        <BoardLane>
          <BoardLaneHeader>
            <BoardLaneTitleContainer sx={{ my: '0.3359rem' }}>
              <BoardLaneTitleButton>Outbound shipments</BoardLaneTitleButton>
            </BoardLaneTitleContainer>
            <BoardLaneItemCount itemCount={outboundProcesses.length} />
          </BoardLaneHeader>
          <InfiniteScroll
            element={
              OutboundSupplyChainProcessEventList as unknown as ReactNode
            }
            initialLoad
            loadMore={fetchOutboundSupplyChainProcesses}
            hasMore={
              hasMoreOutboundData === null ||
              (hasMoreOutboundData === true && !isLoadingOutboundProcessesError)
            }
            useWindow={false}
            getScrollParent={getOutboundScrollParent}
            loader={
              <InfiniteScrollLoading
                key='outbound-loader'
                loadingMessage='Loading outbound items...'
              />
            }
          >
            {outboundProcesses.length > 0 &&
              outboundProcesses.map((outboundProcess) => (
                <SupplyChainProcessEventCard
                  key={outboundProcess.id}
                  outboundProcess={outboundProcess}
                  onClick={() => {
                    setSelectedOutboundProcess(outboundProcess);
                    setSelectedOutboundProcessId(outboundProcess.id);
                    setIsOutboundSidebarOpen(true);
                    if (isInboundSidebarOpen) {
                      setIsInboundSidebarOpen(false);
                    }
                  }}
                />
              ))}
            {hasMoreOutboundData === false && (
              <InfiniteScrollEndMessage
                isVisible={outboundProcesses.length > 25}
                direction='column'
                endMessageText='End of outbound items'
                handleScrollToTop={handleScrollToTopOfOutbound}
              />
            )}
            {isLoadingOutboundProcessesError && (
              <InfiniteScrollErrorMessage errorMessage='Sorry, there was an error fetching your outbound items.' />
            )}
          </InfiniteScroll>
        </BoardLane>
        {/* Temporary empty board lanes until we define them */}
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
        <div style={{ width: '100%', minWidth: '13rem' }} />
      </Board>
      {/* Sidebar for selected inbound process */}
      {selectedInboundProcess && (
        <WarehouseManagerInboundSideDrawer
          selectedInboundProcess={selectedInboundProcess}
          setSelectedInboundProcess={setSelectedInboundProcess}
          selectedInboundProcessHasDescription={
            selectedInboundProcessHasDescription
          }
          isOpen={isInboundSidebarOpen}
          setIsOpen={setIsInboundSidebarOpen}
          isDesktop={isDesktop}
          setIsViewShipmentNoteDialogOpen={setIsViewShipmentNoteDialogOpen}
          setIsSignOffGoodsReceivedNoteFormOpen={
            setIsSignOffGoodsReceivedNoteFormOpen
          }
          setIsCreateDraftGoodsReceivedNoteFormOpen={
            setIsCreateDraftGoodsReceivedNoteFormOpen
          }
          setIsViewGoodsReceivedNoteDialogOpen={
            setIsViewGoodsReceivedNoteDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* Sidebar for outbound process */}
      {selectedOutboundProcess && (
        <WarehouseManagerOutboundSideDrawer
          selectedOutboundProcess={selectedOutboundProcess}
          setSelectedOutboundProcess={setSelectedOutboundProcess}
          selectedOutboundProcessHasDescription={
            selectedOutboundProcessHasDescription
          }
          isOpen={isOutboundSidebarOpen}
          setIsOpen={setIsOutboundSidebarOpen}
          isDesktop={isDesktop}
          setIsViewDespatchNoteDialogOpen={setIsViewDespatchNoteDialogOpen}
          setIsAuthoriseForPickingFormOpen={setIsAuthoriseForPickingFormOpen}
          setIsConfirmPickingNoteFormOpen={setIsConfirmPickingNoteFormOpen}
          setIsViewPickingConfirmationDialogOpen={
            setIsViewPickingConfirmationDialogOpen
          }
          setIsCreateOutboundShipmentNoteFormOpen={
            setIsCreateOutboundShipmentNoteFormOpen
          }
          setIsViewOutboundShipmentNoteDialogOpen={
            setIsViewOutboundShipmentNoteDialogOpen
          }
          setIsCreateProofOfDeliveryFormOpen={
            setIsCreateProofOfDeliveryFormOpen
          }
          setIsViewProofOfDeliveryDialogOpen={
            setIsViewProofOfDeliveryDialogOpen
          }
          selectedProcessChildType={selectedProcessChildType}
          setSelectedProcessChildType={setSelectedProcessChildType}
        />
      )}
      {/* View shipment note */}
      <DialogModal
        open={isViewShipmentNoteDialogOpen}
        setOpen={setIsViewShipmentNoteDialogOpen}
        onClose={handleCloseViewShipmentNote}
        title='Shipment Note'
        id='view-shipment-note-form-title'
      >
        <ShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewShipmentNoteDialogOpen(false)}
          primaryAction={() => {
            setIsViewShipmentNoteDialogOpen(false);
            setIsCreateDraftGoodsReceivedNoteFormOpen(true);
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create draft goods received note form */}
      <DialogModal
        open={isCreateDraftGoodsReceivedNoteFormOpen}
        setOpen={setIsCreateDraftGoodsReceivedNoteFormOpen}
        onClose={handleCloseCreateDraftGoodsReceivedNoteForm}
        title='Create Draft Goods Received Note'
        id='create-draft-goods-received-note-form-title'
      >
        <CreateDraftGoodsReceivedNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsCreateDraftGoodsReceivedNoteFormOpen(false);
          }}
          onSubmit={handleSubmitCreateDraftGoodsReceivedNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Sign off goods received note form */}
      <DialogModal
        open={isSignOffGoodsReceivedNoteFormOpen}
        setOpen={setIsSignOffGoodsReceivedNoteFormOpen}
        onClose={handleCloseSignOffGoodsReceivedNoteForm}
        title='Draft Goods Received Note'
        id='sign-off-goods-received-note-form-title'
      >
        <SignOffGoodsReceivedNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => {
            setIsSignOffGoodsReceivedNoteFormOpen(false);
          }}
          onSubmit={handleSubmitSignOffGoodsReceivedNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View despatch note details */}
      <DialogModal
        open={isViewDespatchNoteDialogOpen}
        setOpen={setIsViewDespatchNoteDialogOpen}
        onClose={handleCloseViewDespatchNote}
        title='Despatch Note'
        id='view-despatch-note-form-title'
      >
        <DespatchNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewDespatchNoteDialogOpen(false)}
          primaryAction={() => {
            setIsViewDespatchNoteDialogOpen(false);
            if (
              !selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking
            ) {
              setIsAuthoriseForPickingFormOpen(true);
            } else if (
              selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking &&
              !selectedOutboundProcess?.pickingConfirmation
            ) {
              setIsConfirmPickingNoteFormOpen(true);
            }
          }}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View goods received note details */}
      <DialogModal
        open={isViewGoodsReceivedNoteDialogOpen}
        setOpen={setIsViewGoodsReceivedNoteDialogOpen}
        onClose={handleCloseViewGoodsReceivedNote}
        title='Goods Received Note'
        id='view-goods-received-note-form-title'
      >
        <GoodsReceivedNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedInboundProcess={selectedInboundProcess!}
          onClose={() => setIsViewGoodsReceivedNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Authorise for picking form */}
      <DialogModal
        open={isAuthoriseForPickingFormOpen}
        setOpen={setIsAuthoriseForPickingFormOpen}
        onClose={handleCloseAuthoriseForPickingForm}
        title='Authorise For Picking'
        id='authorise-for-picking-form-title'
      >
        <AuthoriseForPickingForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsAuthoriseForPickingFormOpen(false)}
          onSubmit={handleSubmitAuthoriseForPickingForm}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Confirm picking note form */}
      <DialogModal
        open={isConfirmPickingNoteFormOpen}
        setOpen={setIsConfirmPickingNoteFormOpen}
        onClose={handleCloseConfirmPickingNoteForm}
        title='Confirm Picking'
        id='confirm-picking-note-form-title'
      >
        <ConfirmPickingNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsConfirmPickingNoteFormOpen(false);
          }}
          onSubmit={handleSubmitConfirmPickingNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View picking confirmation */}
      <DialogModal
        open={isViewPickingConfirmationDialogOpen}
        setOpen={setIsViewPickingConfirmationDialogOpen}
        onClose={handleCloseViewPickingConfirmation}
        title='Picking Confirmation'
        id='view-picking-confirmation-form-title'
      >
        <PickingConfirmationDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewPickingConfirmationDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create outbound shipment note form */}
      <DialogModal
        open={isCreateOutboundShipmentNoteFormOpen}
        setOpen={setIsCreateOutboundShipmentNoteFormOpen}
        onClose={handleCloseCreateOutboundShipmentNoteForm}
        title='Create Outbound Shipment Note'
        id='create-outbound-shipment-note-form-title'
      >
        <CreateOutboundShipmentNoteForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsCreateOutboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitOutboundShipmentNote}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View outbound shipment note */}
      <DialogModal
        open={isViewOutboundShipmentNoteDialogOpen}
        setOpen={setIsViewOutboundShipmentNoteDialogOpen}
        onClose={handleCloseViewOutboundShipmentNote}
        title='Outbound Shipment Note'
        id='view-outbound-shipment-note-form-title'
      >
        <OutboundShipmentNoteDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewOutboundShipmentNoteDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* Create proof of delivery form */}
      <DialogModal
        open={isCreateProofOfDeliveryFormOpen}
        setOpen={setIsCreateProofOfDeliveryFormOpen}
        onClose={handleCloseCreateProofOfDeliveryForm}
        title='Create Proof Of Delivery'
        id='create-proof-of-delivery-form-title'
      >
        <CreateProofOfDeliveryForm
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => {
            setIsCreateOutboundShipmentNoteFormOpen(false);
          }}
          onSubmit={handleSubmitCreateProofOfDelivery}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
      {/* View proof of delivery */}
      <DialogModal
        open={isViewProofOfDeliveryDialogOpen}
        setOpen={setIsViewProofOfDeliveryDialogOpen}
        onClose={handleCloseViewProofOfDelivery}
        title='Proof Of Delivery'
        id='view-proof-of-delivery-form-title'
      >
        <ProofOfDeliveryDetails
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedOutboundProcess={selectedOutboundProcess!}
          onClose={() => setIsViewProofOfDeliveryDialogOpen(false)}
          isDesktop={isDesktop}
          isTablet={isTablet}
        />
      </DialogModal>
    </View>
  );
}

WarehouseManagerNoticeboard.displayName = 'WarehouseManagerNoticeboard';
