import { styled } from '@stitchesConfig';

export const StyledButton = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  overflow: 'visible',
  border: 'none',
  fontFamily: '$primary',
  fontWeight: '$medium',
  color: '$gray12',
  transition:
    'background-color 150ms ease-in-out, box-shadow 150ms ease-in-out',
  cursor: 'pointer',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderWidth: '$space$space2',

  '&:focus': {
    outlineColor: 'transparent',
  },

  '&:focus-visible': {
    outline: '2px solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:active': {
    outlineColor: 'transparent',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.5',
  },

  variants: {
    variant: {
      primary: {
        backgroundColor: '$gray3',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray4',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray5',
        },
      },
      secondary: {
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray2',
          boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray3',
        },
      },
      link: {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        textDecorationColor: 'transparent',
        transition:
          'background-color 150ms ease-in-out, box-shadow 150ms ease-in-out, text-decoration-color 150ms ease-in-out',
        '&:hover:not(:disabled), &:active:not(:disabled)': {
          textDecorationColor: '$gray12',
        },
      },
    },
    size: {
      xs: {
        fontSize: '$text2xs',
        lineHeight: '$text2xs',
        padding: '$space4 $space8',
        height: '$size16',
        gap: '$space4',
        borderRadius: '$radii4',
      },
      sm: {
        fontSize: '$textSm',
        lineHeight: '$textSm',
        padding: '$space8 $space12',
        height: '$size20',
        gap: '$space4',
        borderRadius: '$radii4',
      },
      md: {
        fontSize: '$textMd',
        lineHeight: '$space24',
        padding: '$space12 $space16',
        height: '$size24',
        gap: '$space8',
        borderRadius: '$radii8',
      },
      lg: {
        fontSize: '$textLg',
        lineHeight: '$textLg',
        padding: '$space16 $space24',
        height: '$size28',
        gap: '$space12',
        borderRadius: '$radii12',
      },
    },
    action: {
      default: {
        backgroundColor: '$gray3',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray4',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray5',
        },
      },
      cta: {
        color: '$gray1',
        backgroundColor: '$gray9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray11',
        },
      },
      danger: {
        color: '$tomato1',
        backgroundColor: '$tomato9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$tomato10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$tomato11',
        },
      },
    },
    isFullWidth: {
      true: {
        width: '100%',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      action: 'default',
      css: {
        color: '$gray12',
        backgroundColor: '$gray3',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray4',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray5',
        },
      },
    },
    {
      variant: 'secondary',
      action: 'default',
      css: {
        color: '$gray12',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray2',
          boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray3',
        },
      },
    },
    {
      variant: 'link',
      action: 'default',
      css: {
        color: '$gray12',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray2',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray3',
        },
        '&:hover:not(:disabled), &:active:not(:disabled)': {
          textDecorationColor: '$gray12',
        },
      },
    },
    {
      variant: 'primary',
      action: 'cta',
      css: {
        color: '$gray1',
        backgroundColor: '$gray9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray11',
        },
        '.dark-theme &': {
          color: '$gray12',
        },
      },
    },
    {
      variant: 'secondary',
      action: 'cta',
      css: {
        color: '$gray12',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 $$borderWidth $colors$gray9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray2',
          boxShadow: 'inset 0 0 0 $$borderWidth $colors$gray10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray3',
          boxShadow: 'inset 0 0 0 $$borderWidth $colors$gray11',
        },
      },
    },
    {
      variant: 'link',
      action: 'cta',
      css: {
        color: '$gray12',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          backgroundColor: '$gray2',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$gray3',
        },
        '&:hover:not(:disabled), &:active:not(:disabled)': {
          textDecorationColor: '$gray12',
        },
      },
    },
    {
      variant: 'primary',
      action: 'danger',
      css: {
        color: '$tomato1',
        backgroundColor: '$tomato9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$tomato10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$tomato11',
        },
      },
    },
    {
      variant: 'secondary',
      action: 'danger',
      css: {
        color: '$tomato9',
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 $$borderWidth $colors$tomato9',
        '&:hover:not(:disabled)': {
          backgroundColor: '$tomato2',
          boxShadow: 'inset 0 0 0 $$borderWidth $colors$tomato10',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$tomato3',
          boxShadow: 'inset 0 0 0 $$borderWidth $colors$tomato11',
        },
      },
    },
    {
      variant: 'link',
      action: 'danger',
      css: {
        color: '$tomato9',
        backgroundColor: 'transparent',
        '&:hover:not(:disabled)': {
          backgroundColor: '$tomato2',
        },
        '&:active:not(:disabled)': {
          backgroundColor: '$tomato3',
        },
        '&:hover:not(:disabled), &:active:not(:disabled)': {
          textDecorationColor: '$tomato9',
        },
      },
    },
    //
    {
      variant: 'link',
      size: 'xs',
      css: {
        padding: '$space0 $space2',
        borderRadius: '$radii4',
      },
    },
    {
      variant: 'link',
      size: 'sm',
      css: {
        padding: '$space0 $space4',
        borderRadius: '$radii4',
      },
    },
    {
      variant: 'link',
      size: 'md',
      css: {
        padding: '$space0 $space8',
        borderRadius: '$radii8',
      },
    },
    {
      variant: 'link',
      size: 'lg',
      css: {
        padding: '$space0 $space12',
        borderRadius: '$radii12',
      },
    },
    {
      variant: 'primary',
      size: 'xs',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size16)`,
      },
    },
    {
      variant: 'primary',
      size: 'sm',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size24)`,
      },
    },
    {
      variant: 'primary',
      size: 'md',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size32)`,
      },
    },
    {
      variant: 'primary',
      size: 'lg',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size48)`,
      },
    },
    {
      variant: 'secondary',
      size: 'xs',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size16)`,
      },
    },
    {
      variant: 'secondary',
      size: 'sm',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size24)`,
      },
    },
    {
      variant: 'secondary',
      size: 'md',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size32)`,
      },
    },
    {
      variant: 'secondary',
      size: 'lg',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size48)`,
      },
    },
    {
      variant: 'link',
      size: 'xs',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size4)`,
      },
    },
    {
      variant: 'link',
      size: 'sm',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size8)`,
      },
    },
    {
      variant: 'link',
      size: 'md',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size16)`,
      },
    },
    {
      variant: 'link',
      size: 'lg',
      isFullWidth: true,
      css: {
        width: `calc(100% - $size24)`,
      },
    },
  ],
  defaultVariants: {
    variant: 'primary',
    action: 'default',
    size: 'md',
  },
});

StyledButton.displayName = 'StyledButton';
