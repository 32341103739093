import type * as Stitches from '@stitches/react';
import { ComponentPropsWithoutRef } from 'react';
import { StyledStack } from './Stack.styles';

interface StackProps extends ComponentPropsWithoutRef<'div'> {
  direction?: 'horizontal' | 'vertical';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  isInvalid?: boolean;
  css?: Stitches.CSS;
}

export function Stack({
  children,
  direction = 'horizontal',
  justifyContent,
  alignItems,
  isInvalid,
  css,
  ...props
}: StackProps) {
  return (
    <StyledStack
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      isInvalid={isInvalid}
      css={css}
      {...props}
    >
      {children}
    </StyledStack>
  );
}
