import { InboundProcess } from '@features/supplyChain/types';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  supplyChainProcessId: Yup.string().required(),
  goodsReceivedNoteId: Yup.string().required(),
});

export const makeDefaultValues = (
  selectedInboundProcess: InboundProcess | null
) => {
  if (!selectedInboundProcess) {
    return {
      supplyChainProcessId: '',
      goodsReceivedNoteId: '',
    };
  }
  return {
    supplyChainProcessId: selectedInboundProcess.id,
    goodsReceivedNoteId: selectedInboundProcess.goodsReceivedNote?.id,
  };
};
