import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

interface InfiniteScrollErrorMessageProps {
  errorMessage?: string;
  direction?: 'row' | 'column';
  sx?: SxProps;
}

export function InfiniteScrollErrorMessage({
  errorMessage = 'There was an error.',
  direction = 'column',
  sx,
}: InfiniteScrollErrorMessageProps) {
  return (
    <Box
      sx={{
        flex: '1',
        textAlign: 'center',
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        ...sx,
      }}
    >
      <ErrorOutline
        sx={{ width: 'var(--sizes-size32)', height: 'var(--sizes-size32)' }}
      />
      {errorMessage}
    </Box>
  );
}
