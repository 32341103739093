import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { StyledErrorMessage } from './ErrorMessage.styles';

interface ErrorMessageProps extends ComponentPropsWithoutRef<'span'> {
  css?: Stitches.CSS;
}

export function ErrorMessage({ children, css, ...props }: ErrorMessageProps) {
  return (
    <StyledErrorMessage css={css} {...props}>
      {children}
    </StyledErrorMessage>
  );
}

ErrorMessage.displayName = 'ErrorMessage';
