export const fixedPalletisingOptions = [
  {
    id: 'Mixed GB Pallet',
    type: 'Mixed GB Pallet',
    customPalletSpecification: null,
    amountOfLayersInPallet: 0,
    amountOfSkusInLayer: 0,
    dimensions: {
      width: null,
      length: null,
      height: null,
      dimensionsUnitOfMeasure: '',
    },
  },
  {
    id: 'Mixed EU Pallet',
    type: 'Mixed EU Pallet',
    customPalletSpecification: null,
    amountOfLayersInPallet: 0,
    amountOfSkusInLayer: 0,
    dimensions: {
      width: null,
      length: null,
      height: null,
      dimensionsUnitOfMeasure: '',
    },
  },
  {
    id: 'Unpalletised',
    type: 'Unpalletised',
    customPalletSpecification: null,
    amountOfLayersInPallet: 0,
    amountOfSkusInLayer: 0,
    dimensions: {
      width: null,
      length: null,
      height: null,
      dimensionsUnitOfMeasure: '',
    },
  },
  {
    id: 'Other',
    type: 'Other',
    customPalletSpecification: null,
    amountOfLayersInPallet: 0,
    amountOfSkusInLayer: 0,
    dimensions: {
      width: null,
      length: null,
      height: null,
      dimensionsUnitOfMeasure: '',
    },
  },
];
