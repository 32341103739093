import { Stack } from '@mui/material';
import { Text } from '@components/Text';
import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import { getDayDifference } from '@utils';

interface ProofOfDeliverySummaryProps {
  selectedOutboundProcess: OutboundProcess;
  totalPODItemsQuantity: number | undefined;
  isPODDiscrepancy: boolean;
  podDiscrepancyAmount: number;
  isNegativePODDiscrepancy: boolean;
}

export function ProofOfDeliverySummary({
  selectedOutboundProcess,
  totalPODItemsQuantity,
  isPODDiscrepancy,
  podDiscrepancyAmount,
  isNegativePODDiscrepancy,
}: ProofOfDeliverySummaryProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { requiredTimeOfArrival } = selectedOutboundProcess.despatchNote!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { dateOfArrival } = selectedOutboundProcess.goodsReceivedNote!;

  const deliveryDayDifference = getDayDifference(
    requiredTimeOfArrival as string,
    dateOfArrival as string
  );

  const isLate = deliveryDayDifference < 0;
  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Received by: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.userBusinessName}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Proof of Delivery #: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.goodsReceivedNote?.grnNoteNumber}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>POD date: </strong>
        </Text>
        <Text as='span' size='xs'>
          {dayjs(
            selectedOutboundProcess?.goodsReceivedNote?.dateOfArrival
          ).format('DD/MM/YYYY, H:mm')}
          {isLate && (
            <Text as='span' size='xs' css={{ color: 'var(--colors-tomato9)' }}>
              {` (+${deliveryDayDifference.toString().substring(1)} ${
                deliveryDayDifference.toString().substring(1) === '1'
                  ? 'day'
                  : 'days'
              })`}
            </Text>
          )}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>SKUs: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.goodsReceivedNote?.skus.length}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Total Quantity (Items): </strong>
        </Text>
        <Text size='xs'>
          {totalPODItemsQuantity}{' '}
          {isPODDiscrepancy && (
            <Text
              as='span'
              size='xs'
              css={{
                color: isNegativePODDiscrepancy
                  ? 'var(--colors-tomato9)'
                  : 'var(--colors-gray12)',
              }}
            >
              {`(${
                isNegativePODDiscrepancy ? '-' : '+'
              }${podDiscrepancyAmount})`}
            </Text>
          )}
        </Text>
      </Stack>
      {selectedOutboundProcess.goodsReceivedNote?.comment && (
        <Stack gap={4}>
          <Text as='h3' size='xs'>
            <strong>Comment</strong>
          </Text>
          <Text size='xs'>
            {selectedOutboundProcess.goodsReceivedNote.comment}
          </Text>
        </Stack>
      )}
    </>
  );
}
