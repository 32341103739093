import { styled } from '@stitchesConfig';

export const StyledErrorMessage = styled('span', {
  fontSize: '$textSm',
  lineHeight: '$textSm',
  fontWeight: '$medium',
  color: '$tomato9',
  pl: '$space16',
});

StyledErrorMessage.displayName = 'StyledErrorMessage';
