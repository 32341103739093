import { Stack } from '@components/Stack';
import type {
  RadioGroupProps,
  RadioGroupIndicatorProps,
  RadioGroupItemProps,
} from '@radix-ui/react-radio-group';
import * as LabelPrimitive from '@radix-ui/react-label';
import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import {
  StyledRadioGroup,
  StyledRadioCard,
  StyledRadioItem,
  StyledRadioIndicator,
} from './Radio.styles';

interface IRadioGroupProps extends RadioGroupProps {
  isInvalid?: boolean;
  css?: Stitches.CSS;
}

export function RadioGroup({
  children,
  asChild = false,
  defaultValue,
  value,
  onValueChange,
  disabled,
  name,
  required,
  orientation = 'horizontal',
  dir,
  loop = true,
  isInvalid,
  css,
  ...props
}: IRadioGroupProps) {
  return (
    <StyledRadioGroup
      asChild={asChild}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
      {...(props.onBlur && { onBlur: props.onBlur })}
      disabled={disabled}
      name={name}
      required={required}
      orientation={orientation}
      dir={dir}
      loop={loop}
    >
      <Stack direction={orientation} isInvalid={isInvalid} css={css}>
        {children}
      </Stack>
    </StyledRadioGroup>
  );
}

interface RadioCardProps
  extends ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  htmlFor: string;
  disabled?: boolean;
  css?: Stitches.CSS;
}

export function RadioCard({
  children,
  htmlFor,
  disabled = false,
  css,
  ...props
}: RadioCardProps) {
  return (
    <StyledRadioCard htmlFor={htmlFor} disabled={disabled} css={css} {...props}>
      {children}
    </StyledRadioCard>
  );
}

export function RadioIndicator({ forceMount }: RadioGroupIndicatorProps) {
  return <StyledRadioIndicator forceMount={forceMount} />;
}

RadioIndicator.displayName = 'RadioIndicator';

interface RadioItemProps extends RadioGroupItemProps {
  css?: Stitches.CSS;
}

export function RadioItem({
  asChild = false,
  id,
  value,
  disabled,
  required,
  css,
}: RadioItemProps) {
  return (
    <StyledRadioItem
      asChild={asChild}
      id={id}
      value={value}
      disabled={disabled}
      required={required}
      css={css}
    >
      <RadioIndicator />
    </StyledRadioItem>
  );
}

RadioItem.displayName = 'Radio';
