import { SelectChangeEvent } from '@mui/material';
import { ShipmentType, Status } from '@views/Shipments/types';
import { Dayjs } from 'dayjs';
import { ChangeEvent, useState } from 'react';

export const useShipmentFilter = () => {
  const [isFilterContainerOpen, setIsFilterContainerOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [shipmentType, setShipmentType] = useState<ShipmentType>('Inbound');
  const [selectedStatus, setSelectedStatus] = useState<Status>('All');
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<Dayjs | null>(null);
  const [exceptions, setExceptions] = useState(false);

  const handleToggleFilterContainerOpen = () =>
    setIsFilterContainerOpen((prev) => !prev);

  const handleChangeSearchFilter = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchFilter(event.target.value);

  const handleChangeShipmentType = (event: SelectChangeEvent) =>
    setShipmentType(event.target.value as ShipmentType);

  const handleChangeStatus = (event: SelectChangeEvent) =>
    setSelectedStatus(event.target.value as Status);

  const handleChangeDateFrom = (value: Dayjs | null) => setDateFrom(value);

  const handleChangeDateTo = (value: Dayjs | null) => setDateTo(value);

  const handleToggleExceptions = () => setExceptions((prev) => !prev);

  return {
    searchFilter,
    handleChangeSearchFilter,
    shipmentType,
    handleChangeShipmentType,
    selectedStatus,
    handleChangeStatus,
    dateFrom,
    handleChangeDateFrom,
    dateTo,
    handleChangeDateTo,
    exceptions,
    handleToggleExceptions,
    isFilterContainerOpen,
    handleToggleFilterContainerOpen,
  };
};
