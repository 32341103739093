import {
  AVAILABLE_WAREHOUSES_URL,
  CONTRACTED_MATCH_LIST,
  LSP_WAREHOUSES_URL,
} from '@constants';
import { AxiosInstance } from 'axios';
import {
  WarehouseOption,
  WarehouseOptionsShipperResponse,
  WarehouseOptionsLspResponse,
  WarehouseClientOptionResponse,
  WarehouseClientOption,
} from '@hooks';

export const fetchShipperWarehouses = async (
  authHttp: AxiosInstance
): Promise<WarehouseOption[]> => {
  const {
    data: { matches },
  } = await authHttp.post<WarehouseOptionsShipperResponse>(
    AVAILABLE_WAREHOUSES_URL,
    {
      searchQuery: '',
      pagination: {
        lastItem: null,
      },
      itemsPerPage: 100,
    }
  );

  return matches.map((match) => ({
    warehouseId: match.match.warehouseId,
    warehouseName: match.match.warehouseName,
  }));
};

export const fetchLspWarehouses = async (
  authHttp: AxiosInstance
): Promise<WarehouseOption[]> => {
  const {
    data: { warehouses },
  } = await authHttp.post<WarehouseOptionsLspResponse>(LSP_WAREHOUSES_URL, {
    searchQuery: '',
    isGettingFromAllBusinesses: true,
    pagination: {
      lastItem: null,
      itemsPerPage: 100,
    },
  });

  return warehouses.map((warehouse) => ({
    warehouseId: warehouse.warehouseId,
    warehouseName: warehouse.name,
  }));
};

export const fetchWarehouseClients = async (
  authHttp: AxiosInstance,
  searchQuery = ''
): Promise<WarehouseClientOption[]> => {
  const {
    data: { matches },
  } = await authHttp.post<WarehouseClientOptionResponse>(
    CONTRACTED_MATCH_LIST,
    {
      searchQuery,
      pagination: {
        lastItem: null,
        itemsPerPage: 100,
      },
    }
  );

  return matches.map((match) => match.match);
};
