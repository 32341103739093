import { Stack } from '@mui/material';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { InboundProcess } from '@features/supplyChain/types';
import { getDayDifference } from '@utils';

interface DraftGoodsReceivedNoteSummaryProps {
  selectedInboundProcess: InboundProcess;
  totalReceivedItemsQuantity: number | undefined;
  isDiscrepancy: boolean;
  discrepancyAmount: number;
  isNegativeDiscrepancy: boolean;
}

export function DraftGoodsReceivedNoteSummary({
  selectedInboundProcess,
  totalReceivedItemsQuantity,
  isDiscrepancy,
  discrepancyAmount,
  isNegativeDiscrepancy,
}: DraftGoodsReceivedNoteSummaryProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { estimatedTimeOfArrival } = selectedInboundProcess.shipmentNote!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { createdAt } = selectedInboundProcess.goodsReceivedNote!;

  const deliveryDayDifference = getDayDifference(
    estimatedTimeOfArrival as string,
    createdAt as string
  );

  const isLate = deliveryDayDifference < 0;

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Received by: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedInboundProcess?.goodsReceivedNote?.userBusinessName}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Date of arrival: </strong>
        </Text>
        <Text as='span' size='xs'>
          {dayjs(selectedInboundProcess?.goodsReceivedNote?.createdAt).format(
            'DD/MM/YYYY'
          )}
          {isLate && (
            <Text as='span' size='xs' css={{ color: 'var(--colors-tomato9)' }}>
              {` (+${deliveryDayDifference.toString().substring(1)} ${
                deliveryDayDifference.toString().substring(1) === '1'
                  ? 'day'
                  : 'days'
              })`}
            </Text>
          )}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>SKUs: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedInboundProcess?.goodsReceivedNote?.skus.length}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Total Quantity (Items): </strong>
        </Text>
        <Text size='xs'>
          {totalReceivedItemsQuantity}{' '}
          {isDiscrepancy && (
            <Text
              as='span'
              size='xs'
              css={{
                color: isNegativeDiscrepancy
                  ? 'var(--colors-tomato9)'
                  : 'var(--colors-gray12)',
              }}
            >
              {`(${isNegativeDiscrepancy ? '-' : '+'}${discrepancyAmount})`}
            </Text>
          )}
        </Text>
      </Stack>
      {selectedInboundProcess.goodsReceivedNote?.comment && (
        <Stack gap={4}>
          <Text as='h3' size='xs'>
            <strong>Comment</strong>
          </Text>
          <Text size='xs'>
            {selectedInboundProcess.goodsReceivedNote.comment}
          </Text>
        </Stack>
      )}
    </>
  );
}
