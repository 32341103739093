import { styled } from '@stitchesConfig';
import { NavLink } from 'react-router-dom';

export const StyledLink = styled(NavLink, {
  display: 'flex',
  alignItems: 'center',
  gap: '$space4',
  textDecoration: 'none',
  color: '$gray12',

  '&:hover': {
    textDecoration: 'underline',
  },
});
