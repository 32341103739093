import { useState } from 'react';
import { useAppDispatch } from '@store';
import { upsertSingleOutboundProcess } from '@features/supplyChain';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { CREATE_PROOF_OF_DELIVERY } from '@constants';
import { CreateProofOfDeliveryFormValues } from '@forms/manager/CreateProofOfDeliveryForm/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useProofOfDelivery = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const [isCreateProofOfDeliveryFormOpen, setIsCreateProofOfDeliveryFormOpen] =
    useState(false);

  const handleCloseCreateProofOfDeliveryForm = useCloseDialog(
    setIsCreateProofOfDeliveryFormOpen
  );

  const handleSubmitCreateProofOfDelivery = async (
    values: CreateProofOfDeliveryFormValues
  ) => {
    try {
      const formValues = {
        goodsReceivedNote: {
          supplyChainProcessId: values.supplyChainProcessId,
          shipmentNoteId: values.shipmentNoteId,
          comment: values.comment,
          dateOfArrival: dayjs(values.actualDeliveryDate)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
          skus: values.skus.map((sku) => ({
            productId: sku.productId,
            skuId: sku.skuId,
            receivedSkuQuantity: sku.receivedSkuQuantity,
            discrepancyReason: sku.discrepancyReason,
          })),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(CREATE_PROOF_OF_DELIVERY, formValues);

      dispatch(upsertSingleOutboundProcess(supplyChainProcess));
      setIsCreateProofOfDeliveryFormOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isCreateProofOfDeliveryFormOpen,
    setIsCreateProofOfDeliveryFormOpen,
    handleCloseCreateProofOfDeliveryForm,
    handleSubmitCreateProofOfDelivery,
  };
};
