import { styled } from '@stitchesConfig';

export const StyledThumbnailGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  flexWrap: 'wrap',
  gap: '$space16',
  marginBottom: '$space16',

  '@sm': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@md': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
});

StyledThumbnailGrid.displayName = 'StyledThumbnailGrid';

export const StyledThumbnail = styled('div', {
  $$borderWidth: '$sizes$size1',
  $$borderColor: '$colors$gray7',
  position: 'relative',
  borderRadius: '$radii4',
  overflow: 'hidden',
  boxShadow: '0 0 0 $$borderWidth $$borderColor, $shadow',
  cursor: 'pointer',
  aspectRatio: 1,
});

StyledThumbnail.displayName = 'StyledThumbnail';
