import * as Yup from 'yup';

export const validationSchema = Yup.object({
  purchaseOrderNumber: Yup.string().required(
    'A purchase order number is required.'
  ),
  despatchNoteNumber: Yup.string().required(
    'A despatch note number is required.'
  ),
  customerId: Yup.string().required('A customer name is required.'),
  warehouseId: Yup.string().required('A warehouse is required.'),
  requiredTimeOfArrival: Yup.date()
    .typeError('A valid required time of arrival is required.')
    .required('A required time of arrival is required.'),
  skus: Yup.array()
    .of(
      Yup.object().shape({
        skuId: Yup.string().required('A SKU ID is required.'),
        productId: Yup.string().required('A product ID is required.'),
        skuQuantity: Yup.number()
          .max(
            Yup.ref('stockByWarehouse.0.availableStock'),
            'Maximum available stock exceeded.'
          )
          .typeError('A SKU quantity is required.')
          .required('A SKU quantity is required.'),
        // availableStock: Yup.number().required(
        //   'An available stock amount is required.'
        // ),
        palletisingOptions: Yup.array().of(
          Yup.object({
            id: Yup.string(),
            type: Yup.string(),
            customPalletSpecification: Yup.string().nullable(),
            amountOfLayersInPallet: Yup.number(),
            amountOfSkusInLayer: Yup.number(),
            dimensions: Yup.object({
              width: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              length: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              height: Yup.number().when('type', ([type], $schema) =>
                type === 'Custom Pallet'
                  ? $schema.required()
                  : $schema.nullable()
              ),
              dimensionsUnitOfMeasure: Yup.string().when(
                'type',
                ([type], $schema) =>
                  type === 'Custom Pallet'
                    ? $schema.required()
                    : $schema.nullable()
              ),
            }),
          })
        ),
        palletising: Yup.object().shape(
          {
            palletisingId: Yup.string().when('palletisingType', {
              is: (palletisingType: string) =>
                ![
                  'Mixed GB Pallet',
                  'Mixed EU Pallet',
                  'Unpalletised',
                  'Other',
                ].includes(palletisingType),
              then: () => Yup.string().required('A pallet type is required.'),
              otherwise: () => Yup.string().notRequired(),
            }),

            palletisingType: Yup.string().when('palletisingId', {
              is: (palletisingId: string | null) => palletisingId === null,
              then: () => Yup.string().required('A pallet type is required.'),
              otherwise: () => Yup.string().nullable().notRequired(),
            }),
            description: Yup.string()
              .nullable()
              .when(['palletisingType'], {
                is: (palletisingType: string | null) =>
                  palletisingType === 'Other',
                then: () =>
                  Yup.string().required(
                    'Please describe the palletising option.'
                  ),
              }),
            hasAgreedToLspShipmentOption: Yup.boolean().when(
              ['palletisingType'],
              {
                is: (palletisingType: string) =>
                  palletisingType === 'Mixed GB Pallet' ||
                  palletisingType === 'Mixed EU Pallet' ||
                  palletisingType === 'Other',
                then: () =>
                  Yup.boolean()
                    .oneOf([true], 'Please confirm agreement.')
                    .required(),
              }
            ),
          },
          [['palletisingId', 'palletisingType']]
        ),
        stockByWarehouse: Yup.array().of(
          Yup.object().shape({
            allocatedStock: Yup.number(),
            allocatedStockValue: Yup.number(),
            availableStock: Yup.number(),
            availableStockValue: Yup.number(),
            blockedStock: Yup.number(),
            blockedStockValue: Yup.number(),
            id: Yup.string(),
            inTransitStock: Yup.number(),
            inTransitStockValue: Yup.number(),
            skuId: Yup.string(),
            stockDiscrepancy: Yup.number(),
            stockDiscrepancyValue: Yup.number(),
            totalStock: Yup.number(),
            totalStockValue: Yup.number(),
            userBusinessId: Yup.string(),
            userId: Yup.string(),
            warehouseBusinessId: Yup.string(),
            warehouseId: Yup.string(),
            warehouseName: Yup.string(),
          })
        ),
      })
    )
    .min(1, 'At least one SKU is required.'),
  comment: Yup.string().max(500, 'Max 500 characters.'),
});

export const defaultValues = {
  purchaseOrderNumber: '',
  despatchNoteNumber: '',
  customerId: '',
  warehouseId: '',
  requiredTimeOfArrival: '',
  skus: [],
  comment: '',
};
