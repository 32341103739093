import { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Text } from '@components/Text';
import { SxProps } from '@mui/material';

interface SidebarAccordionContainerProps {
  children: NonNullable<ReactNode>;
  sx?: SxProps;
}

export function SidebarAccordionContainer({
  children,
  sx,
}: SidebarAccordionContainerProps) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        border: '0.0625rem solid var(--colors-gray7)',
        borderRadius: '0.25rem',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

interface SidebarAccordionProps {
  expanded: boolean;
  children: NonNullable<ReactNode>;
  setSelectedProcessChildType: (payload: string | null) => void;
}

export function SidebarAccordion({
  children,
  expanded,
  setSelectedProcessChildType,
}: SidebarAccordionProps) {
  const [expandedInternal, setExpandedInternal] = useState<boolean>(expanded);

  const handleChange = () => {
    if (expanded) setSelectedProcessChildType(null);
    setExpandedInternal(!expandedInternal);
  };

  return (
    <Accordion
      onChange={handleChange}
      sx={{
        '&.MuiAccordion-root': {
          backgroundColor: 'var(--colors-gray4)',
          color: 'var(--colors-gray12)',
          boxShadow: 'none',
        },
        '&.MuiAccordion-root.Mui-expanded': {
          marginTop: 0,
          marginBottom: 0,
        },
        '&.MuiAccordion-root:before': {
          display: 'none',
        },
        '& .MuiButtonBase-root': {
          padding: '0.5625rem 1.375rem',
          minHeight: 'unset',
        },
        '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded':
          {
            margin: 0,
            minHeight: 'unset',
          },
        '& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded':
          {
            minHeight: 'unset',
          },
      }}
      TransitionProps={{
        timeout: 150,
      }}
      expanded={expanded || expandedInternal}
    >
      {children}
    </Accordion>
  );
}

interface SidebarAccordionSummaryProps {
  title: string;
  ariaControls: string;
  id: string;
}

export function SidebarAccordionSummary({
  title,
  ariaControls,
  id,
}: SidebarAccordionSummaryProps) {
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon sx={{ color: 'var(--colors-gray12)' }} />}
      aria-controls={ariaControls}
      id={id}
    >
      <Text as='h3' size='md' weight='bold' css={{ fontSize: '0.9375rem' }}>
        {title}
      </Text>
    </AccordionSummary>
  );
}

interface SidebarAccordionDetailsProps {
  children: NonNullable<ReactNode>;
}

export function SidebarAccordionDetails({
  children,
}: SidebarAccordionDetailsProps) {
  return (
    <AccordionDetails
      sx={{
        '&.MuiAccordionDetails-root': {
          padding: '1.375rem',
          backgroundColor: 'var(--colors-gray2)',
        },
      }}
    >
      {children}
    </AccordionDetails>
  );
}

export function SidebarAccordionDivider() {
  return <Divider sx={{ borderColor: 'var(--colors-gray7)' }} />;
}
