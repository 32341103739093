import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { useDarkMode } from 'usehooks-ts';
import { StyledToggleTheme } from './ToggleTheme.styles';

function LightModeIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      style={{ minWidth: '1.25rem', height: '1.25rem' }}
    >
      <path
        fill='currentColor'
        d='M11.09 4.727V2h1.82v2.727h-1.82Zm0 17.273v-2.727h1.82V22h-1.82Zm8.183-9.09v-1.82H22v1.82h-2.727ZM2 12.91v-1.82h2.727v1.82H2Zm16.09-5.728-1.272-1.273 1.591-1.636 1.318 1.318-1.636 1.59ZM5.59 19.727 4.274 18.41l1.636-1.59 1.273 1.272-1.591 1.636Zm12.82 0-1.592-1.636 1.273-1.273 1.636 1.591-1.318 1.318ZM5.91 7.182 4.272 5.59 5.59 4.273l1.59 1.636L5.91 7.182ZM12 17.454c-1.515 0-2.803-.53-3.864-1.59-1.06-1.061-1.59-2.349-1.59-3.864 0-1.515.53-2.803 1.59-3.864 1.061-1.06 2.349-1.59 3.864-1.59 1.515 0 2.803.53 3.864 1.59 1.06 1.061 1.59 2.349 1.59 3.864 0 1.515-.53 2.803-1.59 3.864-1.061 1.06-2.349 1.59-3.864 1.59Zm0-1.818c1.015 0 1.875-.352 2.58-1.057.704-.704 1.056-1.564 1.056-2.579 0-1.015-.352-1.875-1.056-2.58-.705-.704-1.565-1.056-2.58-1.056-1.015 0-1.875.352-2.58 1.056-.704.705-1.056 1.565-1.056 2.58 0 1.015.352 1.875 1.057 2.58.704.704 1.564 1.056 2.579 1.056Z'
      />
    </svg>
  );
}

function DarkModeIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      style={{ minWidth: '1.25rem', height: '1.25rem' }}
    >
      <path
        fill='currentColor'
        d='M12 22c-2.778 0-5.139-.972-7.083-2.917C2.972 17.14 2 14.778 2 12c0-2.778.972-5.139 2.917-7.083C6.86 2.972 9.222 2 12 2c.26 0 .514.01.764.028.25.018.495.046.736.083a5.876 5.876 0 0 0-1.819 2.097A5.898 5.898 0 0 0 11 7c0 1.667.583 3.083 1.75 4.25C13.917 12.417 15.333 13 17 13a5.87 5.87 0 0 0 2.806-.681 5.908 5.908 0 0 0 2.083-1.819A10.368 10.368 0 0 1 22 12c0 2.778-.972 5.139-2.917 7.083C17.14 21.028 14.778 22 12 22Zm0-2.222c1.63 0 3.093-.45 4.389-1.347a7.795 7.795 0 0 0 2.833-3.514c-.37.092-.74.166-1.11.222-.371.055-.742.083-1.112.083-2.278 0-4.218-.8-5.82-2.402C9.579 11.218 8.778 9.278 8.778 7c0-.37.028-.74.083-1.111.056-.37.13-.74.222-1.111A7.789 7.789 0 0 0 5.57 7.61C4.671 8.907 4.222 10.371 4.222 12c0 2.148.76 3.982 2.278 5.5 1.519 1.518 3.352 2.278 5.5 2.278Z'
      />
    </svg>
  );
}

interface ToggleThemeProps extends ComponentPropsWithoutRef<'button'> {
  css?: Stitches.CSS;
}

export function ToggleTheme({ css, ...props }: ToggleThemeProps) {
  const { isDarkMode, toggle } = useDarkMode();
  return (
    <StyledToggleTheme onClick={toggle} css={css} {...props}>
      {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
    </StyledToggleTheme>
  );
}
