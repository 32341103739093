/* eslint-disable no-nested-ternary */
import { Text } from '@components/Text';
import { useAuth } from '@context';
import Collapse from '@mui/material/Collapse';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { UserRoleService } from '../../services';
import {
  CompletedStep,
  IncompleteStep,
  StyledStarterChecklist,
} from './StarterChecklist.styles';
import { classNames } from '../../utils/classes';

export interface StarterChecklistDto {
  hasFinalisedAccountSetup: boolean;
  hasSetUpFinancialInformation: boolean;
  hasCreatedProduct: boolean;
  hasCreatedSupplierMaster: boolean;
  hasCreatedCustomer: boolean;
  hasSearchedForOrOnboardedWarehouse: boolean;
  hasEngagedWithWarehouse: boolean;
  hasContractedWarehouse: boolean;
  hasStockInWarehouse: boolean;
  hasCreatedInboundShippingNote: boolean;
  hasCreatedOutboundShippingNote: boolean;
  hasCreatedWarehouseProfile: boolean;
  hasOnboardedCustomers: boolean;
  hasContractedCustomer: boolean;
  hasReceivedInboundShipment: boolean;
}

interface ChecklistStepProps {
  isComplete: boolean;
  displayText: string;
  explainerText: string;
  linkToAction: string;
  linkToTutorial: string;
}

function ChecklistStep({
  isComplete,
  displayText,
  explainerText = '',
  linkToAction = '',
  linkToTutorial = '',
}: ChecklistStepProps) {
  return isComplete ? (
    <CompletedStep className='flex gap-x-3 text-green-500'>
      <CheckIcon className='h-6 w-5 flex-none' aria-hidden='true' />
      {displayText}
    </CompletedStep>
  ) : (
    <IncompleteStep className='flex gap-x-3'>
      <CheckIcon className='h-6 w-5 flex-none' aria-hidden='true' />
      <Box className='flex' sx={{ flexDirection: 'column' }}>
        <a className='font-weight-bold' href={linkToAction ? '/' + linkToAction : ''}>
          <Text weight='bold' size='sm' css={{ color: 'inherit' }}>
            {displayText}
            <ChevronRightIcon
              className='inline h-5 w-5 font-semibold'
              aria-hidden='true'
            />
          </Text>
        </a>
        <a className='fs-6' href={linkToTutorial ?? ''}>
          <Text size='xs' css={{ color: 'inherit' }}>
            {explainerText}
          </Text>
        </a>
      </Box>
    </IncompleteStep>
  );
}

function ProgressBar({
  completionPercentage,
}: {
  completionPercentage: number;
}) {
  return (
    <div className='flex flex-col'>
      <div className='overflow-hidden rounded-full bg-gray-200'>
        <div
          style={{ width: `${completionPercentage.toFixed(1)}%` }}
          className='h-2 rounded-full bg-green-500'
        />
      </div>
      <div className='text-gray-200'>{`${completionPercentage.toFixed(
        0
      )}% complete`}</div>{' '}
      {/* Could consider replacing with eg. '3/9 steps complete' */}
    </div>
  );
}

interface StarterChecklistProps {
  starterChecklist: StarterChecklistDto;
}

export function StarterChecklist({ starterChecklist }: StarterChecklistProps) {
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();
  const [collapsed, setCollapsed] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const relevantSteps = !starterChecklist
    ? []
    : isWarehouseClient(user)
    ? [
        starterChecklist.hasFinalisedAccountSetup,
        // starterChecklist.hasSetUpFinancialInformation,
        starterChecklist.hasCreatedProduct,
        starterChecklist.hasCreatedSupplierMaster,
        starterChecklist.hasCreatedCustomer,
        // starterChecklist.hasSearchedForOrOnboardedWarehouse,
        starterChecklist.hasEngagedWithWarehouse,
        starterChecklist.hasContractedWarehouse,
        starterChecklist.hasStockInWarehouse,
        starterChecklist.hasCreatedInboundShippingNote,
        starterChecklist.hasCreatedOutboundShippingNote,
      ]
    : isWarehouseManager(user)
    ? [
        starterChecklist.hasFinalisedAccountSetup,
        // starterChecklist.hasSetUpFinancialInformation,
        starterChecklist.hasCreatedWarehouseProfile,
        // starterChecklist.hasOnboardedCustomers,
        starterChecklist.hasContractedCustomer,
        // starterChecklist.hasReceivedInboundShipment,
      ]
    : [];

  const isComplete = () => {
    return relevantSteps.every((step) => !!step);
  };

  useEffect(() => {
    function updateCompletionPercentage() {
      const totalSteps = relevantSteps.length;
      const completedSteps = relevantSteps.filter((step) => !!step).length;

      const percentageValue = (100 * completedSteps) / totalSteps;
      setCompletionPercentage(percentageValue);
    }
    updateCompletionPercentage();
  });

  return isComplete() ? null : !starterChecklist ? null : (
    <StyledStarterChecklist
      id='checklist'
      className={classNames(
        'flex grow flex-col space-y-3 text-sm leading-6 xl:mt-10 text-white bg-floxLightBlue rounded-tr-3xl mt-1 mr-4 pr-4 pt-2 pl-4 lg:w-72',
        collapsed ? '' : 'pb-4 mx-auto'
      )}
    >
      <Collapse in easing='easeInSine'>
        <a
          data-mbd-collapse-init
          href='#collapseButton'
          role='button'
          aria-controls='checklist'
          onClick={() => setCollapsed(!collapsed)}
        >
          <h3 className='text-lg font-semibold leading-8'>
            Starter Checklist
            {collapsed ? (
              <ChevronUpIcon
                className='inline h-5 w-5 font-semibold'
                aria-hidden='true'
              />
            ) : (
              <ChevronDownIcon
                className='inline h-5 w-5 font-semibold'
                aria-hidden='true'
              />
            )}
          </h3>
        </a>
      </Collapse>
      <ProgressBar completionPercentage={completionPercentage} />
      {!collapsed ? (
        isWarehouseClient(user) ? (
          <div className='mb-2'>
            <ChecklistStep
              isComplete={starterChecklist.hasFinalisedAccountSetup}
              displayText='Complete Account Setup'
              explainerText=''
              linkToAction='profile'
              linkToTutorial=''
            />
            {/* <ChecklistStep
              isComplete={starterChecklist.hasSetUpFinancialInformation}
              displayText='Set Up Financial Information'
              explainerText=''
              linkToAction=''
              linkToTutorial=''
            /> */}
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedProduct}
              displayText='Create a Product'
              explainerText=''
              linkToAction='product-sku-master'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedSupplierMaster}
              displayText='Create a Supplier'
              explainerText=''
              linkToAction='supplier-master'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedCustomer}
              displayText='Create a Customer'
              explainerText=''
              linkToAction='customer-master'
              linkToTutorial=''
            />
            {/* <ChecklistStep
              isComplete={starterChecklist.hasSearchedForOrOnboardedWarehouse}
              displayText='Search for a Warehouse'
              explainerText=''
              linkToAction='warehouse-search'
              linkToTutorial=''
            /> */}
            <ChecklistStep
              isComplete={starterChecklist.hasEngagedWithWarehouse}
              displayText='Engage with Warehouse'
              explainerText=''
              linkToAction='warehouse-shortlist'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasContractedWarehouse}
              displayText='Contract a Warehouse'
              explainerText=''
              linkToAction='warehouse-shortlist'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedInboundShippingNote}
              displayText='Create an Inbound Shipment Note'
              explainerText=''
              linkToAction='shipments'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasStockInWarehouse}
              displayText='Have stock in a Warehouse'
              explainerText=''
              linkToAction='stock'
              linkToTutorial=''
            />
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedOutboundShippingNote}
              displayText='Create an Outbound Shipment Note'
              explainerText=''
              linkToAction='shipments'
              linkToTutorial=''
            />
          </div>
        ) : isWarehouseManager(user) ? (
          <div className='mb-2'>
            <ChecklistStep
              isComplete={starterChecklist.hasFinalisedAccountSetup}
              displayText='Complete Account Setup'
              explainerText=''
              linkToAction='profile'
              linkToTutorial=''
            />
            {/* <ChecklistStep
              isComplete={starterChecklist.hasSetUpFinancialInformation}
              displayText='Set Up Financial Information'
              explainerText=''
              linkToAction=''
              linkToTutorial=''
            /> */}
            <ChecklistStep
              isComplete={starterChecklist.hasCreatedWarehouseProfile}
              displayText='Create a Warehouse'
              explainerText=''
              linkToAction='warehouse-master'
              linkToTutorial=''
            />
            {/* <ChecklistStep
              isComplete={starterChecklist.hasOnboardedCustomers}
              displayText='Onboard Customers'
              explainerText=''
              linkToAction='customers'
              linkToTutorial=''
            /> */}
            <ChecklistStep
              isComplete={starterChecklist.hasContractedCustomer}
              displayText='Contract Customers'
              explainerText=''
              linkToAction='customers'
              linkToTutorial=''
            />
            {/* <ChecklistStep
              isComplete={starterChecklist.hasReceivedInboundShipment}
              displayText='Receive an Inbound Shipment'
              explainerText=''
              linkToAction='shipments'
              linkToTutorial=''
            /> */}
          </div>
        ) : null
      ) : null}
    </StyledStarterChecklist>
  );
}
