import { styled, keyframes } from '@stitchesConfig';
import * as DialogPrimitive from '@radix-ui/react-dialog';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const StyledModalOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: 'rgba(17 24 28 / 0.25)',
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 1000,
});

StyledModalOverlay.displayName = 'StyledModalOverlay';

export const StyledModalContainer = styled(DialogPrimitive.Content, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  maxWidth: 'calc($size384 * 3)',
  maxHeight: '85vh',
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$size$size1',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
  zIndex: 1001,
});

StyledModalContainer.displayName = 'StyledModalContainer';

// Modal Header

export const StyledModalHeader = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$space$space1',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$space16',
  backgroundColor: '$gray1',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  borderTopLeftRadius: '$radii8',
  borderTopRightRadius: '$radii8',

  '@lg': {
    padding: '$space16 $space32',
  },
});

StyledModalHeader.displayName = 'StyledModalHeader';

export const StyledModalHeaderTitle = styled('h2', {
  fontSize: '$headingXs',
  fontWeight: '$bold',
  lineHeight: '$headingXs',
});

StyledModalHeaderTitle.displayName = 'StyledModalHeaderTitle';

export const StyledModalCloseButton = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  border: 'none',
  borderRadius: '$radii8',
  padding: '$space4',
  color: '$gray12',
  transition: 'background-color 150ms ease-in-out',
  cursor: 'pointer',

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.5',
  },

  '&:focus': {
    outlineColor: 'transparent',
  },

  '&:focus-visible': {
    outline: '0.125rem solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:active:not(:disabled)': {
    backgroundColor: '$gray5',
  },

  '&:hover:not(:disabled)': {
    backgroundColor: '$gray3',
  },
});

StyledModalCloseButton.displayName = 'StyledModalCloseButton';

export const StyledModalContent = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$space$space1',
  backgroundColor: '$gray1',
  padding: '$space16 $space16 $space32',
  // Box-sizing set to content-box to line up with
  // the modal header and modal footer borders
  // created by the box-shadow
  // boxSizing: 'content-box',
  borderLeft: '0.0625rem solid $$borderColor',
  borderRight: '0.0625rem solid $$borderColor',
  maxHeight: 'calc(85vh - 9rem)',
  overflowX: 'hidden',
  overflowY: 'auto',

  '@lg': {
    padding: '$space32',
  },
});

StyledModalContent.displayName = 'StyledModalContent';

export const StyledModalFooter = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$space$space1',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '$space16',
  padding: '$space16',
  backgroundColor: '$gray1',
  boxShadow: 'inset 0 -0.0625rem 0 $$borderWidth $$borderColor',
  borderBottomLeftRadius: '$radii8',
  borderBottomRightRadius: '$radii8',

  '@lg': {
    padding: '$space32',
  },
});

StyledModalFooter.displayName = 'StyledModalFooter';
