/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useState } from 'react';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { useAtom, useAtomValue } from 'jotai';
import { EmptyState } from '@components/EmptyState';
import {
  MdAdd,
  MdDelete,
  MdDownload,
  MdOutlineInfo,
  MdUploadFile,
} from 'react-icons/md';
import { View } from '@views/View';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import {
  ProductList,
  ProductCard,
  ProductLoading,
} from '@components/ProductList';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, Stack, Typography } from '@mui/material';
import { Dropzone } from '@components/Dropzone';
import { AddProductFormValues } from '@forms/client/AddProductForm/types';
import { AddProductForm } from '@forms/client/AddProductForm';
import {
  InfiniteScrollEndMessage,
  InfiniteScrollLoading,
} from '@components/InfiniteScroll';
import { Icon } from '@components/Icon';
import { DialogModal } from '@components/DialogModal';
import { ProductSummary } from '@components/ProductSummary';
import { StyledProductMasterHeaderContainer } from './ProductMaster.styles';
import { useInventory } from './hooks/useInventory';
import { mapSelectedProductToProduct } from './utils';
import { isUploadProductsModalOpenAtom } from '../../store/jotai';
import { useAuthHttp } from '../../hooks';
import { UPLOAD_PRODUCT_MASTER_URL } from '../../constants';

export function ProductMaster() {
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttpFormData = useAuthHttp('multipart/form-data');

  useDocumentTitle('FLOX - Products');

  const [isUploadProductsModalOpen, setUploadProductsModalOpen] = useAtom(
    isUploadProductsModalOpenAtom
  );
  const [uploadFile, setUploadFile] = useState<any>(undefined);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<
    { message: string; row: number }[]
  >([]);

  const searchParams = new URLSearchParams(window.location.search);

  const productId = searchParams.get('productId');

  const {
    isAddOrEditProductModalOpen,
    setIsAddOrEditProductModalOpen,
    isProductSummaryModalOpen,
    setIsProductSummaryModalOpen,
    handleCloseProductSummaryModal,
    fetchProductInventory,
    isProductInventoryLoading,
    products,
    refreshProductInventory,
    hasMoreInventoryData,
    selectedProduct,
    setSelectedProduct,
    isFetchingSelectedProduct,
    handleSubmitProduct,
    handleViewProductSummary,
    getProductsScrollParent,
    handleScrollToTopOfProducts,
  } = useInventory();

  const handleUploadProductMaster = async () => {
    try {
      setIsUploadLoading(true);
      const { data } = await authHttpFormData.post(
        UPLOAD_PRODUCT_MASTER_URL,
        {
          productFile: uploadFile,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (data) {
        setUploadFile(undefined);
        setHasUploadError(false);
        setUploadProductsModalOpen(false);
        refreshProductInventory();
      }
    } catch (error: any) {
      const errorResponse = error as any;
      // console.log('errors', errorResponse.response.data.errors);
      setHasUploadError(true);
      setUploadErrors(errorResponse.response.data.errors);
    } finally {
      setIsUploadLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      handleViewProductSummary(productId);
    }
  }, [productId]);

  return (
    <View>
      <StyledProductMasterHeaderContainer>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Product/SKU master
        </Heading>
        <Stack sx={{ flexDirection: isDesktop ? 'row' : 'column' }} gap={12}>
          <Button
            variant='primary'
            action='cta'
            size={isDesktop ? 'md' : 'sm'}
            leftIcon={<MdAdd size='1.5rem' />}
            onClick={() => setUploadProductsModalOpen(true)}
          >
            Upload Product Master
          </Button>
          <Button
            variant='primary'
            action='cta'
            size={isDesktop ? 'md' : 'sm'}
            leftIcon={<MdAdd size='1.5rem' />}
            onClick={() => setIsAddOrEditProductModalOpen(true)}
          >
            Add Product
          </Button>
        </Stack>
      </StyledProductMasterHeaderContainer>
      <InfiniteScroll
        element={ProductList as unknown as ReactNode}
        initialLoad
        loadMore={fetchProductInventory}
        hasMore={hasMoreInventoryData === null || hasMoreInventoryData === true}
        useWindow={false}
        getScrollParent={getProductsScrollParent}
        loader={
          <InfiniteScrollLoading
            key='product-loader'
            loadingMessage='Loading products...'
            sx={{ gridColumn: '1 / -1' }}
          />
        }
        // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>
        //     &#8595; Pull down to refresh
        //   </h3>
        // }
        // releaseToRefreshContent={
        //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
        // }
        // refreshFunction={fetchProductInventory}
      >
        {!isProductInventoryLoading && !products.length && (
          <EmptyState
            icon={<MdOutlineInfo size='8rem' />}
            title='No products found.'
            subtitle='Add a product to get started.'
            primaryAction={
              <Stack
                sx={{ flexDirection: isDesktop ? 'row' : 'column' }}
                gap={12}
              >
                <Button
                  action='cta'
                  leftIcon={<MdAdd size='1.5rem' />}
                  onClick={() => setUploadProductsModalOpen(true)}
                >
                  Upload Product Master
                </Button>
                <Button
                  action='cta'
                  leftIcon={<MdAdd size='1.5rem' />}
                  onClick={() => setIsAddOrEditProductModalOpen(true)}
                >
                  Add a product
                </Button>
              </Stack>
            }
            css={{ gridColumn: '1 / -1' }}
          />
        )}
        {products.map((product, index) => (
          <ProductCard
            {...(index === 0 && { id: 'first' })}
            key={product.id}
            productSummary={product}
            orientation={isTablet ? 'horizontal' : 'vertical'}
            onClick={() => handleViewProductSummary(product.id)}
          />
        ))}
        {isFetchingSelectedProduct && <ProductLoading />}
        {hasMoreInventoryData === false && (
          <InfiniteScrollEndMessage
            isVisible={products.length > (isDesktop ? 100 : 25)}
            direction={isTablet ? 'row' : 'column'}
            endMessageText='End of products'
            handleScrollToTop={handleScrollToTopOfProducts}
            sx={{ gridColumn: '1 /-1' }}
          />
        )}
      </InfiniteScroll>
      <Modal
        open={isAddOrEditProductModalOpen}
        onOpenChange={(isOpen: boolean) => {
          if (selectedProduct) {
            setSelectedProduct(undefined);
          }
          setIsAddOrEditProductModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader
            title={selectedProduct ? 'Edit Product' : 'Add Product'}
          />
          <AddProductForm
            selectedProduct={
              selectedProduct && mapSelectedProductToProduct(selectedProduct)
            }
            onClose={() => {
              setIsAddOrEditProductModalOpen(false);
            }}
            onSubmit={(values) =>
              handleSubmitProduct(
                values,
                !!selectedProduct,
                selectedProduct ? selectedProduct.product.id : undefined
              )
            }
            isSmall={isSmall}
            isTablet={isTablet}
            isDesktop={isDesktop}
          />
        </ModalContainer>
      </Modal>
      <Modal
        open={isUploadProductsModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setUploadProductsModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Upload Product Master' />
          <ModalContent
            css={{
              maxHeight: 'calc(85vh - 12.5rem)',
              minHeight: 'calc(85vh - 12.5rem)',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Stack
              maxHeight='400px'
              gap={12}
              alignItems='center'
              sx={{ maxWidth: { xs: '100%', md: '50%' } }}
            >
              <Link href='/files/product_master_template.xlsx' width='100%'>
                <Button
                  variant='primary'
                  action='cta'
                  size={isDesktop ? 'md' : 'sm'}
                  leftIcon={<MdDownload size='1.5rem' />}
                  isFullWidth
                >
                  Download excel template
                </Button>
              </Link>
              <Typography variant='body2' sx={{ fontWeight: 600 }}>
                OR
              </Typography>
              {uploadFile ? (
                <Stack gap={12} width='100%'>
                  <Stack direction='row' gap={6} alignItems='center'>
                    <MdUploadFile size='1rem' />
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '1rem' }}
                    >
                      {uploadFile.name}
                    </Typography>
                  </Stack>
                  <Button
                    variant='primary'
                    action='cta'
                    size={isDesktop ? 'md' : 'sm'}
                    leftIcon={<MdUploadFile size='1.5rem' />}
                    onClick={handleUploadProductMaster}
                    isLoading={isUploadLoading}
                    loadingIcon={<Icon name='loading' />}
                    loadingText='Uploading...'
                  >
                    Upload Product Master
                  </Button>
                  <Button
                    variant='primary'
                    action='danger'
                    leftIcon={<MdDelete size='1rem' />}
                    size={isDesktop ? 'md' : 'sm'}
                    onClick={() => {
                      setHasUploadError(false);
                      setUploadFile(undefined);
                    }}
                  >
                    Clear selected file
                  </Button>
                  {hasUploadError && (
                    <Stack gap={2}>
                      <Typography
                        variant='body2'
                        sx={{ color: 'red', fontWeight: 600 }}
                      >
                        Error uploading file.{' '}
                        {uploadErrors
                          ? 'Please fix the following errors and try again.'
                          : 'Please try again.'}
                      </Typography>
                      <>
                        {uploadErrors?.map((error) => (
                          <Typography
                            key={error.message}
                            variant='body2'
                            sx={{ color: 'red' }}
                          >
                            Row {error.row}: {error.message}
                          </Typography>
                        ))}
                      </>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Dropzone
                  id='product-master-dropzone'
                  acceptAnyFile
                  primaryAction={
                    <Button
                      size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                      leftIcon={<MdAdd size={isSmall ? '1.25rem' : '1rem'} />}
                    >
                      Upload Product Master
                    </Button>
                  }
                  onDrop={(acceptedFiles: any) => {
                    setUploadFile(acceptedFiles[0]);
                  }}
                />
              )}
            </Stack>
          </ModalContent>
        </ModalContainer>
      </Modal>
      <DialogModal
        open={isProductSummaryModalOpen}
        setOpen={(isOpen: boolean) => {
          setSelectedProduct(undefined);
          setIsProductSummaryModalOpen(isOpen);
        }}
        onClose={handleCloseProductSummaryModal}
        title='Product Summary'
        id='product-summary-dialog-title'
      >
        <ProductSummary
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedProduct={selectedProduct!}
          setOpen={(isOpen: boolean) => {
            setSelectedProduct(undefined);
            setIsProductSummaryModalOpen(isOpen);
          }}
          handleEditProductClick={() => {
            setSelectedProduct(selectedProduct);
            setIsProductSummaryModalOpen(false);
            setIsAddOrEditProductModalOpen(true);
          }}
          isTablet={isTablet}
          isDesktop={isDesktop}
        />
      </DialogModal>
    </View>
  );
}
