import { useAuth } from '@context';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Navigation } from '@components/Navigation';
import { NavigationItemList } from '@components/Navigation/types';
import { useEnableWebSockets, UserRoleService } from '@services';
import { useSetAtom } from 'jotai';
import { clientNavigation, managerNavigation } from './navigationItems';
import { isPublicPageAtom } from '../../store/jotai';

export function RequireAuth() {
  const setIsPublicPage = useSetAtom(isPublicPageAtom);
  const { isWarehouseClient } = UserRoleService();
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();
  useEnableWebSockets();

  const buildNavigationItems = (): NavigationItemList =>
    isWarehouseClient(user) ? clientNavigation : managerNavigation;

  setIsPublicPage(false);

  return isAuthenticated ? (
    <Navigation items={buildNavigationItems()}>
      <Outlet />
    </Navigation>
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}
