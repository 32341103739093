import { InboundProcess } from '@features/supplyChain/types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useReadLocalStorage } from 'usehooks-ts';
import { CollapsibleInboundRow } from './CollapsibleInboundRow';

interface InboundShipmentsTableProps {
  inboundProcesses: InboundProcess[];
}
export function InboundShipmentsTable({
  inboundProcesses,
}: InboundShipmentsTableProps) {
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  return (
    <TableContainer
      component={Paper}
      sx={{
        position: 'relative',
        backgroundColor: 'var(--colors-gray1)',
        border: darkMode ? '0.0625rem solid var(--colors-gray7)' : 'none',
      }}
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                width: '4.5rem',
              }}
            />
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '16rem',
              }}
            >
              Shipment note #
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '16rem',
              }}
            >
              From
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
              }}
            >
              Creation date
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '12rem',
              }}
            >
              ETA / Delivered date
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '20rem',
              }}
            >
              SKU Q-ty
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '20rem',
              }}
            >
              SKU Q-ty (Pallets)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inboundProcesses.map((inboundProcess) => (
            <CollapsibleInboundRow
              key={inboundProcess.id}
              inboundProcess={inboundProcess}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
