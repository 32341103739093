import { MouseEvent } from 'react';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Box, IconButton, useTheme } from '@mui/material';

interface StockBySKUTablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

export function StockBySKUTablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: StockBySKUTablePaginationActionsProps) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
        sx={{
          cursor: 'pointer',
          color: 'var(--colors-gray12)',
          '&:disabled': {
            cursor: 'not-allowed',
            color: 'var(--colors-gray9)',
          },
        }}
      >
        {theme.direction === 'rtl' ? (
          <LastPageIcon
            sx={{
              color: 'var(--colors-gray12)',
            }}
          />
        ) : (
          <FirstPageIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        sx={{
          cursor: 'pointer',
          color: 'var(--colors-gray12)',
          '&:disabled': {
            cursor: 'not-allowed',
            color: 'var(--colors-gray9)',
          },
        }}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        sx={{
          cursor: 'pointer',
          color: 'var(--colors-gray12)',
          '&:disabled': {
            cursor: 'not-allowed',
            color: 'var(--colors-gray9)',
          },
        }}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
        sx={{
          cursor: 'pointer',
          color: 'var(--colors-gray12)',
          '&:disabled': {
            cursor: 'not-allowed',
            color: 'var(--colors-gray9)',
          },
        }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
