import * as RadioGroup from '@radix-ui/react-radio-group';
import * as Label from '@radix-ui/react-label';
import { styled } from '@stitchesConfig';

export const StyledRadioGroup = styled(RadioGroup.Root, {
  width: '100%',
  display: 'flex',

  variants: {
    orientation: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
  },
});

StyledRadioGroup.displayName = 'StyledRadioGroup';

export const StyledRadioItem = styled(RadioGroup.Item, {
  minWidth: '$size24',
  minHeight: '$size24',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$radii12',
  border: 'none',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderColorChecked: '$colors$gray12',
  $$borderWidth: '0.0625rem',
  backgroundColor: '$gray1',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  cursor: 'pointer',

  '&:hover': {
    boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
  },

  '&[data-state="checked"]': {
    boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorChecked',
  },

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});

StyledRadioItem.displayName = 'StyledRadioItem';

export const StyledRadioIndicator = styled(RadioGroup.Indicator, {
  width: '$size14',
  height: '$size14',
  borderRadius: '$radii8',
  backgroundColor: 'transparent',
  cursor: 'pointer',

  '&[data-state="checked"]': {
    backgroundColor: '$gray12',
  },

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
});

StyledRadioIndicator.displayName = 'StyledRadioIndicator';

export const StyledRadioCard = styled(Label.Root, {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '$space16',
  padding: '$space16',
  backgroundColor: '$gray1',
  borderRadius: '$radii4',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderColorChecked: '$colors$gray12',
  $$borderWidth: '0.0625rem',
  $$borderWidthChecked: '0.125rem',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  cursor: 'pointer',

  '&:has(button[data-state="checked"])': {
    backgroundColor: '$gray1',
    boxShadow: 'inset 0 0 0 $$borderWidthChecked $$borderColor',
    $$borderColor: '$colors$gray12',
  },

  '&:has(button:focus)': {
    outline: '2px solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:has(button:focus) button:focus': {
    outlineColor: 'transparent',
  },

  '&:hover': {
    backgroundColor: '$gray2',
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        cursor: 'not-allowed',
        '&:has(button[data-state="unchecked"])': {
          backgroundColor: '$gray2',
        },
        '&:has(button[data-state="checked"])': {
          backgroundColor: '$gray4',
        },
      },
    },
  },
});

StyledRadioCard.displayName = 'StyledRadioCard';
