import { InboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  actualDateOfArrival: Yup.string().required('An arrival date is required.'),
  skus: Yup.array()
    .of(
      Yup.object({
        skuId: Yup.string().required('A SKU ID is required.'),
        skuIdentifier: Yup.string().required('A SKU identifier is required.'),
        productName: Yup.string().required('A product name is required.'),
        productId: Yup.string().required('A product ID is required.'),
        // skuName: Yup.string().required('A SKU name is required.'),
        skuUnitOfMeasure: Yup.string().required('A SKU UoM is required.'),
        despatchedQuantity: Yup.number().required(
          'A despatched quantity is required.'
        ),
        receivedSkuQuantity: Yup.number().required(
          'A delivered quantity is required.'
        ),
        discrepancyReason: Yup.string()

          .nullable()
          .when(['despatchedQuantity', 'receivedSkuQuantity'], {
            is: (despatchedQuantity: number, receivedSkuQuantity: number) =>
              despatchedQuantity !== receivedSkuQuantity,
            then: () =>
              Yup.string().required('A discrepancy reason is required.'),
          }),
      })
    )
    .min(1, 'At least one SKU is required'),
  comment: Yup.string().max(500, 'Max 500 characters.'),
});

export const makeDefaultValues = (
  selectedInboundProcess: InboundProcess | null
) => {
  if (!selectedInboundProcess) {
    return {
      supplyChainProcessId: '',
      shipmentNoteId: '',
      skus: [],
      comment: '',
      actualDateOfArrival: '',
    };
  }
  return {
    supplyChainProcessId: selectedInboundProcess.id,
    shipmentNoteId: selectedInboundProcess.shipmentNote.id,
    skus: selectedInboundProcess.shipmentNote.skus.map((sku) => ({
      skuId: sku.skuId,
      skuIdentifier: sku.skuIdentifier,
      productName: sku.productName,
      productId: sku.productId,
      skuName: sku.name,
      skuUnitOfMeasure: sku.unitOfMeasure,
      despatchedQuantity: sku.skuQuantity,
      receivedSkuQuantity: sku.skuQuantity,
      discrepancyReason: null,
    })),
    comment: '',
    actualDateOfArrival: dayjs(
      selectedInboundProcess.shipmentNote.estimatedTimeOfArrival
    ).format(),
  };
};
