import { useState } from 'react';
import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import { Text } from '@components/Text';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getTotalPallets } from '@utils';
import { useSetAtom } from 'jotai';
import { selectedShipmentNoteNumberAtom } from '../../../store/jotai';

interface CollapsibleInboundRowProps {
  outboundProcess: OutboundProcess;
}

export function CollapsibleOutboundRow({
  outboundProcess,
}: CollapsibleInboundRowProps) {
  const [open, setOpen] = useState(false);
  const setSelectedShipmentNoteNumber = useSetAtom(
    selectedShipmentNoteNumberAtom
  );

  return (
    <>
      <TableRow key={outboundProcess.id}>
        <TableCell
          component='td'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            sx={{ color: 'var(--colors-gray11)' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          onClick={() =>
            setSelectedShipmentNoteNumber(
              outboundProcess.shipmentNote?.shipmentNoteNumber ?? ''
            )
          }
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {outboundProcess.shipmentNote?.shipmentNoteNumber ??
            outboundProcess.despatchNote?.despatchNoteNumber ??
            ''}
        </TableCell>
        <TableCell>
          {outboundProcess.shipmentNote?.customerName ??
            outboundProcess.despatchNote?.customerName ??
            ''}
        </TableCell>
        <TableCell>
          {dayjs(outboundProcess.shipmentNote?.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          <strong>ETA</strong>:{' '}
          {dayjs(outboundProcess.shipmentNote?.estimatedTimeOfArrival).format(
            'DD/MM/YYYY'
          )}
        </TableCell>
        <TableCell>ABC Haulage</TableCell>
        <TableCell>
          {outboundProcess.shipmentNote
            ? outboundProcess.shipmentNote.skus.reduce(
                (prev, current) => prev + current.skuQuantity,
                0
              )
            : outboundProcess.despatchNote.skus.reduce(
                (prev, current) => prev + current.skuQuantity,
                0
              )}
        </TableCell>
        <TableCell>
          {outboundProcess.shipmentNote
            ? outboundProcess.shipmentNote.skus
                .map((sku, index) => {
                  const totalPallets = getTotalPallets(
                    sku.skuQuantity,
                    sku.packagingDetails.packagingInfo?.amountOfSkusInLayer ??
                      0,
                    sku.packagingDetails.packagingInfo
                      ?.amountOfLayersInPallet ?? 0
                  );

                  return totalPallets;
                })
                .reduce((prev, current) => prev + current, 0)
            : outboundProcess.despatchNote.skus
                .map((sku, index) => {
                  const totalPallets = getTotalPallets(
                    sku.skuQuantity,
                    sku.packagingDetails.packagingInfo?.amountOfSkusInLayer ??
                      0,
                    sku.packagingDetails.packagingInfo
                      ?.amountOfLayersInPallet ?? 0
                  );

                  return totalPallets;
                })
                .reduce((prev, current) => prev + current, 0)}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow
          sx={{
            backgroundColor: 'var(--colors-gray2)',
          }}
        >
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }}>{outboundProcess.status}</TableCell>
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }}>
            {outboundProcess.shipmentNote
              ? outboundProcess.shipmentNote.skus.map((sku, index) => (
                  <Text
                    key={sku.skuId}
                    size='xs'
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text size='xs' as='span' weight='medium'>
                      {index + 1}. {sku.productName} ({sku.name})
                    </Text>
                    <Text size='xs' as='span'>
                      {sku.skuQuantity}
                    </Text>
                  </Text>
                ))
              : outboundProcess.despatchNote.skus.map((sku, index) => (
                  <Text
                    key={sku.skuId}
                    size='xs'
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text size='xs' as='span' weight='medium'>
                      {index + 1}. {sku.productName} ({sku.name})
                    </Text>
                    <Text size='xs' as='span'>
                      {sku.skuQuantity}
                    </Text>
                  </Text>
                ))}
          </TableCell>
          <TableCell sx={{ p: 16 }}>
            {outboundProcess.shipmentNote
              ? outboundProcess.shipmentNote.skus.map((sku, index) => {
                  const totalPallets =
                    sku.packagingDetails.packagingInfo &&
                    getTotalPallets(
                      sku.skuQuantity,
                      sku.packagingDetails.packagingInfo.amountOfSkusInLayer,
                      sku.packagingDetails.packagingInfo.amountOfLayersInPallet
                    );

                  return (
                    sku.packagingDetails.packagingInfo && (
                      <Text
                        key={sku.skuIdentifier}
                        size='xs'
                        as='span'
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Text size='xs' as='span' weight='medium'>
                          {index + 1}. {sku.packagingDetails.packagingInfo.type}
                          {totalPallets && totalPallets > 1 ? 's' : ''}
                        </Text>
                        <Text size='xs' as='span'>
                          {totalPallets}
                        </Text>
                      </Text>
                    )
                  );
                })
              : outboundProcess.despatchNote.skus.map((sku, index) => {
                  const totalPallets =
                    sku.packagingDetails.packagingInfo &&
                    getTotalPallets(
                      sku.skuQuantity,
                      sku.packagingDetails.packagingInfo.amountOfSkusInLayer,
                      sku.packagingDetails.packagingInfo.amountOfLayersInPallet
                    );

                  return (
                    sku.packagingDetails.packagingInfo && (
                      <Text
                        key={sku.skuIdentifier}
                        size='xs'
                        as='span'
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Text size='xs' as='span' weight='medium'>
                          {index + 1}. {sku.packagingDetails.packagingInfo.type}
                          {totalPallets && totalPallets > 1 ? 's' : ''}
                        </Text>
                        <Text size='xs' as='span'>
                          {totalPallets}
                        </Text>
                      </Text>
                    )
                  );
                })}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
