import type * as Stitches from '@stitches/react';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { StyledHeading } from './Heading.styles';

export interface HeadingProps
  extends ComponentPropsWithoutRef<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'> {
  children: ReactNode;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  weight?: 'regular' | 'medium' | 'semibold' | 'bold';
  css?: Stitches.CSS;
}

export function Heading({
  children,
  as,
  size = 'md',
  weight = 'bold',
  css,
  ...props
}: HeadingProps) {
  return (
    <StyledHeading as={as} size={size} weight={weight} css={css} {...props}>
      {children}
    </StyledHeading>
  );
}

Heading.displayName = 'Heading';
