import { useEffect, useState } from 'react';
import { FieldErrors, UseFormWatch } from 'react-hook-form';
import { AddProductFormValues, Pallet } from '../types';

interface UseFormErrorsProps {
  errors: FieldErrors<AddProductFormValues>;
  watch: UseFormWatch<AddProductFormValues>;
}

export const useValidSteps = ({ errors, watch }: UseFormErrorsProps) => {
  const [stepOneValid, setStepOneValid] = useState(false);
  const [stepTwoValid, setStepTwoValid] = useState(false);
  const [stepThreeValid, setStepThreeValid] = useState(false);

  const productIdLength = watch('product.productId').length;
  const productNameLength = watch('product.name').length;
  const netVolume = watch('product.netVolume');
  const productValid = productIdLength && productNameLength && netVolume;

  const costPrices = watch('product.costPrices');
  const costPricesLength = watch('product.costPrices').length;
  const costPricesValid =
    costPricesLength > 0 &&
    costPrices.every((costPrice) => {
      return (
        costPrice.currency !== '' &&
        costPrice.amount !== null &&
        !Number.isNaN(costPrice.amount)
      );
    });

  const productAndCostPricesValid =
    productValid && (costPricesLength === 0 || costPricesValid);
  // Check if step one is valid:
  useEffect(() => {
    if (productAndCostPricesValid) {
      setStepOneValid(true);
    }

    if (!productAndCostPricesValid) {
      setStepOneValid(false);
    }
  }, [productAndCostPricesValid, setStepOneValid]);

  const skus = watch('product.skus');
  const skusValid =
    skus.length > 0 &&
    skus.every((sku, index) => {
      const { listPrices, purchasePrices } = sku;
      const listPricesLength = listPrices.length;
      const listPricesValid = listPrices.every((listPrice) => {
        return (
          listPrice.currency !== '' &&
          listPrice.amount !== null &&
          !Number.isNaN(listPrice.amount)
        );
      });

      const purchasePricesLength = purchasePrices.length;
      const purchasePricesValid = purchasePrices.every((purchasePrice) => {
        return (
          purchasePrice.currency !== '' &&
          purchasePrice.amount !== null &&
          !Number.isNaN(purchasePrice.amount)
        );
      });

      return (
        sku.skuId.length > 2 &&
        sku.unitOfMeasure?.length > 0 &&
        sku.amountOfProductInSku !== null &&
        sku.amountOfProductInSku > 0 &&
        sku.dimensions.length !== null &&
        sku.dimensions.length > 0 &&
        sku.dimensions.width !== null &&
        sku.dimensions.width > 0 &&
        sku.dimensions.height !== null &&
        sku.dimensions.height > 0 &&
        !(errors.product?.skus && errors.product.skus[index]?.grossWeight) &&
        (listPricesLength === 0 || listPricesValid) &&
        (purchasePricesLength === 0 || purchasePricesValid)
      );
    });

  // Check if step two is valid:
  useEffect(() => {
    if (skusValid) {
      setStepTwoValid(true);
    }

    if (!skusValid) {
      setStepTwoValid(false);
    }
  }, [skusValid]);

  const skuPalletising = watch('product.skus').map((sku) => sku.palletising);

  const palletisingValid =
    // skuPalletising[0] === null ||
    skuPalletising.every((item) => {
      const isSkuPalletisingArrayValid = (
        palletisingArray: Omit<Pallet, 'id'>[]
      ) => {
        return (
          // palletisingArray.length === 0 ||
          // eslint-disable-next-line consistent-return
          palletisingArray.every((pallet: Omit<Pallet, 'id'>) => {
            const hasPalletType = pallet.type !== null || pallet.type !== '';
            const hasAmountOfSkusInLayer =
              pallet.amountOfSkusInLayer !== null &&
              !Number.isNaN(pallet.amountOfSkusInLayer);

            const hasAmountOfLayersInPallet =
              pallet.amountOfLayersInPallet !== null &&
              !Number.isNaN(pallet.amountOfLayersInPallet);

            const hasPalletLength =
              pallet.dimensions.length !== null &&
              !Number.isNaN(pallet.dimensions.length);

            const hasPalletWidth =
              pallet.dimensions.width !== null &&
              !Number.isNaN(pallet.dimensions.width);

            const hasPalletHeight =
              pallet.dimensions.height !== null &&
              !Number.isNaN(pallet.dimensions.height);

            const hasCustomPalletName =
              pallet.type === 'Custom Pallet'
                ? pallet.customPalletSpecification !== ''
                : true;

            return (
              hasPalletType &&
              hasPalletLength &&
              hasPalletWidth &&
              hasPalletHeight &&
              hasAmountOfSkusInLayer &&
              hasAmountOfLayersInPallet &&
              hasCustomPalletName
            );
          })
        );
      };

      return (
        // item.length === 0 ||
        item.length > 0 && isSkuPalletisingArrayValid(item)
      );
    });

  // Check if step three is valid:
  useEffect(() => {
    if (palletisingValid) {
      setStepThreeValid(true);
    }

    if (!palletisingValid) {
      setStepThreeValid(false);
    }
  }, [palletisingValid]);

  return [stepOneValid, stepTwoValid, stepThreeValid];
};
