import { Dispatch, SetStateAction } from 'react';
import {
  AddProductFormValues,
  ProductImage,
  UserImage,
} from '@forms/client/AddProductForm/types';
import { Text } from '@components/Text';
import { Button } from '@components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useReadLocalStorage } from 'usehooks-ts';
import { UseFormGetValues } from 'react-hook-form';
import { INVENTORY_IMAGES_URL } from '@constants';
import { ErrorMessage } from '@components/ErrorMessage';
import {
  StyledSKUSummaryCard,
  StyledSKUSummaryCardHeader,
  StyledSKUSummaryCardHeaderImage,
  StyledSKUSummaryCardHeaderImageContainer,
  StyledSKUSummaryCardHeaderTitleContainer,
  StyledSKUSummaryCardDetailsContainer,
  StyledSKUSummaryCardVolumeAndWeightContainer,
  StyledSKUSummaryCardButtonsContainer,
} from './SKUSummary.styles';

interface SKUSummaryProps {
  getValues: UseFormGetValues<AddProductFormValues>;
  remove: () => void;
  skuIndex: number;
  setSelectedSkuIndex: Dispatch<SetStateAction<number | null>>;
}

export function SKUSummary({
  getValues,
  remove,
  skuIndex,
  setSelectedSkuIndex,
}: SKUSummaryProps) {
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');

  const {
    name: productName,
    netVolume,
    netVolumeUnitOfMeasure,
  } = getValues().product;

  const {
    productImageId,
    skuId,
    name: skuName,
    description,
    amountOfProductInSku,
    dimensions,
    grossWeight,
    grossWeightUnitOfMeasure,
    dimensions: { width, height, length, dimensionsUnitOfMeasure },
    listPrices,
    purchasePrices,
  } = getValues().product.skus[skuIndex];

  const hasLength = dimensions.length !== null && dimensions.length > 0;
  const hasWidth = dimensions.width !== null && dimensions.width > 0;
  const hasHeight = dimensions.height !== null && dimensions.height > 0;

  const hasWeightMeasurements =
    grossWeight !== null && grossWeight > 0 && grossWeightUnitOfMeasure;

  const hasAmountOfProductInSku =
    amountOfProductInSku !== null && amountOfProductInSku > 0;

  const hasNetVolumeAndAmountOfProduct =
    netVolume && netVolumeUnitOfMeasure && hasAmountOfProductInSku;

  const listPricesHaveCurrency = listPrices.every(
    (listPrice) => listPrice.currency !== ''
  );

  const listPricesHaveAmount = listPrices.every(
    (listPrice) => listPrice.amount !== null && !Number.isNaN(listPrice.amount)
  );

  const purchasePricesHaveCurrency = purchasePrices.every(
    (purchasePrice) => purchasePrice.currency !== ''
  );

  const purchasePricesHaveAmount = purchasePrices.every(
    (purchasePrice) =>
      purchasePrice.amount !== null && !Number.isNaN(purchasePrice.amount)
  );

  const skuImage = getValues().skuImages.find(
    (_skuImage) => _skuImage.id === productImageId
  );

  return (
    <StyledSKUSummaryCard>
      <StyledSKUSummaryCardHeader>
        <StyledSKUSummaryCardHeaderImageContainer>
          <StyledSKUSummaryCardHeaderImage
            src={
              skuImage
                ? URL.createObjectURL(skuImage.image)
                : `/images/product-placeholder-${
                    darkMode ? 'dark' : 'light'
                  }.svg`
            }
          />
        </StyledSKUSummaryCardHeaderImageContainer>
        <StyledSKUSummaryCardHeaderTitleContainer>
          <Text size='sm' weight='bold'>
            {productName}
          </Text>
          {skuName ? (
            <Text size='sm' weight='bold'>
              SKU Name:
              <Text as='span' size='sm' weight='regular'>
                {' '}
                {`${skuName.charAt(0).toUpperCase()}${skuName.substring(1)}`}
              </Text>
            </Text>
          ) : (
            <ErrorMessage css={{ paddingLeft: '0' }}>
              Missing SKU name.
            </ErrorMessage>
          )}
          {skuId ? (
            <Text size='sm' weight='bold'>
              SKU ID:
              <Text as='span' size='sm' weight='regular'>
                {' '}
                {skuId}
              </Text>
            </Text>
          ) : (
            <ErrorMessage css={{ paddingLeft: '0' }}>
              Missing SKU id.
            </ErrorMessage>
          )}
          {hasAmountOfProductInSku ? (
            <Text size='sm' weight='bold'>
              Product Qty:
              <Text as='span' size='sm' weight='regular'>
                {' '}
                {amountOfProductInSku}
              </Text>
            </Text>
          ) : (
            <ErrorMessage css={{ paddingLeft: '0' }}>
              Missing product amount.
            </ErrorMessage>
          )}
        </StyledSKUSummaryCardHeaderTitleContainer>
      </StyledSKUSummaryCardHeader>
      <StyledSKUSummaryCardDetailsContainer>
        <StyledSKUSummaryCardVolumeAndWeightContainer>
          {hasNetVolumeAndAmountOfProduct && (
            <Text size='sm' weight='regular' css={{ flex: 1 }}>
              {netVolume * amountOfProductInSku}
              <Text as='span' size='sm' weight='regular'>
                {netVolumeUnitOfMeasure}
              </Text>
            </Text>
          )}
          {hasWeightMeasurements && (
            <Text size='sm' weight='regular' css={{ flex: 1 }}>
              {grossWeight}
              <Text as='span' size='sm' weight='regular'>
                {grossWeightUnitOfMeasure}
              </Text>
            </Text>
          )}
        </StyledSKUSummaryCardVolumeAndWeightContainer>

        {hasLength && hasWidth && hasHeight && (
          <Text size='sm' weight='regular'>
            {width}
            {dimensionsUnitOfMeasure} X {height}
            {dimensionsUnitOfMeasure} X {length}
            {dimensionsUnitOfMeasure}
          </Text>
        )}
        {description && (
          <Text size='sm' weight='regular'>
            {description}
          </Text>
        )}
        {!hasLength && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing SKU length.
          </ErrorMessage>
        )}
        {!hasWidth && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing SKU width.
          </ErrorMessage>
        )}
        {!hasHeight && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing SKU height.
          </ErrorMessage>
        )}
        {!hasWeightMeasurements && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing gross weight.
          </ErrorMessage>
        )}
        {!listPricesHaveCurrency && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing list price currency.
          </ErrorMessage>
        )}
        {!listPricesHaveAmount && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing list price amount.
          </ErrorMessage>
        )}
        {!purchasePricesHaveCurrency && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing purchase price currency.
          </ErrorMessage>
        )}
        {!purchasePricesHaveAmount && (
          <ErrorMessage css={{ paddingLeft: '0', flex: '1' }}>
            Missing purchase price amount.
          </ErrorMessage>
        )}
      </StyledSKUSummaryCardDetailsContainer>
      <StyledSKUSummaryCardButtonsContainer>
        <Button
          size='sm'
          action='danger'
          leftIcon={<MdDelete size='1.25rem' />}
          isFullWidth
          onClick={remove}
        >
          Delete
        </Button>
        <Button
          size='sm'
          leftIcon={<MdEdit size='1.25rem' />}
          isFullWidth
          onClick={() => setSelectedSkuIndex(skuIndex)}
        >
          Edit
        </Button>
      </StyledSKUSummaryCardButtonsContainer>
    </StyledSKUSummaryCard>
  );
}

SKUSummary.displayName = 'SKUSummary';
