import { Stack } from '@mui/material';
import { Text } from '@components/Text';
import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import { getDayDifference } from '@utils';

interface OutboundShipmentNoteSummaryProps {
  selectedOutboundProcess: OutboundProcess;
  totalShippedItemsQuantity: number | undefined;
  isShippingDiscrepancy: boolean;
  shippingDiscrepancyAmount: number;
  isNegativeShippingDiscrepancy: boolean;
}

export function OutboundShipmentNoteSummary({
  selectedOutboundProcess,
  totalShippedItemsQuantity,
  isShippingDiscrepancy,
  shippingDiscrepancyAmount,
  isNegativeShippingDiscrepancy,
}: OutboundShipmentNoteSummaryProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { requiredTimeOfArrival } = selectedOutboundProcess.despatchNote!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { estimatedTimeOfArrival } = selectedOutboundProcess.shipmentNote!;

  const deliveryDayDifference = getDayDifference(
    requiredTimeOfArrival as string,
    estimatedTimeOfArrival as string
  );

  const isLate = deliveryDayDifference < 0;

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Shipped with: </strong>
        </Text>
        <Text as='span' size='xs'>
          ABC Haulage
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Shipment Note #: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.shipmentNote?.shipmentNoteNumber}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>ETA: </strong>
        </Text>
        <Text as='span' size='xs'>
          {dayjs(
            selectedOutboundProcess?.shipmentNote?.estimatedTimeOfArrival
          ).format('DD/MM/YYYY, H:mm')}
          {isLate && (
            <Text as='span' size='xs' css={{ color: 'var(--colors-tomato9)' }}>
              {` (+${deliveryDayDifference.toString().substring(1)} ${
                deliveryDayDifference.toString().substring(1) === '1'
                  ? 'day'
                  : 'days'
              })`}
            </Text>
          )}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>SKUs: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.shipmentNote?.skus.length}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Total Quantity (Items): </strong>
        </Text>
        <Text size='xs'>
          {totalShippedItemsQuantity}{' '}
          {isShippingDiscrepancy && (
            <Text
              as='span'
              size='xs'
              css={{
                color: isNegativeShippingDiscrepancy
                  ? 'var(--colors-tomato9)'
                  : 'var(--colors-gray12)',
              }}
            >
              {`(${
                isNegativeShippingDiscrepancy ? '-' : '+'
              }${shippingDiscrepancyAmount})`}
            </Text>
          )}
        </Text>
      </Stack>
      {selectedOutboundProcess.shipmentNote?.comment && (
        <Stack gap={4}>
          <Text as='h3' size='xs'>
            <strong>Comment</strong>
          </Text>
          <Text size='xs'>{selectedOutboundProcess.shipmentNote.comment}</Text>
        </Stack>
      )}
    </>
  );
}
