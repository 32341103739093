import {
  WAREHOUSE_CLIENT,
  WAREHOUSE_MANAGER,
  WAREHOUSE_OPERATOR,
} from '@constants';
import { IUserRoleService, User } from './types';

export function UserRoleService(): IUserRoleService {
  const isWarehouseClient = (user: User | null) =>
    user?.roles.includes(WAREHOUSE_CLIENT) as boolean;

  const isWarehouseManager = (user: User | null) =>
    user?.roles.includes(WAREHOUSE_MANAGER) as boolean;

  const isWarehouseOperator = (user: User | null) =>
    user?.roles.includes(WAREHOUSE_OPERATOR) as boolean;

  return {
    isWarehouseClient,
    isWarehouseManager,
    isWarehouseOperator,
  };
}
