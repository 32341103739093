import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const STANDARD_BUSINESS_HOURS_TYPE = [
  {
    label: 'Standard 24/7',
    value: 'Standard24/7',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export function WorkingHours() {
  const { control, getValues, setValue, watch } = useFormContext();
  const watchStandardBusinessHours = watch('standardBusinessHoursType');

  return (
    <Stack spacing={20}>
      <Heading as='h3' size='sm'>
        Working hours
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='standardBusinessHoursType'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Standard business hours
        </Label>
        <Controller
          name='standardBusinessHoursType'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`standardBusinessHoursType`)}
                onBlur={field.onBlur}
                placeholder='Please select'
                css={{ width: '25%' }}
                isInvalid={Boolean(error)}
              >
                {STANDARD_BUSINESS_HOURS_TYPE.map((businessHour) => (
                  <SelectItem
                    key={businessHour.label}
                    value={businessHour.value}
                  >
                    {`${businessHour.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='numberOfShifts'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of shifts'
            placeholder='# of shifts'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='minHeadcount'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Minimum headcount'
            placeholder='Minimum headcount'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='maxHeadcount'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Maximum headcount'
            placeholder='Maximum headcount'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      {watchStandardBusinessHours === 'Other' && (
        <Stack spacing={16}>
          <Stack>
            {!getValues().isMondayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='mondayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Monday open *'
                      placeholder='Monday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
                <Controller
                  name='mondayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Monday close *'
                      placeholder='Monday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isMondayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isMondayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Monday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isTuesdayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='tuesdayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Tuesday open *'
                      placeholder='Tuesday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
                <Controller
                  name='tuesdayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Tuesday close *'
                      placeholder='Tuesday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isTuesdayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isTuesdayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Tuesday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isWednesdayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='wednesdayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Wednesday open *'
                      placeholder='Wednesday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
                <Controller
                  name='wednesdayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Wednesday close *'
                      placeholder='Wednesday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isWednesdayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isWednesdayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Wednesday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isThursdayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='thursdayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Thursday open *'
                      placeholder='Thursday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
                <Controller
                  name='thursdayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Thursday close *'
                      placeholder='Thursday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isThursdayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isThursdayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Thursday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isFridayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='fridayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Friday open *'
                      placeholder='Friday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
                <Controller
                  name='fridayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Friday close *'
                      placeholder='Friday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                      defaultValue={field.value}
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isFridayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isFridayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Friday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isSaturdayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='saturdayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      defaultValue={field.value}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Saturday open *'
                      placeholder='Saturday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                    />
                  )}
                />
                <Controller
                  name='saturdayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      defaultValue={field.value}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Saturday close *'
                      placeholder='Saturday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isSaturdayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isSaturdayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Saturday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
          <Stack>
            {!getValues().isSundayClosed && (
              <Stack direction='row' spacing={16}>
                <Controller
                  name='sundayOpen'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      defaultValue={field.value}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Sunday open *'
                      placeholder='Sunday open *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                    />
                  )}
                />
                <Controller
                  name='sundayClose'
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      defaultValue={field.value}
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                      variant='outlined'
                      label='Sunday close *'
                      placeholder='Sunday close *'
                      sx={{
                        width: '100%',
                      }}
                      type='time'
                    />
                  )}
                />
              </Stack>
            )}
            <InputGroup
              direction='row'
              css={{
                gap: '$space16',
                padding: '$space2',
              }}
            >
              <Controller
                name='isSundayClosed'
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => {
                              setValue('isSundayClosed', checked, {
                                shouldValidate: true,
                              });
                            }}
                            name={field.name}
                          />
                        }
                        label='Sunday closed'
                      />
                    </FormGroup>
                  );
                }}
              />
            </InputGroup>
          </Stack>
        </Stack>
      )}
      <Controller
        name='despatchCutoffTime'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            defaultValue={field.value}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label="Cut-off time for tomorrow's despatch planning"
            placeholder="Cut-off time for tomorrow's despatch planning"
            sx={{
              width: '100%',
            }}
            type='time'
          />
        )}
      />
      <InputGroup
        direction='row'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='hasFlexibleBusinessHours'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setValue('hasFlexibleBusinessHours', checked, {
                          shouldValidate: true,
                        });
                      }}
                      name={field.name}
                    />
                  }
                  label='Flexible hours on-demand'
                />
              </FormGroup>
            );
          }}
        />
      </InputGroup>
      <Heading as='h3' size='xs'>
        Loading times
      </Heading>
      <Stack spacing={16}>
        <Stack>
          {!getValues().hasNoOperationsOnMonday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingMondayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Monday open *'
                    placeholder='Monday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='loadingMondayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Monday close *'
                    placeholder='Monday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnMonday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnMonday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Monday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnTuesday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingTuesdayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Tuesday open *'
                    placeholder='Tuesday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='loadingTuesdayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Tuesday close *'
                    placeholder='Tuesday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnTuesday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnTuesday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Tuesday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnWednesday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingWednesdayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Wednesday open *'
                    placeholder='Wednesday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='loadingWednesdayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Wednesday close *'
                    placeholder='Wednesday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnWednesday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnWednesday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Wednesday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnThursday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingThursdayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Thursday open *'
                    placeholder='Thursday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='loadingThursdayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Thursday close *'
                    placeholder='Thursday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnThursday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnThursday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Thursday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnFriday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingFridayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Friday open *'
                    placeholder='Friday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='loadingFridayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Friday close *'
                    placeholder='Friday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnFriday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnFriday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Friday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnSaturday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingSaturdayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    defaultValue={field.value}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Saturday open *'
                    placeholder='Saturday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                  />
                )}
              />
              <Controller
                name='loadingSaturdayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    defaultValue={field.value}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Saturday close *'
                    placeholder='Saturday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnSaturday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnSaturday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Saturday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
        <Stack>
          {!getValues().hasNoOperationsOnSunday && (
            <Stack direction='row' spacing={16}>
              <Controller
                name='loadingSundayOpen'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    defaultValue={field.value}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Sunday open *'
                    placeholder='Sunday open *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                  />
                )}
              />
              <Controller
                name='loadingSundayClose'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    defaultValue={field.value}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Sunday close *'
                    placeholder='Sunday close *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                  />
                )}
              />
            </Stack>
          )}
          <InputGroup
            direction='row'
            css={{
              gap: '$space16',
              padding: '$space2',
            }}
          >
            <Controller
              name='hasNoOperationsOnSunday'
              control={control}
              render={({ field }) => {
                return (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(
                            event: ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => {
                            setValue('hasNoOperationsOnSunday', checked, {
                              shouldValidate: true,
                            });
                          }}
                          name={field.name}
                        />
                      }
                      label='No operations on Sunday'
                    />
                  </FormGroup>
                );
              }}
            />
          </InputGroup>
        </Stack>
      </Stack>
    </Stack>
  );
}
