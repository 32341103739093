import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';
import { View } from '@views/View';
import {
  Button,
  CircularProgress,
  Portal,
  Stack,
  TableFooter,
  useMediaQuery,
} from '@mui/material';
import { Heading } from '@components/Heading';
import AddIcon from '@mui/icons-material/Add';
import { useReadLocalStorage } from 'usehooks-ts';
import InviteTeamMemberDialog from './components/InviteTeamMemberDialog';
import { useAuthHttp } from '../../hooks';
import {
  ACTIVATE_TEAM_MEMBER_URL,
  DEACTIVATE_TEAM_MEMBER_URL,
  LIST_USERS_URL,
} from '../../constants';

interface Data {
  email: string;
  status: string;
  name: string;
  roles: string[];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | string[] },
  b: { [key in Key]: number | string | string[] }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Roles',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        color: 'var(--colors-gray12)',
        borderBottomColor: 'var(--colors-gray7)',
        backgroundColor: 'var(--colors-gray2)',
      }}
    >
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all team members',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          Team Members
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title='Filter list'>
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export function Team() {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const [isInviteDialogOpen, setIsInviteDialogOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const authHttp = useAuthHttp();
  const [rows, setRows] = React.useState<Data[]>([]);
  const localUser: { email: string } | null = useReadLocalStorage('user');

  React.useEffect(() => {
    async function fetchTeamMembers() {
      setIsLoading(true);
      try {
        const { data } = await authHttp.post(LIST_USERS_URL);

        if (data)
          setRows(
            data.users.map((user: { isActive: boolean }) =>
              user.isActive
                ? { ...user, status: 'Active' }
                : { ...user, status: 'Deactivated' }
            )
          );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTeamMembers();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  const fetchStatusChange = async (
    url: string,
    newStatus: 'Active' | 'Deactivated',
    email: string
  ) => {
    try {
      const { status } = await authHttp.post(url, {
        email,
      });

      if (status === 200) {
        const updatedRows = rows.map((row) =>
          row.email === email ? { ...row, status: newStatus } : row
        );
        setRows(updatedRows);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
    }
  };

  const handleStatusChange = async (
    e: React.MouseEvent<HTMLButtonElement>,
    email: string,
    currentStatus: string
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentStatus === 'Active') {
      fetchStatusChange(DEACTIVATE_TEAM_MEMBER_URL, 'Deactivated', email);
    } else {
      fetchStatusChange(ACTIVATE_TEAM_MEMBER_URL, 'Active', email);
    }
  };

  if (!rows.length)
    return (
      <Stack
        direction='row'
        gap={16}
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '14.25rem',
        }}
      >
        <CircularProgress size={24} sx={{ fill: 'var(--colors-gray12)' }} />
      </Stack>
    );

  return (
    <View>
      <Stack
        gap={16}
        direction={isTablet ? 'row' : 'column'}
        justifyContent='space-between'
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Team Members
        </Heading>
        <Stack direction='row' gap={16} width={isTablet ? 'auto' : '100%'}>
          <Button
            variant='contained'
            size='large'
            startIcon={<AddIcon />}
            onClick={() => setIsInviteDialogOpen(true)}
            sx={{ textTransform: 'none' }}
            fullWidth
          >
            Invite
          </Button>
        </Stack>
      </Stack>
      <Box sx={{ width: '100%' }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        color='primary'
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component='th'
                      id={labelId}
                      scope='row'
                      padding='none'
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.roles.join(', ')}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    {localUser?.email !== row.email && (
                      <TableCell>
                        <Button
                          variant='contained'
                          onClick={(e) =>
                            handleStatusChange(e, row.email, row.status)
                          }
                        >
                          {row.status === 'Active' ? 'Deactivate' : 'Activate'}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: 'var(--colors-gray12)',
              borderBottomColor: 'var(--colors-gray7)',
              backgroundColor: 'var(--colors-gray2)',
            }}
          />
        </TableContainer>
      </Box>{' '}
      <Portal>
        <InviteTeamMemberDialog
          isOpen={isInviteDialogOpen}
          handleClose={() => setIsInviteDialogOpen(false)}
        />
      </Portal>
    </View>
  );
}
