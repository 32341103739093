import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const FULFILMENT_SERVICES = [
  '3rd Party Couriers',
  'Trans Loading',
  'Cross Docking',
  'Merge In Transit',
  'Returns Logistics',
  'Critical Inventory Logistics',
];

export function Fleet() {
  const { control, getValues, setValue, watch } = useFormContext();
  const watchHasOwnFleet = watch('hasOwnFleet');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const fulfilmentServices = getValues('fulfilmentServices');
    if (checked) {
      setValue('fulfilmentServices', [...fulfilmentServices, value]);
    } else {
      const filteredAnswers = (fulfilmentServices as string[]).filter(
        (answer) => answer !== value
      );

      setValue('fulfilmentServices', filteredAnswers);
    }
  };

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Fleet
      </Heading>
      <InputGroup
        direction='row'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='hasOwnFleet'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setValue('hasOwnFleet', checked, {
                          shouldValidate: true,
                        });
                      }}
                      name={field.name}
                    />
                  }
                  label='I have my own fleet'
                />
              </FormGroup>
            );
          }}
        />
      </InputGroup>
      {watchHasOwnFleet && (
        <>
          <Controller
            name='MultiAxleArticMoreThan26t'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of Artics > 26t'
                placeholder='# of Artics > 26t'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='TwoAxleRigidLessThan26t'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of Rigids < 26t'
                placeholder='# of Rigids < 26t'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='TwoAxleRigid18t'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of <18t rigids'
                placeholder='# of <18t rigids'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='TwoAxleRigidLessThan7AndHalf5t'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of <7.5t rigids'
                placeholder='# of <7.5t rigids'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='LgvLessThan3AndHalft'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of <3.5t LDVs'
                placeholder='# of <3.5t LDVs'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='VanLessThan1AndHalft'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of Vans < 1.5t'
                placeholder='# of Vans < 1.5t'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='SpecialOrOther'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='# of Special/Other'
                placeholder='# of Special/Other'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
        </>
      )}
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Label
          htmlFor='fulfilmentServices'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Fulfilment Services
        </Label>
        <Controller
          name='fulfilmentServices'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                {FULFILMENT_SERVICES.map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={
                          (field.value as string[])?.includes(option) || false
                        }
                        onChange={handleCheckboxChange}
                        name={field.name}
                        value={option}
                      />
                    }
                    label={option}
                  />
                ))}
              </FormGroup>
            );
          }}
        />
      </InputGroup>
    </Stack>
  );
}
