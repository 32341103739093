import { styled } from '@stitchesConfig';

export const StyledText = styled('p', {
  fontFamily: '$primary',
  color: '$gray12',

  variants: {
    size: {
      '2xs': {
        fontSize: '$text2xs',
        lineHeight: '$text2xs',
      },
      xs: {
        fontSize: '$textXs',
        lineHeight: '$textXs',
      },
      sm: {
        fontSize: '$textSm',
        lineHeight: '$textSm',
      },
      md: {
        fontSize: '$textMd',
        lineHeight: '$textMd',
      },
      lg: {
        fontSize: '$textLg',
        lineHeight: '$textLg',
      },
      xl: {
        fontSize: '$textXl',
        lineHeight: '$textXl',
      },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      medium: {
        fontWeight: '$medium',
      },
      semibold: {
        fontWeight: '$semibold',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
});

StyledText.displayName = 'StyledText';
