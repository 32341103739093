import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { StyledInputGroup } from './InputGroup.styles';

interface InputGroupProps extends ComponentPropsWithoutRef<'div'> {
  direction?: 'row' | 'column';
  css?: Stitches.CSS;
}
export function InputGroup({
  children,
  direction = 'column',
  css,
  ...props
}: InputGroupProps) {
  return (
    <StyledInputGroup direction={direction} css={css} role='group' {...props}>
      {children}
    </StyledInputGroup>
  );
}

InputGroup.displayName = 'InputGroup';
