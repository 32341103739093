import { Dispatch, SetStateAction } from 'react';

export const useCloseDialog = (
  setClosed: Dispatch<SetStateAction<boolean>>
) => {
  return (
    event: MouseEvent | KeyboardEvent,
    reason: 'escapeKeyDown' | 'backdropClick'
  ) => {
    if (reason === 'backdropClick') return;
    setClosed(false);
  };
};
