import { Heading } from '@components/Heading';
import { InboundProcess } from '@features/supplyChain/types';
import { Search } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { Text } from '@components/Text';
import {
  SidebarAccordionContainer,
  SidebarAccordion,
  SidebarAccordionSummary,
  SidebarAccordionDetails,
} from '@components/SidebarAccordion';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import { GoodsReceivedNoteSummary } from './GoodsReceivedNoteSummary';
import { getNoticeColors } from '../utils';

interface WarehouseClientInboundSideDrawerProps {
  selectedInboundProcess: InboundProcess;
  setSelectedInboundProcess: (payload: InboundProcess | null) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  selectedInboundProcessHasDescription: boolean;
  setIsViewShipmentNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsViewGoodsReceivedNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  selectedProcessChildType: string | null;
  setSelectedProcessChildType: (payload: string | null) => void;
}

export function WarehouseClientInboundSideDrawer({
  selectedInboundProcess,
  setSelectedInboundProcess,
  isOpen,
  setIsOpen,
  isDesktop,
  selectedInboundProcessHasDescription,
  setIsViewShipmentNoteDialogOpen,
  setIsViewGoodsReceivedNoteDialogOpen,
  selectedProcessChildType,
  setSelectedProcessChildType,
}: WarehouseClientInboundSideDrawerProps) {
  const {
    status,
    createdAt,
    description,
    shipmentNote,
    goodsReceivedNote,
    notices,
  } = selectedInboundProcess;

  const totalItemsQuantity = shipmentNote.skus.reduce(
    (prev, current) => prev + current.skuQuantity,
    0
  );

  const totalReceivedItemsQuantity = goodsReceivedNote?.skus.reduce(
    (prev, current) => prev + current.receivedSkuQuantity,
    0
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isDiscrepancy = totalReceivedItemsQuantity! !== totalItemsQuantity;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const discrepancyAmount = totalItemsQuantity - totalReceivedItemsQuantity!;
  const isNegativeDiscrepancy = discrepancyAmount > 0;

  const hasIssues = (notices?.length ?? 0) > 0;
  const isProcessComplete = !!goodsReceivedNote?.finalisedAt;
  const hasIssuesBeforeProcessIsComplete = hasIssues && !isProcessComplete;

  const onClose = () => {
    setIsOpen(false);
    setSelectedInboundProcess(null);
    setSelectedProcessChildType(null);
  };

  return (
    <Drawer
      hideBackdrop
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={onClose}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: isDesktop ? '32rem' : '100%',
          p: 16,
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          size='medium'
          sx={{
            height: '2.5rem',
            color: (theme) => theme.palette.grey[500],
            mb: '1rem',
            ...(!isDesktop && {
              alignSelf: 'flex-end',
            }),
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ p: 8, width: '100%' }}>
          <Heading as='h2' size='sm' css={{ mb: '$space32' }}>
            Inbound Shipment Note
          </Heading>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Status: </strong>
            </Text>
            <Text as='span' size='md'>
              {status}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Supplier: </strong>
            </Text>
            <Text as='span' size='md'>
              {shipmentNote.supplierName}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Creation date: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Shipment note #: </strong>
            </Text>
            <Text as='span' size='md'>
              {shipmentNote.shipmentNoteNumber}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>ETA: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(shipmentNote.estimatedTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{ mb: 16 }}
            data-testid='sku-count'
          >
            <Text as='span' size='md'>
              <strong>SKUs: </strong>
            </Text>
            <Text as='span' size='md'>
              {shipmentNote.skus.length}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Total Quantity (Items): </strong>
            </Text>
            <Text as='span' size='md'>
              {totalItemsQuantity}
            </Text>
          </Stack>
          {selectedInboundProcessHasDescription && (
            <Stack gap={8} sx={{ mb: hasIssues ? 16 : 32 }}>
              <Text as='h3' size='md'>
                <strong>Description</strong>
              </Text>
              <Text size='md'>{description}</Text>
            </Stack>
          )}
          {hasIssues && (
            <>
              <Text as='h3' size='md' css={{ mb: 16 }}>
                <strong>Issues</strong>
              </Text>
              <Stack
                gap={8}
                sx={{
                  mb: 32,
                }}
              >
                {notices?.map((notice) => (
                  <Stack
                    key={notice}
                    direction='row'
                    alignItems='center'
                    gap={8}
                    sx={{
                      backgroundColor: getNoticeColors(
                        hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                      ).backgroundColor,
                      p: 8,
                      borderRadius: 2,
                    }}
                  >
                    <ErrorIcon
                      sx={{
                        width: 16,
                        height: 16,
                        fill: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).fill,
                      }}
                    />
                    <Text
                      size='sm'
                      css={{
                        color: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).color,
                      }}
                    >
                      {notice}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          <Button
            variant='contained'
            size='large'
            startIcon={<Search />}
            fullWidth
            sx={{ textTransform: 'none', mb: 16 }}
            onClick={() => {
              setIsOpen(false);
              setIsViewShipmentNoteDialogOpen(true);
            }}
          >
            View Shipment Note
          </Button>
          {goodsReceivedNote?.finalisedAt && (
            <SidebarAccordionContainer>
              <SidebarAccordion
                expanded={selectedProcessChildType === 'goodsReceivedNote'}
                setSelectedProcessChildType={setSelectedProcessChildType}
              >
                <SidebarAccordionSummary
                  title='Goods Received Note'
                  ariaControls='goods-received-note-content'
                  id='goods-received-note-header'
                />
                <SidebarAccordionDetails>
                  <Stack gap={8}>
                    <GoodsReceivedNoteSummary
                      selectedInboundProcess={selectedInboundProcess}
                      totalReceivedItemsQuantity={totalReceivedItemsQuantity}
                      isDiscrepancy={isDiscrepancy}
                      discrepancyAmount={discrepancyAmount}
                      isNegativeDiscrepancy={isNegativeDiscrepancy}
                    />
                    <Button
                      variant='contained'
                      size='large'
                      startIcon={<Search />}
                      fullWidth
                      sx={{ textTransform: 'none', mt: '0.5rem' }}
                      onClick={() => {
                        setIsOpen(false);
                        setIsViewGoodsReceivedNoteDialogOpen(true);
                      }}
                    >
                      View Goods Received Note
                    </Button>
                  </Stack>
                </SidebarAccordionDetails>
              </SidebarAccordion>
            </SidebarAccordionContainer>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
