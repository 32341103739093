import { SyntheticEvent, useState } from 'react';
import { Heading } from '@components/Heading';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { HERE_APIKEY } from '../../../../constants';

export function InitialSetup() {
  const { control, setValue } = useFormContext();
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [options, setOptions] = useState([]);

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleFetchSuggestions = (input: string) => {
    fetch(
      `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items = data.items.map(
            (item: {
              id: any;
              address: { label: string; city: string; countryName: string };
            }) => ({
              id: item.id,
              label: item.address.label,
              city: item.address.city,
              country: item.address.countryName,
            })
          );
          setOptions(items);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const handleOptionSelect = async (event: any, value: any) => {
    if (value && value.id) {
      setValue('address', value.label);
      setValue('city', value.city);
      setValue('country', value.country);
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            const { lat, lng: lon } = position;

            const location = {
              lat,
              lon,
            };

            setValue('location', location);
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Initial setup
      </Heading>
      <Controller
        name='name'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Warehouse name *'
            placeholder='Warehouse name *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='description'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Warehouse description'
            placeholder='Warehouse description'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='address'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            freeSolo
            disablePortal
            options={options}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            filterOptions={(options, state) => options}
            inputValue={inputValue ?? field.value}
            onInputChange={(event, newInputValue) => {
              handleInputChange(event, newInputValue);
              handleFetchSuggestions(newInputValue);
            }}
            onChange={(e, value) => {
              handleOptionSelect(e, value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Location *'
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
              />
            )}
          />
        )}
      />
      <Typography>* is required</Typography>
    </Stack>
  );
}
