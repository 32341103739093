/* eslint-disable no-nested-ternary */
import { OutboundProcess } from '@features/supplyChain/types';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { PalletCalculator } from '@components/PalletCalculator';

interface PickingConfirmationDetailsProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  primaryAction?: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}
export function PickingConfirmationDetails({
  selectedOutboundProcess,
  onClose,
  primaryAction,
  isDesktop,
  isTablet,
}: PickingConfirmationDetailsProps) {
  const { despatchNote, pickingConfirmation } = selectedOutboundProcess;

  return (
    <>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking note #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess?.pickingConfirmation?.pickingNoteNumber}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picked by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {pickingConfirmation?.createdByName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(pickingConfirmation?.actualPickingDate).format(
                'DD/MM/YYYY'
              )}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Customer name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess.despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required time of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(
                selectedOutboundProcess.despatchNote.requiredTimeOfArrival
              ).format('DD/MM/YYYY, H:mm')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(pickingConfirmation?.actualPickingDate)
                .add(1, 'day')
                .format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 7' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '7 / 9' }}
              >
                Ordered SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '9 / 11' }}
              >
                Picked SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '11 / 14' }}
              >
                Picked Pallet Q-ty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem'>
          {pickingConfirmation?.skus.map((sku, index) => {
            const isDiscrepancy = sku.discrepancyReason !== null;
            const discrepancyAmount =
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              despatchNote.skus.find((s) => s.skuId === sku.skuId)!
                .skuQuantity - sku.pickedQuantity;
            const isNegativeDiscrepancy = discrepancyAmount > 0;
            const skuQuantity = despatchNote.skus.find(
              (s) => s.skuId === sku.skuId
            )?.skuQuantity;

            const hasPackagingInfo =
              despatchNote.skus.find((s) => s.skuId === sku.skuId)
                ?.packagingDetails.packagingInfo?.amountOfSkusInLayer &&
              despatchNote.skus.find((s) => s.skuId === sku.skuId)
                ?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

            let amountOfSkusInLayer: number | undefined;
            let amountOfLayersInPallet: number | undefined;
            let packagingInfoType: string | undefined;
            let palletisingType: string | null | undefined;

            if (hasPackagingInfo) {
              amountOfSkusInLayer = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.amountOfSkusInLayer;

              amountOfLayersInPallet = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

              packagingInfoType = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.type;
            } else {
              palletisingType = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.palletisingType;
            }

            return (
              <>
                <Box
                  key={sku.skuId}
                  display='grid'
                  gridTemplateColumns='2rem repeat(12, 1fr)'
                  columnGap='1rem'
                  rowGap='1rem'
                  alignItems={isDesktop ? 'center' : 'flex-start'}
                >
                  {/* SKU index  */}
                  <Stack
                    gridColumn={isDesktop ? '1 / 2' : '1 / 2'}
                    justifyContent='center'
                  >
                    <Chip label={index + 1} />
                  </Stack>
                  {/* SKU ID */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '2 / 4' : isTablet ? '2 / 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        SKU ID
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.skuIdentifier}
                    </Text>
                  </Stack>
                  {/* Product name */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '4 / 7' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Product name
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.productName} {`(${sku.name})`}
                    </Text>
                  </Stack>
                  {/* SKU Qty */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '7 / 9' : isTablet ? '2 / 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Ordered SKU Q-ty
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {skuQuantity}
                    </Text>
                  </Stack>
                  {/* Picked Qty */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '9 / 11' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Picked SKU Q-ty
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.pickedQuantity}
                      {sku.discrepancyReason && (
                        <>
                          {' '}
                          <Text
                            as='span'
                            size='md'
                            css={{
                              color: 'var(--colors-tomato9)',
                            }}
                          >
                            {`(${isNegativeDiscrepancy ? '-' : '+'}${
                              isNegativeDiscrepancy
                                ? discrepancyAmount
                                : discrepancyAmount.toString().substring(1)
                            })`}
                          </Text>
                        </>
                      )}
                    </Text>
                  </Stack>

                  {/* Picked Pallet Qty */}
                  {hasPackagingInfo ? (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '11 / 14' : isTablet ? '2 / 7' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '5'}
                    >
                      {!isDesktop && (
                        <Text as='p' size='sm' weight='semibold'>
                          Picked Pallet Q-ty
                        </Text>
                      )}
                      <Text as='p' size='md'>
                        <PalletCalculator
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          palletName={packagingInfoType!}
                          skuQuantity={sku.pickedQuantity}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          amountOfSkusInLayer={amountOfSkusInLayer!}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          amountOfLayersInPallet={amountOfLayersInPallet!}
                        />
                      </Text>
                    </Stack>
                  ) : (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '11 / 14' : isTablet ? '2 / 7' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '5'}
                    >
                      {!isDesktop && (
                        <Text as='p' size='sm' weight='semibold'>
                          Picked Pallet Q-ty
                        </Text>
                      )}
                      <Text as='p' size='md'>
                        {palletisingType}
                      </Text>
                    </Stack>
                  )}

                  {sku.packagingComment && (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '9 / 14' : isTablet ? '7 / 14' : '2 / 14'
                      }
                    >
                      <Text as='p' size='sm' weight='semibold'>
                        Packaging comment
                      </Text>
                      <Text as='p' size='md'>
                        {sku.packagingComment}
                      </Text>
                    </Stack>
                  )}

                  {/* Discrepancy reason */}
                  {isDiscrepancy && (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '9 / 14' : isTablet ? '7 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '2' : isTablet ? '3' : '6'}
                    >
                      <>
                        <Text as='p' size='sm' weight='semibold'>
                          Discrepancy reason
                        </Text>
                        <Text as='p' size='md'>
                          {sku.discrepancyReason}
                        </Text>
                      </>
                    </Stack>
                  )}
                </Box>
                {index !== pickingConfirmation.skus.length - 1 && (
                  <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
                )}
              </>
            );
          })}
        </Stack>
      </Stack>
      {pickingConfirmation?.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {pickingConfirmation.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction={isDesktop ? 'row' : 'column-reverse'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          size='large'
          variant='outlined'
          fullWidth={!isDesktop}
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
