import * as Yup from 'yup';

export const defaultValues = {
  secret: '',
  authenticatorCode: '',
};

const googleAuthenticatorNumberOfCharacters = 6;

export const validationSchema = Yup.object({
  authenticatorCode: Yup.string()
    .required('2FA Authenticator Code is required')
    .min(
      googleAuthenticatorNumberOfCharacters,
      `Authenticator code must be ${googleAuthenticatorNumberOfCharacters} characters`
    )
    .max(
      googleAuthenticatorNumberOfCharacters,
      `Authenticator code must be ${googleAuthenticatorNumberOfCharacters} characters`
    ),
});
