import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const SYSTEMS = ['WMS', 'TMS', 'WiFi', 'API', 'EDI', 'Other'];

const SAFETY_AND_SECURITY = [
  '24/7 Security',
  '24/7 Access',
  'Bunded',
  'Caged',
  'Sprinklered',
  'Alarmed',
  'Patrolled',
  'Fenced Yard',
  'CCTV',
  'ESFR Sprinkler System',
];

const ACCREDITATIONS = [
  'None',
  'Amazon Inbound Preferred Carrier (IPCP)',
  'BRC',
  'CBP Supply Chain Security',
  'MHRA',
  'Organic Food Federation',
  'Organic Farmers and Growers',
  'SOIL',
  'UKWA',
];

export function Capabilities() {
  const { control, getValues, setValue, watch } = useFormContext();

  const handleSystemsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const systems = getValues('systemsAvailable');
    if (checked) {
      setValue('systemsAvailable', [...systems, value]);
    } else {
      const filteredAnswers = (systems as string[]).filter(
        (answer) => answer !== value
      );

      setValue('systemsAvailable', filteredAnswers);
    }
  };

  const handleSafetyAndSecurityCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const safetyAndSecurity = getValues('safetyAndSecurity');
    if (checked) {
      setValue('safetyAndSecurity', [...safetyAndSecurity, value]);
    } else {
      const filteredAnswers = (safetyAndSecurity as string[]).filter(
        (answer) => answer !== value
      );

      setValue('safetyAndSecurity', filteredAnswers);
    }
  };

  const handleAccreditationsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const accreditations = getValues('accreditationsAndMemberships');
    if (checked) {
      if (value === 'None') {
        setValue('accreditationsAndMemberships', [value]);
      } else {
        const removedNone = (accreditations as string[]).filter(
          (answer) => answer !== 'None'
        );
        setValue('accreditationsAndMemberships', [...removedNone, value]);
      }
    } else {
      const filteredAnswers = (accreditations as string[]).filter(
        (answer) => answer !== value
      );

      setValue('accreditationsAndMemberships', filteredAnswers);
    }
  };

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Capabilities
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='accreditationsAndMemberships'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Label
                  htmlFor='accreditationsAndMemberships'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                    color: error ? '#d32f2f' : 'initial',
                  }}
                >
                  Accreditations *
                </Label>
                {Boolean(error) && (
                  <Typography color='error' fontSize='0.75rem' paddingTop={4}>
                    {error?.message}
                  </Typography>
                )}
                <FormGroup>
                  {ACCREDITATIONS.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleAccreditationsCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='systemsAvailable'
          control={control}
          render={({ field }) => {
            return (
              <>
                <Label
                  htmlFor='systemsAvailable'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                  }}
                >
                  Systems
                </Label>
                <FormGroup>
                  {SYSTEMS.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleSystemsCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='safetyAndSecurity'
          control={control}
          render={({ field }) => {
            return (
              <>
                <Label
                  htmlFor='safetyAndSecurity'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                  }}
                >
                  Safety and security
                </Label>
                <FormGroup>
                  {SAFETY_AND_SECURITY.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleSafetyAndSecurityCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
    </Stack>
  );
}
