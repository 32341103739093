import { styled } from '@stitchesConfig';

export const StyledInputGroup = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '$space8',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderWidth: '0.125rem',

  variants: {
    direction: {
      row: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      column: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
});

StyledInputGroup.displayName = 'StyledInputGroup';
