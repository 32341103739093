import { Button } from '@components/Button';
import moment from 'moment';
import { BillingCycle } from './invoiceTypes';

export function InvoicePaymentDetails({
  isLsp,
  hasConfirmed,
  hasPaid,
  billingCycle,
  invoiceNotes,
  dueDate,
  isConfirming,
  handleConfirmInvoice,
  setHasPaid,
}: {
  isLsp?: boolean;
  hasConfirmed?: boolean;
  hasPaid?: boolean;
  billingCycle: BillingCycle;
  invoiceNotes: string;
  dueDate: string;
  isConfirming?: boolean;
  handleConfirmInvoice?: () => void;
  setHasPaid: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const createdAt = moment(billingCycle.invoice.createdAt);
  const today = moment();
  const elapsedDays = today.diff(createdAt, 'days');

  return (
    <>
      <div className='mt-8'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='space-y-4'>
            <div>
              Beneficiary Bank:{' '}
              {isLsp ? billingCycle.lspBankName : 'VCL Trading Europe Ltd'}
            </div>
            <div>
              Sort Code:{' '}
              {isLsp
                ? billingCycle.lspBankSortCode
                : import.meta.env.VITE_VCL_SORT_CODE}
            </div>
            <div>
              Account Number:{' '}
              {isLsp
                ? billingCycle.lspBankAccountNumber
                : import.meta.env.VITE_VCL_ACCOUNT_NUMBER}
            </div>
            <div>
              BIC/SWIFT:{' '}
              {isLsp
                ? billingCycle.lspBankSwiftCode
                : import.meta.env.VITE_VCL_BIC_SWIFT}
            </div>
            <div>
              IBAN:{' '}
              {isLsp ? billingCycle.lspBankIban : import.meta.env.VITE_VCL_IBAN}
            </div>
          </div>
          <div className='space-y-4'>
            {hasConfirmed && !hasPaid && (
              <div>
                Payment due date: {moment(dueDate).format('DD/MM/YYYY')}
              </div>
            )}
            {hasPaid && (
              <>
                <div>Payment date: {today.format('DD/MM/YYYY')}</div>
                <div>Transaction reference: </div>
              </>
            )}
            {!hasConfirmed && isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={handleConfirmInvoice}
                disabled={isConfirming}
              >
                {isConfirming ? 'Confirming...' : 'Confirm'}
              </Button>
            )}
            {hasConfirmed && !hasPaid && isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={() => {
                  setHasPaid(true);
                }}
                disabled={false} // Set this to true when demo is over
              >
                Finance
              </Button>
            )}
            {!hasPaid && !isLsp && (
              <Button
                variant='primary'
                size='lg'
                onClick={() => {
                  setHasPaid(true);
                }}
              >
                Pay Invoice
              </Button>
            )}
            {!hasConfirmed && isLsp && (
              <div>Elapsed from PI issue date: {elapsedDays} days</div>
            )}
          </div>
        </div>
      </div>
      {!hasConfirmed && isLsp && (
        <>
          <div className='mt-8'>Invoice note(s):</div>
          <div>
            <textarea
              id='comments'
              name='comments'
              rows={4}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm'
              placeholder='Enter any additional invoice notes here...'
            />
          </div>
        </>
      )}
      {hasConfirmed && (
        <>
          <div className='mt-8'>Invoice note(s):</div>
          <div>{invoiceNotes}</div>
        </>
      )}
    </>
  );
}
