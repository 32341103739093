import {
  ComponentPropsWithoutRef,
  forwardRef,
  HTMLInputTypeAttribute,
} from 'react';
import * as Stitches from '@stitches/react';
import { StyledInput } from './Input.styles';

interface InputProps extends ComponentPropsWithoutRef<'input'> {
  type?: HTMLInputTypeAttribute;
  isInvalid?: boolean;
  css?: Stitches.CSS;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ css, type = 'text', isInvalid, ...props }, forwardedRef) => (
    <StyledInput
      type={type}
      isInvalid={isInvalid}
      css={css}
      {...props}
      ref={forwardedRef}
    />
  )
);

Input.displayName = 'Input';
