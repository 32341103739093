/* eslint-disable no-nested-ternary */
import { Fragment } from 'react';
import { InboundProcess } from '@features/supplyChain/types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack/Stack';
import dayjs from 'dayjs';
import { Text } from '@components/Text';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

interface GoodsReceivedNoteDetailsProps {
  selectedInboundProcess: InboundProcess;
  onClose: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function GoodsReceivedNoteDetails({
  selectedInboundProcess,
  onClose,
  isDesktop,
  isTablet,
}: GoodsReceivedNoteDetailsProps) {
  const { userBusinessName, status } = selectedInboundProcess;
  const { estimatedTimeOfArrival } = selectedInboundProcess.shipmentNote;
  const createdAt = selectedInboundProcess.goodsReceivedNote?.createdAt;
  const skus = selectedInboundProcess.goodsReceivedNote?.skus;
  const comment = selectedInboundProcess.goodsReceivedNote?.comment;
  return (
    <>
      <Stack
        spacing={isTablet ? 16 : 8}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Client name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{userBusinessName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedInboundProcess.goodsReceivedNote?.finalisedByName}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Status
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{status}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Original ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(estimatedTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Actual date of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
              data-testid='sku-grid'
            >
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '4 / 8' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                SKU name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Despatched Qty
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Delivered Qty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem' data-testid='sku-items-container'>
          {skus?.map((sku, index) => {
            const isDiscrepancy = sku.discrepancyReason !== null;
            const { despatchedSkuQuantity, receivedSkuQuantity } = sku;
            const discrepancyAmount =
              despatchedSkuQuantity - receivedSkuQuantity;
            const isNegativeDiscrepancy = discrepancyAmount > 0;

            return (
              <Fragment key={sku.skuId}>
                <Box
                  display='grid'
                  gridTemplateColumns='2rem repeat(12, 1fr)'
                  columnGap='1rem'
                  rowGap='1rem'
                  alignItems={isDesktop ? 'center' : 'flex-start'}
                >
                  {/* SKU index  */}
                  <Stack
                    gridColumn={isDesktop ? '1 / 2' : '1 / 2'}
                    justifyContent='center'
                  >
                    <Chip label={index + 1} />
                  </Stack>
                  {/* SKU ID */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '2 / 4' : isTablet ? '2 / 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        SKU ID
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.skuIdentifier}
                    </Text>
                  </Stack>
                  {/* Product name */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '4 / 8' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Product name
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.productName}
                    </Text>
                  </Stack>
                  {/* Sku name */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '8 / 10' : isTablet ? '2 / 7' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        SKU name
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {sku.name}
                    </Text>
                  </Stack>
                  {/* Despatched Qty */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '10 / 12' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Despatched Quantity
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {despatchedSkuQuantity}
                    </Text>
                  </Stack>
                  {/* Delivered Qty */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='semibold'>
                        Delivered Quantity
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {receivedSkuQuantity}
                      {sku.discrepancyReason && (
                        <>
                          {' '}
                          <Text
                            as='span'
                            size='md'
                            css={{
                              color: 'var(--colors-tomato9)',
                            }}
                          >
                            {`(${isNegativeDiscrepancy ? '-' : '+'}${
                              isNegativeDiscrepancy
                                ? discrepancyAmount
                                : discrepancyAmount.toString().substring(1)
                            })`}
                          </Text>
                        </>
                      )}
                    </Text>
                  </Stack>
                  {/* Discrepancy reason */}
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '10 / 14' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    {isDiscrepancy && (
                      <>
                        <Text as='p' size='sm' weight='semibold'>
                          Discrepancy reason
                        </Text>
                        <Text as='p' size='md'>
                          {sku.discrepancyReason}
                        </Text>
                      </>
                    )}
                  </Stack>
                </Box>
                {index !== skus.length - 1 && (
                  <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Stack>
      {comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }} data-testid='grn-comment'>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
          fullWidth={!isDesktop}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
