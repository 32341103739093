/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useState } from 'react';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { EmptyState } from '@components/EmptyState';
import {
  MdAdd,
  MdDelete,
  MdDownload,
  MdOutlineInfo,
  MdUploadFile,
} from 'react-icons/md';
import { View } from '@views/View';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import { FleetCard, FleetList } from '@components/FleetList';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { Alert, Link, Snackbar, Stack, Typography } from '@mui/material';
import { VehicleForm } from '@forms/manager/AddVehicleForm';
import { useAtom, useAtomValue } from 'jotai';
import { Icon } from '@components/Icon';
import { useNavigate } from 'react-router-dom';
import { VehicleFormValues } from '@forms/manager/AddVehicleForm/VehicleForm';
import { DialogModal } from '@components/DialogModal';
import { VehicleSummary } from '@components/VehicleSummary';
import { Dropzone } from '@components/Dropzone';
import { StyledFleetMasterHeaderContainer } from './FleetMasterStyles';
import { useInventory } from './hooks/useInventory';
import {
  editVehicleIdAtom,
  isUploadFleetModalOpenAtom,
  isVehicleModalOpenAtom,
  isVehicleSummaryModalOpenAtom,
} from '../../store/jotai';
import {
  FLEET_GET,
  UPLOAD_FLEET_MASTER_URL,
  VEHICLE_CREATE,
  VEHICLE_UPDATE,
} from '../../constants';
import { useAuthHttp, useCloseDialog } from '../../hooks';
import { Fleet, VehicleSummary as Vehicle, fleetMapping } from './types';

export function FleetMaster() {
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useAtom(
    isVehicleModalOpenAtom
  );
  const [isVehicleSummaryModalOpen, setIsVehicleSummaryModalOpen] = useAtom(
    isVehicleSummaryModalOpenAtom
  );
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(
    undefined
  );
  const [isUploadFleetModalOpen, setIsUploadFleetModalOpen] = useAtom(
    isUploadFleetModalOpenAtom
  );
  const [uploadFile, setUploadFile] = useState<any>(undefined);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<
    { message: string; row: number }[]
  >([]);
  const [isFleetLoading, setIsFleetLoading] = useState(true);
  const [hasCreatedVehicle, setHasCreatedVehicle] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [editVehicleId, setEditVehicleId] = useAtom(editVehicleIdAtom);
  const [editVehicle, setEditVehicle] = useState<Vehicle | undefined>();

  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const navigate = useNavigate();

  const isSmall = useMediaQuery('(min-width: 36em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  useDocumentTitle('FLOX - Fleet');

  const { getProductsScrollParent } = useInventory();

  const fetchFleet = async () => {
    try {
      const { data } = await authHttp.post(FLEET_GET, {
        pagination: {
          itemsPerPage: isDesktop ? 100 : 25,
        },
      });

      if (data) setVehicles(data.vehicles);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsFleetLoading(false);
      setIsAddVehicleModalOpen(false);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  const handleCloseVehicleSummaryModal = useCloseDialog(
    setIsVehicleSummaryModalOpen
  );

  const handleViewVehicleSummary = (vehicleId: string) => {
    setSelectedVehicle(
      vehicles.find((vehicle) => vehicle.vehicleId === vehicleId)
    );
    setIsVehicleSummaryModalOpen(true);
  };

  useEffect(() => {
    fetchFleet();
  }, []);

  useEffect(() => {
    if (!hasCreatedVehicle) return;
    fetchFleet();
  }, [hasCreatedVehicle]);

  useEffect(() => {
    if (!isAddVehicleModalOpen) return;
    setHasCreatedVehicle(false);
  }, [isAddVehicleModalOpen]);

  const handleOnSubmit = async (data: VehicleFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: vehicleData } = await authHttpFormData.post(
        editVehicleId ? VEHICLE_UPDATE : VEHICLE_CREATE,
        {
          vehicle: {
            vehicleId: editVehicleId,
            model: data.model,
            registrationNumber: data.registrationNumber,
            vehicleType: data.vehicleType,
            vehicleCategory: data.vehicleCategory,
            specialEquipment: data.specialEquipment,
            vehicleCapacity: {
              maxCapacityDimensions: {
                width: data.vehicleCapacity.maxCapacityDimensions.width,
                length: data.vehicleCapacity.maxCapacityDimensions.length,
                height: data.vehicleCapacity.maxCapacityDimensions.height,
                dimensionsUnitOfMeasure:
                  data.vehicleCapacity.maxCapacityDimensions
                    .dimensionsUnitOfMeasure,
              },
              maxPallets: data.vehicleCapacity.maxPallets,
              maxKilograms: data.vehicleCapacity.maxKilograms,
            },
            fuelType: data.fuelType,
            emissionStandard: data.emissionStandard,
            averageAnnualMileage: data.averageAnnualMileage,
            averageAnnualMileageUnits: data.averageAnnualMileageUnits,
            deletedImageIds: data.deletedImagesIds,
          },
          images: data.vehicleImages,
          deletedImageIds: data.deletedImagesIds,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (vehicleData) {
        if (editVehicleId) setEditVehicleId('');
        fetchFleet();
        setHasCreatedVehicle(true);
        // setIsSnackbarOpen(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // const handleUploadFleetMaster = () => {
  //   alert('Waiting on backend to make this endpoint :)');
  // };

  const handleUploadFleetMaster = async () => {
    try {
      setIsUploadLoading(true);
      const { data } = await authHttpFormData.post(
        UPLOAD_FLEET_MASTER_URL,
        {
          productFile: uploadFile,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (data) {
        setUploadFile(undefined);
        setHasUploadError(false);
        setIsUploadFleetModalOpen(false);
        fetchFleet();
      }
    } catch (error: any) {
      const errorResponse = error as any;
      // console.log('errors', errorResponse.response.data.errors);
      setHasUploadError(true);
      setUploadErrors(errorResponse.response.data.errors);
    } finally {
      setIsUploadLoading(false);
    }
  };

  const sortedVehicles = vehicles.sort((a, b) =>
    a.vehicleType.toUpperCase().localeCompare(b.vehicleType.toUpperCase())
  );

  const groupedVehicles: { [key: string]: Vehicle[] } = {};

  sortedVehicles.forEach((vehicle) => {
    const { vehicleType } = vehicle;
    if (!groupedVehicles[vehicleType]) {
      groupedVehicles[vehicleType] = [];
    }
    groupedVehicles[vehicleType].push(vehicle);
  });

  useEffect(() => {
    if (editVehicleId) {
      setEditVehicle(
        vehicles.find((vehicle) => vehicle.vehicleId === editVehicleId)
      );
    } else {
      setEditVehicle(undefined);
    }
  }, [editVehicleId]);

  return (
    <View>
      <StyledFleetMasterHeaderContainer>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Fleet master
        </Heading>
        <Stack sx={{ flexDirection: isDesktop ? 'row' : 'column' }} gap={12}>
          <Button
            variant='primary'
            action='cta'
            size={isDesktop ? 'md' : 'sm'}
            leftIcon={<MdAdd size='1.5rem' />}
            onClick={() => setIsUploadFleetModalOpen(true)}
          >
            Upload Fleet Master
          </Button>
          <Button
            variant='primary'
            action='cta'
            size={isDesktop ? 'md' : 'sm'}
            leftIcon={<MdAdd size='1.5rem' />}
            onClick={() => setIsAddVehicleModalOpen(true)}
          >
            Add Vehicle
          </Button>
        </Stack>
      </StyledFleetMasterHeaderContainer>
      <InfiniteScroll
        element={FleetList as unknown as ReactNode}
        initialLoad
        loadMore={() => {}}
        hasMore={false}
        useWindow={false}
        getScrollParent={getProductsScrollParent}
        loader={
          <InfiniteScrollLoading
            key='fleet-loader'
            loadingMessage='Loading vehicles...'
            sx={{ gridColumn: '1 / -1' }}
          />
        }
      >
        {isFleetLoading && !vehicles.length && (
          <InfiniteScrollLoading
            key='fleet-loader'
            loadingMessage='Loading vehicles...'
            sx={{ gridColumn: '1 / -1' }}
          />
        )}
        {!isFleetLoading && !vehicles.length && (
          <EmptyState
            icon={<MdOutlineInfo size='8rem' />}
            title='No vehicles found.'
            subtitle='Add a vehicle to get started.'
            primaryAction={
              <Stack
                sx={{ flexDirection: isDesktop ? 'row' : 'column' }}
                gap={12}
              >
                <Button
                  action='cta'
                  leftIcon={<MdAdd size='1.5rem' />}
                  onClick={() => setIsUploadFleetModalOpen(true)}
                >
                  Upload Fleet Master
                </Button>
                <Button
                  action='cta'
                  leftIcon={<MdAdd size='1.5rem' />}
                  onClick={() => setIsAddVehicleModalOpen(true)}
                >
                  Add a vehicle
                </Button>
              </Stack>
            }
            css={{ gridColumn: '1 / -1' }}
          />
        )}
        <Stack gap={36}>
          {Object.entries(groupedVehicles).map(([vehicleType, $vehicles]) => (
            <Stack key={vehicleType} gap={12}>
              <h2>{fleetMapping[vehicleType as Fleet] || vehicleType}</h2>
              <Stack direction='row' gap={12}>
                {$vehicles.map((vehicle, index) => (
                  <FleetCard
                    {...(index === 0 && { id: 'first' })}
                    key={vehicle.vehicleId}
                    vehicleSummary={vehicle}
                    orientation={isTablet ? 'horizontal' : 'vertical'}
                    onClick={() => handleViewVehicleSummary(vehicle.vehicleId)}
                  />
                ))}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </InfiniteScroll>
      <Modal
        open={
          isAddVehicleModalOpen || (!!editVehicleId && !!editVehicle?.vehicleId)
        }
        onOpenChange={(isOpen: boolean) => {
          setIsAddVehicleModalOpen(isOpen);
          setEditVehicleId('');
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader
            title={editVehicle?.vehicleId ? 'Edit Vehicle' : 'Add Vehicle'}
          />
          <VehicleForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
            prefilledValues={editVehicle}
          />
        </ModalContainer>
      </Modal>
      <Modal
        open={isUploadFleetModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsUploadFleetModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Upload Fleet Master' />
          <ModalContent
            css={{
              maxHeight: 'calc(85vh - 12.5rem)',
              minHeight: 'calc(85vh - 12.5rem)',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Stack
              maxHeight='400px'
              gap={12}
              alignItems='center'
              sx={{ maxWidth: { xs: '100%', md: '50%' } }}
            >
              <Link href='/files/fleet_master_template.xlsx' width='100%'>
                <Button
                  variant='primary'
                  action='cta'
                  size={isDesktop ? 'md' : 'sm'}
                  leftIcon={<MdDownload size='1.5rem' />}
                  isFullWidth
                >
                  Download excel template
                </Button>
              </Link>
              <Typography variant='body2' sx={{ fontWeight: 600 }}>
                OR
              </Typography>
              {uploadFile ? (
                <Stack gap={12} width='100%'>
                  <Stack direction='row' gap={6} alignItems='center'>
                    <MdUploadFile size='1rem' />
                    <Typography
                      variant='body2'
                      sx={{ fontWeight: 600, fontSize: '1rem' }}
                    >
                      {uploadFile.name}
                    </Typography>
                  </Stack>
                  <Button
                    variant='primary'
                    action='cta'
                    size={isDesktop ? 'md' : 'sm'}
                    leftIcon={<MdUploadFile size='1.5rem' />}
                    onClick={handleUploadFleetMaster}
                    isLoading={isUploadLoading}
                    loadingIcon={<Icon name='loading' />}
                    loadingText='Uploading...'
                  >
                    Upload Product Master
                  </Button>
                  <Button
                    variant='primary'
                    action='danger'
                    leftIcon={<MdDelete size='1rem' />}
                    size={isDesktop ? 'md' : 'sm'}
                    onClick={() => {
                      setHasUploadError(false);
                      setUploadFile(undefined);
                    }}
                  >
                    Clear selected file
                  </Button>
                  {hasUploadError && (
                    <Stack gap={2}>
                      <Typography
                        variant='body2'
                        sx={{ color: 'red', fontWeight: 600 }}
                      >
                        Error uploading file. Please fix the following errors
                        and try again.
                      </Typography>
                      <>
                        {uploadErrors.map((error) => (
                          <Typography
                            key={error.message}
                            variant='body2'
                            sx={{ color: 'red' }}
                          >
                            Row {error.row}: {error.message}
                          </Typography>
                        ))}
                      </>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Dropzone
                  id='fleet-master-dropzone'
                  acceptAnyFile
                  primaryAction={
                    <Button
                      size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                      leftIcon={<MdAdd size={isSmall ? '1.25rem' : '1rem'} />}
                    >
                      Upload Fleet Master
                    </Button>
                  }
                  onDrop={(acceptedFiles: any) => {
                    setUploadFile(acceptedFiles[0]);
                  }}
                />
              )}
            </Stack>
          </ModalContent>
        </ModalContainer>
      </Modal>
      <DialogModal
        open={isVehicleSummaryModalOpen}
        setOpen={(isOpen: boolean) => {
          setSelectedVehicle(undefined);
          setIsVehicleSummaryModalOpen(isOpen);
        }}
        onClose={handleCloseVehicleSummaryModal}
        title='Vehicle Summary'
        id='vehicle-summary-dialog-title'
      >
        <VehicleSummary
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedVehicle={selectedVehicle!}
          setOpen={(isOpen: boolean) => {
            setSelectedVehicle(undefined);
            setIsVehicleSummaryModalOpen(isOpen);
          }}
          isTablet={isTablet}
          isDesktop={isDesktop}
        />
      </DialogModal>
      {/* <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='info'
          sx={{ width: '100%' }}
        >
          Your warehouse profile is being processed. To ensure your prospective
          clients access accurate and up-to-date information, we may need
          additional details or verify certain data for your profile. If
          required, we&apos;ll reach out via email. Once cleared, your service
          listing will go live on FLOX. Thank you!
        </Alert>
      </Snackbar> */}
    </View>
  );
}
