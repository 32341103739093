import { Text } from '@components/Text';
import {
  StyledFormProgress,
  StyledProgressRoot,
  StyledProgressIndicator,
  StyledFormProgressStep,
} from './FormProgress.styles';

interface FormProgressProps {
  totalSteps: number;
  currentStep: number;
  currentStepName: string;
  previousStepName?: string;
  nextStepName?: string;
}

export function FormProgress({
  totalSteps,
  currentStep,
  currentStepName,
  previousStepName,
  nextStepName,
}: FormProgressProps) {
  const progress: number = (currentStep / totalSteps) * 100;

  return (
    <StyledFormProgress>
      <StyledFormProgressStep>
        {previousStepName && (
          <Text size='2xs' weight='semibold' css={{ alignSelf: 'center' }}>
            Previous:
            <br />
            <Text as='span' size='2xs'>
              {previousStepName}
            </Text>
          </Text>
        )}
        <Text
          weight='bold'
          css={{ gridColumn: '2', textAlign: 'center', alignSelf: 'center' }}
        >
          {currentStepName}
        </Text>
        {nextStepName && (
          <Text
            size='2xs'
            weight='semibold'
            css={{ textAlign: 'right', alignSelf: 'center' }}
          >
            Next:
            <br />
            <Text as='span' size='2xs'>
              {nextStepName}
            </Text>
          </Text>
        )}
      </StyledFormProgressStep>
      <StyledProgressRoot>
        <StyledProgressIndicator style={{ width: `${progress}%` }} />
      </StyledProgressRoot>
    </StyledFormProgress>
  );
}
