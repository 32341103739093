export const VAT_RATE = 20;

export type ServiceRates = {
  palletStoragePricePerWeek: { acceptedValue: number };
  palletReceiptAndPutAwayPrice: {
    acceptedValue: number;
  };
  palletPickingAndDespatchPrice: {
    acceptedValue: number;
  };
  casePickingAndDespatchPrice: { acceptedValue: number };
  itemPickingAndDespatchPrice: { acceptedValue: number };
  containerHandlingPrice: { acceptedValue: number };
  demurragePricePerDay: { acceptedValue: number };
  palletReturnsPrice: { acceptedValue: number };
  itemReturnsPrice: { acceptedValue: number };
  caseReturnsPrice: { acceptedValue: number };
  auxiliaryServicePricePerManHour: {
    acceptedValue: number;
  };
  additionalSurchargesPercentage: {
    acceptedValue: number;
  };
};

export type InvoiceLine = {
  id: string;
  lineNumber: number;
  description: string;
  amount: number;
  currencyCode: string;
  currencySymbol: string;
  vatRatePercentage: number;
};

export type InvoiceAdditionalService = {
  id: string;
  lineNumber: number;
  description: string;
  amount: number;
};

export type Invoice = {
  id: string;
  invoiceNumber: string;
  createdAt: string;
  approvedAt: string;
  dueDate: string;
  status: string;
  lines: InvoiceLine[];
  notes: string;
  vatRatePercentage: number;
};

export type BillingCycle = {
  id: string;
  warehouseId: string;
  warehouseMatchId: string;
  shipperBusinessId: string;
  lspBusinessId: string;
  startDate: string;
  endDate: string;
  invoice: Invoice;
  shipperBusinessName: string;
  shipperBusinessAddress: string;
  shipperCompanyNumber: string;
  lspBusinessName: string;
  lspBusinessAddress: string;
  lspVatNumber: string;
  lspCompanyNumber: string;
  lspBankName: string;
  lspBankAccountNumber: string;
  lspBankSortCode: string;
  lspBankIban: string;
  lspBankSwiftCode: string;
};

export const additionalServicesOptions = [
  {
    label: 'Case Picking and Despatch',
    value: 'casePickingAndDespatchPrice',
  },
  {
    label: 'Container Handling',
    value: 'containerHandlingPrice',
  },
  {
    label: 'Demurrage',
    value: 'demurragePricePerDay',
  },
  {
    label: 'Item Picking and Despatch',
    value: 'itemPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Picking and Despatch',
    value: 'palletPickingAndDespatchPrice',
  },
  {
    label: 'Pallet Receipt and Put Away',
    value: 'palletReceiptAndPutAwayPrice',
  },
  {
    label: 'Pallet Storage',
    value: 'palletStoragePricePerWeek',
  },
  {
    label: 'Pallet Returns',
    value: 'palletReturnsPrice',
  },
  {
    label: 'Case Returns',
    value: 'caseReturnsPrice',
  },
  {
    label: 'Item Returns',
    value: 'itemReturnsPrice',
  },
];
