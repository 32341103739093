import { Stack } from '@mui/material';
import { Text } from '@components/Text';
import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';

interface PickingConfirmationSummaryProps {
  selectedOutboundProcess: OutboundProcess;
  totalPickedItemsQuantity: number | undefined;
  isPickingDiscrepancy: boolean;
  pickingDiscrepancyAmount: number;
  isNegativePickingDiscrepancy: boolean;
}

export function PickingConfirmationSummary({
  selectedOutboundProcess,
  totalPickedItemsQuantity,
  isPickingDiscrepancy,
  pickingDiscrepancyAmount,
  isNegativePickingDiscrepancy,
}: PickingConfirmationSummaryProps) {
  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Picked by: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.pickingConfirmation?.createdByName}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Picking date: </strong>
        </Text>
        <Text as='span' size='xs'>
          {dayjs(
            selectedOutboundProcess?.pickingConfirmation?.actualPickingDate
          ).format('DD/MM/YYYY')}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Picking Note #: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.pickingConfirmation?.pickingNoteNumber}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>ETA: </strong>
        </Text>
        <Text as='span' size='xs'>
          {dayjs(
            selectedOutboundProcess?.pickingConfirmation?.actualPickingDate
          )
            .add(1, 'day')
            .format('DD/MM/YYYY')}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>SKUs: </strong>
        </Text>
        <Text as='span' size='xs'>
          {selectedOutboundProcess?.pickingConfirmation?.skus.length}
        </Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text as='span' size='xs'>
          <strong>Total Quantity (Items): </strong>
        </Text>
        <Text size='xs'>
          {totalPickedItemsQuantity}{' '}
          {isPickingDiscrepancy && (
            <Text
              as='span'
              size='xs'
              css={{
                color: isNegativePickingDiscrepancy
                  ? 'var(--colors-tomato9)'
                  : 'var(--colors-gray12)',
              }}
            >
              {`(${
                isNegativePickingDiscrepancy ? '-' : '+'
              }${pickingDiscrepancyAmount})`}
            </Text>
          )}
        </Text>
      </Stack>
      {selectedOutboundProcess.pickingConfirmation?.comment && (
        <Stack gap={4}>
          <Text as='h3' size='xs'>
            <strong>Comment</strong>
          </Text>
          <Text size='xs'>
            {selectedOutboundProcess.pickingConfirmation.comment}
          </Text>
        </Stack>
      )}
    </>
  );
}
