import {
  gray,
  grayA,
  grayDark,
  grayDarkA,
  tomato,
  tomatoDark,
  blue,
  blueDark,
  green,
  greenDark,
} from '@radix-ui/colors';

export const grayPalette = {
  primary: {
    main: gray.gray9,
  },
};
