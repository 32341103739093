type PalletType =
  | 'GB Pallet'
  | 'GB Half Pallet'
  | 'EU Pallet'
  | 'EU Half Pallet';

export const getStandardPalletLengthInMetres = (
  palletType: PalletType
  // eslint-disable-next-line consistent-return
) => {
  // eslint-disable-next-line default-case
  switch (palletType) {
    case 'GB Pallet':
      return 1.2;
    case 'GB Half Pallet':
      return 1;
    case 'EU Pallet':
      return 1.2;
    case 'EU Half Pallet':
      return 0.6;
  }
};

export const getStandardPalletWidthInMetres = (
  palletType: PalletType
  // eslint-disable-next-line consistent-return
) => {
  // eslint-disable-next-line default-case
  switch (palletType) {
    case 'GB Pallet':
      return 1;
    case 'GB Half Pallet':
      return 0.6;
    case 'EU Pallet':
      return 0.8;
    case 'EU Half Pallet':
      return 0.8;
  }
};

export const getStandardPalletWeightInLb = (
  palletType: PalletType
  // eslint-disable-next-line consistent-return
) => {
  // eslint-disable-next-line default-case
  switch (palletType) {
    case 'GB Pallet':
      return 40;
    case 'GB Half Pallet':
      return 20;
    case 'EU Pallet':
      return 55;
    case 'EU Half Pallet':
      return 22.5;
  }
};

export const getStandardPalletWeightInKg = (
  palletType: PalletType
  // eslint-disable-next-line consistent-return
) => {
  // eslint-disable-next-line default-case
  switch (palletType) {
    case 'GB Pallet':
      return 18;
    case 'GB Half Pallet':
      return 9;
    case 'EU Pallet':
      return 25;
    case 'EU Half Pallet':
      return 12.5;
  }
};
