import { styled } from '@stitchesConfig';

export const StyledStarterChecklist = styled('ul', {
  position: 'fixed',
  margin: 0,
  left: 0,
  bottom: 0,
  'z-index': 60,
});

export const CompletedStep = styled('li', {
  textDecoration: 'line-through',
  margin: '0 0 0.5rem 0',
});

export const IncompleteStep = styled('li', {
  textDecoration: 'none',
  margin: '0 0 0.5rem 0',
});
