import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material';
import { SHIPMENT_NOTE_TYPES } from '@constants';
import { ShipmentType } from '../types';

interface ShipmentTypeFilterProps {
  value: ShipmentType;
  onChange: (event: SelectChangeEvent) => void;
}

export function ShipmentTypeFilter({
  value,
  onChange,
}: ShipmentTypeFilterProps) {
  return (
    <FormControl>
      <InputLabel
        id='select-helper-label-shipment-type'
        size='small'
        sx={{ zIndex: 'unset' }}
      >
        Shipment type
      </InputLabel>
      <Select
        size='small'
        label='Shipment type'
        labelId='select-helper-label-shipment-type'
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Without label' }}
        fullWidth
      >
        {SHIPMENT_NOTE_TYPES.map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
