import { ChangeEvent, useEffect, useState } from 'react';
import { useAuthHttp } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store';
import { AVAILABLE_SKUS_URL, DEBOUNCE_TIME_IN_MS } from '@constants';
import { addSkus, clearSkus } from '@features/inventory';
import { useAuth } from '@context';
import { useDebounce } from 'usehooks-ts';
import { UserRoleService } from '../services';

interface UseSkusOptions {
  minTotalStock: number;
  isPalletisingRequired: boolean;
  warehouseId: string | null;
  shipperBusinessId?: string | null;
}

export const useSkus = ({
  minTotalStock,
  isPalletisingRequired,
  warehouseId,
  shipperBusinessId = null,
}: UseSkusOptions) => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();

  const skus = useAppSelector((state) => state.inventory.inventory.skus);

  const [isLoadingSkus, setIsLoadingSkus] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [page, setPage] = useState(0);

  const [highestPage, setHighestPage] = useState(0);

  const lastItem = useAppSelector(
    (state) => state.inventory.inventory.skusCurrentQuery.pagination?.lastItem
  );

  const hasMoreSkusData = useAppSelector(
    (state) => state.inventory.inventory.skusHasMoreData
  );

  const [isInitialFetch, setIsInitialFetch] = useState(
    import.meta.env.MODE === 'development'
  );

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_TIME_IN_MS);

  const handleChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const [isCleared, setIsCleared] = useState(false);

  const fetchSkus = async () => {
    if (isLoadingSkus || isInitialFetch) {
      setIsInitialFetch(false);
      return;
    }

    setIsLoadingSkus(true);

    try {
      const { data } = await authHttp.post(AVAILABLE_SKUS_URL, {
        searchQuery: debouncedSearchQuery,
        minTotalStock,
        isPalletisingRequired,
        warehouseId:
          warehouseId === 'All' || warehouseId === null ? null : warehouseId,
        ...(isWarehouseManager(user) && {
          shipperBusinessId:
            shipperBusinessId === 'All' || shipperBusinessId === null
              ? null
              : shipperBusinessId,
        }),
        pagination: {
          lastItem,
          itemsPerPage,
        },
      });

      data.hasMoreData = !(data.skus.length < itemsPerPage);
      dispatch(addSkus(data));
      setTotalCount(data.totalCount);
      setPage(0);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoadingSkus(false);
      setIsCleared(false);
    }
  };

  // Initial data load
  useEffect(() => {
    dispatch(clearSkus());
    setIsCleared(true);
    // fetchSkus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialFetch, warehouseId, shipperBusinessId]);

  useEffect(() => {
    if (debouncedSearchQuery.length >= 1 && debouncedSearchQuery.length < 3)
      return;

    dispatch(clearSkus());
    setIsCleared(true);
    // fetchSkus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (!isCleared) return;

    fetchSkus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialFetch, isCleared]);

  return {
    skus: skus.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage),
    fetchSkus,
    isLoadingSkus,
    hasMoreSkusData,
    itemsPerPage,
    setItemsPerPage,
    totalCount,
    page,
    setPage,
    highestPage,
    setHighestPage,
    searchQuery,
    handleChangeSearchQuery,
  };
};
