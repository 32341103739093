import { ReactNode, ComponentPropsWithoutRef, forwardRef } from 'react';
import type * as Stitches from '@stitches/react';
import { IconContainer } from '@components/Icon';
import { StyledButton } from './Button.styles';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'link';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  action?: 'default' | 'cta' | 'danger';
  isFullWidth?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  isLoading?: boolean;
  loadingIcon?: ReactNode;
  loadingText?: string;
  css?: Stitches.CSS;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'primary',
      size = 'md',
      action = 'default',
      isFullWidth = false,
      leftIcon,
      rightIcon,
      isLoading = false,
      loadingIcon,
      loadingText,
      css,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <StyledButton
        variant={variant}
        size={size}
        action={action}
        isFullWidth={isFullWidth}
        type='button'
        css={css}
        {...props}
        ref={forwardedRef}
      >
        {!isLoading && leftIcon && (
          <IconContainer aria-hidden>{leftIcon}</IconContainer>
        )}
        {isLoading ? loadingText : children}
        {!isLoading && rightIcon && (
          <IconContainer aria-hidden>{rightIcon}</IconContainer>
        )}
        {isLoading && loadingIcon && (
          <IconContainer aria-hidden isLoading>
            {loadingIcon}
          </IconContainer>
        )}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
