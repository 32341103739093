/* eslint-disable no-nested-ternary */
import { Fragment } from 'react';
import { OutboundProcess } from '@features/supplyChain/types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack/Stack';
import dayjs from 'dayjs';
import { Text } from '@components/Text';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { getDayDifference } from '@utils';
import { PalletCalculator } from '@components/PalletCalculator';

function QuantitySpacer() {
  return <span>&nbsp;</span>;
}

interface ProofOfDeliveryDetailsProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function ProofOfDeliveryDetails({
  selectedOutboundProcess,
  onClose,
  isDesktop,
  isTablet,
}: ProofOfDeliveryDetailsProps) {
  const { despatchNote, pickingConfirmation } = selectedOutboundProcess;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { estimatedTimeOfArrival } = selectedOutboundProcess.shipmentNote!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { customerName } = selectedOutboundProcess.despatchNote!;
  const {
    createdByName,
    skus,
    comment,
    grnNoteNumber,
    finalisedAt,
    dateOfArrival,
  } =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedOutboundProcess.goodsReceivedNote!;

  const deliveryDayDifference = getDayDifference(
    estimatedTimeOfArrival,
    dateOfArrival as string
  );

  const isLate = deliveryDayDifference < 0;

  return (
    <>
      <Stack
        spacing={isTablet ? 16 : 8}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Proof of Delivery #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{grnNoteNumber}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Customer name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{customerName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Delivery address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>123 Main St</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Signed off by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{createdByName}</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required time of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(
                selectedOutboundProcess?.despatchNote?.requiredTimeOfArrival
              ).format('DD/MM/YYYY, H:mm')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              POD date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(dateOfArrival).format('DD/MM/YYYY, H:mm')}
              {isLate && (
                <Text
                  as='span'
                  size='xs'
                  css={{ color: 'var(--colors-tomato9)' }}
                >
                  {` (+${deliveryDayDifference.toString().substring(1)} ${
                    deliveryDayDifference.toString().substring(1) === '1'
                      ? 'day'
                      : 'days'
                  })`}
                </Text>
              )}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 6' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '6 / 8' }}
              >
                UoM
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                Despatched <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Delivered <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Delivered <br />
                Pallet Q-ty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem' data-testid='sku-items-container'>
          {skus?.map((sku, index) => {
            const isDiscrepancy = sku.discrepancyReason;
            const { despatchedSkuQuantity, receivedSkuQuantity } = sku;
            const discrepancyAmount =
              despatchedSkuQuantity - receivedSkuQuantity;
            const isNegativeDiscrepancy = discrepancyAmount > 0;

            const hasPackagingInfo =
              despatchNote.skus.find((s) => s.skuId === sku.skuId)
                ?.packagingDetails.packagingInfo?.amountOfSkusInLayer &&
              despatchNote.skus.find((s) => s.skuId === sku.skuId)
                ?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

            let amountOfSkusInLayer: number | undefined;
            let amountOfLayersInPallet: number | undefined;
            let packagingInfoType: string | undefined;
            let palletisingType: string | null | undefined;

            if (hasPackagingInfo) {
              amountOfSkusInLayer = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.amountOfSkusInLayer;

              amountOfLayersInPallet = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.amountOfLayersInPallet;

              packagingInfoType = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.packagingInfo?.type;
            } else {
              palletisingType = despatchNote.skus.find(
                (s) => s.skuId === sku.skuId
              )?.packagingDetails.palletisingType;
            }

            return (
              <Fragment key={sku.skuId}>
                <Box
                  display='grid'
                  gridTemplateColumns='2rem repeat(12, 1fr)'
                  columnGap='1rem'
                  rowGap='1rem'
                  alignItems={isDesktop ? 'center' : 'flex-start'}
                >
                  {/* SKU index  */}
                  {/* SKU index  */}
                  <Stack gridColumn='1 / 2' sx={{ alignSelf: 'center' }}>
                    <Chip label={index + 1} />
                  </Stack>
                  {/* SKU ID */}
                  <Stack
                    gridColumn={
                      isDesktop ? '2 / 4' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '1' : '1'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        SKU ID
                      </Text>
                    )}
                    <Text>{sku.skuIdentifier}</Text>
                  </Stack>
                  {/* Product name */}
                  <Stack
                    gridColumn={
                      isDesktop ? '4 / 6' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '2' : '2'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Product name
                      </Text>
                    )}
                    <Text>{sku.productName}</Text>
                  </Stack>
                  {/* UoM */}
                  <Stack
                    gridColumn={
                      isDesktop ? '6 / 8' : isTablet ? '2 / 8' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '3'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        UoM
                      </Text>
                    )}
                    <Text>{sku.name}</Text>
                  </Stack>
                  {/* Despatched Qty */}
                  <Stack
                    gridColumn={
                      isDesktop ? '8 / 10' : isTablet ? 'span 6' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '1' : '4'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Despatched SKU Q-ty
                      </Text>
                    )}
                    <Text>{sku.despatchedSkuQuantity}</Text>
                  </Stack>
                  {/* Delivered Qty */}
                  <Stack
                    gridColumn={
                      isDesktop ? '10 / 12' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '2' : '5'}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text as='p' size='sm' weight='bold'>
                        Delivered SKU Q-ty
                      </Text>
                    )}
                    <Text as='p' size='md'>
                      {receivedSkuQuantity}
                      {sku.discrepancyReason && (
                        <>
                          {' '}
                          <Text
                            as='span'
                            size='md'
                            css={{
                              color: 'var(--colors-tomato9)',
                            }}
                          >
                            {`(${isNegativeDiscrepancy ? '-' : '+'}${
                              isNegativeDiscrepancy
                                ? discrepancyAmount
                                : discrepancyAmount.toString().substring(1)
                            })`}
                          </Text>
                        </>
                      )}
                    </Text>
                  </Stack>
                  {/* Delivered Pallets Q-ty */}
                  {hasPackagingInfo ? (
                    <Stack
                      gridColumn={
                        isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                      spacing={8}
                      sx={{ alignSelf: 'center' }}
                    >
                      {!isDesktop && (
                        <Text size='sm' weight='bold'>
                          Delivered Pallet Q-ty
                        </Text>
                      )}
                      <Text css={{ whiteSpace: 'nowrap' }}>
                        {receivedSkuQuantity ? (
                          <PalletCalculator
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            palletName={packagingInfoType!}
                            skuQuantity={receivedSkuQuantity}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            amountOfSkusInLayer={amountOfSkusInLayer!}
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            amountOfLayersInPallet={amountOfLayersInPallet!}
                          />
                        ) : (
                          <QuantitySpacer />
                        )}
                      </Text>
                    </Stack>
                  ) : (
                    <Stack
                      gridColumn={
                        isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                      }
                      gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                      spacing={8}
                      sx={{ alignSelf: 'center' }}
                    >
                      {!isDesktop && (
                        <Text size='sm' weight='bold'>
                          Delivered Pallet Q-ty
                        </Text>
                      )}
                      <Text as='p' size='md'>
                        {palletisingType}
                      </Text>
                    </Stack>
                  )}
                  {/* Discrepancy reason */}
                  {isDiscrepancy && (
                    <Stack
                      gap={4}
                      gridColumn={
                        isDesktop ? '8 / 14' : isTablet ? '7 / 14' : '2 / 14'
                      }
                    >
                      <>
                        <Text as='p' size='sm' weight='bold'>
                          Discrepancy reason
                        </Text>
                        <Text as='p' size='md'>
                          {sku.discrepancyReason}
                        </Text>
                      </>
                    </Stack>
                  )}
                </Box>
                {index !== skus.length - 1 && (
                  <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Stack>
      {comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }} data-testid='grn-comment'>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
          fullWidth={!isDesktop}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
