import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import H from '@here/maps-api-for-javascript';
import './map.css';
import { WAREHOUSE_IMAGES_URL } from '../../constants';

export default function Map({
  apikey,
  mapPosition,
  warehouseData,
  disablePanning,
  height,
}: {
  apikey: string;
  mapPosition: { lat: number; lng: number } | null;
  warehouseData: { warehouses: any } | null;
  disablePanning?: boolean;
  height?: string;
}) {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const map = useRef<H.Map | null>(null);
  const platform = useRef<H.service.Platform | null>(null);
  const [searchParams] = useSearchParams();

  const [markerBubble, setMarkerBubble] = useState<any>(null);

  const markerIcon = `/images/warehouse_pin.svg`;

  // function addMarkerToGroup(group: any, coordinate: any, html: any) {
  //   const icon = new H.map.Icon(markerIcon, { size: { w: 30, h: 50 } });

  //   const marker = new H.map.Marker(coordinate, { icon, data: null });
  //   marker.setData(html);
  //   group.addObject(marker);
  // }

  // function addInfoBubble(currentMap: any, ui: any) {
  //   currentMap.removeObjects(currentMap.getObjects());

  //   ui.getBubbles().forEach((bub: any) => ui.removeBubble(bub));

  //   const group = new H.map.Group();

  //   currentMap.addObject(group);

  //   group.addEventListener(
  //     'tap',
  //     function (evt: any) {
  //       const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
  //         content: evt.target.getData(),
  //       });
  //       ui.addBubble(bubble);
  //     },
  //     false
  //   );

  //   if (warehouseData) {
  //     warehouseData.warehouses.map((warehouse: any) => {
  //       const images = warehouse.images.map((image: any) => ({
  //         imgPath: `${WAREHOUSE_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`,
  //       }));

  //       let vans = 0;
  //       let ldv = 0;
  //       let hdv = 0;

  //       if (Object.keys(warehouse.fleet).length !== 0) {
  //         Object.keys(warehouse.fleet).forEach((key) => {
  //           if (
  //             key === 'MultiAxleArticMoreThan26t' ||
  //             key === 'TwoAxleRigidLessThan26t'
  //           ) {
  //             hdv += warehouse.fleet[key];
  //           } else if (
  //             key === 'LgvLessThan3AndHalft' ||
  //             key === 'VanLessThan1AndHalft'
  //           ) {
  //             vans += warehouse.fleet[key];
  //           } else if (
  //             key === 'TwoAxleRigid18t' ||
  //             key === 'TwoAxleRigidLessThan7AndHalf5t' ||
  //             key === 'SpecialOrOther'
  //           ) {
  //             ldv += warehouse.fleet[key];
  //           } else {
  //             return null;
  //           }

  //           return null;
  //         });
  //       }

  //       const warehouseImagePlaceholder = `/images/warehouse_details_placeholder.png`;

  //       // <h3>${warehouse.currency.toUpperCase()} ${
  //       //   warehouse.palletStoragePricePerWeek
  //       // }</h3>
  //       // <div>per pallet per week</div>

  //       return addMarkerToGroup(
  //         group,
  //         { lat: warehouse.location.lat, lng: warehouse.location.lon },
  // `
  //   <img src="${
  //     images.length > 0 ? images[0].imgPath : warehouseImagePlaceholder
  //   }" class="warehouseImage"></img>
  //   <h2>Warehouse in ${
  //     warehouse.city ? warehouse.city : warehouse.address
  //   }</h2>
  //   <div class="warehouseDetails">
  //     <div class="warehouseDetails__detail">
  //       <div>Size:</div>
  //       <h3>${warehouse.maxStorageCapacity} ${
  //   warehouse.storageCapacityUnitOfMeasure
  //     ? warehouse.storageCapacityUnitOfMeasure.toLowerCase()
  //     : null
  // }</h3>
  //     </div>
  //     <div class="warehouseDetails__detail">
  //       <div>Price:</div>
  //       ${
  //         warehouse.palletStoragePricePerWeek
  //           ? `<h3>${warehouse.currency.toUpperCase()} ${
  //               warehouse.palletStoragePricePerWeek
  //             }</h3><div>per pallet per week</div>`
  //           : `<h3>PoA</h3>`
  //       }
  //     </div>
  //   </div>
  //   ${
  //     Object.keys(warehouse.fleet).length > 0
  //       ? `
  //           <div class="warehouseDetails">
  //               <div class="warehouseDetails__detail">
  //                 <div>Own fleet:</div>
  //                 <div>${vans} Vans, ${ldv} LDV, ${hdv} HDV</div>
  //               </div>
  //           </div>
  //         `
  //       : ''
  //   }
  //   <a href="/warehouse/${
  //     warehouse.warehouseId
  //   }?${searchParams}" class="seeMoreDetailsLink"><button class="seeMoreDetailsBtn">See more details</a>
  // `
  //       );
  //     });
  //   }
  // }

  function getBubbleContent(warehouse: any) {
    const images = warehouse.images.map((image: any) => ({
      imgPath: `${WAREHOUSE_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`,
    }));

    let vans = 0;
    let ldv = 0;
    let hdv = 0;

    if (Object.keys(warehouse.fleet).length !== 0) {
      Object.keys(warehouse.fleet).forEach((key) => {
        if (
          key === 'MultiAxleArticMoreThan26t' ||
          key === 'TwoAxleRigidLessThan26t'
        ) {
          hdv += warehouse.fleet[key];
        } else if (
          key === 'LgvLessThan3AndHalft' ||
          key === 'VanLessThan1AndHalft'
        ) {
          vans += warehouse.fleet[key];
        } else if (
          key === 'TwoAxleRigid18t' ||
          key === 'TwoAxleRigidLessThan7AndHalf5t' ||
          key === 'SpecialOrOther'
        ) {
          ldv += warehouse.fleet[key];
        } else {
          return null;
        }

        return null;
      });
    }

    const warehouseImagePlaceholder = `/images/warehouse_details_placeholder.png`;
    return `
      <img src="${
        images.length > 0 ? images[0].imgPath : warehouseImagePlaceholder
      }" class="warehouseImage"></img>
      <h2>Warehouse in ${
        warehouse.city ? warehouse.city : warehouse.address
      }</h2>
      <div class="warehouseDetails">
        <div class="warehouseDetails__detail">
          <div>Size:</div>
          <h3>${warehouse.maxStorageCapacity} ${
      warehouse.storageCapacityUnitOfMeasure
        ? warehouse.storageCapacityUnitOfMeasure.toLowerCase()
        : null
    }</h3>
        </div>
        <div class="warehouseDetails__detail">
          <div>Price:</div>
          ${
            warehouse.palletStoragePricePerWeek
              ? `<h3>${warehouse.currency.toUpperCase()} ${
                  warehouse.palletStoragePricePerWeek
                }</h3><div>per pallet per week</div>`
              : `<h3>PoA</h3>`
          }
        </div>
      </div>
      ${
        Object.keys(warehouse.fleet).length > 0
          ? `
              <div class="warehouseDetails">
                  <div class="warehouseDetails__detail">
                    <div>Own fleet:</div>
                    <div>${vans} Vans, ${ldv} LDV, ${hdv} HDV</div>
                  </div>
              </div>
            `
          : ''
      }
      <a href="/warehouse/${
        warehouse.warehouseId
      }?${searchParams}" class="seeMoreDetailsLink"><button class="seeMoreDetailsBtn">See more details</a>
    `;
  }

  function getRandomDataPoint(cluster: any) {
    const dataPoints: any = [];

    // Iterate through all points which fall into the cluster and store references to them
    cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

    // Randomly pick an index from [0, dataPoints.length) range
    // Note how we use bitwise OR ("|") operator for that instead of Math.floor
    // eslint-disable-next-line no-bitwise
    return dataPoints[(Math.random() * dataPoints.length) | 0];
  }

  function onMarkerClick(e: any, ui: any) {
    // Get position of the "clicked" marker
    const position = e.target.getGeometry();
    // Get the data associated with that marker
    const data = e.target.getData();
    // Merge default template with the data and get HTML
    const bubbleContent = getBubbleContent(data);

    // For all markers create only one bubble, if not created yet
    if (!markerBubble) {
      // Cache the bubble object
      setMarkerBubble(
        ui.addBubble(
          new H.ui.InfoBubble(position, {
            content: bubbleContent,
          })
        )
      );
    } else {
      // Reuse existing bubble object
      markerBubble.setPosition(position);
      markerBubble.setContent(bubbleContent);
      markerBubble.open();
    }

    // Move map's center to a clicked marker
    map.current?.setCenter(position, true);
  }

  const clusterSvgTemplate =
    '<svg xmlns="http://www.w3.org/2000/svg" height="50px" width="50px">' +
    '<circle cx="25px" cy="25px" r="18" fill="{fill}" />' + // Solid fill circle
    '<circle cx="25px" cy="25px" r="25" fill="{fill}" fill-opacity="0.25" />' + // Fainter outer circle
    '<text x="25" y="32" font-size="14pt" font-family="arial" font-weight="bold" text-anchor="middle" fill="white">{text}</text>' +
    '</svg>';

  const CUSTOM_THEME = {
    getClusterPresentation(cluster: any) {
      // Get random DataPoint from our cluster
      const randomDataPoint = getRandomDataPoint(cluster);
      // Get a reference to data object that DataPoint holds
      const data = randomDataPoint.getData();

      let fill;
      const weight = cluster.getWeight();

      // Set fill color based on the weight
      if (weight <= 6) {
        fill = '#549C30';
      } else if (weight <= 12) {
        fill = '#FFA500';
      } else {
        fill = '#880808';
      }

      let svgString = clusterSvgTemplate.replace(
        '{radius}',
        String(weight * 5)
      );
      svgString = svgString.replace('{text}', String(+weight));
      svgString = svgString.replace('{fill}', fill);

      let w;
      let h;

      // Set cluster size depending on the weight
      if (weight <= 6) {
        w = 35;
        h = 35;
      } else if (weight <= 12) {
        w = 50;
        h = 50;
      } else {
        w = 75;
        h = 75;
      }

      const clusterIcon = new H.map.Icon(svgString, {
        size: { w, h },
        anchor: { x: w / 2, y: h / 2 },
      });

      // Create a marker from a random point in the cluster
      const clusterMarker = new H.map.Marker(cluster.getPosition(), {
        icon: clusterIcon,
        // Set min/max zoom with values from the cluster, otherwise
        // clusters will be shown at all zoom levels:
        min: cluster.getMinZoom(),
        max: cluster.getMaxZoom(),
        data: null,
      });

      // Link data from the random point from the cluster to the marker,
      // to make it accessible inside onMarkerClick
      clusterMarker.setData(data);

      return clusterMarker;
    },
    getNoisePresentation(noisePoint: any) {
      // Get a reference to data object our noise points
      const data = noisePoint.getData();
      // Create a marker for the noisePoint
      const noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
        // Use min zoom from a noise point
        // to show it correctly at certain zoom levels:
        min: noisePoint.getMinZoom(),
        icon: new H.map.Icon(markerIcon, {
          size: { w: 30, h: 50 },
        }),
        data: null,
      });

      // Link a data from the point to the marker
      // to make it accessible inside onMarkerClick
      noiseMarker.setData(data);

      return noiseMarker;
    },
  };

  function startClustering(currentMap: any, warehouses: any, ui: any) {
    const dataPoints = warehouses.map(function (warehouse: any) {
      return new H.clustering.DataPoint(
        warehouse.location.lat,
        warehouse.location.lon,
        1,
        warehouse
      );
    });

    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        // Maximum radius of the neighborhood
        eps: 128,
        // minimum weight of points required to form a cluster
        minWeight: 1,
      },
      theme: CUSTOM_THEME,
    });
    // Note that we attach the event listener to the cluster provider, and not to
    // the individual markers
    clusteredDataProvider.addEventListener('tap', (e: any) =>
      onMarkerClick(e, ui)
    );

    // Create a layer that will consume objects from our clustering provider
    const layer = new H.map.layer.ObjectLayer(clusteredDataProvider);

    // To make objects from clustering provider visible,
    // we need to add our layer to the map
    currentMap.addLayer(layer);
  }

  useEffect(
    () => {
      if (!map.current) {
        platform.current = new H.service.Platform({ apikey });

        const rasterTileService = platform.current.getRasterTileService({
          queryParams: {
            style: 'explore.day',
            size: 512,
          },
        });

        const rasterTileProvider = new H.service.rasterTile.Provider(
          rasterTileService
        );

        const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);

        const newMap = new H.Map(
          mapRef.current as HTMLElement,
          rasterTileLayer,
          {
            pixelRatio: window.devicePixelRatio,
            center: mapPosition ?? {
              lat: 54.3781,
              lng: -2.436,
            },
            zoom: 6,
          }
        );

        window.addEventListener('resize', () => newMap.getViewPort().resize());

        if (!disablePanning) {
          const behavior = new H.mapevents.Behavior(
            new H.mapevents.MapEvents(newMap)
          );
        }

        map.current = newMap;
      }

      if (mapPosition) {
        map.current.getViewModel().setLookAtData(
          {
            position: mapPosition,
            zoom: 11,
          },
          false
        );
      }
    },
    // Dependencies array
    [apikey, mapPosition]
  );

  useEffect(() => {
    if (!warehouseData) return;
    if (!platform.current) return;
    if (!map.current) return;

    const defaultLayers = platform.current.createDefaultLayers();

    const ui = H.ui.UI.createDefault(map.current, defaultLayers);

    startClustering(map.current, warehouseData.warehouses, ui);
  }, [warehouseData]);

  // Return a div element to hold the map
  return (
    <div
      style={{ width: '100%', height: height ?? '1000px', marginLeft: 'auto' }}
      ref={mapRef}
    />
  );
}
