import { styled } from '@stitchesConfig';

export const StyledEmptyState = styled('div', {
  borderRadius: '$radii14',
  padding: '$space28',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space28',
  flex: 1,
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$space$space1',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
});

export const StyledEmptyStateTitle = styled('h2', {
  fontSize: '$headingXs',
  fontWeight: '$bold',
  lineHeight: '$headingXs',
  color: '$gray12',
  textAlign: 'center',
});

export const StyledEmptyStateSubtitle = styled('p', {
  fontSize: '$textMd',
  fontWeight: '$regular',
  lineHeight: '$textMd',
  color: '$gray12',
  textAlign: 'center',
});
