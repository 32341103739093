import { useState } from 'react';
import { InboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import { Text } from '@components/Text';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getTotalPallets } from '@utils';
import { useSetAtom } from 'jotai';
import { selectedShipmentNoteNumberAtom } from '../../../store/jotai';

interface CollapsibleInboundRowProps {
  inboundProcess: InboundProcess;
}

export function CollapsibleInboundRow({
  inboundProcess,
}: CollapsibleInboundRowProps) {
  const [open, setOpen] = useState(false);
  const setSelectedShipmentNoteNumber = useSetAtom(
    selectedShipmentNoteNumberAtom
  );

  return (
    <>
      <TableRow key={inboundProcess.id}>
        <TableCell
          component='td'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            sx={{ color: 'var(--colors-gray11)' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          onClick={() =>
            setSelectedShipmentNoteNumber(
              inboundProcess.shipmentNote.shipmentNoteNumber
            )
          }
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          {inboundProcess.shipmentNote.shipmentNoteNumber}
        </TableCell>
        <TableCell>{inboundProcess.shipmentNote.supplierName}</TableCell>
        <TableCell>
          {dayjs(inboundProcess.shipmentNote.createdAt).format('DD/MM/YYYY')}
        </TableCell>
        <TableCell>
          <strong>ETA</strong>:{' '}
          {dayjs(inboundProcess.shipmentNote.estimatedTimeOfArrival).format(
            'DD/MM/YYYY'
          )}
        </TableCell>
        <TableCell>
          {inboundProcess.shipmentNote.skus.reduce(
            (prev, current) => prev + current.skuQuantity,
            0
          )}
        </TableCell>
        <TableCell>
          {inboundProcess.shipmentNote.skus
            .map((sku, index) => {
              const totalPallets = getTotalPallets(
                sku.skuQuantity,
                sku.packagingDetails.packagingInfo.amountOfSkusInLayer,
                sku.packagingDetails.packagingInfo.amountOfLayersInPallet
              );

              return totalPallets;
            })
            .reduce((prev, current) => prev + current, 0)}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow
          sx={{
            backgroundColor: 'var(--colors-gray2)',
          }}
        >
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }}>{inboundProcess.status}</TableCell>
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }} />
          <TableCell sx={{ p: 16 }}>
            {inboundProcess.shipmentNote.skus.map((sku, index) => (
              <Text
                key={sku.skuId}
                size='xs'
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text size='xs' as='span' weight='medium'>
                  {index + 1}. {sku.productName} ({sku.name})
                </Text>
                <Text size='xs' as='span'>
                  {sku.skuQuantity}
                </Text>
              </Text>
            ))}
          </TableCell>
          <TableCell sx={{ p: 16 }}>
            {inboundProcess.shipmentNote.skus.map((sku, index) => {
              const totalPallets = getTotalPallets(
                sku.skuQuantity,
                sku.packagingDetails.packagingInfo.amountOfSkusInLayer,
                sku.packagingDetails.packagingInfo.amountOfLayersInPallet
              );

              return (
                <Text
                  key={sku.skuIdentifier}
                  size='xs'
                  as='span'
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text size='xs' as='span' weight='medium'>
                    {index + 1}. {sku.packagingDetails.packagingInfo.type}
                    {totalPallets > 1 ? 's' : ''}
                  </Text>
                  <Text size='xs' as='span'>
                    {totalPallets}
                  </Text>
                </Text>
              );
            })}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
