import { ComponentPropsWithoutRef } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { StyledLink } from './PageLink.styles';

interface PageLinkProps extends ComponentPropsWithoutRef<'a'> {
  to: string;
}

export function PageLink({ children, to }: PageLinkProps) {
  return (
    <StyledLink to={to}>
      <MdChevronLeft size='1.25rem' />
      {children}
    </StyledLink>
  );
}
