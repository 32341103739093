import { IFilterService } from '@services';
import { FilterKey, Filters } from './types';

export const FilterService = (): IFilterService => {
  const localStorageFilterKey = 'filters';
  const filterSchemaVersion = '1.0.0';

  const clearFilters = () => localStorage.removeItem(localStorageFilterKey);

  const getFilters = (): Filters => {
    const localFilterString = localStorage.getItem(localStorageFilterKey);
    const newFilters = {
      schemaVersion: filterSchemaVersion,
      noticeboardWarehouseId: null,
      noticeboardWarehouseClientId: null,
      skuStockWarehouseId: 'All',
      skuStockShipperId: null,
    };

    if (localFilterString !== null) {
      const currentFilters = JSON.parse(localFilterString);

      if (currentFilters.schemaVersion !== filterSchemaVersion) {
        clearFilters();
        return newFilters;
      }

      return currentFilters;
    }

    return newFilters;
  };

  const setFilters = (filters: Filters) =>
    localStorage.setItem(localStorageFilterKey, JSON.stringify(filters));

  const getFilterValue = (filterKey: FilterKey) => {
    const currentFilters = getFilters();

    return currentFilters[filterKey];
  };

  const setFilterValue = (filterKey: FilterKey, value: string) => {
    const currentFilters = getFilters();

    currentFilters[filterKey] = value;

    setFilters(currentFilters);
  };

  return {
    getFilters,
    setFilters,
    getFilterValue,
    setFilterValue,
    clearFilters,
  };
};
