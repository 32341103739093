import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Text } from '@components/Text';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import {
  useAvailableWarehouses,
  useAvailableSkus,
  useReactHookFormWithFieldArray,
  useAuthHttp,
} from '@hooks';
import {
  INVENTORY_IMAGES_URL,
  SUGGESTED_ID_URL,
  SUPPLIER_GET,
} from '@constants';
import { Supplier } from '@views/SupplierMaster/SupplierMaster';
import {
  AvailableSKU,
  AvailableWarehouse,
  CreateInboundShipmentNoteFormValues,
} from './types';
import {
  validationSchema,
  defaultValues,
  calculateTotalPalletAmount,
} from './form';
import { getTotalPallets } from '../../../utils';

interface CreateInboundShipmentNoteFormProps {
  onClose: () => void;
  onSubmit: (values: CreateInboundShipmentNoteFormValues) => void;
}

export function CreateInboundShipmentNoteForm({
  onClose,
  onSubmit,
}: CreateInboundShipmentNoteFormProps) {
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isSupplierLoading, setIsSupplierLoading] = useState(false);
  const [openAvailableSupplierOptions, setOpenAvailableSupplierOptions] =
    useState(false);

  const {
    availableWarehouseOptions,
    loadingAvailableWarehouses,
    openAvailableWarehouseOptions,
    setOpenAvailableWarehouseOptions,
  } = useAvailableWarehouses();

  console.log(availableWarehouseOptions);

  const {
    availableSkuOptions,
    loadingAvailableSkus,
    openSkuIndex,
    setOpenSkuIndex,
    openAvailableSkuOptions,
    setOpenAvailableSkuOptions,
    availableSkusQuery,
    setAvailableSkusQuery,
    openPalletisingIndex,
    setOpenPalletisingIndex,
    openPalletisingOptions,
    setOpenPalletisingOptions,
  } = useAvailableSkus();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    resetField,
    clearErrors,
    errors,
    isSubmitting,
    isValid,
    fields: skusFields,
    append: appendSku,
    remove: removeSku,
  } = useReactHookFormWithFieldArray<CreateInboundShipmentNoteFormValues>({
    defaultValues,
    validationSchema,
    fieldArrayName: 'skus',
  });

  const authHttp = useAuthHttp();

  useEffect(() => {
    async function fetchSuggestedID() {
      try {
        const { data } = await authHttp.get(SUGGESTED_ID_URL);

        if (data.suggestedId) setValue('shipmentNoteNumber', data.suggestedId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
    async function fetchSuppliers() {
      setIsSupplierLoading(true);
      try {
        const { data } = await authHttp.post(SUPPLIER_GET, {
          pagination: {
            itemsPerPage: isDesktop ? 100 : 25,
          },
        });

        if (data) setSuppliers(data.suppliers);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSupplierLoading(false);
      }
    }
    fetchSuppliers();
    fetchSuggestedID();
  }, []);

  // console.log(isValid, errors);

  const getGrandTotals = () => {
    const skusByPalletType = getValues('skus').reduce<{ [key: string]: any }>(
      (groups, sku) => {
        let palletType;
        if (sku.palletisingId) {
          const palletisingOption = sku.palletising.find(
            (option) => option.id === sku.palletisingId
          );
          palletType = palletisingOption?.type;
        } else {
          palletType = '';
        }

        if (palletType) {
          if (!groups[palletType]) {
            // eslint-disable-next-line no-param-reassign
            groups[palletType] = [];
          }
          groups[palletType].push(sku);
        }
        return groups;
      },
      {}
    );

    return Object.entries(skusByPalletType).map(([palletType, skus]) => {
      const grandTotal = (skus as any).reduce((total: number, sku: any) => {
        const palletisingOption = sku.palletising.find(
          (option: any) => option.id === sku.palletisingId
        );
        const amountOfSkusInLayer = palletisingOption?.amountOfSkusInLayer ?? 0;
        const amountOfLayersInPallet =
          palletisingOption?.amountOfLayersInPallet ?? 0;
        return (
          total +
          getTotalPallets(
            sku?.skuQuantity ?? 0,
            amountOfSkusInLayer,
            amountOfLayersInPallet
          )
        );
      }, 0);

      return (
        <Text
          key={palletType}
          size='md'
          weight='medium'
          css={{ textAlign: 'right' }}
        >
          Total {palletType}s: {grandTotal}
        </Text>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={24} p={6}>
        <Stack spacing={24}>
          {/* Source location id */}
          <Controller
            name='sourceLocationId'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                {...field}
                error={Boolean(error && isTouched)}
                helperText={Boolean(error && isTouched) && error?.message}
                variant='outlined'
                label='Source location ID (required)'
                placeholder='Source location ID (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
              />
            )}
          />
          {/* Shipment note number */}
          <Controller
            name='shipmentNoteNumber'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                {...field}
                error={Boolean(error && isTouched)}
                helperText={Boolean(error && isTouched) && error?.message}
                variant='outlined'
                label='Shipment note number (required)'
                placeholder='Shipment note number (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
              />
            )}
          />
          {/* Supplier name */}
          {/* <Controller
            name='supplierName'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                {...field}
                variant='outlined'
                label='Supplier name (required)'
                placeholder='Supplier name (required)'
                error={Boolean(error && isTouched)}
                helperText={Boolean(error && isTouched) && error?.message}
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
              />
            )}
          /> */}
          <Controller
            name='supplierId'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => {
              return (
                <Autocomplete
                  open={openAvailableSupplierOptions}
                  onOpen={() => setOpenAvailableSupplierOptions(true)}
                  onClose={() => setOpenAvailableSupplierOptions(false)}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: Supplier | null
                  ) => {
                    if (value) {
                      field.onChange(value.id);
                      setValue('supplierName', value.name);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option.name}
                  options={suppliers}
                  loadingText='Loading suppliers...'
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        label='Supplier name (required)'
                        placeholder='Supplier name (required)'
                        error={Boolean(error && isTouched)}
                        helperText={
                          Boolean(error && isTouched) && error?.message
                        }
                        multiline={!isDesktop}
                        {...(!isDesktop && {
                          InputLabelProps: {
                            shrink: true,
                          },
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isSupplierLoading ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
          {/* To warehouse id */}
          <Controller
            name='warehouseId'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => {
              return (
                <Autocomplete
                  open={openAvailableWarehouseOptions}
                  onOpen={() => setOpenAvailableWarehouseOptions(true)}
                  onClose={() => setOpenAvailableWarehouseOptions(false)}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: AvailableWarehouse | null
                  ) => {
                    if (value) {
                      field.onChange(value.warehouse.warehouseId);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option.warehouse.name}
                  options={availableWarehouseOptions}
                  loading={loadingAvailableWarehouses}
                  loadingText='Loading available warehouses...'
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        label='To warehouse (required)'
                        placeholder='To warehouse (required)'
                        error={Boolean(error && isTouched)}
                        helperText={
                          Boolean(error && isTouched) && error?.message
                        }
                        multiline={!isDesktop}
                        {...(!isDesktop && {
                          InputLabelProps: {
                            shrink: true,
                          },
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingAvailableWarehouses ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
          {/* ETA date and time */}
          <Controller
            name='estimatedTimeOfArrival'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => {
              return (
                <DatePicker
                  format='DD/MM/YYYY'
                  minDate={dayjs(new Date())}
                  inputRef={field.ref}
                  onChange={(value) => {
                    setValue('estimatedTimeOfArrival', dayjs(value).format(), {
                      shouldValidate: true,
                    });
                  }}
                  label='ETA (required)'
                  slotProps={{
                    textField: {
                      onBlur: field.onBlur,
                      name: field.name,
                      multiline: !isDesktop,
                      ...(!isDesktop && {
                        InputLabelProps: {
                          shrink: true,
                        },
                      }),
                      error: Boolean(error && isTouched),
                      helperText: Boolean(error) && error?.message,
                    },
                  }}
                />
              );
            }}
          />
        </Stack>
        {/* Skus */}
        <Typography fontWeight='bold'>SKU / items</Typography>
        {skusFields.length > 0 && (
          <Stack spacing={isDesktop ? 16 : 32}>
            {skusFields.map((item, index) => {
              return (
                <Stack
                  key={item.id}
                  direction={isDesktop ? 'row' : 'column'}
                  spacing={16}
                >
                  {/* SKU ID And Name */}
                  <Controller
                    name={`skus.${index}.skuId`}
                    control={control}
                    render={({ field, fieldState: { error, isTouched } }) => (
                      <Autocomplete
                        open={
                          availableSkusQuery.length >= 3 &&
                          openSkuIndex === index
                        }
                        onOpen={() => {
                          setOpenSkuIndex(index);
                          setOpenAvailableSkuOptions(true);
                        }}
                        onClose={() => {
                          setOpenSkuIndex(null);
                          setOpenAvailableSkuOptions(false);
                        }}
                        filterOptions={(x) => x}
                        onChange={(
                          event: SyntheticEvent<Element, Event>,
                          value: AvailableSKU | null
                        ) => {
                          if (value) {
                            setValue(
                              `skus.${index}`,
                              {
                                skuId: value.id,
                                skuName: value.unitOfMeasure,
                                productId: value.productId,
                                palletisingId: '',
                                palletising: value.palletising,
                                skuQuantity: null,
                              },
                              { shouldValidate: true }
                            );
                          }
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return (
                            option.skuId === value.skuId &&
                            option.productId === value.productId &&
                            option.productName === value.productName
                          );
                        }}
                        // inputValue={availableSkusQuery}
                        onInputChange={(
                          event: SyntheticEvent<Element, Event>,
                          value: string
                        ) => {
                          if (event.type === 'change') {
                            setAvailableSkusQuery(value);
                          }
                        }}
                        options={availableSkuOptions}
                        getOptionLabel={(option) =>
                          `${option.productName} (${option.unitOfMeasure}) - SKU # ${option.skuId}`
                        }
                        noOptionsText='No matching SKUs found.'
                        loading={loadingAvailableSkus && openSkuIndex === index}
                        loadingText='Loading available SKUs...'
                        sx={{ width: '100%' }}
                        renderInput={(params) => {
                          return (
                            <>
                              <TextField
                                {...params}
                                label='SKU ID or SKU name (required)'
                                {...field}
                                value={field.value || ''}
                                error={error && isTouched}
                                helperText={
                                  error && isTouched && error?.message
                                }
                                placeholder='Search for an existing SKU ID or SKU name...'
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loadingAvailableSkus &&
                                      openSkuIndex === index ? (
                                        <CircularProgress
                                          color='inherit'
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                multiline={!isDesktop}
                                InputLabelProps={{ shrink: true }}
                              />
                              {availableSkusQuery.length < 3 && (
                                <FormHelperText variant='filled'>
                                  Please enter at least 3 characters.
                                </FormHelperText>
                              )}
                            </>
                          );
                        }}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '3rem 1fr',
                              gap: 16,
                              borderBottom: '1px solid #CCC',
                              padding: '0.5rem 1rem',
                            }}
                          >
                            <img
                              src={
                                option.images.length > 0
                                  ? `${INVENTORY_IMAGES_URL}/${option.images[0].imageLocation}/small/${option.images[0].remoteName}`
                                  : `/images/product-placeholder-${
                                      darkMode ? 'dark' : 'light'
                                    }.svg`
                              }
                              alt={`${option.productName} (${option.unitOfMeasure}) yoyoyo`}
                              style={{
                                width: '3rem',
                                minWidth: '3rem',
                                height: 'auto',
                                borderRadius: 'var(--radii-radii8)',
                                margin: '0 auto',
                              }}
                            />
                            <Stack spacing={6}>
                              <Typography variant='body2'>
                                <strong>Product:</strong> {option.productName} (
                                {option.unitOfMeasure})
                              </Typography>
                              <Typography variant='body2'>
                                <strong>SKU #:</strong> {option.skuId}
                              </Typography>
                              {option.description && (
                                <Typography variant='body2'>
                                  {option.description}
                                </Typography>
                              )}
                            </Stack>
                          </li>
                        )}
                      />
                    )}
                  />
                  {/* SKU Quantity */}
                  <Controller
                    name={`skus.${index}.skuQuantity`}
                    control={control}
                    render={({ field, fieldState: { error, isTouched } }) => {
                      return (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            min: 1,
                          }}
                          name={field.name}
                          onBlur={field.onBlur}
                          inputRef={field.ref}
                          value={field.value ?? ''}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (event.target.value === '') {
                              setValue(`skus.${index}.skuQuantity`, 1, {
                                shouldValidate: true,
                              });
                            } else {
                              setValue(
                                `skus.${index}.skuQuantity`,
                                parseInt(event.target.value, 10),
                                { shouldValidate: true }
                              );
                            }
                          }}
                          sx={{ minWidth: '12rem' }}
                          error={Boolean(error && isTouched)}
                          helperText={
                            Boolean(error && isTouched) &&
                            'A SKU quantity is required.'
                          }
                          variant='outlined'
                          label='SKU Qty (required)'
                          placeholder='SKU Qty (required)'
                          multiline={!isDesktop}
                          InputLabelProps={{ shrink: true }}
                        />
                      );
                    }}
                  />
                  {/* Pallet type */}
                  <Stack spacing={8} width='100%'>
                    <Controller
                      name={`skus.${index}.palletisingId`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => {
                        return (
                          <Autocomplete
                            id={`palletising-options-${index}-id`}
                            open={
                              openPalletisingOptions &&
                              openPalletisingIndex === index
                            }
                            onOpen={() => {
                              setOpenPalletisingIndex(index);
                              setOpenPalletisingOptions(true);
                            }}
                            onClose={() => {
                              setOpenSkuIndex(null);
                              setOpenPalletisingOptions(false);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.type === value.type
                            }
                            getOptionLabel={(option: {
                              id: string;
                              type: string;
                              amountOfLayersInPallet: number;
                              amountOfSkusInLayer: number;
                              dimensions: {
                                width: number | null;
                                length: number | null;
                                height: number | null;
                                dimensionsUnitOfMeasure: string;
                              } | null;
                            }) => `${option.type}`}
                            onChange={(
                              event: SyntheticEvent<Element, Event>,
                              value: {
                                id: string;
                                type: string;
                                amountOfLayersInPallet: number;
                                amountOfSkusInLayer: number;
                                dimensions: {
                                  width: number | null;
                                  length: number | null;
                                  height: number | null;
                                  dimensionsUnitOfMeasure: string;
                                } | null;
                              } | null
                            ) => {
                              if (value) {
                                setValue(
                                  `skus.${index}.palletisingId`,
                                  value.id,
                                  { shouldValidate: true }
                                );
                                setOpenPalletisingIndex(null);
                                clearErrors(`skus.${index}.palletisingId`);
                              }
                            }}
                            placeholder='Select a palletising option...'
                            sx={{ width: '100%' }}
                            options={watch(`skus.${index}.palletising`).map(
                              (palletisingOption) => ({
                                id: palletisingOption.id,
                                type: palletisingOption.type,
                                amountOfLayersInPallet:
                                  palletisingOption.amountOfLayersInPallet,
                                amountOfSkusInLayer:
                                  palletisingOption.amountOfSkusInLayer,
                                dimensions: {
                                  width: palletisingOption.dimensions.width,
                                  length: palletisingOption.dimensions.length,
                                  height: palletisingOption.dimensions.height,
                                  dimensionsUnitOfMeasure:
                                    palletisingOption.dimensions
                                      .dimensionsUnitOfMeasure,
                                },
                              })
                            )}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  label='Pallet type'
                                  inputRef={field.ref}
                                  value={field.value || ''}
                                  error={error && isTouched}
                                  helperText={
                                    error && isTouched && error?.message
                                  }
                                  placeholder='Select pallet type...'
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  multiline={!isDesktop}
                                  InputLabelProps={{ shrink: true }}
                                />
                              );
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.type}</li>
                            )}
                          />
                        );
                      }}
                    />
                    {getValues(`skus.${index}.skuId`) &&
                      getValues(`skus.${index}.palletisingId`) &&
                      getValues(`skus.${index}.skuQuantity`) &&
                      !(errors.skus && errors.skus[index]?.skuQuantity) &&
                      (() => {
                        const palletisingOption = getValues(
                          `skus.${index}.palletising`
                        ).find(
                          (option) =>
                            option.id ===
                            getValues(`skus.${index}.palletisingId`)
                        );

                        return (
                          <Text
                            size='sm'
                            weight='medium'
                            css={{ pl: '0.75rem' }}
                          >
                            Total pallets:{' '}
                            {palletisingOption &&
                              watch(`skus.${index}.skuQuantity`) !== null &&
                              calculateTotalPalletAmount(
                                palletisingOption.amountOfSkusInLayer,
                                palletisingOption.amountOfLayersInPallet,
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                watch(`skus.${index}.skuQuantity`) as number
                              )}
                          </Text>
                        );
                      })()}
                  </Stack>

                  {/* Delete item */}
                  {isDesktop ? (
                    <IconButton
                      sx={{ width: '3.5rem', height: '3.5rem' }}
                      onClick={() => removeSku(index)}
                      aria-label='Delete SKU'
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant='contained'
                      size='large'
                      startIcon={<DeleteIcon />}
                      sx={{ textTransform: 'none' }}
                      onClick={() => removeSku(index)}
                    >
                      Delete SKU
                    </Button>
                  )}
                </Stack>
              );
            })}
          </Stack>
        )}
        <Button
          startIcon={<AddIcon />}
          variant='contained'
          size='large'
          onClick={() =>
            appendSku({
              skuId: '',
              skuName: '',
              productId: '',
              palletisingId: '',
              palletising: [],
              skuQuantity: null,
            })
          }
          sx={{ textTransform: 'none', marginBottom: '1rem !important' }}
        >
          Add SKU
        </Button>
        {getGrandTotals()}
        {/* Comment */}
        <Controller
          name='comment'
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              multiline
              minRows={4}
              error={Boolean(error && isTouched)}
              helperText={Boolean(error && isTouched) && error?.message}
              label='Comment (optional)'
              placeholder='Comment'
              {...(!isDesktop && {
                InputLabelProps: {
                  shrink: true,
                },
              })}
              variant='outlined'
            />
          )}
        />
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
