import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material';
import { SHIPMENT_NOTE_STATUSES } from '@constants';
import { Status } from '../types';

interface ShipmentStatusFilterProps {
  value: Status;
  onChange: (event: SelectChangeEvent) => void;
}
export function ShipmentStatusFilter({
  value,
  onChange,
}: ShipmentStatusFilterProps) {
  return (
    <FormControl>
      <InputLabel
        id='select-helper-label-status'
        size='small'
        sx={{ zIndex: 'unset' }}
      >
        Status
      </InputLabel>
      <Select
        size='small'
        label='Status'
        labelId='select-helper-label-status'
        value={value}
        onChange={onChange}
        inputProps={{ 'aria-label': 'Without label' }}
        fullWidth
      >
        {SHIPMENT_NOTE_STATUSES.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
