import { styled } from '@stitchesConfig';
import * as SelectPrimitive from '@radix-ui/react-select';

// Select styles

export const StyledSelectTrigger = styled(SelectPrimitive.Trigger, {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '$textMd',
  fontWeight: '$regular',
  lineHeight: '$textMd',
  color: '$gray12',
  padding: '$space12 $space16',
  border: 'none',
  backgroundColor: '$gray1',
  borderRadius: '$radii8',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderColorInvalid: '$colors$tomato9',
  $$borderWidth: '$sizes$size1',
  $$borderWidthInvalid: '$sizes$size2',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  cursor: 'pointer',
  transition: 'box-shadow 150ms ease-in-out',
  height: '3.5rem',

  '&:hover:not(:disabled)': {
    boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
  },

  '&:focus': {
    outlineColor: 'transparent',
  },

  '&:focus-visible': {
    outline: '0.125rem solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:focus-within': {
    outline: '0.125rem solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.5',
  },

  variants: {
    isInvalid: {
      true: {
        boxShadow: 'inset 0 0 0 $$borderWidthInvalid $$borderColorInvalid',

        '&:hover:not(:disabled)': {
          boxShadow: 'inset 0 0 0 $$borderWidthInvalid $$borderColorInvalid',
        },
      },
    },
  },
});

StyledSelectTrigger.displayName = 'StyledSelectTrigger';

export const StyledSelectIcon = styled(SelectPrimitive.Icon, {
  width: '$size24',
  height: '$size24',
});

StyledSelectIcon.displayName = 'StyledSelectIcon';

export const StyledSelectContent = styled(SelectPrimitive.Content, {
  // overflow: 'hidden',
  backgroundColor: '$gray1',
  borderRadius: '$radii8',
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$sizes$size1',
  boxShadow: '0 0 0 $$borderWidth $$borderColor, $shadow',
  padding: '$space8',
});

StyledSelectContent.displayName = 'StyledSelectContent';

const scrollButtonStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$gray1',
  color: '$gray12',
};

export const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

StyledScrollUpButton.displayName = 'StyledScrollUpButton';

export const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

StyledScrollDownButton.displayName = 'StyledScrollDownButton';

export const StyledSelectPortal = styled(SelectPrimitive.Portal, {
  zIndex: 1001, // Set your desired z-index value here
});

StyledSelectPortal.displayName = 'StyledSelectPortal';

// SelectItem styles

export const StyledSelectItem = styled(SelectPrimitive.Item, {
  backgroundColor: '$gray1',
  fontSize: '$textMd',
  fontWeight: '$regular',
  lineHeight: '$textMd',
  color: '$gray12',
  padding: '$space12 $space14',
  borderRadius: '$radii8',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '$space16',
  userSelect: 'none',
  textAlign: 'left',

  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '$gray4',
  },

  '&[data-disabled]': {
    color: '$gray11',
    cursor: 'not-allowed',
  },
});

StyledSelectItem.displayName = 'StyledSelectItem';

export const StyledSelectItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  width: '$size24',
  height: '$size24',
});

StyledSelectItemIndicator.displayName = 'StyledSelectItemIndicator';

export const StyledSelectGroup = styled(SelectPrimitive.Group, {});

StyledSelectGroup.displayName = 'StyledSelectGroup';

export const StyledSelectSeparator = styled(SelectPrimitive.Separator, {
  height: '$size1',
  backgroundColor: '$gray7',
  margin: '$space4',
});

StyledSelectSeparator.displayName = 'StyledSelectSeparator';

export const StyledSelectLabel = styled(SelectPrimitive.Label, {
  fontSize: '$textSm',
  lineHeight: '$textSm',
  fontWeight: '$medium',
  padding: '$space12 $space14 $space6',
  color: '$gray11',
});

StyledSelectLabel.displayName = 'StyledSelectLabel';
