import { useState } from 'react';
import Stack from '@mui/material/Stack/Stack';
import { INVENTORY_IMAGES_URL, SUPPORTED_CURRENCY_GROUPS } from '@constants';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Text } from '@components/Text';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  ProductImage,
  SelectedProduct,
  SelectedProductSKU,
} from '@forms/client/AddProductForm/types';
import { useReadLocalStorage } from 'usehooks-ts';

interface RowProps<T> {
  sku: T;
  index: number;
}

const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
  (currencyGroup) => currencyGroup.currencies
);

const finalRowBorderStyles = {
  borderBottom: 'none',
};

function CollapsibleRow({ sku, index }: RowProps<SelectedProductSKU>) {
  const [open, setOpen] = useState(false);
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');

  return (
    <>
      <TableRow
        sx={{
          '& > *': {
            borderBottomColor: 'var(--colors-gray7)',
          },
        }}
      >
        <TableCell
          component='td'
          sx={{ borderBottomColor: 'var(--colors-gray7)', maxWidth: '2.25rem' }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            sx={{ color: 'var(--colors-gray11)' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              width: '3rem',
              height: '3rem',
              borderRadius: 'var(--radii-radii8)',
              backgroundColor: darkMode
                ? 'var(--colors-gray12)'
                : 'var(--colors-gray1)',
            }}
          >
            <img
              src={
                sku.images && sku.images.length > 0
                  ? `${INVENTORY_IMAGES_URL}/${sku.images[0].imageLocation}/small/${sku.images[0].remoteName}`
                  : `/images/product-placeholder-${
                      darkMode ? 'dark' : 'light'
                    }.svg`
              }
              alt={`${sku.productName} (${sku.name})`}
              style={{
                maxHeight: '100%',
                width: 'auto',
                borderRadius: 'var(--radii-radii8)',
                margin: '0 auto',
              }}
            />
          </Stack>
        </TableCell>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{sku.skuId}</TableCell>
        <TableCell>{sku.unitOfMeasure}</TableCell>
        <TableCell>{sku.amountOfProductInSku}</TableCell>
      </TableRow>
      {open && (
        <>
          {sku.listPrices.length > 0 && (
            <TableRow sx={{ backgroundColor: 'var(--colors-gray2)' }}>
              <TableCell colSpan={5} sx={{ borderBottomColor: 'transparent' }}>
                {/* List prices */}
                <Text as='h3' size='md' weight='bold'>
                  List prices
                </Text>
                <TableContainer>
                  <Table size='small' sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Currency
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sku.listPrices
                        .filter(
                          (listPrice) => listPrice.amount && listPrice.currency
                        )
                        .map((listPrice, listPriceIndex) => {
                          const isFinalRow =
                            listPriceIndex === sku.listPrices.length - 1;

                          const rowStyles = {
                            color: 'var(--colors-gray12)',
                            borderBottom: '0.0625rem solid var(--colors-gray7)',
                            ...(isFinalRow && finalRowBorderStyles),
                          };

                          const currencySymbol = currencies.find(
                            (currency) => currency.code === listPrice.currency
                          )?.symbol;

                          const currencyCode = currencies.find(
                            (currency) => currency.code === listPrice.currency
                          )?.code;

                          return (
                            <TableRow key={listPrice.amount}>
                              <TableCell sx={rowStyles}>
                                {listPriceIndex + 1}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {currencySymbol} {currencyCode}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {listPrice.amount}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {listPrice.comment}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
            </TableRow>
          )}
          {sku.purchasePrices.length > 0 && (
            <TableRow sx={{ backgroundColor: 'var(--colors-gray2)' }}>
              <TableCell colSpan={5} sx={{ borderBottomColor: 'transparent' }}>
                {/* Purchase prices */}
                <Text as='h3' size='md' weight='bold'>
                  Purchase prices
                </Text>
                <TableContainer>
                  <Table size='small' sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Currency
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Amount
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sku.purchasePrices
                        .filter(
                          (purchasePrice) =>
                            purchasePrice.amount && purchasePrice.currency
                        )
                        .map((purchasePrice, purchasePriceIndex) => {
                          const isFinalRow =
                            purchasePriceIndex ===
                            sku.purchasePrices.length - 1;

                          const rowStyles = {
                            color: 'var(--colors-gray12)',
                            borderBottom: '0.0625rem solid var(--colors-gray7)',
                            ...(isFinalRow && finalRowBorderStyles),
                          };

                          const currencySymbol = currencies.find(
                            (currency) =>
                              currency.code === purchasePrice.currency
                          )?.symbol;

                          const currencyCode = currencies.find(
                            (currency) =>
                              currency.code === purchasePrice.currency
                          )?.code;

                          return (
                            <TableRow key={purchasePrice.amount}>
                              <TableCell sx={rowStyles}>
                                {purchasePriceIndex + 1}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {currencySymbol} {currencyCode}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {purchasePrice.amount}
                              </TableCell>
                              <TableCell sx={rowStyles}>
                                {purchasePrice.comment}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
            </TableRow>
          )}
          {sku.palletising && (
            <TableRow sx={{ backgroundColor: 'var(--colors-gray2)' }}>
              <TableCell colSpan={5}>
                {/* Palletising */}
                <Text as='h3' size='md' weight='bold'>
                  Palletising
                </Text>
                <TableContainer>
                  <Table size='small' sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          SKUs in layer
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Layers
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          SKUs
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            color: 'var(--colors-gray12)',
                          }}
                        >
                          Cube
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sku.palletising.map((pallet, palletIndex) => {
                        const isFinalRow =
                          palletIndex === sku.palletising.length - 1;

                        const rowStyles = {
                          color: 'var(--colors-gray12)',
                          borderBottom: '0.0625rem solid var(--colors-gray7)',
                          ...(isFinalRow && finalRowBorderStyles),
                        };

                        return (
                          <TableRow key={pallet.id}>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              {palletIndex + 1}
                            </TableCell>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              {pallet.type}
                            </TableCell>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              {pallet.amountOfSkusInLayer}
                            </TableCell>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              {pallet.amountOfLayersInPallet}
                            </TableCell>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              {pallet.amountOfSkusInLayer &&
                                pallet.amountOfLayersInPallet &&
                                pallet.amountOfSkusInLayer *
                                  pallet.amountOfLayersInPallet}
                            </TableCell>
                            <TableCell
                              sx={{ verticalAlign: 'top', ...rowStyles }}
                            >
                              <Text as='h3' size='sm'>
                                {pallet.dimensions.width &&
                                  pallet.dimensions.length &&
                                  pallet.dimensions.height &&
                                  `${(
                                    pallet.dimensions.width *
                                    pallet.dimensions.length *
                                    pallet.dimensions.height
                                  ).toFixed(1)}${
                                    pallet.dimensions.dimensionsUnitOfMeasure
                                  }`}
                                {pallet.dimensions.width &&
                                  pallet.dimensions.length &&
                                  pallet.dimensions.height && <sup>3</sup>}
                              </Text>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
}

interface ProductSummaryProps {
  selectedProduct: SelectedProduct;
  setOpen: (isOpen: boolean) => void;
  handleEditProductClick: () => void;
  isTablet: boolean;
  isDesktop: boolean;
}

export function ProductSummary({
  selectedProduct,
  setOpen,
  handleEditProductClick,
  isTablet,
  isDesktop,
}: ProductSummaryProps) {
  return (
    <>
      <Stack p={6} spacing={32}>
        <Stack spacing={32} direction={isTablet ? 'row' : 'column'}>
          {/* Product Name */}
          <Stack spacing={16} sx={{ width: '100%' }}>
            <Text as='h3' size='lg' weight='bold'>
              {selectedProduct?.product.name}
            </Text>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: isDesktop
                  ? 'repeat(3, 1fr)'
                  : 'repeat(2, 1fr)',
                gap: 16,
              }}
            >
              {/* Product images */}
              {selectedProduct?.product.images.map((image: ProductImage) => (
                <img
                  key={image.id}
                  src={`${INVENTORY_IMAGES_URL}/${image.imageLocation}/small/${image.remoteName}`}
                  style={{
                    borderRadius: 'var(--radii-radii8)',
                    borderWidth: 'var(--sizes-size1)',
                    borderColor: 'var(--colors-gray7)',
                    borderStyle: 'solid',
                    boxShadow: 'var(--shadows-shadow)',
                  }}
                  alt={image.title}
                />
              ))}
            </Box>
          </Stack>
          {/* Product details */}
          <Stack
            sx={{
              width: '100%',
              pt: isTablet ? '2.75rem' : 0,
              flexDirection: 'row',
              justifyContent: isTablet ? 'flex-start' : 'space-between',
              gap: isTablet ? 64 : 0,
            }}
          >
            <Stack>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Product ID </strong>
                {selectedProduct?.product.productId}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Brand </strong>
                {selectedProduct?.product.brand}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Category </strong>
                {selectedProduct?.product.category}
              </Text>
            </Stack>
            <Stack>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Subcategory </strong>
                {selectedProduct?.product.subCategory}
              </Text>
              <Text
                size='md'
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <strong>Net volume </strong>
                {selectedProduct?.product.netVolume}
                {selectedProduct?.product.netVolumeUnitOfMeasure}
              </Text>
              {selectedProduct?.product.description && (
                <Text
                  size='md'
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <strong>Description </strong>
                  {selectedProduct?.product.description}
                </Text>
              )}
            </Stack>
          </Stack>
        </Stack>
        {/* Cost prices */}
        {selectedProduct?.product.costPrices.length > 0 && (
          <Stack spacing={8}>
            <Text as='h3' size='lg' weight='bold'>
              Cost prices
            </Text>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                    >
                      Comment
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedProduct.product.costPrices
                    .filter(
                      (costPrice) => costPrice.amount && costPrice.currency
                    )
                    .map((costPrice, index) => {
                      const isFinalRow =
                        index === selectedProduct.product.costPrices.length - 1;

                      const rowStyles = {
                        color: 'var(--colors-gray12)',
                        borderBottom: '0.0625rem solid var(--colors-gray7)',
                        ...(isFinalRow && finalRowBorderStyles),
                      };

                      const currencySymbol = currencies.find(
                        (currency) => currency.code === costPrice.currency
                      )?.symbol;

                      const currencyCode = currencies.find(
                        (currency) => currency.code === costPrice.currency
                      )?.code;

                      return (
                        <TableRow key={costPrice.amount}>
                          <TableCell sx={rowStyles}>{index + 1}</TableCell>
                          <TableCell sx={rowStyles}>
                            {currencySymbol} {currencyCode}
                          </TableCell>
                          <TableCell sx={rowStyles}>
                            {costPrice.amount}
                          </TableCell>
                          <TableCell sx={rowStyles}>
                            {costPrice.comment}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
        {/* Product SKUs */}
        <Stack spacing={8}>
          <Text as='h3' size='lg' weight='bold'>
            SKUs
          </Text>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                  />
                  <TableCell
                    sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                  />
                  <TableCell
                    sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                  >
                    #
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                  >
                    SKU ID
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: 'bold', color: 'var(--colors-gray12)' }}
                  >
                    UoM
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      color: 'var(--colors-gray12)',
                      minWidth: '10rem',
                    }}
                  >
                    Products in SKU
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProduct?.skus.map((sku, index) => (
                  <CollapsibleRow key={sku.id} sku={sku} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='contained'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={handleEditProductClick}
          fullWidth={!isDesktop}
        >
          Edit
        </Button>
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={() => setOpen(false)}
          fullWidth={!isDesktop}
        >
          Close
        </Button>
      </Stack>
    </>
  );
}
