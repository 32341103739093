/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect, useState } from 'react';
import { Heading } from '@components/Heading';
import { Modal, ModalContainer, ModalHeader } from '@components/Modal';
import { EmptyState } from '@components/EmptyState';
import { MdAdd, MdOutlineInfo } from 'react-icons/md';
import { View } from '@views/View';
import { useDocumentTitle, useMediaQuery } from 'usehooks-ts';
import { FleetList } from '@components/FleetList';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { Alert, Link, Snackbar, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { Ratecard } from '@components/RatecardList';
import { RatecardType } from '@components/RatecardList/RatecardList';
import { RatecardPricingForm } from '@forms/manager/AddRatecardPricingForm';
import { RatecardPricingFormValues } from '@forms/manager/AddRatecardPricingForm/RatecardForm';
import { StyledFleetMasterHeaderContainer } from './PriceMasterStyles';
import { useInventory } from './hooks/useInventory';
import {
  isRatecardEditModalOpenAtom,
  isRatecardModalOpenAtom,
  ratecardPricingTypeAtom,
} from '../../store/jotai';
import {
  ADDITIONAL_FTL_RATECARD_CREATE,
  DAILY_RATECARD_CREATE,
  MULTISTOP_RATECARD_CREATE,
  POSTCODE_RATECARD_CREATE,
  RATECARDS_GET,
  VEHICLE_CATEGORIES,
} from '../../constants';
import { useAuthHttp } from '../../hooks';
import { Fleet, fleetMapping } from './types';

export const ratecardMappings = {
  postcode: 'Postcode',
  daily: 'Day Rate',
  multiStop: 'Multistop',
  additionalLoad: 'Additional FTL',
};

type Ratecards = 'postcode' | 'daily' | 'multiStop' | 'additionalLoad';
type PricingType = 'Postcode' | 'Day Rate' | 'Multistop' | 'Additional FTL';
type VehicleRatecards = {
  rateCardId: string;
  transportBaseAddress: string;
};
type VehicleRatecard = {
  pricingType: Ratecards;
  vehicleRateCards: VehicleRatecards[];
  vehicleType: string;
};

export function PriceMaster() {
  const [isRatecardsLoading, setIsRatecardsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vehicleCategories, setVehicleCategories] = useState<string[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isRatecardModalOpen, setIsRatecardModalOpen] = useAtom(
    isRatecardModalOpenAtom
  );
  const [isRatecardEditModalOpen, setIsRatecardEditModalOpen] = useAtom(
    isRatecardEditModalOpenAtom
  );
  const [ratecardPricingType, setRatecardPricingType] = useAtom(
    ratecardPricingTypeAtom
  );
  const [ratecards, setRatecards] = useState<{
    [key: string]: [{ vehicleType: string; vehicleRateCards: object[] }];
  }>({});
  const [hasUploadedRatecard, setHasUploadedRatecard] = useState(false);
  const [selectedRatecard, setSelectedRatecard] = useState<any>({});
  const [selectedVehicleType, setSelectedVehicleType] = useState<string>('');

  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const navigate = useNavigate();

  const isSmall = useMediaQuery('(min-width: 36em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  useDocumentTitle('FLOX - Fleet');

  const { getProductsScrollParent } = useInventory();

  const fetchRatecards = async () => {
    setIsRatecardsLoading(true);
    try {
      const { data } = await authHttp.get(RATECARDS_GET);

      if (data) setRatecards(data.transportRatecards);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsRatecardsLoading(false);
      setIsRatecardModalOpen(false);
    }
  };

  const fetchVehicleCategories = async () => {
    try {
      const { data } = await authHttp.get(VEHICLE_CATEGORIES);

      if (data) setVehicleCategories(data.vehicleCategories);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsRatecardsLoading(false);
      setIsRatecardModalOpen(false);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  useEffect(() => {
    fetchRatecards();
    fetchVehicleCategories();
  }, []);

  useEffect(() => {
    if (!hasUploadedRatecard) return;
    fetchRatecards();
  }, [hasUploadedRatecard]);

  useEffect(() => {
    if (!isRatecardModalOpen) return;
    setHasUploadedRatecard(false);
  }, [isRatecardModalOpen]);

  const handleOnSubmit = async (data: RatecardPricingFormValues) => {
    setIsSubmitting(true);

    const createUrl = (pricingType: PricingType): string => {
      switch (pricingType) {
        case 'Day Rate':
          return DAILY_RATECARD_CREATE;
        case 'Postcode':
          return POSTCODE_RATECARD_CREATE;
        case 'Additional FTL':
          return ADDITIONAL_FTL_RATECARD_CREATE;
        case 'Multistop':
          return MULTISTOP_RATECARD_CREATE;
        default:
          throw new Error(`Invalid pricingType: ${pricingType}`);
      }
    };

    const postDailyRate = async () => {
      try {
        const { data: uploadRateCard } = await authHttp.post(
          createUrl(ratecardPricingType as PricingType),
          {
            rateCard: {
              transportBaseAddress: data.transportBaseAddress,
              transportBaseLocation: data.location,
              chargeCurrency: data.currency,
              chargeForEightHourDay: data.chargeForEightHourDay,
              hourlyChargeOverEightHours: data.hourlyChargeOverEightHours,
              hourlyChargeOverTehnHours: data.hourlyChargeOverTehnHours,
              vehicleType: selectedVehicleType,
            },
          }
        );

        if (uploadRateCard) {
          setIsRatecardModalOpen(false);
          setHasUploadedRatecard(true);
          // setIsSnackbarOpen(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    };

    const postPostcode = async () => {
      try {
        const { data: uploadRateCard } = await authHttpFormData.post(
          createUrl(ratecardPricingType as PricingType),
          {
            transportBaseAddress: data.transportBaseAddress,
            transportBaseLocation: data.location,
            rateCurrency: data.currency,
            rates: data.uploadFile,
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        if (uploadRateCard) {
          setIsRatecardModalOpen(false);
          setHasUploadedRatecard(true);
          // setIsSnackbarOpen(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    };

    const postAdditionalFTL = async () => {
      try {
        const { data: uploadRateCard } = await authHttpFormData.post(
          createUrl(ratecardPricingType as PricingType),
          {
            transportBaseAddress: data.transportBaseAddress,
            transportBaseLocation: data.location,
            rateCurrency: data.currency,
            rates: data.uploadFile,
          },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        if (uploadRateCard) {
          setIsRatecardModalOpen(false);
          setHasUploadedRatecard(true);
          // setIsSnackbarOpen(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    };

    const postMultiStop = async () => {
      try {
        console.log(data);
        const { data: uploadRateCard } = await authHttp.post(
          createUrl(ratecardPricingType as PricingType),
          {
            rateCard: {
              transportBaseAddress: data.transportBaseAddress,
              transportBaseLocation: data.location,
              baseCharge: data.baseCharge,
              chargeCurrency: data.currency,
              includedDistance: data.includedDistance,
              distanceUnit: data.distanceUoM,
              chargePerAdditionalDistanceUnit:
                data.chargePerAdditionalDistanceUnit,
              chargePerAdditionalStop: data.chargePerAdditionalStop,
              chargePerAdditionalHour: data.chargePerAdditionalHour,
              chargePerOvertimeHour: data.chargePerOvertimeHour,
              standardIncludedLoadingMinutesPerDrop:
                data.standardIncludedLoadingMinutesPerDrop,
              standardIncludedUnloadingMinutesPerDrop:
                data.standardIncludedUnloadingMinutesPerDrop,
              vehicleType: selectedVehicleType,
            },
          }
        );

        if (uploadRateCard) {
          setIsRatecardModalOpen(false);
          setHasUploadedRatecard(true);
          // setIsSnackbarOpen(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    };

    try {
      switch (ratecardPricingType) {
        case 'Day Rate':
          await postDailyRate();
          break;
        case 'Postcode':
          await postPostcode();
          break;
        case 'Additional FTL':
          await postAdditionalFTL();
          break;
        case 'Multistop':
          await postMultiStop();
          break;
        default:
          throw new Error(`Invalid pricingType: ${ratecardPricingType}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const remapRatecards = ($ratecards: {
    [key: string]: unknown[];
  }): { [key: string]: unknown[] } => {
    const remappedRatecards: { [key: string]: VehicleRatecard[] } = {};

    Object.entries($ratecards).forEach(([pricingType, ratecard]) => {
      ratecard.forEach((item: any) => {
        const { vehicleType, vehicleRateCards } = item;
        const mappedVehicleType =
          fleetMapping[vehicleType as Fleet] || vehicleType;
        if (!remappedRatecards[mappedVehicleType]) {
          remappedRatecards[mappedVehicleType] = [];
        }
        remappedRatecards[mappedVehicleType].push({
          pricingType: pricingType as Ratecards,
          vehicleRateCards,
          vehicleType,
        });
      });
    });

    const apiRatecards = ['postcode', 'daily', 'multiStop', 'additionalLoad'];

    Object.entries(vehicleCategories).forEach(([key, vehicleType]) => {
      const mappedVehicleType =
        fleetMapping[vehicleType as Fleet] || vehicleType;

      if (!remappedRatecards[mappedVehicleType]) {
        remappedRatecards[mappedVehicleType] = [];
      }

      apiRatecards.forEach((ratecardType) => {
        const existingRatecard = remappedRatecards[mappedVehicleType].find(
          (rc: { pricingType: string }) => rc.pricingType === ratecardType
        );

        if (!existingRatecard) {
          remappedRatecards[mappedVehicleType].push({
            pricingType: ratecardType as Ratecards,
            vehicleRateCards: [],
            vehicleType: '',
          });
        } else {
          remappedRatecards[mappedVehicleType].push({
            pricingType: existingRatecard.pricingType,
            vehicleRateCards: [],
            vehicleType: '',
          });
        }
      });
    });

    return remappedRatecards;
  };

  const testratecards = {
    daily: [
      {
        vehicleType: 'VanLessThan1AndHalft',
        vehicleRateCards: [
          {
            rateCardId: '1c063dc5-b033-433c-970d-edc80d6b12e6',
            transportBaseAddress: '1231231231',
          },
          // ... other rate cards
        ],
      },
    ],
  };

  const remappedRatecards = remapRatecards(ratecards);

  // console.log(remappedRatecards);

  return (
    <View>
      <StyledFleetMasterHeaderContainer>
        <Stack gap={6}>
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
            Transport price master
          </Heading>
          <Stack direction='row' gap={6} alignItems='center'>
            <MdOutlineInfo size='1.25rem' />
            <Typography variant='body2'>
              Click on a Ratecard to enter pricing details for that vehicle
              category
            </Typography>
          </Stack>
        </Stack>
      </StyledFleetMasterHeaderContainer>
      <InfiniteScroll
        element={FleetList as unknown as ReactNode}
        initialLoad
        loadMore={() => {}}
        hasMore={false}
        useWindow={false}
        getScrollParent={getProductsScrollParent}
        loader={
          <InfiniteScrollLoading
            key='price-loader'
            loadingMessage='Loading ratecards...'
            sx={{ gridColumn: '1 / -1' }}
          />
        }
      >
        {isRatecardsLoading && !vehicleCategories.length && (
          <InfiniteScrollLoading
            key='price-loader'
            loadingMessage='Loading ratecards...'
            sx={{ gridColumn: '1 / -1' }}
          />
        )}
        {!isRatecardsLoading && !vehicleCategories.length && (
          <EmptyState
            icon={<MdOutlineInfo size='8rem' />}
            title='No vehicle categories found.'
            subtitle='Add a vehicle in the Fleet Master and return here to get started'
            css={{ gridColumn: '1 / -1' }}
          />
        )}
        <Stack gap={36}>
          {Object.entries(remappedRatecards).map(
            ([vehicleType, $ratecards]) => (
              <Stack key={vehicleType} gap={12}>
                <h2>{vehicleType}</h2>
                <Stack direction='row' gap={12} flexWrap='wrap'>
                  {Object.entries($ratecards).map(
                    ([ratecardKey, $ratecard], index) => (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {($ratecard as VehicleRatecard).vehicleRateCards
                          ?.length > 0 ? (
                          ($ratecard as VehicleRatecard).vehicleRateCards.map(
                            (vehicleRateCard) => (
                              <Ratecard
                                {...(index === 0 && { id: 'first' })}
                                key={vehicleRateCard.rateCardId}
                                type={
                                  (ratecardMappings[
                                    ($ratecard as VehicleRatecard)
                                      .pricingType as Ratecards
                                  ] as RatecardType) ||
                                  (ratecardKey as RatecardType)
                                }
                                baseAddress={
                                  vehicleRateCard.transportBaseAddress
                                }
                                active
                                orientation={
                                  isTablet ? 'horizontal' : 'vertical'
                                }
                                onClick={() => {
                                  setSelectedRatecard(vehicleRateCard);
                                  setSelectedVehicleType(
                                    Object.keys(fleetMapping).find(
                                      (key) => fleetMapping[key] === vehicleType
                                    ) || vehicleType
                                  );
                                  // setIsRatecardEditModalOpen(true);
                                  setRatecardPricingType(
                                    (ratecardMappings[
                                      ($ratecard as VehicleRatecard)
                                        .pricingType as Ratecards
                                    ] as RatecardType) ||
                                      (($ratecard as VehicleRatecard)
                                        .pricingType as RatecardType)
                                  );
                                }}
                              />
                            )
                          )
                        ) : (
                          <Ratecard
                            {...(index === 0 && { id: 'first' })}
                            key={($ratecard as VehicleRatecard).pricingType}
                            type={
                              (ratecardMappings[
                                ($ratecard as VehicleRatecard)
                                  .pricingType as Ratecards
                              ] as RatecardType) ||
                              (ratecardKey as RatecardType)
                            }
                            active={false}
                            orientation={isTablet ? 'horizontal' : 'vertical'}
                            onClick={() => {
                              setSelectedVehicleType(
                                Object.keys(fleetMapping).find(
                                  (key) => fleetMapping[key] === vehicleType
                                ) || vehicleType
                              );
                              setIsRatecardModalOpen(true);
                              setRatecardPricingType(
                                (ratecardMappings[
                                  ($ratecard as VehicleRatecard)
                                    .pricingType as Ratecards
                                ] as RatecardType) ||
                                  (($ratecard as VehicleRatecard)
                                    .pricingType as RatecardType)
                              );
                            }}
                          />
                        )}
                      </>
                    )
                  )}
                </Stack>
              </Stack>
            )
          )}
        </Stack>
      </InfiniteScroll>
      <Modal
        open={isRatecardModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsRatecardModalOpen(isOpen);
          setRatecardPricingType('');
          setSelectedVehicleType('');
          setSelectedRatecard({});
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Add Ratecard Pricing' />
          <RatecardPricingForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
            prefilledValues={{
              transportBaseAddress: selectedRatecard.transportBaseAddress,
              vehicleType: selectedVehicleType,
            }}
          />
        </ModalContainer>
      </Modal>
      <Modal
        open={isRatecardEditModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsRatecardEditModalOpen(isOpen);
          setRatecardPricingType('');
          setSelectedVehicleType('');
          setSelectedRatecard({});
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Edit Ratecard Pricing' />
          <RatecardPricingForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
            prefilledValues={{
              transportBaseAddress: selectedRatecard.transportBaseAddress,
              vehicleType: selectedVehicleType,
            }}
          />
        </ModalContainer>
      </Modal>
      {/* <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity='info'
          sx={{ width: '100%' }}
        >
          Your warehouse profile is being processed. To ensure your prospective
          clients access accurate and up-to-date information, we may need
          additional details or verify certain data for your profile. If
          required, we&apos;ll reach out via email. Once cleared, your service
          listing will go live on FLOX. Thank you!
        </Alert>
      </Snackbar> */}
    </View>
  );
}
