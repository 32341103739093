/* eslint-disable react/destructuring-assignment */
import { Button } from '@components/Button';
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Typography,
  Stack,
  useMediaQuery,
  Autocomplete,
} from '@mui/material';
import { ModalContent, ModalFooter } from '@components/Modal';
import { MdCheck } from 'react-icons/md';
import { Icon } from '@components/Icon';
import { SyntheticEvent, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Fleet, fleetMapping } from '@views/FleetMaster/types';
import moment from 'moment';
import {
  isPartialAvailabilityModalOpenAtom,
  partialAvaiabilityVehicleIdAtom,
  tomorrowOrDayAfterAtom,
} from '../../store/jotai';
import { FLEET_IMAGES_URL, HERE_APIKEY } from '../../constants';
import { Vehicle } from './VehicleTable';

const schema = yup.object().shape({
  pickUpDetails: yup.array().of(
    yup.object().shape({
      location: yup.string().required('Location is required'),
      geoLocation: yup.object().shape({
        lon: yup.number(),
        lat: yup.number(),
      }),
      departureTime: yup.string().required('Departure time is required'),
      loadSize: yup.object().shape({
        pallets: yup
          .number()
          .required('Number of pallets is required')
          .typeError('Number of pallets must be a number')
          .moreThan(0, 'Number of pallets must be greater than 0'),
        weight: yup
          .number()
          .required('Weight is required')
          .typeError('Weight must be a number')
          .moreThan(0, 'Weight must be greater than 0'),
      }),
    })
  ),
  dropOffDetails: yup.array().of(
    yup.object().shape({
      location: yup.string().required('Location is required'),
      geoLocation: yup.object().shape({
        lon: yup.number(),
        lat: yup.number(),
      }),
      latestDropOffTime: yup
        .string()
        .required('Latest drop off time is required'),
      loadSize: yup.object().shape({
        pallets: yup
          .number()
          .required('Number of pallets is required')
          .typeError('Number of pallets must be a number')
          .moreThan(0, 'Number of pallets must be greater than 0'),
        weight: yup
          .number()
          .required('Weight is required')
          .typeError('Weight must be a number')
          .moreThan(0, 'Weight must be greater than 0'),
      }),
    })
  ),
  availableCapacity: yup.object().shape({
    pallets: yup
      .number()
      .required('Available capacity in pallets is required')
      .typeError('Available capacity in pallets must be a number')
      .moreThan(-1, 'Available capacity in pallets must be positive'),
    weight: yup
      .number()
      .required('Available capacity in weight is required')
      .typeError('Available capacity in weight must be a number')
      .moreThan(-1, 'Available capacity in weight must be positive'),
  }),
  additionalDiscount: yup
    .number()
    .required('Additional discount is required')
    .typeError('Additional discount must be a number')
    .moreThan(-1, 'Additional discount must be positive'),
});

export type PartialAvailabilityFormValues = {
  pickUpDetails: {
    location: string;
    geoLocation: { lon: number; lat: number };
    departureTime: string;
    loadSize: {
      pallets: number;
      weight: number;
    };
  }[];
  dropOffDetails: {
    location: string;
    geoLocation: { lon: number; lat: number };
    latestDropOffTime: string;
    loadSize: {
      pallets: number;
      weight: number;
    };
  }[];
  availableCapacity: {
    pallets: number;
    weight: number;
  };
  additionalDiscount: number;
};

interface PartialAvailabilityFormProps {
  vehicles: Vehicle[];
  vehicleAvailabilities: any;
  handleOnSubmit: (data: PartialAvailabilityFormValues) => unknown;
  isSubmitting: boolean;
}

export function PartialAvailabilityForm({
  vehicles,
  vehicleAvailabilities,
  handleOnSubmit,
  isSubmitting,
}: PartialAvailabilityFormProps) {
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [pickupInputValue, setPickupInputValue] = useState<string[]>([]);
  const [pickupOptions, setPickupOptions] = useState<any>([]);
  const [dropoffInputValue, setDropoffInputValue] = useState<string[]>([]);
  const [dropoffOptions, setDropoffOptions] = useState<any>([]);
  const fleetImagePlaceholder = `/images/vehicle_placeholder.png`;

  const [partialAvaiabilityVehicleId, setPartialAvailabilityVehicleId] =
    useAtom(partialAvaiabilityVehicleIdAtom);

  const vehicle = vehicles.find(
    ($vehicle) => $vehicle.vehicleId === partialAvaiabilityVehicleId
  );

  const vehicleAvailability = vehicleAvailabilities.find(
    ($vehicleAvailability: any) =>
      $vehicleAvailability.vehicle.vehicleId === partialAvaiabilityVehicleId
  );

  const { maxPallets, maxKilograms } = vehicle?.vehicleCapacity || {
    maxPallets: 0,
    maxKilograms: 0,
  };

  const defaultValues: PartialAvailabilityFormValues = {
    pickUpDetails: vehicleAvailability?.partialAvailabilityPickUps?.map(
      (detail: any) => ({
        location: detail.locationAddress,
        geoLocation: {
          lat: detail.geoLocation.lat,
          lon: detail.geoLocation.lon,
        },
        departureTime: detail.departureTime,
        loadSize: {
          pallets: detail.loadSizeInPallets,
          weight: detail.loadSizeInKilograms,
        },
      })
    ) || [
      {
        location: '',
        departureTime: '',
        loadSize: {
          pallets: maxPallets,
          weight: maxKilograms,
        },
      },
    ],
    dropOffDetails: vehicleAvailability?.partialAvailabilityDropOffs?.map(
      (detail: any) => ({
        location: detail.locationAddress,
        geoLocation: {
          lat: detail.geoLocation.lat,
          lon: detail.geoLocation.lon,
        },
        latestDropOffTime: detail.latestDropOffTime,
        loadSize: {
          pallets: detail.loadSizeInPallets,
          weight: detail.loadSizeInKilograms,
        },
      })
    ) || [
      {
        location: '',
        latestDropOffTime: '',
        loadSize: {
          pallets: maxPallets,
          weight: maxKilograms,
        },
      },
    ],
    availableCapacity: {
      pallets: maxPallets,
      weight: maxKilograms,
    },
    additionalDiscount: vehicleAvailability?.additionalDiscountPercentage || 0,
  };

  const methods = useForm<PartialAvailabilityFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues,
  });

  const {
    handleSubmit,
    trigger,
    formState,
    getValues,
    control,
    setValue,
    register,
    watch,
  } = methods;

  const pickUpDetails = watch('pickUpDetails');

  const { errors } = formState;

  const {
    fields: pickUpFields,
    append: appendPickUp,
    remove: removePickUp,
  } = useFieldArray({
    control,
    name: 'pickUpDetails',
  });

  const {
    fields: dropOffFields,
    append: appendDropOff,
    remove: removeDropOff,
  } = useFieldArray({
    control,
    name: 'dropOffDetails',
  });

  const setIsPartialAvailabilityModalOpen = useSetAtom(
    isPartialAvailabilityModalOpenAtom
  );

  const tomorrowOrDayAfter = useAtomValue(tomorrowOrDayAfterAtom);

  const handlePickupInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string,
    index: number
  ) => {
    const newPickupInputValue: string[] = [...pickupInputValue];
    newPickupInputValue[index] = newInputValue;
    setPickupInputValue(newPickupInputValue);
  };

  const handlePickupFetchSuggestions = (input: string, index: number) => {
    fetch(
      `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items: {
            id: any;
            label: string;
            city: string;
            country: string;
          }[] = data.items.map(
            (item: {
              id: any;
              address: { label: string; city: string; countryName: string };
            }) => ({
              id: item.id,
              label: item.address.label,
              city: item.address.city,
              country: item.address.countryName,
            })
          );
          const newPickupOptions = [...pickupOptions];
          newPickupOptions[index] = items;
          setPickupOptions(newPickupOptions);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const handlePickupOptionSelect = async (
    event: any,
    value: any,
    index: number
  ) => {
    if (value && value.id) {
      setValue(`pickUpDetails.${index}.location`, value.label);
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            const { lat, lng: lon } = position;

            const geoLocation = {
              lat,
              lon,
            };

            setValue(`pickUpDetails.${index}.geoLocation`, geoLocation);
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  const handleDropoffInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string,
    index: number
  ) => {
    const newDropoffInputValue: string[] = [...dropoffInputValue];
    newDropoffInputValue[index] = newInputValue;
    setDropoffInputValue(newDropoffInputValue);
  };

  const handleDropoffFetchSuggestions = (input: string, index: number) => {
    fetch(
      `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${input}&apiKey=${HERE_APIKEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items = data.items.map(
            (item: {
              id: any;
              address: { label: string; city: string; countryName: string };
            }) => ({
              id: item.id,
              label: item.address.label,
              city: item.address.city,
              country: item.address.countryName,
            })
          );
          const newDropoffOptions = [...dropoffOptions];
          newDropoffOptions[index] = items;
          setDropoffOptions(newDropoffOptions);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const handleDropoffOptionSelect = async (
    event: any,
    value: any,
    index: number
  ) => {
    if (value && value.id) {
      setValue(`dropOffDetails.${index}.location`, value.label);
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            const { lat, lng: lon } = position;

            const location = {
              lat,
              lon,
            };

            setValue(`dropOffDetails.${index}.geoLocation`, location);
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  const onClose = () => {
    setIsPartialAvailabilityModalOpen(false);
    setPartialAvailabilityVehicleId('');
  };

  const onSubmit = async (data: PartialAvailabilityFormValues) => {
    console.log(getValues(), errors);
    const isValid = await trigger();
    if (isValid) {
      try {
        handleOnSubmit(data);
        console.log(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
  };

  const calculateRemainingPallets = () => {
    const totalPickupPallets = pickUpDetails.reduce(
      (total: number, detail: any) => total + Number(detail.loadSize.pallets),
      0
    );

    const remainingPallets = maxPallets - totalPickupPallets;

    return remainingPallets;
  };

  const calculateRemainingWeight = () => {
    const totalPickupWeight = pickUpDetails.reduce(
      (total: number, detail: any) => total + Number(detail.loadSize.weight),
      0
    );

    // Subtract the total pickup weight from the initial weight
    const remainingWeight = maxKilograms - totalPickupWeight;

    return remainingWeight;
  };

  const vehicleTypePlaceholders = {
    VanLessThan1AndHalft: '/images/VanLessThan1AndHalft.png',
    LgvLessThan3AndHalft: '/images/LgvLessThan3AndHalft.png',
    TwoAxleRigidLessThan7AndHalf5t:
      '/images/TwoAxleRigidLessThan7AndHalf5t.png',
    TwoAxleRigid18t: '/images/TwoAxleRigidLessThan7AndHalf5t.png',
    TwoAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26t.png',
    ThreeAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26t.png',
    MultiAxleArticMoreThan26t: '/images/MultiAxleArticMoreThan26t.png',
    SpecialOrOther: '/images/LgvLessThan3AndHalft.png',
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: '100%' }}
      >
        <ModalContent
          css={{
            maxHeight: 'calc(85vh - 12.5rem)',
            minHeight: 'calc(85vh - 12.5rem)',
          }}
          id='warehouse-form-modal'
        >
          <Stack gap={24}>
            <Stack gap={12} direction='row' justifyContent='space-between'>
              <Stack>
                <Typography variant='h6'>
                  Vehicle Type:{' '}
                  {fleetMapping[vehicle?.vehicleType as Fleet] ||
                    vehicle?.vehicleType}
                </Typography>
                <Typography variant='h6'>
                  Make & Model: {vehicle?.model}
                </Typography>
                <Typography variant='h6'>
                  Registration Number: {vehicle?.registrationNumber}
                </Typography>
                <Typography variant='h6'>
                  Vehicle Capacity:{' '}
                  {`${vehicle?.vehicleCapacity.maxPallets} pallets, ${vehicle?.vehicleCapacity.maxKilograms} kg`}
                </Typography>
                {/* <Typography variant='h6' fontWeight='bold'>
                  Route planning date:{' '}
                  {tomorrowOrDayAfter === 'tomorrow'
                    ? `Tomorrow (${moment().add(1, 'days').format('DD/MM')})`
                    : `Day after tomorrow (${moment()
                        .add(1, 'days')
                        .format('DD/MM')})`}
                </Typography> */}
              </Stack>
              <Stack width='50%'>
                <img
                  style={{ height: '200px', objectFit: 'contain' }}
                  src={
                    vehicle?.images && vehicle.images.length
                      ? `${FLEET_IMAGES_URL}/${vehicle.images[0].imageLocation}/small/${vehicle.images[0].remoteName}`
                      : (vehicleTypePlaceholders as { [key: string]: string })[
                          vehicle?.vehicleType ?? ''
                        ] || fleetImagePlaceholder
                  }
                  alt={vehicle?.vehicleType}
                />
              </Stack>
            </Stack>
            <Stack gap={12}>
              {pickUpFields.map((pickupField, index) => (
                <Stack key={pickupField.id} gap={12}>
                  <Controller
                    name={`pickUpDetails.${index}.location`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        freeSolo
                        options={pickupOptions[index] || []}
                        getOptionLabel={(option: string | { label: string }) =>
                          typeof option === 'string' ? option : option.label
                        }
                        filterOptions={(options, state) => options}
                        inputValue={pickupInputValue[index] || field.value}
                        onInputChange={(event, newInputValue) => {
                          handlePickupInputChange(event, newInputValue, index);
                          handlePickupFetchSuggestions(newInputValue, index);
                        }}
                        onChange={(e, value) => {
                          handlePickupOptionSelect(e, value, index);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(error)}
                            helperText={Boolean(error) && error?.message}
                            label='Pick Up Location'
                            placeholder='Enter postcode'
                          />
                        )}
                        disablePortal
                      />
                    )}
                  />
                  <Controller
                    name={`pickUpDetails.${index}.departureTime`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Departure Time'
                        type='time'
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Controller
                    name={`pickUpDetails.${index}.loadSize.pallets`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Load Size (Pallets)'
                      />
                    )}
                  />
                  <Controller
                    name={`pickUpDetails.${index}.loadSize.weight`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Load Size (Weight in kg)'
                      />
                    )}
                  />
                  <Button onClick={() => removePickUp(index)}>Remove</Button>
                </Stack>
              ))}
              <Button
                onClick={() =>
                  appendPickUp({
                    location: '',
                    geoLocation: {
                      lon: 0,
                      lat: 0,
                    },
                    departureTime: '',
                    loadSize: {
                      pallets: 0,
                      weight: 0,
                    },
                  })
                }
              >
                Add Pick Up
              </Button>
            </Stack>
            <Stack gap={12}>
              {dropOffFields.map((dropoffField, index) => (
                <Stack key={dropoffField.id} gap={12}>
                  <Controller
                    name={`dropOffDetails.${index}.location`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        freeSolo
                        options={dropoffOptions[index] || []}
                        getOptionLabel={(option: string | { label: string }) =>
                          typeof option === 'string' ? option : option.label
                        }
                        inputValue={dropoffInputValue[index] || field.value}
                        onInputChange={(event, newInputValue) => {
                          handleDropoffInputChange(event, newInputValue, index);
                          handleDropoffFetchSuggestions(newInputValue, index);
                        }}
                        onChange={(e, value) => {
                          handleDropoffOptionSelect(e, value, index);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(error)}
                            helperText={Boolean(error) && error?.message}
                            label='Drop Off Location'
                            placeholder='Enter postcode'
                          />
                        )}
                        disablePortal
                      />
                    )}
                  />
                  <Controller
                    name={`dropOffDetails.${index}.latestDropOffTime`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Latest Drop Off Time'
                        type='time'
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <Controller
                    name={`dropOffDetails.${index}.loadSize.pallets`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Load Size (Pallets)'
                      />
                    )}
                  />
                  <Controller
                    name={`dropOffDetails.${index}.loadSize.weight`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                        label='Load Size (Weight  in kg)'
                      />
                    )}
                  />
                  <Button onClick={() => removeDropOff(index)}>Remove</Button>
                </Stack>
              ))}
              <Button
                onClick={() =>
                  appendDropOff({
                    location: '',
                    geoLocation: {
                      lon: 0,
                      lat: 0,
                    },
                    latestDropOffTime: '',
                    loadSize: {
                      pallets: 0,
                      weight: 0,
                    },
                  })
                }
              >
                Add Drop Off
              </Button>
            </Stack>
            <Stack gap={24}>
              <Stack gap={12}>
                <Typography>
                  Remaining Available Capacity (Pallets):{' '}
                  {calculateRemainingPallets()}
                </Typography>
                <Typography>
                  Remaining Available Capacity (Weight):{' '}
                  {calculateRemainingWeight()}
                </Typography>
              </Stack>
              <Controller
                name='additionalDiscount'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    label='Additional Discount (%)'
                  />
                )}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button
            variant='secondary'
            size={isTablet ? 'md' : 'sm'}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type='submit'
            rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
            isLoading={isSubmitting}
            loadingIcon={<Icon name='loading' />}
            loadingText='Submitting...'
            size={isTablet ? 'md' : 'sm'}
            action='cta'
            disabled={
              calculateRemainingPallets() < 0 || calculateRemainingWeight() < 0
            }
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
}
