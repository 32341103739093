import {
  Control,
  FieldErrors,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { AddProductFormValues } from '../types';
import { Palletising } from '../components/Palletising';

interface StepThreeProps {
  register: UseFormRegister<AddProductFormValues>;
  control: Control<AddProductFormValues>;
  watch: UseFormWatch<AddProductFormValues>;
  getValues: UseFormGetValues<AddProductFormValues>;
  setValue: UseFormSetValue<AddProductFormValues>;
  setFocus: UseFormSetFocus<AddProductFormValues>;
  getFieldState: UseFormGetFieldState<AddProductFormValues>;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export function StepThree({
  register,
  control,
  watch,
  getValues,
  setValue,
  setFocus,
  getFieldState,
  isSmall,
  isTablet,
  isDesktop,
}: StepThreeProps) {
  return (
    <div>
      {getValues().product.skus &&
        getValues().product.skus.length &&
        getValues().product.skus.map((sku, skuIndex) => (
          <Palletising
            key={sku.skuId}
            sku={sku}
            skuIndex={skuIndex}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setFocus={setFocus}
            getFieldState={getFieldState}
            isSmall={isSmall}
            isTablet={isTablet}
            isDesktop={isDesktop}
            watch={watch}
          />
        ))}
    </div>
  );
}

StepThree.displayName = 'StepThree';
