import Search from '@mui/icons-material/Search';
import { SxProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ChangeEvent } from 'react';

interface ShipmentSearchProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}

export function ShipmentSearchFilter({
  value,
  onChange,
  sx,
}: ShipmentSearchProps) {
  return (
    <FormControl variant='outlined' sx={sx}>
      <OutlinedInput
        size='small'
        fullWidth
        startAdornment={
          <InputAdornment position='start'>
            <IconButton edge='start'>
              <Search />
            </IconButton>
          </InputAdornment>
        }
        value={value}
        onChange={onChange}
        placeholder='Search...'
      />
    </FormControl>
  );
}
