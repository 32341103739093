import { styled, css } from '@stitchesConfig';
import { Form } from 'formik';

export const StyledSupplierHeaderContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

StyledSupplierHeaderContainer.displayName = 'StyledSupplierHeaderContainer';

export const AddSKUForm = styled(Form, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$space16',
});

AddSKUForm.displayName = 'AddSKUForm';

export const DropzoneContainerStyles = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
});

export const DropzoneFocusedStyles = css({
  borderColor: '#2196f3',
});

export const DropzoneAcceptStyles = css({
  borderColor: '#00e676',
});

export const DropzoneRejectStyles = css({
  borderColor: '#2196ff',
});

export const StyledPriceGrid = styled('div', {
  display: 'grid',
  gap: '$space16',
  mb: '$space16',

  '@sm': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

StyledPriceGrid.displayName = 'StyledPriceGrid';

export const StyledPriceGridItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space16',
});

StyledPriceGridItem.displayName = 'StyledPriceGridItem';

export const StyledAddPriceContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space16',
  flex: 1,
  borderRadius: '$radii8',
  borderWidth: '$sizes$size2',
  borderStyle: 'dashed',
  borderColor: '$colors$gray7',
  height: '100%',
  minHeight: '16.5rem',
  alignSelf: 'flex-end',
});

StyledAddPriceContainer.displayName = 'StyledAddPriceContainer';
