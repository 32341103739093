import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Fleet, fleetMapping } from '@views/FleetMaster/types';
import { useReadLocalStorage } from 'usehooks-ts';
import { useState } from 'react';
import moment from 'moment';
import { Button, Checkbox } from '@mui/material';
import {
  FLEET_AVAILABILITY_CREATE,
  FLEET_AVAILABILITY_UPDATE,
  FLEET_IMAGES_URL,
} from '../../constants';
import {
  createdVehicleAvailabilityIdsAtom,
  fullCapacityVehiclesDayAfterTomorrowAtom,
  fullCapacityVehiclesTomorrowAtom,
  isPartialAvailabilityModalOpenAtom,
  partialAvaiabilityVehicleIdAtom,
  tomorrowOrDayAfterAtom,
  vehicleAvailabilityIdsAtom,
} from '../../store/jotai';
import { useAuthHttp } from '../../hooks';

type VehicleImage = {
  id: string;
  title: string;
  originalFileName: string;
  remoteName: string;
  imageLocation: string;
};

export interface Vehicle {
  vehicleId: string;
  images: VehicleImage[];
  vehicleType: string;
  make: string;
  model: string;
  registrationNumber: string;
  vehicleCapacity: {
    maxPallets: number;
    maxKilograms: number;
  };
}

interface VehiclesTableProps {
  vehicles: Vehicle[];
  vehicleAvailabilities: any[];
  fetchFleet: () => void;
}

export function VehiclesTable({
  vehicles,
  vehicleAvailabilities,
  fetchFleet,
}: VehiclesTableProps) {
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const fleetImagePlaceholder = `/images/vehicle_placeholder.png`;
  const authHttp = useAuthHttp();

  const [fullCapacityVehiclesTomorrow, setFullCapacityVehiclesTomorrow] =
    useAtom(fullCapacityVehiclesTomorrowAtom);

  // const [notAvailableVehiclesTomorrow, setNotAvailableVehiclesTomorrow] =
  //   useAtom(notAvailableVehiclesTomorrowAtom);

  const [
    fullCapacityVehiclesDayAfterTomorrow,
    setFullCapacityVehiclesDayAfterTomorrow,
  ] = useAtom(fullCapacityVehiclesDayAfterTomorrowAtom);

  // const [
  //   notAvailableVehiclesDayAfterTomorrow,
  //   setNotAvailableVehiclesDayAfterTomorrow,
  // ] = useAtom(notAvailableVehiclesDayAfterTomorrowAtom);

  const setIsPartialAvailabilityModalOpen = useSetAtom(
    isPartialAvailabilityModalOpenAtom
  );

  const setPartialAvailabilityVehicleId = useSetAtom(
    partialAvaiabilityVehicleIdAtom
  );

  const [createdVehicleAvailabilityIds, setCreatedVehicleAvailabilityIds] =
    useAtom(createdVehicleAvailabilityIdsAtom);

  const [vehicleAvailabilitiesIds, setVehicleAvailabilitiesIds] = useAtom(
    vehicleAvailabilityIdsAtom
  );

  const tomorrowOrDayAfter = useAtomValue(tomorrowOrDayAfterAtom);

  const handleFullCapacityChange = async (id: string) => {
    const tomorrow = moment().add(1, 'days');
    const dayAfterTomorrow = moment().add(2, 'days');

    const changeVehicleAvailabilityRequest = async () => {
      await authHttp.post(
        createdVehicleAvailabilityIds.find(
          (availability) =>
            availability.vehicleId === id &&
            moment(availability.date).isSame(
              tomorrowOrDayAfter === 'tomorrow' ? tomorrow : dayAfterTomorrow,
              'day'
            )
        )
          ? FLEET_AVAILABILITY_UPDATE
          : FLEET_AVAILABILITY_CREATE,
        {
          vehicleAvailability: {
            availabilityId:
              vehicleAvailabilitiesIds.find(
                (availability) =>
                  availability.vehicleId === id &&
                  moment(availability.date).isSame(
                    tomorrowOrDayAfter === 'tomorrow'
                      ? tomorrow
                      : dayAfterTomorrow,
                    'day'
                  )
              )?.availabilityId ?? undefined,
            vehicleId: id,
            isFullyAvailable:
              tomorrowOrDayAfter === 'tomorrow'
                ? !fullCapacityVehiclesTomorrow.includes(id)
                : !fullCapacityVehiclesDayAfterTomorrow.includes(id),
            availabilityDate: moment()
              .add(tomorrowOrDayAfter === 'tomorrow' ? 1 : 2, 'days')
              .format('YYYY-MM-DD'),
            partialAvailabilityPickUps: null,
            partialAvailabilityDropOffs: null,
            additionalDiscountPercentage: 0,
          },
        }
      );
    };

    if (tomorrowOrDayAfter === 'tomorrow') {
      setFullCapacityVehiclesTomorrow((prevState) =>
        prevState.includes(id)
          ? prevState.filter((i) => i !== id)
          : [...prevState, id]
      );

      setCreatedVehicleAvailabilityIds((oldArray) => [
        ...oldArray,
        {
          vehicleId: id,
          date: moment()
            .add(tomorrowOrDayAfter === 'tomorrow' ? 1 : 2, 'days')
            .format('YYYY-MM-DD'),
        },
      ]);

      try {
        await changeVehicleAvailabilityRequest();
        fetchFleet();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      // setNotAvailableVehiclesTomorrow((prevState) =>
      //   prevState.filter((i) => i !== id)
      // );
    } else {
      setFullCapacityVehiclesDayAfterTomorrow((prevState) =>
        prevState.includes(id)
          ? prevState.filter((i) => i !== id)
          : [...prevState, id]
      );

      setCreatedVehicleAvailabilityIds((oldArray) => [
        ...oldArray,
        {
          vehicleId: id,
          date: moment()
            .add(tomorrowOrDayAfter === 'tomorrow' ? 1 : 2, 'days')
            .format('YYYY-MM-DD'),
        },
      ]);

      try {
        await changeVehicleAvailabilityRequest();
        fetchFleet();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      // setNotAvailableVehiclesDayAfterTomorrow((prevState) =>
      //   prevState.filter((i) => i !== id)
      // );
    }
  };

  // const handleNotAvailableChange = (id: string) => {
  //   if (tomorrowOrDayAfter === 'tomorrow') {
  //     setNotAvailableVehiclesTomorrow((prevState) =>
  //       prevState.includes(id)
  //         ? prevState.filter((i) => i !== id)
  //         : [...prevState, id]
  //     );
  //     setFullCapacityVehiclesTomorrow((prevState) =>
  //       prevState.filter((i) => i !== id)
  //     );
  //   } else {
  //     setNotAvailableVehiclesDayAfterTomorrow((prevState) =>
  //       prevState.includes(id)
  //         ? prevState.filter((i) => i !== id)
  //         : [...prevState, id]
  //     );
  //     setFullCapacityVehiclesDayAfterTomorrow((prevState) =>
  //       prevState.filter((i) => i !== id)
  //     );
  //   }
  // };

  const vehicleTypePlaceholders = {
    VanLessThan1AndHalft: '/images/VanLessThan1AndHalft.png',
    LgvLessThan3AndHalft: '/images/LgvLessThan3AndHalft.png',
    TwoAxleRigidLessThan7AndHalf5t:
      '/images/TwoAxleRigidLessThan7AndHalf5t.png',
    TwoAxleRigid18t: '/images/TwoAxleRigidLessThan7AndHalf5t.png',
    TwoAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26t.png',
    ThreeAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26t.png',
    MultiAxleArticMoreThan26t: '/images/MultiAxleArticMoreThan26t.png',
    SpecialOrOther: '/images/LgvLessThan3AndHalft.png',
  };

  const vehicleTypeEmptyPlaceholders = {
    VanLessThan1AndHalft: '/images/VanLessThan1AndHalftEmpty.png',
    LgvLessThan3AndHalft: '/images/LgvLessThan3AndHalftEmpty.png',
    TwoAxleRigidLessThan7AndHalf5t:
      '/images/TwoAxleRigidLessThan7AndHalf5tEmpty.png',
    TwoAxleRigid18t: '/images/TwoAxleRigidLessThan7AndHalf5tEmpty.png',
    TwoAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26tEmpty.png',
    ThreeAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26tEmpty.png',
    MultiAxleArticMoreThan26t: '/images/MultiAxleArticMoreThan26tEmpty.png',
    SpecialOrOther: '/images/SpecialOrOtherEmpty.png',
  };

  const vehicleTypePartialPlaceholders = {
    VanLessThan1AndHalft: '/images/VanLessThan1AndHalftPartial.png',
    LgvLessThan3AndHalft: '/images/LgvLessThan3AndHalftPartial.png',
    TwoAxleRigidLessThan7AndHalf5t:
      '/images/TwoAxleRigidLessThan7AndHalf5tPartial.png',
    TwoAxleRigid18t: '/images/TwoAxleRigidLessThan7AndHalf5tPartial.png',
    TwoAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26tPartial.png',
    ThreeAxleRigidLessThan26t: '/images/TwoAxleRigidLessThan26tPartial.png',
    MultiAxleArticMoreThan26t: '/images/MultiAxleArticMoreThan26tPartial.png',
    SpecialOrOther: '/images/SpecialOrOtherPartial.png',
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        position: 'relative',
        backgroundColor: 'var(--colors-gray1)',
        border: darkMode ? '0.0625rem solid var(--colors-gray7)' : 'none',
      }}
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                width: '4.5rem',
              }}
            />
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '16rem',
              }}
            >
              Vehicle Type
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '16rem',
              }}
            >
              Make & Model
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
              }}
            >
              Registration Number
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '12rem',
              }}
            >
              Vehicle Capacity
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              Full availability
            </TableCell>
            {/* <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              Not available
            </TableCell> */}
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
              }}
            >
              Partial availability
            </TableCell>
            <TableCell
              sx={{
                borderBottomColor: 'var(--colors-gray7)',
                backgroundColor: 'var(--colors-gray2)',
                minWidth: '10rem',
              }}
            >
              {/* Partial availability */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((vehicle) => {
            const vehicleAvailability = vehicleAvailabilities.find(
              (availability) =>
                availability.vehicle.vehicleId === vehicle.vehicleId
            );

            const availabilityImageSrc = // eslint-disable-next-line no-nested-ternary
              vehicleAvailability?.isFullyAvailable
                ? (
                    vehicleTypeEmptyPlaceholders as {
                      [key: string]: string;
                    }
                  )[vehicle.vehicleType] || fleetImagePlaceholder
                : vehicleAvailability?.partialAvailabilityPickUps?.length > 0
                ? (
                    vehicleTypePartialPlaceholders as {
                      [key: string]: string;
                    }
                  )[vehicle.vehicleType] || fleetImagePlaceholder
                : (
                    vehicleTypePlaceholders as {
                      [key: string]: string;
                    }
                  )[vehicle.vehicleType] || fleetImagePlaceholder;

            console.log(
              vehicleAvailability?.isFullyAvailable,
              vehicleAvailability?.availabilityDate
            );

            return (
              <TableRow key={vehicle.registrationNumber}>
                <TableCell>
                  <img
                    src={
                      vehicle.images && vehicle.images.length
                        ? `${FLEET_IMAGES_URL}/${vehicle.images[0].imageLocation}/small/${vehicle.images[0].remoteName}`
                        : (
                            vehicleTypePlaceholders as { [key: string]: string }
                          )[vehicle.vehicleType] || fleetImagePlaceholder
                    }
                    alt={vehicle.vehicleType}
                  />
                </TableCell>
                <TableCell>
                  {fleetMapping[vehicle?.vehicleType as Fleet] ||
                    vehicle?.vehicleType}
                </TableCell>
                <TableCell>{vehicle.model}</TableCell>
                <TableCell>{vehicle.registrationNumber}</TableCell>
                <TableCell>{`${vehicle.vehicleCapacity.maxPallets} pallets, ${vehicle.vehicleCapacity.maxKilograms} kg`}</TableCell>
                <TableCell
                  align='center'
                  sx={{ margin: 'auto', textAlign: 'center' }}
                >
                  <Checkbox
                    checked={vehicleAvailability?.isFullyAvailable ?? false}
                    onChange={() => handleFullCapacityChange(vehicle.vehicleId)}
                  />
                </TableCell>
                {/* <TableCell
                align='center'
                sx={{ margin: 'auto', textAlign: 'center' }}
              >
                <Checkbox
                  checked={
                    tomorrowOrDayAfter === 'tomorrow'
                      ? notAvailableVehiclesTomorrow.includes(vehicle.id)
                      : notAvailableVehiclesDayAfterTomorrow.includes(
                          vehicle.id
                        )
                  }
                  onChange={() => handleNotAvailableChange(vehicle.id)}
                />
              </TableCell> */}
                <TableCell>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setIsPartialAvailabilityModalOpen(true);
                      setPartialAvailabilityVehicleId(vehicle.vehicleId);
                    }}
                  >
                    Add partial capacity
                  </Button>
                </TableCell>
                <TableCell>
                  <img
                    style={{ height: '100px', objectFit: 'contain' }}
                    src={availabilityImageSrc}
                    alt={vehicle.vehicleType}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
