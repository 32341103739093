import type * as Stitches from '@stitches/react';
import { zxcvbn } from 'zxcvbn-typescript';
import {
  StyledContainer,
  StyledPasswordStrengthBarContainer,
  StyledPasswordStrengthBar,
  StyledTextContainer,
  StyledText,
} from './PasswordStrengthBar.styles';

interface PasswordStrengthBarProps {
  score: number;
  css?: Stitches.CSS;
}

function GreyWarningIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
      style={{ width: '0.75rem', height: '0.75rem' }}
      aria-hidden
      data-testid='gray-warning-icon'
    >
      <path
        fill='#687076'
        d='M6 8.5c.142 0 .26-.048.356-.144A.483.483 0 0 0 6.5 8a.485.485 0 0 0-.144-.356A.485.485 0 0 0 6 7.5a.483.483 0 0 0-.356.144A.484.484 0 0 0 5.5 8c0 .142.048.26.144.356A.483.483 0 0 0 6 8.5Zm-.5-2h1v-3h-1v3ZM6 11a4.866 4.866 0 0 1-1.95-.394 5.045 5.045 0 0 1-1.587-1.069c-.45-.45-.807-.979-1.069-1.587A4.866 4.866 0 0 1 1 6c0-.692.131-1.342.394-1.95a5.046 5.046 0 0 1 1.069-1.587c.45-.45.979-.807 1.587-1.07A4.871 4.871 0 0 1 6 1c.692 0 1.342.131 1.95.393a5.053 5.053 0 0 1 1.587 1.07c.45.45.807.979 1.069 1.587.263.608.394 1.258.394 1.95s-.131 1.342-.394 1.95a5.045 5.045 0 0 1-1.069 1.587c-.45.45-.979.807-1.587 1.069A4.866 4.866 0 0 1 6 11Zm0-1c1.117 0 2.063-.387 2.838-1.162C9.613 8.063 10 7.117 10 6s-.387-2.063-1.162-2.837C8.063 2.388 7.117 2 6 2s-2.063.388-2.837 1.163C2.388 3.938 2 4.883 2 6c0 1.117.388 2.063 1.163 2.838C3.938 9.613 4.883 10 6 10Z'
      />
    </svg>
  );
}

function RedWarningIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
      style={{ width: '0.75rem', height: '0.75rem' }}
      aria-hidden
      data-testid='red-warning-icon'
    >
      <path
        fill='#E54D2E'
        d='M6 8.5c.142 0 .26-.048.356-.144A.483.483 0 0 0 6.5 8a.485.485 0 0 0-.144-.356A.485.485 0 0 0 6 7.5a.483.483 0 0 0-.356.144A.484.484 0 0 0 5.5 8c0 .142.048.26.144.356A.483.483 0 0 0 6 8.5Zm-.5-2h1v-3h-1v3ZM6 11a4.866 4.866 0 0 1-1.95-.394 5.045 5.045 0 0 1-1.587-1.069c-.45-.45-.807-.979-1.069-1.587A4.866 4.866 0 0 1 1 6c0-.692.131-1.342.394-1.95a5.046 5.046 0 0 1 1.069-1.587c.45-.45.979-.807 1.587-1.07A4.871 4.871 0 0 1 6 1c.692 0 1.342.131 1.95.393a5.053 5.053 0 0 1 1.587 1.07c.45.45.807.979 1.069 1.587.263.608.394 1.258.394 1.95s-.131 1.342-.394 1.95a5.045 5.045 0 0 1-1.069 1.587c-.45.45-.979.807-1.587 1.069A4.866 4.866 0 0 1 6 11Zm0-1c1.117 0 2.063-.387 2.838-1.162C9.613 8.063 10 7.117 10 6s-.387-2.063-1.162-2.837C8.063 2.388 7.117 2 6 2s-2.063.388-2.837 1.163C2.388 3.938 2 4.883 2 6c0 1.117.388 2.063 1.163 2.838C3.938 9.613 4.883 10 6 10Z'
      />
    </svg>
  );
}

function BlueCheckIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
      style={{ width: '0.75rem', height: '0.75rem' }}
      aria-hidden
      data-testid='blue-warning-icon'
    >
      <path
        fill='#006ADC'
        d='m5.3 8.3 3.525-3.525-.7-.7L5.3 6.9 3.875 5.475l-.7.7L5.3 8.3ZM6 11a4.866 4.866 0 0 1-1.95-.394 5.045 5.045 0 0 1-1.587-1.069c-.45-.45-.807-.979-1.069-1.587A4.866 4.866 0 0 1 1 6c0-.692.131-1.342.394-1.95a5.046 5.046 0 0 1 1.069-1.587c.45-.45.979-.807 1.587-1.07A4.871 4.871 0 0 1 6 1c.692 0 1.342.131 1.95.393a5.053 5.053 0 0 1 1.587 1.07c.45.45.807.979 1.069 1.587.263.608.394 1.258.394 1.95s-.131 1.342-.394 1.95a5.045 5.045 0 0 1-1.069 1.587c-.45.45-.979.807-1.587 1.069A4.866 4.866 0 0 1 6 11Zm0-1c1.117 0 2.063-.387 2.838-1.162C9.613 8.063 10 7.117 10 6s-.387-2.063-1.162-2.837C8.063 2.388 7.117 2 6 2s-2.063.388-2.837 1.163C2.388 3.938 2 4.883 2 6c0 1.117.388 2.063 1.163 2.838C3.938 9.613 4.883 10 6 10Z'
      />
    </svg>
  );
}

function GreenCheckIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 12 12'
      style={{ width: '0.75rem', height: '0.75rem' }}
      aria-hidden
      data-testid='green-warning-icon'
    >
      <path
        fill='#299764'
        d='m5.3 8.3 3.525-3.525-.7-.7L5.3 6.9 3.875 5.475l-.7.7L5.3 8.3ZM6 11a4.866 4.866 0 0 1-1.95-.394 5.045 5.045 0 0 1-1.587-1.069c-.45-.45-.807-.979-1.069-1.587A4.866 4.866 0 0 1 1 6c0-.692.131-1.342.394-1.95a5.046 5.046 0 0 1 1.069-1.587c.45-.45.979-.807 1.587-1.07A4.871 4.871 0 0 1 6 1c.692 0 1.342.131 1.95.393a5.053 5.053 0 0 1 1.587 1.07c.45.45.807.979 1.069 1.587.263.608.394 1.258.394 1.95s-.131 1.342-.394 1.95a5.045 5.045 0 0 1-1.069 1.587c-.45.45-.979.807-1.587 1.069A4.866 4.866 0 0 1 6 11Zm0-1c1.117 0 2.063-.387 2.838-1.162C9.613 8.063 10 7.117 10 6s-.387-2.063-1.162-2.837C8.063 2.388 7.117 2 6 2s-2.063.388-2.837 1.163C2.388 3.938 2 4.883 2 6c0 1.117.388 2.063 1.163 2.838C3.938 9.613 4.883 10 6 10Z'
      />
    </svg>
  );
}

export const getPasswordStrength = (value: string): number => {
  // skip validation if field is empty by returning 0
  if (!value) return 0;
  return zxcvbn(value).score;
};

export const getPasswordFeedback = (value: string) => {
  let message = '';
  if (!value) return message;

  const myObject = zxcvbn(value);
  if (myObject.feedback.warning) {
    message += `${myObject.feedback.warning}.`;
  }
  return `${message} ${myObject.feedback.suggestions.join(' ')}`;
};

export const getScoreIcon = (score: number) => {
  switch (score) {
    case 0:
      return <GreyWarningIcon />;
    case 1:
      return <RedWarningIcon />;
    case 2:
      return <BlueCheckIcon />;
    case 3:
    case 4:
      return <GreenCheckIcon />;
    default:
      throw new Error('Invalid score amount.');
  }
};

export const getScoreText = (score: number) => {
  switch (score) {
    case 0:
      return 'Very weak';
    case 1:
      return 'Weak';
    case 2:
      return 'Average';
    case 3:
      return 'Good';
    case 4:
      return 'Strong';
    default:
      throw new Error('Invalid score amount.');
  }
};

export function PasswordStrengthBar({ score, css }: PasswordStrengthBarProps) {
  return (
    <StyledContainer css={css}>
      <StyledPasswordStrengthBarContainer>
        <StyledPasswordStrengthBar score={score} />
      </StyledPasswordStrengthBarContainer>
      <StyledTextContainer>
        {getScoreIcon(score)}
        <StyledText score={score}>{getScoreText(score)}</StyledText>
      </StyledTextContainer>
    </StyledContainer>
  );
}
