function isNumber(char: string) {
  return /^\d$/.test(char);
}

export const formatVolumeUnit = (unitOfMeasureName: string) => {
  const lastChar = unitOfMeasureName.charAt(unitOfMeasureName.length - 1);
  if (isNumber(lastChar)) {
    return (
      <>
        {unitOfMeasureName.substring(0, unitOfMeasureName.length - 1)}
        <sup>{lastChar}</sup>
      </>
    );
  }

  return unitOfMeasureName;
};

export type UnitOfMeasure = 'mm' | 'cm' | 'dm' | 'm';

export const convertUnitToMetres = (amount: number, unit: UnitOfMeasure) => {
  const conversionFunctions = {
    cm: (value: number) => parseFloat((value / 100).toFixed(2)),
    mm: (value: number) => parseFloat((value / 1000).toFixed(2)),
    dm: (value: number) => parseFloat((value / 10).toFixed(2)),
    m: (value: number) => value,
  };

  // Ensures rounding to 2 decimal places:
  return (
    Math.round((conversionFunctions[unit](amount) + Number.EPSILON) * 100) / 100
  );
};

export type UnitOfWeight = 'g' | 'Kg' | 'oz' | 'lb';

export const convertUnitToTonnes = (amount: number, unit: UnitOfWeight) => {
  const conversionFunctions = {
    g: (value: number) => value / 1000000,
    Kg: (value: number) => value / 1000,
    oz: (value: number) => value / 35840,
    lb: (value: number) => value / 2240,
  };

  // Ensures rounding to 2 decimal places:
  return conversionFunctions[unit](amount);
};

export const formatPalletHeight = (
  skuHeight: number,
  skuHeightUnitOfMeasure: UnitOfMeasure,
  amountOfLayers: number,
  palletHeight = 15,
  palletHeightUnitOfMeasure: UnitOfMeasure = 'cm'
) => {
  const skuHeightInMetres = convertUnitToMetres(
    skuHeight,
    skuHeightUnitOfMeasure
  );

  const palletHeightInMetres = convertUnitToMetres(
    palletHeight,
    palletHeightUnitOfMeasure
  );

  return (
    Math.round(
      (skuHeightInMetres * amountOfLayers +
        palletHeightInMetres +
        Number.EPSILON) *
        100
    ) / 100
  );
};

export const formatPalletWeight = (
  skuWeight: number,
  skuWeightUnitOfMeasure: UnitOfWeight,
  amountOfSkusInLayer: number,
  amountOfLayers: number,
  palletWeight = 18,
  palletWeightUnitOfMeasure: UnitOfWeight = 'Kg'
) => {
  const skuWeightInTonnes = convertUnitToTonnes(
    skuWeight,
    skuWeightUnitOfMeasure
  );

  const palletWeightInTonnes = convertUnitToTonnes(
    palletWeight,
    palletWeightUnitOfMeasure
  );

  return (
    skuWeightInTonnes * (amountOfSkusInLayer * amountOfLayers) +
    palletWeightInTonnes
  );
};

export const formatPalletCube = (
  palletLength: number,
  palletWidth: number,
  totalHeight: number
) => Math.round(palletLength * palletWidth * totalHeight * 100) / 100;

export const numberToLocale = (
  value: number,
  locale: string = navigator.language
) => value.toLocaleString(locale);
