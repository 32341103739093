import { configureStore } from '@reduxjs/toolkit';
import inventoryReducer from './features/inventory/inventorySlice';
import supplyChainReducer from './features/supplyChain/supplyChainSlice';
// eslint-disable-next-line import/no-cycle
import requestQueueReducer from './features/auth';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    inventory: inventoryReducer,
    supplyChain: supplyChainReducer,
    requestQueue: requestQueueReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
