import { useState } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@store';
import { clearInventory } from '@features/inventory';
import { clearSupplyChain } from '@features/supplyChain';
import {
  Credentials,
  ICredentialsService,
  TokenCredentials,
  User,
} from './types';
import { FilterService } from './filterService';

export function useCredentialsService(): ICredentialsService {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { clearFilters } = FilterService();

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  );

  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem('refreshToken')
  );

  const [tokenExpiry, setTokenExpiry] = useState(
    localStorage.getItem('tokenExpiry')
  );

  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.getItem('user') as string)
  );

  const [isAuthenticated, setIsAuthenticated] = useState(
    Boolean(localStorage.getItem('isAuthenticated'))
  );

  const setAuthenticated = (authStatus: boolean) => {
    if (authStatus) {
      localStorage.setItem('isAuthenticated', authStatus.toString());
    } else {
      localStorage.removeItem('isAuthenticated');
    }

    setIsAuthenticated(authStatus);
  };

  const getCredentials = (): Credentials => {
    return {
      accessToken,
      refreshToken,
      tokenExpiry,
      isAuthenticated,
    };
  };

  const setCredentials = (
    tokenCredentials: TokenCredentials,
    authStatus: boolean
  ) => {
    localStorage.setItem('accessToken', tokenCredentials.accessToken);
    localStorage.setItem('refreshToken', tokenCredentials.refreshToken);
    localStorage.setItem('tokenExpiry', tokenCredentials.tokenExpiry);
    setAccessToken(tokenCredentials.accessToken);
    setRefreshToken(tokenCredentials.refreshToken);
    setTokenExpiry(tokenCredentials.tokenExpiry);
    setAuthenticated(authStatus);
  };

  const deleteCredentials = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiry');
    setAccessToken(null);
    setRefreshToken(null);
    setTokenExpiry(null);
    setAuthenticated(false);
  };

  const logIn = (
    userCredentials: User,
    auth: TokenCredentials,
    url: string
  ) => {
    flushSync(() => {
      localStorage.setItem('user', JSON.stringify(userCredentials));
      setCredentials(
        {
          accessToken: auth.accessToken,
          refreshToken: auth.refreshToken,
          tokenExpiry: auth.tokenExpiry,
        },
        true
      );
    });
    navigate(url);
  };

  const logOut = () => {
    dispatch(clearInventory());
    dispatch(clearSupplyChain());
    deleteCredentials();
    setUser(null);
    clearFilters();
    navigate('/login');
  };

  const forgotPassword = () => {
    navigate('/forgotten-password');
  };

  return {
    user,
    setUser,
    getCredentials,
    setCredentials,
    deleteCredentials,
    isAuthenticated,
    logIn,
    logOut,
    forgotPassword,
  };
}
