import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller } from 'react-hook-form';
import {
  useAuthHttp,
  useAvailableSkus,
  useAvailableWarehouses,
  useReactHookFormWithFieldArray,
} from '@hooks';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import { Text } from '@components/Text';
import { Customer } from '@views/CustomerMaster/CustomerMaster';
import Checkbox from '@mui/material/Checkbox';
import {
  CUSTOMER_GET,
  INVENTORY_IMAGES_URL,
  SUGGESTED_ID_URL,
} from '@constants';
import { useReadLocalStorage } from 'usehooks-ts';
import { validationSchema, defaultValues } from './form';
import { CreateDespatchNotesFormValues } from './types';
import {
  AvailableSKU,
  AvailableSKUPalletising,
  AvailableWarehouse,
} from '../CreateInboundShipmentNoteForm/types';
import { numberToLocale } from '../AddProductForm/utils';
import { fixedPalletisingOptions } from './fixedPalletisingOptions';
import { getTotalPallets } from '../../../utils';

interface CreateDespatchNoteFormProps {
  onClose: () => void;
  onSubmit: (values: CreateDespatchNotesFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
  selectedSKU?: any;
}

export function CreateDespatchNoteForm({
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
  selectedSKU,
}: CreateDespatchNoteFormProps) {
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [openAvailableCustomerOptions, setOpenAvailableCustomerOptions] =
    useState(false);

  const [customerAddress, setCustomerAddress] = useState<string | null>(null);
  const [warehouseAddress, setWarehouseAddress] = useState<string | null>(null);

  const {
    availableSkuOptions,
    loadingAvailableSkus,
    openSkuIndex,
    setOpenSkuIndex,
    setOpenAvailableSkuOptions,
    availableSkusQuery,
    setAvailableSkusQuery,
  } = useAvailableSkus();

  const {
    availableWarehouseOptions,
    loadingAvailableWarehouses,
    openAvailableWarehouseOptions,
    setOpenAvailableWarehouseOptions,
    singleContractedWarehouse,
  } = useAvailableWarehouses();

  const {
    handleSubmit,
    control,
    setValue,
    isSubmitting,
    watch,
    getValues,
    fields: skusFields,
    append: appendSku,
    remove: removeSku,
  } = useReactHookFormWithFieldArray<CreateDespatchNotesFormValues>({
    defaultValues: {
      ...defaultValues,
      skus: selectedSKU
        ? [
            {
              ...selectedSKU,
              palletisingOptions: selectedSKU.palletising,
              skuId: selectedSKU.id,
            },
          ]
        : [],
    },
    validationSchema,
    fieldArrayName: 'skus',
    mode: 'onSubmit',
  });

  // This keep a list of the skus that have already been selected
  // This will enable to autocomplete to filter out existing selected
  // skus from the options dropdown by disabling them
  const selectedSkus = watch('skus').filter(
    (sku) => sku.stockByWarehouse[0]?.availableStock
  );

  const agreeToAllCheckboxRef = useRef<HTMLInputElement>(null);

  const hasMultipleAgreeToAllInputs =
    getValues('skus').filter(
      (skusField) =>
        skusField.palletising.palletisingType &&
        ['Mixed GB Pallet', 'Mixed EU Pallet', 'Other'].includes(
          skusField.palletising.palletisingType
        )
    ).length > 1;

  const authHttp = useAuthHttp();

  useEffect(() => {
    async function fetchSuggestedID() {
      try {
        const { data } = await authHttp.get(SUGGESTED_ID_URL);

        if (data.suggestedId) setValue('despatchNoteNumber', data.suggestedId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
    async function fetchCustomers() {
      try {
        const { data } = await authHttp.post(CUSTOMER_GET, {
          pagination: {
            itemsPerPage: isDesktop ? 100 : 25,
          },
        });

        if (data) setCustomers(data.customers);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    fetchCustomers();
    fetchSuggestedID();
  }, []);

  useEffect(() => {
    setValue(
      'warehouseId',
      singleContractedWarehouse?.warehouse.warehouseId ?? ''
    );
    setWarehouseAddress(singleContractedWarehouse?.warehouse.address ?? '');
  }, [singleContractedWarehouse]);

  const getGrandTotals = () => {
    const skusByPalletType = getValues('skus').reduce<{ [key: string]: any }>(
      (groups, sku) => {
        let palletType;
        if (sku.palletising.palletisingId) {
          const palletisingOption = sku.palletisingOptions.find(
            (option) => option.id === sku.palletising.palletisingId
          );
          palletType = palletisingOption?.type;
        } else if (
          ['Mixed GB Pallet', 'Mixed EU Pallet'].includes(
            sku.palletising.palletisingType ?? ''
          )
        ) {
          palletType =
            sku.palletising.palletisingType?.replace('Mixed ', '') ?? '';
        } else if (
          ['Unpalletised', 'Other'].includes(
            sku.palletising.palletisingType ?? ''
          )
        ) {
          palletType = sku.palletising.palletisingType ?? '';
        }

        if (palletType) {
          if (!groups[palletType]) {
            // eslint-disable-next-line no-param-reassign
            groups[palletType] = [];
          }
          groups[palletType].push(sku);
        }
        return groups;
      },
      {}
    );

    return Object.entries(skusByPalletType).map(([palletType, skus]) => {
      let grandTotal;
      if (['Unpalletised', 'Other'].includes(palletType)) {
        grandTotal = (skus as any).reduce(
          (total: number, sku: any) => total + (sku?.skuQuantity ?? 0),
          0
        );
      } else {
        grandTotal = (skus as any).reduce((total: number, sku: any) => {
          if (
            ['Mixed GB Pallet', 'Mixed EU Pallet'].includes(
              sku.palletising.palletisingType
            )
          ) {
            return total;
          }

          const palletisingOption = sku.palletisingOptions.find(
            (option: any) => option.id === sku.palletising.palletisingId
          );
          const amountOfSkusInLayer =
            palletisingOption?.amountOfSkusInLayer ?? 0;
          const amountOfLayersInPallet =
            palletisingOption?.amountOfLayersInPallet ?? 0;
          return (
            total +
            getTotalPallets(
              sku?.skuQuantity ?? 0,
              amountOfSkusInLayer,
              amountOfLayersInPallet
            )
          );
        }, 0);
      }

      const isMixedPallet =
        ['GB Pallet', 'EU Pallet'].includes(palletType) &&
        (skus as any).some((sku: any) =>
          ['Mixed GB Pallet', 'Mixed EU Pallet'].includes(
            sku.palletising.palletisingType
          )
        );

      return (
        <Text
          key={palletType}
          size='md'
          weight='medium'
          css={{ textAlign: 'right' }}
        >
          {['Unpalletised', 'Other'].includes(palletType)
            ? `Total ${palletType} SKUs`
            : `Total ${palletType}s`}
          : {grandTotal}
          {isMixedPallet ? '+' : ''}
        </Text>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={24} p={6}>
        <Stack spacing={24}>
          {/* Purchase order number */}
          <Controller
            name='purchaseOrderNumber'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='Purchase order number (required)'
                placeholder='Purchase order number (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
                inputProps={{
                  max: 20,
                }}
              />
            )}
          />
          {/* Despatch note number */}
          <Controller
            name='despatchNoteNumber'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                variant='outlined'
                label='Despatch note number (required)'
                placeholder='Despatch note number (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
                inputProps={{
                  max: 20,
                }}
              />
            )}
          />
          {/* Customer name */}
          {/* <Controller
            name='customerName'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={error?.message}
                variant='outlined'
                label='Customer name (required)'
                placeholder='Customer name (required)'
                multiline={!isDesktop}
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
              />
            )}
          /> */}
          <Controller
            name='customerId'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => {
              return (
                <Autocomplete
                  open={openAvailableCustomerOptions}
                  onOpen={() => setOpenAvailableCustomerOptions(true)}
                  onClose={() => setOpenAvailableCustomerOptions(false)}
                  onChange={(
                    event: SyntheticEvent<Element, Event>,
                    value: Customer | null
                  ) => {
                    if (value) {
                      field.onChange(value.id);
                      setValue('customerName', value.name);
                      setCustomerAddress(value.address);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option.name}
                  options={customers}
                  loadingText='Loading customers...'
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        ref={field.ref}
                        onBlur={field.onBlur}
                        name={field.name}
                        label='Customer name (required)'
                        placeholder='Customer name (required)'
                        error={Boolean(error && isTouched)}
                        helperText={
                          Boolean(error && isTouched) && error?.message
                        }
                        multiline={!isDesktop}
                        {...(!isDesktop && {
                          InputLabelProps: {
                            shrink: true,
                          },
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingAvailableWarehouses ? (
                                <CircularProgress color='inherit' size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
          <div className='text-sm !mt-1'>
            Customer Address: {customerAddress}
          </div>
          {/* From warehouse */}
          <Controller
            name='warehouseId'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                open={openAvailableWarehouseOptions}
                onOpen={() => setOpenAvailableWarehouseOptions(true)}
                onClose={() => setOpenAvailableWarehouseOptions(false)}
                onChange={(
                  event: SyntheticEvent<Element, Event>,
                  value: AvailableWarehouse | null
                ) => {
                  if (value) {
                    field.onChange(value.warehouse.warehouseId);
                    setWarehouseAddress(value.warehouse.address);
                  }
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.warehouse.name}
                options={availableWarehouseOptions}
                loading={loadingAvailableWarehouses}
                loadingText='Loading available warehouses...'
                value={singleContractedWarehouse ?? null}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      name={field.name}
                      label='From warehouse (required)'
                      placeholder='From warehouse (required)'
                      error={Boolean(error)}
                      helperText={error?.message}
                      multiline={!isDesktop}
                      {...(!isDesktop && {
                        InputLabelProps: {
                          shrink: true,
                        },
                      })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingAvailableWarehouses ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  );
                }}
              />
            )}
          />
          <div className='text-sm !mt-1'>
            Warehouse Address: {warehouseAddress}
          </div>
          {/* Required time of arrival */}
          <Controller
            name='requiredTimeOfArrival'
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <DateTimePicker
                  ampm={false}
                  format='DD/MM/YYYY H:mm'
                  minDate={dayjs(new Date())}
                  inputRef={field.ref}
                  onChange={(value) => {
                    setValue('requiredTimeOfArrival', dayjs(value).format(), {
                      shouldValidate: true,
                    });
                  }}
                  label='Required time of arrival (required)'
                  slotProps={{
                    textField: {
                      onBlur: field.onBlur,
                      name: field.name,
                      multiline: !isDesktop,
                      ...(!isDesktop && {
                        InputLabelProps: {
                          shrink: true,
                        },
                      }),
                      error: Boolean(error),
                      helperText: error?.message,
                    },
                  }}
                />
              );
            }}
          />
          {/* Skus */}
          <Typography fontWeight='bold'>SKU / items</Typography>
          {skusFields.length > 0 && (
            <Stack spacing={isDesktop ? 24 : 32}>
              {skusFields.map((item, index) => {
                const availableStock = watch(
                  `skus.${index}.stockByWarehouse.0.availableStock`
                );
                return (
                  <Stack
                    key={item.id}
                    direction={isDesktop ? 'row' : 'column'}
                    spacing={16}
                  >
                    {/* SKU ID And Name */}
                    <Controller
                      name={`skus.${index}.skuId`}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            open={
                              availableSkusQuery.length >= 3 &&
                              openSkuIndex === index
                            }
                            onOpen={() => {
                              setOpenSkuIndex(index);
                              setOpenAvailableSkuOptions(true);
                            }}
                            onClose={() => {
                              setOpenSkuIndex(null);
                              setOpenAvailableSkuOptions(false);
                            }}
                            filterOptions={(x) => x}
                            defaultValue={
                              selectedSKU
                                ? {
                                    ...selectedSKU,
                                    palletisingOptions: selectedSKU.palletising,
                                  }
                                : null
                            }
                            onChange={(
                              event: SyntheticEvent<Element, Event>,
                              value: AvailableSKU | null
                            ) => {
                              if (value) {
                                setValue(`skus.${index}`, {
                                  skuId: value.id,
                                  productId: value.productId,
                                  skuQuantity: undefined,
                                  // availableStock: value.availableStock,
                                  palletisingOptions: value.palletising,
                                  palletising: {
                                    palletisingId: null,
                                    palletisingType: null,
                                    description: null,
                                    hasAgreedToLspShipmentOption: false,
                                  },
                                  stockByWarehouse: [
                                    {
                                      allocatedStock:
                                        value.stockByWarehouse[0]
                                          .allocatedStock,
                                      allocatedStockValue:
                                        value.stockByWarehouse[0]
                                          .allocatedStockValue,
                                      availableStock:
                                        value.stockByWarehouse[0]
                                          .availableStock,
                                      availableStockValue:
                                        value.stockByWarehouse[0]
                                          .availableStockValue,
                                      blockedStock:
                                        value.stockByWarehouse[0].blockedStock,
                                      blockedStockValue:
                                        value.stockByWarehouse[0]
                                          .blockedStockValue,
                                      id: value.stockByWarehouse[0].id,
                                      inTransitStock:
                                        value.stockByWarehouse[0]
                                          .inTransitStock,
                                      inTransitStockValue:
                                        value.stockByWarehouse[0]
                                          .inTransitStockValue,
                                      skuId: value.stockByWarehouse[0].skuId,
                                      stockDiscrepancy:
                                        value.stockByWarehouse[0]
                                          .stockDiscrepancy,
                                      stockDiscrepancyValue:
                                        value.stockByWarehouse[0]
                                          .stockDiscrepancyValue,
                                      totalStock:
                                        value.stockByWarehouse[0].totalStock,
                                      totalStockValue:
                                        value.stockByWarehouse[0]
                                          .totalStockValue,
                                      userBusinessId:
                                        value.stockByWarehouse[0]
                                          .userBusinessId,
                                      userId: value.stockByWarehouse[0].userId,
                                      warehouseBusinessId:
                                        value.stockByWarehouse[0]
                                          .warehouseBusinessId,
                                      warehouseId:
                                        value.stockByWarehouse[0].warehouseId,
                                      warehouseName:
                                        value.stockByWarehouse[0].warehouseName,
                                    },
                                  ],
                                });
                              }
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return (
                                option.skuId === value.skuId &&
                                option.productId === value.productId &&
                                option.productName === value.productName
                              );
                            }}
                            onInputChange={(
                              event: SyntheticEvent<Element, Event>,
                              value: string
                            ) => {
                              if (event?.type === 'change') {
                                setAvailableSkusQuery(value);
                              }
                            }}
                            options={availableSkuOptions}
                            getOptionDisabled={(option) =>
                              selectedSkus.find(
                                (selectedSku) => selectedSku.skuId === option.id
                              ) !== undefined
                            }
                            getOptionLabel={(option) =>
                              `${option.productName} (${option.unitOfMeasure}) - SKU # ${option.skuId}`
                            }
                            noOptionsText='No matching SKUs found.'
                            loading={
                              loadingAvailableSkus && openSkuIndex === index
                            }
                            loadingText='Loading available SKUs...'
                            sx={{ width: '100%' }}
                            renderInput={(params) => {
                              return (
                                <>
                                  <TextField
                                    {...params}
                                    label='SKU ID or SKU name (required)'
                                    {...field}
                                    value={field.value || ''}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder='Search for an existing SKU ID or SKU name...'
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {loadingAvailableSkus &&
                                          openSkuIndex === index ? (
                                            <CircularProgress
                                              color='inherit'
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                    multiline={!isDesktop}
                                    {...(!isDesktop && {
                                      InputLabelProps: {
                                        shrink: true,
                                      },
                                    })}
                                  />
                                  {availableSkusQuery.length < 3 && (
                                    <FormHelperText variant='filled'>
                                      Please enter at least 3 characters.
                                    </FormHelperText>
                                  )}
                                </>
                              );
                            }}
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '3rem 1fr',
                                  gap: 16,
                                  borderBottom: '1px solid #CCC',
                                  padding: '0.5rem 1rem',
                                }}
                              >
                                <img
                                  src={
                                    option.images.length > 0
                                      ? `${INVENTORY_IMAGES_URL}/${option.images[0].imageLocation}/small/${option.images[0].remoteName}`
                                      : `/images/product-placeholder-${
                                          darkMode ? 'dark' : 'light'
                                        }.svg`
                                  }
                                  alt={`${option.productName} (${option.unitOfMeasure})`}
                                  style={{
                                    width: '3rem',
                                    minWidth: '3rem',
                                    height: 'auto',
                                    borderRadius: 'var(--radii-radii8)',
                                    margin: '0 auto',
                                  }}
                                />
                                <Stack spacing={6}>
                                  <Typography variant='body2'>
                                    <strong>Product:</strong>{' '}
                                    {option.productName} ({option.unitOfMeasure}
                                    )
                                  </Typography>
                                  <Typography variant='body2'>
                                    <strong>SKU #:</strong> {option.skuId}
                                  </Typography>
                                  {option.description && (
                                    <Typography variant='body2'>
                                      {option.description}
                                    </Typography>
                                  )}
                                </Stack>
                              </li>
                            )}
                          />
                        );
                      }}
                    />
                    {/* Quantity */}
                    <Controller
                      name={`skus.${index}.skuQuantity`}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const hasExceededAvailableStock =
                          error?.message ===
                          'Maximum available stock exceeded.';
                        return (
                          <Stack spacing={8}>
                            <TextField
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                min: 1,
                                ...(availableStock && {
                                  max: availableStock,
                                }),
                                sx: {
                                  color: hasExceededAvailableStock
                                    ? 'var(--colors-tomato11)'
                                    : 'inherit',
                                },
                              }}
                              name={field.name}
                              onBlur={field.onBlur}
                              inputRef={field.ref}
                              value={field.value ?? ''}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                setValue(
                                  `skus.${index}.skuQuantity`,
                                  parseInt(event.target.value, 10),
                                  {
                                    shouldValidate: true,
                                  }
                                );
                              }}
                              sx={{ minWidth: '16rem' }}
                              error={Boolean(
                                error || hasExceededAvailableStock
                              )}
                              helperText={error && error?.message}
                              variant='outlined'
                              label='Quantity of SKUs (required)'
                              placeholder='Quantity of SKUs (required)'
                              multiline={!isDesktop}
                              {...(!isDesktop && {
                                InputLabelProps: {
                                  shrink: true,
                                },
                              })}
                            />
                            {availableStock && (
                              <Text
                                size='xs'
                                weight='medium'
                                css={{ pl: '0.75rem' }}
                              >
                                Max available: {numberToLocale(availableStock)}
                              </Text>
                            )}
                          </Stack>
                        );
                      }}
                    />
                    <Stack spacing={16} width='100%'>
                      {/* Pallet type */}
                      <Controller
                        name={`skus.${index}.palletising.palletisingId`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <Autocomplete
                              filterOptions={(x) => x}
                              onChange={(
                                event: SyntheticEvent<Element, Event>,
                                value: AvailableSKUPalletising | null
                              ) => {
                                if (value) {
                                  if (
                                    [
                                      'Mixed GB Pallet',
                                      'Mixed EU Pallet',
                                      'Unpalletised',
                                      'Other',
                                    ].includes(value.id)
                                  ) {
                                    // Unpalletised
                                    if (value.id === 'Unpalletised') {
                                      setValue(
                                        `skus.${index}.palletising`,
                                        {
                                          palletisingId: null,
                                          palletisingType: 'Unpalletised',
                                          description: null,
                                          hasAgreedToLspShipmentOption: false,
                                        },
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }
                                    // Other
                                    if (value.id === 'Other') {
                                      setValue(
                                        `skus.${index}.palletising`,
                                        {
                                          palletisingId: null,
                                          palletisingType: 'Other',
                                          description: null,
                                          hasAgreedToLspShipmentOption: false,
                                        },
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }
                                    // Mixed pallet
                                    if (
                                      value.id === 'Mixed GB Pallet' ||
                                      value.id === 'Mixed EU Pallet'
                                    ) {
                                      setValue(
                                        `skus.${index}.palletising`,
                                        {
                                          palletisingId: null,
                                          palletisingType: value.id,
                                          description: null,
                                          hasAgreedToLspShipmentOption: false,
                                        },
                                        {
                                          shouldValidate: true,
                                        }
                                      );
                                    }
                                  } else {
                                    // Predetermined palletising option from shipper
                                    setValue(
                                      `skus.${index}.palletising`,
                                      {
                                        palletisingId: value.id,
                                        palletisingType: null,
                                        description: null,
                                        hasAgreedToLspShipmentOption: false,
                                      },
                                      {
                                        shouldValidate: true,
                                      }
                                    );
                                  }
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              options={
                                getValues(`skus.${index}.palletisingOptions`)
                                  ? [
                                      ...getValues(
                                        `skus.${index}.palletisingOptions`
                                      ),
                                      ...fixedPalletisingOptions,
                                    ]
                                  : []
                              }
                              getOptionDisabled={(option) =>
                                selectedSkus.find(
                                  (selectedSku) =>
                                    selectedSku.skuId === option.id
                                ) !== undefined
                              }
                              getOptionLabel={(option) => option.type}
                              noOptionsText='No matching SKUs found.'
                              loading={
                                loadingAvailableSkus && openSkuIndex === index
                              }
                              loadingText='Loading pallets...'
                              sx={{ width: '100%' }}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    label='Pallet type (required)'
                                    {...field}
                                    value={field.value}
                                    error={Boolean(error)}
                                    helperText={error?.message}
                                    placeholder='Pallet type (required)'
                                    multiline={!isDesktop}
                                    {...(!isDesktop && {
                                      InputLabelProps: {
                                        shrink: true,
                                      },
                                    })}
                                  />
                                );
                              }}
                            />
                          );
                        }}
                      />
                      {/* Description - for 'Other' pallet type */}
                      {getValues(
                        `skus.${index}.palletising.palletisingType`
                      ) === 'Other' && (
                        <Controller
                          name={`skus.${index}.palletising.description`}
                          control={control}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <TextField
                                size='small'
                                name={field.name}
                                onBlur={field.onBlur}
                                inputRef={field.ref}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setValue(
                                    `skus.${index}.palletising.description`,
                                    event.target.value,
                                    { shouldValidate: true }
                                  );
                                }}
                                multiline
                                minRows={2}
                                error={Boolean(error)}
                                helperText={error?.message}
                                label='Pallet description (required)'
                                fullWidth
                                placeholder='Pallet description (required)'
                                {...(!isDesktop && {
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                })}
                                variant='outlined'
                                sx={{
                                  '.Mui-disabled': { cursor: 'not-allowed' },
                                }}
                              />
                            );
                          }}
                        />
                      )}
                      {/* Confirm LSP handling of palletising */}
                      {['Mixed GB Pallet', 'Mixed EU Pallet', 'Other'].includes(
                        getValues(
                          `skus.${index}.palletising.palletisingType`
                        ) as string
                      ) && (
                        <Controller
                          name={`skus.${index}.palletising.hasAgreedToLspShipmentOption`}
                          control={control}
                          render={({ field, fieldState: { error } }) => {
                            return (
                              <Stack
                                component='label'
                                direction='row'
                                alignItems='flex-start'
                                gap={0}
                                sx={{
                                  cursor: 'pointer',
                                  mt: '-0rem !important',
                                }}
                              >
                                <Checkbox
                                  checked={field.value}
                                  name={field.name}
                                  onBlur={field.onBlur}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => {
                                    setValue(
                                      `skus.${index}.palletising.hasAgreedToLspShipmentOption`,
                                      checked,
                                      { shouldValidate: true }
                                    );

                                    const formConsentStatusList: boolean[] = [];

                                    getValues(`skus`).forEach((sku) => {
                                      const { palletising } = sku;

                                      formConsentStatusList.push(
                                        palletising.hasAgreedToLspShipmentOption
                                      );
                                    });

                                    if (
                                      formConsentStatusList.every(
                                        (status) => status
                                      )
                                    ) {
                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      agreeToAllCheckboxRef.current!.checked =
                                        true;
                                    }

                                    if (formConsentStatusList.includes(false)) {
                                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                      agreeToAllCheckboxRef.current!.checked =
                                        false;
                                    }
                                  }}
                                  inputRef={field.ref}
                                  {...{
                                    'aria-label':
                                      'By selecting this option you confirm that you are instructing your Logistics Service Provider to palletise your SKUs as they deem optimal.',
                                  }}
                                />
                                <Stack gap={8}>
                                  <Text size='sm' css={{ mt: '0.5625rem' }}>
                                    By selecting this option you confirm that
                                    you are instructing your Logistics Service
                                    Provider to palletise your SKUs as they deem
                                    optimal (required).
                                  </Text>
                                  {error && (
                                    <Text
                                      size='xs'
                                      css={{ color: 'var(--colors-tomato9)' }}
                                    >
                                      {error?.message}
                                    </Text>
                                  )}
                                </Stack>
                              </Stack>
                            );
                          }}
                        />
                      )}
                    </Stack>
                    {/* Delete item */}
                    {isDesktop ? (
                      <IconButton
                        sx={{ width: '3.5rem', height: '3.5rem' }}
                        onClick={() => removeSku(index)}
                        aria-label='Delete SKU'
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <Button
                        variant='contained'
                        size='large'
                        startIcon={<DeleteIcon />}
                        sx={{ textTransform: 'none' }}
                        onClick={() => removeSku(index)}
                      >
                        Delete SKU
                      </Button>
                    )}
                  </Stack>
                );
              })}
              <Stack
                component='label'
                direction='row'
                alignItems='center'
                gap={0}
                sx={{
                  cursor: 'pointer',
                  maxWidth: '9rem',
                  overflow: 'hidden',
                  gap: '1rem',
                  height: hasMultipleAgreeToAllInputs ? '2.625rem' : 0,
                  marginTop: hasMultipleAgreeToAllInputs
                    ? '1rem !important'
                    : '0 !important',
                }}
              >
                <input
                  type='checkbox'
                  ref={agreeToAllCheckboxRef}
                  aria-label='Agree to all.'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    skusFields.forEach((skusField, index) => {
                      const fieldPalletisingValue = getValues(
                        `skus.${index}.palletising`
                      );
                      if (fieldPalletisingValue.palletisingType) {
                        if (
                          [
                            'Mixed GB Pallet',
                            'Mixed EU Pallet',
                            'Other',
                          ].includes(fieldPalletisingValue.palletisingType)
                        ) {
                          setValue(
                            `skus.${index}.palletising.hasAgreedToLspShipmentOption`,
                            e.target.checked,
                            { shouldValidate: true }
                          );
                        }
                      }
                    });
                  }}
                  style={{
                    accentColor: 'hsl(0, 0%, 56.1%)',
                    transformOrigin: 'left center',
                    transform: 'scale(1.4)',
                  }}
                />
                <Text size='sm'>Agree to all.</Text>
              </Stack>
            </Stack>
          )}
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            size='large'
            onClick={() =>
              appendSku(
                {
                  skuId: '',
                  productId: '',
                  skuQuantity: undefined,
                  availableStock: undefined,
                  palletisingOptions: [],
                  palletising: {
                    palletisingId: null,
                    palletisingType: null,
                    description: null,
                    hasAgreedToLspShipmentOption: false,
                  },
                  stockByWarehouse: [
                    {
                      allocatedStock: 0,
                      allocatedStockValue: 0,
                      availableStock: 0,
                      availableStockValue: 0,
                      blockedStock: 0,
                      blockedStockValue: 0,
                      id: '',
                      inTransitStock: 0,
                      inTransitStockValue: 0,
                      skuId: '',
                      stockDiscrepancy: 0,
                      stockDiscrepancyValue: 0,
                      totalStock: 0,
                      totalStockValue: 0,
                      userBusinessId: '',
                      userId: '',
                      warehouseBusinessId: '',
                      warehouseId: '',
                      warehouseName: '',
                    },
                  ],
                },
                {
                  shouldFocus: true,
                  focusIndex: 0,
                  focusName: `skus.${skusFields.length - 1}`,
                }
              )
            }
            sx={{ textTransform: 'none', marginBottom: '1rem !important' }}
          >
            Add SKU
          </Button>
          <>{getGrandTotals()}</>
          {/* Comment */}
          <Controller
            name='comment'
            control={control}
            render={({ field, fieldState: { error, isTouched } }) => (
              <TextField
                {...field}
                multiline
                minRows={4}
                error={Boolean(error && isTouched)}
                helperText={Boolean(error && isTouched) && error?.message}
                label='Comment (optional)'
                placeholder='Comment'
                {...(!isDesktop && {
                  InputLabelProps: {
                    shrink: true,
                  },
                })}
                variant='outlined'
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
