import { OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  supplyChainProcessId: Yup.string().required(),
  despatchNoteId: Yup.string().required(),
  comment: Yup.string().max(500, 'Max 500 characters.'),
  pickingDateConfirmation: Yup.string().required(
    'A picking date confirmation is required.'
  ),
  skus: Yup.array().of(
    Yup.object({
      productId: Yup.string().required('A product ID is required.'),
      skuId: Yup.string().required('A SKU ID is required.'),
      packagingComment: Yup.string(),
      pickedQuantity: Yup.number()
        .typeError('Picked quantity must be a number.')
        .max(
          Yup.ref('skuQuantity'),
          "Picked quantity can't be greater than ordered quantity."
        ),
      discrepancyReason: Yup.string()
        .nullable()
        .when(['skuQuantity', 'pickedQuantity'], {
          is: (skuQuantity: number, pickedQuantity: number) =>
            skuQuantity !== pickedQuantity,
          then: () =>
            Yup.string().required('A discrepancy reason is required.'),
        }),
    })
  ),
});

export const makeDefaultValues = (selectedOutboundProcess: OutboundProcess) => {
  if (!selectedOutboundProcess) {
    return {
      supplyChainProcessId: '',
      despatchNoteId: '',
      comment: '',
      skus: [],
      pickingDateConfirmation: '',
    };
  }
  const { despatchNote } = selectedOutboundProcess;
  return {
    supplyChainProcessId: selectedOutboundProcess.id,
    despatchNoteId: despatchNote.id,
    comment: '',
    skus: despatchNote.skus.map((sku) => ({
      productId: sku.productId,
      productName: sku.productName,
      skuId: sku.skuId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      skuIdentifier: sku.skuIdentifier!,
      skuName: sku.name,
      skuQuantity: sku.skuQuantity,
      pickedQuantity: sku.skuQuantity,
      discrepancyReason: null,
      packagingDetails: sku.packagingDetails,
    })),
    pickingDateConfirmation: dayjs(
      selectedOutboundProcess.despatchNote.requiredPickingDate
    ),
  };
};
