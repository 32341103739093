import { Heading } from '@components/Heading';
import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { View } from '@views/View';
import { WarehouseMatchList } from '@views/WarehouseDetails/WarehouseDetails';
import { useMediaQuery } from 'usehooks-ts';
import { useAuth } from '../../context';
import { useAuthHttp } from '../../hooks';
import { CONTRACTED_MATCH_LIST } from '../../constants';
import { businessProfileAtom } from '../../store/jotai';
import { UserRoleService } from '../../services';

type LegalDocument = {
  documentId: string;
  createdAt: string;
  updatedAt: string;
  slug: string;
  documentName: string;
  name: string;
  completedAt: string;
  status: string;
  url: string;
};

export function LegalDocumentsHub() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();
  const authHttp = useAuthHttp();
  const [serviceAgreements, setServiceAgreements] = useState<LegalDocument[]>(
    []
  );
  const businessProfile = useAtomValue(businessProfileAtom);

  useEffect(() => {
    const fetchWarehouseServiceAgreements = async () => {
      try {
        const { data: contractedMatches } =
          await authHttp.post<WarehouseMatchList>(
            CONTRACTED_MATCH_LIST,
            {},
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );

        if (contractedMatches) {
          setServiceAgreements(
            contractedMatches.matches.map(
              (contractedWarehouse) => contractedWarehouse.match.legalDocument
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchWarehouseServiceAgreements();
  }, []);

  return (
    <View title='Legal Documents Hub'>
      {user?.roles.includes('BusinessUserManager') && (
        <Stack spacing={24}>
          <Heading as='h3' size='xs'>
            Legal Documents Hub
          </Heading>
          <Stack spacing={4} width='max-content'>
            <Stack gap={4} flexDirection='row' alignItems='center'>
              <a
                href='https://www.flox.is/platform-terms'
                target='_blank'
                rel='noreferrer'
                className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
              >
                Platform Terms and Conditions
              </a>
              <Typography>- accepted by {user.name}</Typography>
            </Stack>
            {serviceAgreements.length >= 1 && (
              <Stack gap={4} flexDirection='row' alignItems='center'>
                <a
                  href='https://www.flox.is/standard-conditions-of-warehousing-services'
                  className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Standard Conditions of Warehousing Services
                </a>
                {isWarehouseManager(user) ? (
                  <Typography>
                    - accepted{' '}
                    {(() => {
                      const date = new Date(
                        businessProfile?.warehouseTandCAgreement?.agreementDate
                      );
                      return `${date.getDate().toString().padStart(2, '0')}/${(
                        date.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}/${date.getFullYear()}`;
                    })()}{' '}
                    by {businessProfile?.warehouseTandCAgreement?.agreedByName}
                  </Typography>
                ) : (
                  <Typography>
                    - accepted by {serviceAgreements[0].name}
                  </Typography>
                )}
              </Stack>
            )}
          </Stack>
          <Typography fontSize='1.25rem'>
            Warehousing Service Price Schedules
          </Typography>
          <div className={`mt-8 flow-root ${isDesktop ? 'w-1/2' : ''}`}>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead>
                    <tr className='divide-x divide-gray-200'>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        #
                      </th>
                      <th
                        scope='col'
                        className='px-4 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Service Provider
                      </th>
                      <th
                        scope='col'
                        className='px-4 py-3.5 text-left text-sm font-semibold text-gray-900'
                      >
                        Signed Date
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0'
                      >
                        Signed By
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {serviceAgreements.map((agreement, i) => (
                      <tr
                        key={agreement.documentName}
                        className='divide-x divide-gray-200'
                      >
                        <td className='whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0'>
                          {i + 1}
                        </td>
                        <td className='whitespace-nowrap p-4 text-sm text-gray-900'>
                          <a
                            href={agreement.url}
                            target='_blank'
                            rel='noreferrer'
                            className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                          >
                            {agreement.documentName}
                          </a>
                        </td>
                        <td className='whitespace-nowrap p-4 text-sm text-gray-900'>
                          {(() => {
                            const date = new Date(agreement.completedAt);
                            return `${date
                              .getDate()
                              .toString()
                              .padStart(2, '0')}/${(date.getMonth() + 1)
                              .toString()
                              .padStart(2, '0')}/${date.getFullYear()}`;
                          })()}
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-900 sm:pr-0'>
                          {agreement.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Stack>
      )}
    </View>
  );
}
