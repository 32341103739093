import { ExpandLess } from '@mui/icons-material';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface InfiniteScrollEndMessageProps {
  isVisible: boolean;
  endMessageText: string;
  scrollMessage?: string;
  direction?: 'row' | 'column';
  handleScrollToTop: () => void;
  sx?: SxProps;
}

export function InfiniteScrollEndMessage({
  isVisible,
  endMessageText,
  scrollMessage = 'Back to top',
  direction = 'row',
  handleScrollToTop,
  sx,
  ...props
}: InfiniteScrollEndMessageProps) {
  return (
    <Box
      sx={{
        textAlign: 'center',
        display: isVisible ? 'flex' : 'none',
        flexDirection: direction,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        ...sx,
      }}
      {...props}
    >
      <p
        style={{
          fontSize: 'var(--fontSizes-textSm)',
          lineHeight: 'var(--lineHeights-textSm)',
          color: 'var(--colors-gray12)',
        }}
      >
        {endMessageText}
      </p>
      <Button
        variant='text'
        size='small'
        endIcon={<ExpandLess />}
        onClick={handleScrollToTop}
        sx={{
          fontSize: 'var(--fontSizes-textSm)',
          lineHeight: 'var(--lineHeights-textSm)',
          textTransform: 'none',
          color: 'var(--colors-gray12)',
        }}
      >
        {scrollMessage}
      </Button>
    </Box>
  );
}
