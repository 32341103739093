import { styled, keyframes } from '@stitchesConfig';
import * as Accordion from '@radix-ui/react-accordion';
import { MdExpandMore } from 'react-icons/md';

export const StyledPalletisingCard = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '0.0625rem',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  padding: '$space16',
  borderRadius: '$radii8',
  display: 'flex',
  flexDirection: 'column',
  gap: '$space16',
  mb: '$space16',
});

StyledPalletisingCard.displayName = 'StyledPalletisingCard';

export const StyledPalletisingCardHeader = styled('div', {
  display: 'flex',
  gap: '$space16',
});

StyledPalletisingCardHeader.displayName = 'StyledPalletisingCardHeader';

export const StyledPalletisingCardHeaderImageContainer = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '0.0625rem',
  backgroundColor: '$gray1',
  width: '$size96',
  minWidth: '$size96',
  boxShadow: '0 0 0 $$borderWidth $$borderColor, $shadow',
  borderRadius: '$radii8',
  overflow: 'hidden',
});

StyledPalletisingCardHeaderImageContainer.displayName =
  'StyledPalletisingCardHeaderImageContainer';

export const StyledPalletisingCardHeaderTitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space4',
});

StyledPalletisingCardHeaderTitleContainer.displayName =
  'StyledSKUSummaryCardHeaderTitleContainer';

export const StyledAccordionRoot = styled(Accordion.Root, {
  overflow: 'hidden',
});

StyledAccordionRoot.displayName = 'StyledAccordionRoot';

export const StyledAccordionItem = styled(Accordion.Item, {});

StyledAccordionItem.displayName = 'StyledAccordionItem';

export const StyledAccordionTrigger = styled(Accordion.Trigger, {
  $$borderWidth: '$sizes$size1',
  $$borderColor: '$colors$gray7',
  appearance: 'none',
  border: 'none',
  width: '100%',
  backgroundColor: '$gray3',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '$space12 $space16',
  fontSize: '$textMd',
  lineHeight: '$textMd',
  transition: 'background-color 150ms ease-in-out',

  '& span:first-of-type': {
    textAlign: 'left',
  },

  '&:hover': {
    backgroundColor: '$gray4',
  },

  variants: {
    position: {
      first: {
        border: '0.0625rem solid $gray7',
        borderTopLeftRadius: '$radii8',
        borderTopRightRadius: '$radii8',
      },
      last: {
        border: '0.0625rem solid $gray7',
        borderBottomLeftRadius: '$radii8',
        borderBottomRightRadius: '$radii8',

        '&[data-state="open"]': {
          borderBottomLeftRadius: '$radii0',
          borderBottomRightRadius: '$radii0',
        },
      },
      center: {
        borderLeft: '0.0625rem solid $gray7',
        borderRight: '0.0625rem solid $gray7',
        borderBottom: '0.0625rem solid $gray7',
      },
    },
  },
});

StyledAccordionTrigger.displayName = 'StyledAccordionTrigger';

export const StyledAccordionTriggerChevron = styled(MdExpandMore, {
  color: '$gray12',
  transition: 'transform 150ms ease-in-out',

  'button[data-state=open] &': { transform: 'rotate(180deg)' },
});

StyledAccordionTriggerChevron.displayName = 'StyledAccordionTriggerChevron';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const StyledAccordionContent = styled(Accordion.Content, {
  overflow: 'hidden',
  borderLeft: '0.0625rem solid $gray7',
  borderRight: '0.0625rem solid $gray7',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },

  '&[data-state="closed"]': {
    pt: '$space0',
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },

  variants: {
    position: {
      last: {
        borderLeft: '0.0625rem solid $gray7',
        borderRight: '0.0625rem solid $gray7',
        borderBottomLeftRadius: '$radii8',
        borderBottomRightRadius: '$radii8',
        borderBottom: '0.0625rem solid $gray7',
      },
      center: {
        borderLeft: '0.0625rem solid $gray7',
        borderRight: '0.0625rem solid $gray7',
      },
    },
  },
});

StyledAccordionContent.displayName = 'StyledAccordionContent';

export const StyledPalletTotalsGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '$space8 $space16',
  padding: '$space0 $space16 $space16',
});
