import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const WAREHOUSE_OPERATIONS = [
  'Receipt and Putaway',
  'Docking',
  'Picking, Packing & Despatch',
  'Domestic Fulfilment',
  'International Fulfilment',
  'Nationwide and International Distribution & Haulage',
  'Import/Export Documentation',
  'Container Devanning and Sub-sorting',
  'Shrink Wrapping',
  'Waste Disposal',
  'Flexible Operations Schedule',
];

const ADDITIONAL_SERVICES = [
  'Re-packaging, Customised Labelling, Light Assembly & Documentation',
  'Re-processing and Quality Control Programs',
  'Return processing and Management',
  'Contract Packing',
  'Procurement and Management of All Packaging Materials',
  'Product Kitting and Repackaging',
  'Contract Manufacturing',
  'Hazardous Goods handling',
  'Special Projects',
];

export function WarehouseServices() {
  const { control, getValues, setValue, watch } = useFormContext();

  const handleWarehouseOperationsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const warehouseOperations = getValues('warehouseOperations');
    if (checked) {
      setValue('warehouseOperations', [...warehouseOperations, value]);
    } else {
      const filteredAnswers = (warehouseOperations as string[]).filter(
        (answer) => answer !== value
      );

      setValue('warehouseOperations', filteredAnswers);
    }
  };

  const handleAdditionalServicesCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const additionalServices = getValues('additionalServices');
    if (checked) {
      setValue('additionalServices', [...additionalServices, value]);
    } else {
      const filteredAnswers = (additionalServices as string[]).filter(
        (answer) => answer !== value
      );

      setValue('additionalServices', filteredAnswers);
    }
  };

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Warehouse services
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='warehouseOperations'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Label
                  htmlFor='warehouseOperations'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                    color: error ? '#d32f2f' : 'initial',
                  }}
                >
                  Warehouse operations *
                </Label>
                {Boolean(error) && (
                  <Typography color='error' fontSize='0.75rem' paddingTop={4}>
                    {error?.message}
                  </Typography>
                )}
                <FormGroup>
                  {WAREHOUSE_OPERATIONS.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleWarehouseOperationsCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='additionalServices'
          control={control}
          render={({ field }) => {
            return (
              <>
                <Label
                  htmlFor='additionalServices'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                  }}
                >
                  Additional services
                </Label>
                <FormGroup>
                  {ADDITIONAL_SERVICES.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleAdditionalServicesCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
    </Stack>
  );
}
