import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CriteriaMode,
  Mode,
  useFieldArray,
  useForm,
  DefaultValues,
  FieldValues,
  ArrayPath,
} from 'react-hook-form';

interface UseReactHookFormProps<T extends FieldValues> {
  defaultValues: DefaultValues<T>;
  validationSchema: Yup.ObjectSchema<any>;
  mode?: Mode;
  criteriaMode?: CriteriaMode;
}

export const useReactHookForm = <T extends FieldValues>({
  defaultValues,
  validationSchema,
  mode = 'all',
  criteriaMode = 'all',
}: UseReactHookFormProps<T>) => {
  const methods = useForm<T>({
    mode,
    criteriaMode,
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { errors, isSubmitting, isValid },
  } = methods;

  return {
    ...methods,
    errors,
    isSubmitting,
    isValid,
  };
};

interface UseReactHookFormWithFieldArray<T extends FieldValues>
  extends UseReactHookFormProps<T> {
  fieldArrayName: ArrayPath<T>;
}

export const useReactHookFormWithFieldArray = <T extends FieldValues>({
  defaultValues,
  validationSchema,
  fieldArrayName,
  mode = 'all',
  criteriaMode = 'all',
}: UseReactHookFormWithFieldArray<T>) => {
  const methods = useReactHookForm<T>({
    mode,
    criteriaMode,
    defaultValues,
    validationSchema,
  });

  const { fields, append, remove } = useFieldArray<T>({
    control: methods.control,
    name: fieldArrayName,
  });

  return {
    ...methods,
    fields,
    append,
    remove,
  };
};
