import { useSetAtom } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import * as Yup from 'yup';
import { SyntheticEvent, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalContent, ModalFooter } from '@components/Modal';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { Label } from '@components/Label';
import { Button } from '@components/Button';
import { MdCheck } from 'react-icons/md';
import { Icon } from '@components/Icon';
import { isCustomerModalOpenAtom } from '../../store/jotai';
import { HERE_APIKEY } from '../../constants';

const schema = Yup.object().shape({
  customerId: Yup.string().required('Customer ID is required'),
  name: Yup.string().required('Customer name is required'),
  type: Yup.string().required('Customer type is required'),
  contactName: Yup.string().required('Contact name is required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('Contact email is required'),
  hqId: Yup.string().required('HQ ID is required'),
  hqName: Yup.string().required('HQ name is required'),
  address: Yup.string().required('Address is required'),
  country: Yup.string(),
  postcode: Yup.string(),
  location: Yup.object().shape({
    lon: Yup.number(),
    lat: Yup.number(),
  }),
  operatingHoursFrom: Yup.string().required('Operating hours from is required'),
  operatingHoursTo: Yup.string().required('Operating hours to is required'),
  notes: Yup.string(),
});

export type CustomerFormValues = {
  customerId: string;
  name: string;
  type: string;
  contactName: string;
  contactEmail: string;
  hqId: string;
  hqName: string;
  address: string;
  country: string;
  postcode: string;
  location: {
    lon: number;
    lat: number;
  };
  operatingHoursFrom: string;
  operatingHoursTo: string;
  notes: string;
};

export function CustomerForm({
  handleOnSubmit,
  isSubmitting,
}: {
  handleOnSubmit: (data: CustomerFormValues) => unknown;
  isSubmitting?: boolean;
}) {
  const setIsAddCustomerModalOpen = useSetAtom(isCustomerModalOpenAtom);
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  const [inputValue, setInputValue] = useState<string | null>(null);
  const [options, setOptions] = useState([]);

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleFetchSuggestions = (input: string) => {
    fetch(
      `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items = data.items.map(
            (item: {
              id: any;
              address: {
                label: string;
                city: string;
                countryName: string;
                postalCode: string;
              };
            }) => ({
              id: item.id,
              label: item.address.label,
              city: item.address.city,
              country: item.address.countryName,
              postalCode: item.address.postalCode,
            })
          );
          setOptions(items);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const defaultValues = {
    customerId: '',
    name: '',
    type: '',
    contactName: '',
    contactEmail: '',
    hqId: '',
    hqName: '',
    address: '',
    country: '',
    postcode: '',
    location: {
      lon: 0,
      lat: 0,
    },
    operatingHoursFrom: '08:30',
    operatingHoursTo: '19:00',
    notes: '',
  };

  const methods = useForm<CustomerFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues },
  });
  const {
    handleSubmit,
    trigger,
    formState,
    getValues,
    control,
    setValue,
    register,
    watch,
  } = methods;

  const { errors } = formState;

  const handleOptionSelect = async (event: any, value: any) => {
    if (value && value.id) {
      setValue('address', value.label);
      setValue('country', value.country);
      setValue('postcode', value.postalCode);
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            const { lat, lng: lon } = position;

            const location = {
              lat,
              lon,
            };

            setValue('location', location);
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  const onClose = () => {
    setIsAddCustomerModalOpen(false);
  };

  const onSubmit = async (data: CustomerFormValues) => {
    console.log(getValues(), errors);
    const isValid = await trigger();
    if (isValid) {
      try {
        handleOnSubmit(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: '100%' }}
      >
        <ModalContent
          css={{
            maxHeight: 'calc(85vh - 12.5rem)',
            minHeight: 'calc(85vh - 12.5rem)',
          }}
          id='warehouse-form-modal'
        >
          <Stack gap={24}>
            <Stack gap={12}>
              <Label
                htmlFor='fleetCategory'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Customer Details *
              </Label>
              <Controller
                name='customerId'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Customer ID *'
                    placeholder='Customer ID *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='name'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Customer name *'
                    placeholder='Customer name *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='type'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Customer type *'
                    placeholder='Customer type *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='contactName'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Contact name *'
                    placeholder='Contact name *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='contactEmail'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Contact email *'
                    placeholder='Contact email *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='hqId'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='HQ ID *'
                    placeholder='HQ ID *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
              <Controller
                name='hqName'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='HQ name *'
                    placeholder='HQ name *'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
            </Stack>
            <Stack gap={12}>
              <Label
                htmlFor='fleetCategory'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Delivery location info *
              </Label>
              <Controller
                name='address'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    freeSolo
                    disablePortal
                    options={options}
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    filterOptions={(options, state) => options}
                    onInputChange={(event, newInputValue) => {
                      handleInputChange(event, newInputValue);
                      handleFetchSuggestions(newInputValue);
                    }}
                    onChange={(e, value) => {
                      handleOptionSelect(e, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Location *'
                        error={Boolean(error)}
                        helperText={Boolean(error) && error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name='operatingHoursFrom'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Operating hours from *'
                    placeholder='Operating hours from *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='operatingHoursTo'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Operating hours to *'
                    placeholder='Operating hours to *'
                    sx={{
                      width: '100%',
                    }}
                    type='time'
                    defaultValue={field.value}
                  />
                )}
              />
              <Controller
                name='notes'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={Boolean(error)}
                    helperText={Boolean(error) && error?.message}
                    variant='outlined'
                    label='Notes'
                    placeholder='Notes'
                    sx={{
                      width: '100%',
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button
            variant='secondary'
            size={isTablet ? 'md' : 'sm'}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type='submit'
            rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
            isLoading={isSubmitting}
            loadingIcon={<Icon name='loading' />}
            loadingText='Submitting...'
            size={isTablet ? 'md' : 'sm'}
            action='cta'
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
}
