import { ChangeEvent, useState } from 'react';
import { Text } from '@components/Text';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Input } from '@components/Input';
import {
  PasswordStrengthBar,
  getPasswordStrength,
} from '@components/PasswordStrengthBar';
import { ErrorMessage } from '@components/ErrorMessage';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToggleTheme } from '@components/ToggleTheme';
import { useAuthHttp } from '@hooks';
import {
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
  RESET_PASSWORD_URL,
} from '@constants';
import { WarningMessage } from '@components/WarningMessage';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
  LoginForm as ResetPasswordForm,
} from '@views/Login/Login.styles';
import { InfoMessage } from '@components/InfoMessage';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

interface ResetPasswordValues {
  password: string;
  hasAgreedToTandC: boolean;
}

export function ResetPassword() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const authHttp = useAuthHttp();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordErrorText, setResetPasswordErrorText] = useState('');
  const [resetPasswordSendError, setResetPasswordSendError] = useState(false);

  useDocumentTitle('FLOX - Reset Password');

  const hasAgreedToTandC = searchParams.get('hastandc') === 'true';

  const initialValues = {
    password: '',
    hasAgreedToTandC,
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must have at least 8 characters.')
      .required('A new password is required.')
      .test({
        name: 'password-strength-test',
        test: (value, ctx) => {
          if (!(getPasswordStrength(value as string) > 1)) {
            return ctx.createError({
              message: 'Password is not strong enough.',
            });
          }
          return true;
        },
      }),
    hasAgreedToTandC: Yup.boolean().oneOf([true], 'Field must be checked'),
  });

  const handleSubmit = (
    values: FormikValues,
    { resetForm }: FormikHelpers<ResetPasswordValues>
  ) => {
    authHttp
      .post(RESET_PASSWORD_URL, {
        token: searchParams.get('token'),
        password: values.password,
        hasAgreedToTandC: values.hasAgreedToTandC,
      })
      .then((res) => {
        resetForm();
        console.log(res);
        setResetPasswordSuccess(true);
      })
      .catch((err) => {
        console.error(err);
        resetForm();
        setResetPasswordErrorText('Something went wrong.');
        setResetPasswordSendError(true);
      });
  };

  return (
    <Container>
      <ImagePanel variant='login' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          <Logo
            src={darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC}
            alt='Flox logo'
          />
          <Heading
            as='h1'
            size={isTablet ? 'md' : 'sm'}
            css={{ textAlign: isTablet ? 'right' : 'center' }}
          >
            {!resetPasswordSuccess ? 'Reset your password' : 'Success!'}
          </Heading>
        </LogoTitleContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            isValid,
          }) => (
            <ResetPasswordForm>
              {resetPasswordSendError && (
                <WarningMessage>{resetPasswordErrorText}</WarningMessage>
              )}
              {!resetPasswordSuccess ? (
                <>
                  <InputGroup css={{ gap: '$space0' }}>
                    <Label htmlFor='password' visuallyHidden>
                      Password
                    </Label>
                    <Input
                      type='password'
                      id='password'
                      name='password'
                      placeholder='Enter a new password...'
                      isInvalid={
                        (errors.password && touched.password) as boolean
                      }
                      value={values.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldTouched('password');
                        setFieldValue('password', e.target.value);
                        setPasswordStrength(
                          getPasswordStrength(e.target.value)
                        );
                      }}
                      onFocus={() => setPasswordFocus(true)}
                      onBlur={() => setPasswordFocus(false)}
                      autoComplete='new-password'
                      disabled={isSubmitting}
                    />
                    <InfoMessage
                      css={{
                        pt: '$space8',
                        mb:
                          (errors.password && touched.password) || passwordFocus
                            ? '$space8'
                            : '$space0',
                      }}
                    >
                      8 characters minimum, including a number.
                    </InfoMessage>
                    <PasswordStrengthBar
                      score={passwordStrength}
                      css={{
                        display: passwordFocus ? 'flex' : 'none',
                        mb: '$space8',
                        px: '$space16',
                      }}
                    />
                    {errors.password && touched.password && (
                      <ErrorMessage css={{ mt: '$space8' }}>
                        {errors.password}
                      </ErrorMessage>
                    )}
                  </InputGroup>
                  {!hasAgreedToTandC && (
                    <InputGroup
                      direction='row'
                      css={{
                        gap: '$space16',
                        padding: '$space2',
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.hasAgreedToTandC}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                                checked: boolean
                              ) => {
                                setFieldTouched('hasAgreedToTandC');
                                setFieldValue('hasAgreedToTandC', checked);
                              }}
                            />
                          }
                          label='I agree to the Terms and Conditions.'
                        />
                        {errors.hasAgreedToTandC &&
                          touched.hasAgreedToTandC && (
                            <ErrorMessage>
                              {errors.hasAgreedToTandC}
                            </ErrorMessage>
                          )}
                      </FormGroup>
                    </InputGroup>
                  )}
                  <Button
                    type='submit'
                    variant='primary'
                    action='cta'
                    size='lg'
                    isLoading={isSubmitting}
                    loadingIcon={<Icon name='loading' />}
                    loadingText='Resetting password...'
                    isFullWidth
                    disabled={!isValid || isSubmitting}
                    css={{ mt: '$space16' }}
                  >
                    {isSubmitting ? 'Resetting password...' : 'Reset password'}
                  </Button>
                </>
              ) : (
                <Text>Your password was successfully reset.</Text>
              )}
              <Button
                variant='link'
                size={isMobile ? 'md' : 'sm'}
                leftIcon={<Icon name='chevron_left' />}
                onClick={() => navigate('/login')}
                css={{ mt: '$space16' }}
              >
                Log in
              </Button>
            </ResetPasswordForm>
          )}
        </Formik>
      </FormPanel>
    </Container>
  );
}
