import { styled } from '@stitchesConfig';

export const StyledInfoMessage = styled('span', {
  fontSize: '$textXs',
  lineHeight: '$textXs',
  fontWeight: '$regular',
  color: '$gray9',
  pl: '$space16',
});

StyledInfoMessage.displayName = 'StyledInfoMessage';
