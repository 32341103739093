import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface InfiniteScrollLoadingProps {
  loadingMessage?: string;
  direction?: 'row' | 'column';
  sx?: SxProps;
}

export function InfiniteScrollLoading({
  loadingMessage = 'Loading...',
  direction = 'column',
  sx,
}: InfiniteScrollLoadingProps) {
  return (
    <Box
      sx={{
        flex: '1',
        textAlign: 'center',
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        ...sx,
      }}
    >
      <CircularProgress size='var(--sizes-size32)' />
      {loadingMessage}
    </Box>
  );
}
