/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Text } from '@components/Text';
import { numberToLocale } from '@forms/client/AddProductForm/utils';
import dayjs from 'dayjs';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { INVENTORY_IMAGES_URL, SUPPORTED_CURRENCY_GROUPS } from '@constants';
import { useAuth } from '@context';
import { UserRoleService } from '@services';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { DialogModal } from '@components/DialogModal';
import { CreateDespatchNoteForm } from '@forms/client/CreateDespatchNoteForm';
import { useDespatchNote } from '@hooks';
import { InventorySKUListing, RowProps } from '../types';

export function CollapsibleRow({
  row,
  warehouseId,
}: RowProps<InventorySKUListing>) {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();
  const [open, setOpen] = useState(false);
  const [selectedDespatchSKU, setSelectedDespatchSKU] =
    useState<InventorySKUListing>();

  const hasETAs =
    row.shipmentNotes &&
    row.shipmentNotes.filter(
      (shipmentNote) => !shipmentNote.hasGoodsReceivedNote
    ).length > 0;

  const {
    isCreateDespatchNoteFormOpen,
    setIsCreateDespatchNoteFormOpen,
    handleCloseCreateDespatchNoteForm,
    handleSubmitDespatchNote,
  } = useDespatchNote();

  const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
    (currencyGroup) => currencyGroup.currencies
  );

  let currencySymbol: string | undefined;

  if (row.costPrices && row.costPrices[0] && row.costPrices[0].currency) {
    currencySymbol = currencies.find(
      (currency) => currency.code === row.costPrices[0].currency
    )?.symbol;
  }

  const getPalletAmount = (
    quantity: number,
    amountOfSkusInLayer: number,
    amountOfLayersInPallet: number
  ) => Math.ceil(quantity / amountOfSkusInLayer / amountOfLayersInPallet);

  const allAvailableStock = row.stockByWarehouse.reduce(
    (n, { availableStock }) => n + availableStock,
    0
  );

  const allAllocatedStock = row.stockByWarehouse.reduce(
    (n, { allocatedStock }) => n + allocatedStock,
    0
  );

  const allInTransitStock = row.stockByWarehouse.reduce(
    (n, { inTransitStock }) => n + inTransitStock,
    0
  );

  const allBlockedStock = row.stockByWarehouse.reduce(
    (n, { blockedStock }) => n + blockedStock,
    0
  );

  const allStockDiscrepancy = row.stockByWarehouse.reduce(
    (n, { stockDiscrepancy }) => n + stockDiscrepancy,
    0
  );

  const allTotalStock = row.stockByWarehouse.reduce(
    (n, { totalStock }) => n + totalStock,
    0
  );

  const allAvailableStockValue = row.stockByWarehouse.reduce(
    (n, { availableStockValue }) => n + availableStockValue,
    0
  );

  const allAllocatedStockValue = row.stockByWarehouse.reduce(
    (n, { allocatedStockValue }) => n + allocatedStockValue,
    0
  );

  const allInTransitStockValue = row.stockByWarehouse.reduce(
    (sum, warehouse) => sum + warehouse.inTransitStockValue,
    0
  );

  const allBlockedStockValue = row.stockByWarehouse.reduce(
    (n, { blockedStockValue }) => n + blockedStockValue,
    0
  );

  const allStockDiscrepancyValue = row.stockByWarehouse.reduce(
    (n, { stockDiscrepancyValue }) => n + stockDiscrepancyValue,
    0
  );

  const allTotalStockValue = row.stockByWarehouse.reduce(
    (n, { totalStockValue }) => n + totalStockValue,
    0
  );

  const warehouseStock = row.stockByWarehouse.find(
    (warehouse) => warehouse.warehouseId === warehouseId
  );

  return (
    <>
      <TableRow
        sx={{
          '& > *': {
            borderBottomColor: 'var(--colors-gray7)',
            minWidth: '5rem',
          },
        }}
      >
        <TableCell
          component='td'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
            sx={{ color: 'var(--colors-gray11)' }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              width: '3rem',
              height: '3rem',
              borderRadius: 'var(--radii-radii8)',
              backgroundColor: darkMode
                ? 'var(--colors-gray12)'
                : 'var(--colors-gray1)',
            }}
          >
            <img
              src={
                row.images && row.images.length > 0
                  ? `${INVENTORY_IMAGES_URL}/${row.images[0].imageLocation}/small/${row.images[0].remoteName}`
                  : `/images/product-placeholder-${
                      darkMode ? 'dark' : 'light'
                    }.svg`
              }
              alt={`${row.productName} (${row.name})`}
              style={{
                maxHeight: '100%',
                width: 'auto',
                borderRadius: 'var(--radii-radii8)',
                margin: '0 auto',
              }}
            />
          </Stack>
        </TableCell>
        <TableCell
          component='td'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs'>{row.skuId}</Text>
        </TableCell>
        <TableCell sx={{ borderBottomColor: 'var(--colors-gray7)' }}>
          <Stack sx={{ width: '13.5rem' }}>
            <Text size='xs'>
              {row.productName} ({row.name})
            </Text>
            {isWarehouseClient(user) && row.productCategory && (
              <Text size='2xs'>{row.productCategory}</Text>
            )}
          </Stack>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Stack>
            <Text size='xs' weight='bold'>
              {warehouseId === 'All'
                ? numberToLocale(allInTransitStock)
                : numberToLocale(warehouseStock?.inTransitStock ?? 0)}
            </Text>
          </Stack>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {warehouseId === 'All'
              ? numberToLocale(allAvailableStock)
              : numberToLocale(warehouseStock?.availableStock ?? 0)}
          </Text>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {warehouseId === 'All'
              ? numberToLocale(allAllocatedStock)
              : numberToLocale(warehouseStock?.allocatedStock ?? 0)}
          </Text>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {warehouseId === 'All'
              ? allStockDiscrepancy < 0
                ? numberToLocale(
                    allBlockedStock + Math.abs(allStockDiscrepancy)
                  )
                : numberToLocale(warehouseStock?.blockedStock ?? 0)
              : (warehouseStock?.stockDiscrepancy ?? 0) < 0
              ? numberToLocale(
                  (warehouseStock?.blockedStock ?? 0) +
                    Math.abs(warehouseStock?.stockDiscrepancy ?? 0)
                )
              : numberToLocale(warehouseStock?.blockedStock ?? 0)}
          </Text>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {warehouseId === 'All'
              ? numberToLocale(allTotalStock)
              : numberToLocale(warehouseStock?.totalStock ?? 0)}
          </Text>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {currencySymbol || '£'}
            {warehouseId === 'All'
              ? numberToLocale(allTotalStockValue)
              : numberToLocale(warehouseStock?.totalStockValue ?? 0)}
          </Text>
        </TableCell>
        <TableCell
          component='td'
          align='center'
          sx={{ borderBottomColor: 'var(--colors-gray7)' }}
        >
          <Text size='xs' weight='bold'>
            {warehouseId === 'All'
              ? allStockDiscrepancy < 0
                ? 0
                : numberToLocale(allStockDiscrepancy)
              : (warehouseStock?.stockDiscrepancy ?? 0) < 0
              ? 0
              : numberToLocale(warehouseStock?.stockDiscrepancy ?? 0)}
          </Text>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow
          sx={{
            backgroundColor: 'var(--colors-gray2)',
          }}
        >
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              padding: '1rem',
              verticalAlign: 'top',
            }}
          >
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{
                width: '3rem',
                height: '3rem',
                margin: '2.25rem auto 0',
                borderRadius: 'var(--radii-radii8)',
                backgroundColor: darkMode
                  ? 'var(--colors-gray12)'
                  : 'var(--colors-gray1)',
              }}
            >
              <img
                src={
                  row.productImages && row.productImages.length > 0
                    ? `${INVENTORY_IMAGES_URL}/${row.productImages[0].imageLocation}/small/${row.productImages[0].remoteName}`
                    : `/images/product-placeholder-${
                        darkMode ? 'dark' : 'light'
                      }.svg`
                }
                alt={`${row.productName} (${row.name})`}
                style={{
                  maxHeight: '100%',
                  width: 'auto',
                  borderRadius: 'var(--radii-radii8)',
                  margin: '0 auto',
                }}
              />
            </Stack>
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              padding: '1rem',
              verticalAlign: 'top',
            }}
          >
            <Stack gap={8}>
              <Text size='lg' weight='semibold'>
                Shipment details
              </Text>
              <Stack spacing={4}>
                <Text
                  size='xs'
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text size='xs' as='span' weight='medium'>
                    <strong>Product ID:</strong>
                  </Text>
                  <Text size='xs' as='span'>
                    {row.givenProductId}
                  </Text>
                </Text>
                <Text
                  size='xs'
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text size='xs' as='span' weight='medium'>
                    <strong>Brand:</strong>
                  </Text>
                  <Text size='xs' as='span'>
                    {row.brand}
                  </Text>
                </Text>
                {isWarehouseClient(user) && (
                  <Text
                    size='xs'
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text size='xs' as='span' weight='medium'>
                      <strong>Subcategory:</strong>
                    </Text>
                    <Text size='xs' as='span'>
                      {row.productSubCategory}
                    </Text>
                  </Text>
                )}
              </Stack>
            </Stack>
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              padding: '1rem',
              verticalAlign: 'top',
            }}
          >
            <Stack spacing={4} sx={{ mt: 36 }}>
              <Text
                size='xs'
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text size='xs' as='span' weight='medium'>
                  <strong>Product Vol:</strong>
                </Text>
                <Text size='xs' as='span'>
                  {numberToLocale(
                    row.dimensions.length *
                      row.dimensions.width *
                      row.dimensions.height *
                      row.amountOfProductInSku
                  )}
                  {row.dimensions.dimensionsUnitOfMeasure}
                  <sup>3</sup>
                </Text>
              </Text>
              <Text
                size='xs'
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Text size='xs' as='span' weight='medium'>
                  <strong>SKU Cube:</strong>
                </Text>
                <Text size='xs' as='span'>
                  {numberToLocale(
                    row.dimensions.length *
                      row.dimensions.width *
                      row.dimensions.height
                  )}
                  {row.dimensions.dimensionsUnitOfMeasure}
                  <sup>3</sup>
                </Text>
              </Text>
              {isWarehouseClient(user) && (
                <Text
                  size='xs'
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Text size='xs' as='span' weight='medium'>
                    <strong>SKU Cost Price:</strong>
                  </Text>
                  {currencySymbol ? (
                    <Text size='xs' as='span'>
                      {currencySymbol}
                      {row.costPrices[0].amount}
                    </Text>
                  ) : (
                    <Text size='xs' as='span'>
                      £0.00
                    </Text>
                  )}
                </Text>
              )}
              {isWarehouseManager(user) && (
                <>
                  <Text
                    size='xs'
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      pb: 16,
                    }}
                  >
                    <Text size='xs' as='span' weight='medium'>
                      <strong>SKU Weight:</strong>
                    </Text>
                    <Text size='xs' as='span'>
                      {row.grossWeight}
                      {row.grossWeightUnitOfMeasure}
                    </Text>
                  </Text>
                  <Text
                    size='xs'
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text size='xs' as='span' weight='medium'>
                      <strong>Palletising</strong>
                    </Text>
                    <Text size='xs' as='span' weight='medium'>
                      <strong>SKU Count</strong>
                    </Text>
                  </Text>
                  {row.palletising.map((pallet, index) => (
                    <Text
                      key={pallet.id}
                      size='xs'
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text size='xs' as='span' weight='medium'>
                        <strong>
                          {index + 1}
                          {'. '}
                          {pallet.type}
                        </strong>
                      </Text>
                      <Text size='xs' as='span'>
                        {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          pallet.amountOfSkusInLayer! *
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            pallet.amountOfLayersInPallet!
                        }
                      </Text>
                    </Text>
                  ))}
                </>
              )}
            </Stack>
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              padding: '1rem',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id} css={{ mt: '1.125rem' }}>
                  {Number(
                    row.inTransitStock /
                      pallet.amountOfSkusInLayer! /
                      pallet.amountOfLayersInPallet!
                  ) %
                    1 !==
                  0
                    ? Number(
                        row.inTransitStock /
                          pallet.amountOfSkusInLayer! /
                          pallet.amountOfLayersInPallet!
                      ).toFixed(3)
                    : Number(
                        row.inTransitStock /
                          pallet.amountOfSkusInLayer! /
                          pallet.amountOfLayersInPallet!
                      )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem', textAlign: 'center' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allInTransitStockValue)
                    : numberToLocale(warehouseStock?.inTransitStockValue ?? 0)}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem', mt: '1.125rem' }}>
                  £0.00
                </Text>
              ))}
            {hasETAs && (
              <List
                component='ol'
                sx={{
                  // mt: 36,
                  py: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                {row.shipmentNotes &&
                  row.shipmentNotes
                    .filter(
                      (shipmentNote) => !shipmentNote.hasGoodsReceivedNote
                    )
                    .map((shipmentNote, index) => (
                      <Text
                        key={shipmentNote.shipmentNoteId}
                        as='li'
                        size='xs'
                        css={{ textAlign: 'center' }}
                      >
                        {index + 1}. <strong>Q-ty:</strong>{' '}
                        {shipmentNote.quantity} - <strong>ETA: </strong>
                        {dayjs(shipmentNote.estimatedTimeOfArrival).format(
                          'DD/MM/YYYY'
                        )}{' '}
                      </Text>
                    ))}
              </List>
            )}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.availableStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.availableStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) && (
              <>
                {currencySymbol ? (
                  <Text size='xs' css={{ mb: '1rem', mt: '1.9rem' }}>
                    {currencySymbol}
                    {warehouseId === 'All'
                      ? numberToLocale(allAvailableStockValue)
                      : numberToLocale(
                          warehouseStock?.availableStockValue ?? 0
                        )}
                  </Text>
                ) : (
                  <Text size='xs' css={{ mb: '1rem', mt: '1.9rem' }}>
                    £0.00
                  </Text>
                )}
                <Button
                  variant='contained'
                  size='small'
                  startIcon={<AddIcon />}
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    setSelectedDespatchSKU(row);
                    setIsCreateDespatchNoteFormOpen(true);
                  }}
                >
                  Despatch
                </Button>
              </>
            )}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.allocatedStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.allocatedStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allAllocatedStockValue)
                    : numberToLocale(warehouseStock?.allocatedStockValue ?? 0)}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem' }}>
                  £0.00
                </Text>
              ))}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.blockedStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.blockedStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allBlockedStockValue)
                    : numberToLocale(warehouseStock?.blockedStockValue ?? 0)}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem' }}>
                  £0.00
                </Text>
              ))}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allTotalStockValue)
                    : numberToLocale(warehouseStock?.totalStockValue ?? 0)}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem' }}>
                  £0.00
                </Text>
              ))}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allTotalStockValue)
                    : numberToLocale(warehouseStock?.totalStockValue ?? 0)}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem' }}>
                  £0.00
                </Text>
              ))}
          </TableCell>
          <TableCell
            sx={{
              borderBottomColor: 'var(--colors-gray7)',
              textAlign: 'center',
            }}
          >
            {isWarehouseManager(user) &&
              row.palletising.map((pallet) => (
                <Text size='xs' key={pallet.id}>
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  )}{' '}
                  {`${pallet.type}${
                    pallet.customPalletSpecification
                      ? pallet.customPalletSpecification
                      : ''
                  }`}
                  {getPalletAmount(
                    row.totalStock,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfSkusInLayer!,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    pallet.amountOfLayersInPallet!
                  ) > 1
                    ? 's'
                    : ''}
                </Text>
              ))}
            {isWarehouseClient(user) &&
              (currencySymbol ? (
                <Text size='xs' css={{ mb: '1rem' }}>
                  {currencySymbol}
                  {warehouseId === 'All'
                    ? numberToLocale(allStockDiscrepancyValue)
                    : numberToLocale(
                        warehouseStock?.stockDiscrepancyValue ?? 0
                      )}
                </Text>
              ) : (
                <Text size='xs' css={{ mb: '1rem' }}>
                  £0.00
                </Text>
              ))}
          </TableCell>
          {/* </Collapse> */}
        </TableRow>
      )}
      {isWarehouseClient(user) && (
        <DialogModal
          open={isCreateDespatchNoteFormOpen}
          setOpen={setIsCreateDespatchNoteFormOpen}
          onClose={handleCloseCreateDespatchNoteForm}
          title='Create Despatch Note'
          id='despatch-note-form-title'
        >
          <CreateDespatchNoteForm
            onClose={() => {
              setIsCreateDespatchNoteFormOpen(false);
            }}
            onSubmit={handleSubmitDespatchNote}
            isDesktop={isDesktop}
            isTablet={isTablet}
            selectedSKU={selectedDespatchSKU}
          />
        </DialogModal>
      )}
    </>
  );
}
