import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { useAuthHttp, useCloseDialog } from '@hooks';
import {
  INBOUND_SHIPMENT_TYPE,
  LIST_SUPPLY_CHAIN_PROCESSES_URL,
} from '@constants';
import {
  addInboundProcesses,
  setSelectedInboundProcess as _setSelectedInboundProcess,
  setSelectedProcessChildType as _setSelectedProcessChildType,
} from '@features/supplyChain';
import { InboundProcess } from '@features/supplyChain/types';
import { FilterService } from '@services';

export const useInboundProcess = () => {
  const authHttp = useAuthHttp();
  const { getFilterValue } = FilterService();
  const noticeboardWarehouseId = getFilterValue('noticeboardWarehouseId');
  const noticeboardWarehouseClientId = getFilterValue(
    'noticeboardWarehouseClientId'
  );
  const dispatch = useAppDispatch();

  const {
    supplyChain: { inboundProcesses, selectedProcessChildType },
  } = useAppSelector((state) => state.supplyChain);

  const lastInboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.inboundCurrentQuery.pagination?.lastItem
  );

  const hasMoreInboundData = useAppSelector(
    (state) => state.supplyChain.supplyChain.hasMoreInboundData
  );

  const [isLoadingInboundProcesses, setIsLoadingInboundProcesses] =
    useState(false);

  const [isLoadingInboundProcessesError, setIsLoadingInboundProcessesError] =
    useState(false);

  const [isInitialInboundProcessesFetch, setIsInitialInboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');

  // const [selectedInboundProcess, setSelectedInboundProcess] =
  //   useState<InboundProcess | null>(null);

  const {
    supplyChain: { selectedInboundProcess },
  } = useAppSelector((state) => state.supplyChain);

  const setSelectedInboundProcess = (payload: InboundProcess | null) => {
    dispatch(_setSelectedInboundProcess(payload));
  };

  const setSelectedProcessChildType = (payload: string | null) => {
    dispatch(_setSelectedProcessChildType(payload));
  };

  const selectedInboundProcessHasDescription = Boolean(
    selectedInboundProcess &&
      selectedInboundProcess?.description &&
      selectedInboundProcess?.description?.length > 0
  );

  const fetchInboundSupplyChainProcesses = async () => {
    if (
      isInitialInboundProcessesFetch ||
      isLoadingInboundProcesses ||
      isLoadingInboundProcessesError
    ) {
      setIsInitialInboundProcessesFetch(false);
      return;
    }

    setIsLoadingInboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId:
          noticeboardWarehouseClientId === 'All'
            ? null
            : noticeboardWarehouseClientId,
        type: INBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: lastInboundItem,
          itemsPerPage: 25,
        },
      });

      data.hasMoreInboundData = !(data.supplyChainProcesses.length < 25);
      dispatch(addInboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingInboundProcessesError(true);
    } finally {
      setIsLoadingInboundProcesses(false);
    }
  };

  const getInboundScrollParent = () =>
    document.getElementById('inbound-list') as HTMLElement;

  const handleScrollToTopOfInbound = () => {
    getInboundScrollParent().scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isViewShipmentNoteDialogOpen, setIsViewShipmentNoteDialogOpen] =
    useState(false);

  const handleCloseViewShipmentNote = useCloseDialog(
    setIsViewShipmentNoteDialogOpen
  );

  const [
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewGoodsReceivedNote = useCloseDialog(
    setIsViewGoodsReceivedNoteDialogOpen
  );

  return {
    inboundProcesses,
    fetchInboundSupplyChainProcesses,
    hasMoreInboundData,
    isLoadingInboundProcesses,
    isLoadingInboundProcessesError,
    selectedInboundProcess,
    selectedProcessChildType,
    setSelectedInboundProcess,
    selectedInboundProcessHasDescription,
    getInboundScrollParent,
    handleScrollToTopOfInbound,
    isViewShipmentNoteDialogOpen,
    setIsViewShipmentNoteDialogOpen,
    handleCloseViewShipmentNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    setSelectedProcessChildType,
  };
};
