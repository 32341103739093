import { styled } from '@stitchesConfig';

export const StyledStack = styled('div', {
  display: 'flex',
  $$borderColorInvalid: '$colors$tomato9',
  $$borderWidthInvalid: '$sizes$size2',

  variants: {
    direction: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
    justifyContent: {
      'flex-start': {
        justifyContent: 'flex-start',
      },
      'flex-end': {
        justifyContent: 'flex-end',
      },
      center: {
        justifyContent: 'center',
      },
      'space-between': {
        justifyContent: 'space-between',
      },
      'space-around': {
        justifyContent: 'space-around',
      },
      'space-evenly': {
        justifyContent: 'space-evenly',
      },
    },
    alignItems: {
      'flex-start': {
        alignItems: 'flex-start',
      },
      'flex-end': {
        alignItems: 'flex-end',
      },
      center: {
        alignItems: 'center',
      },
      stretch: {
        alignItems: 'stretch',
      },
      baseline: {
        alignItems: 'baseline',
      },
    },
    isInvalid: {
      true: {
        borderRadius: '$radii8',
        padding: '$space2',
        boxShadow: '0 0 0 $$borderWidthInvalid $$borderColorInvalid',

        '&:hover:not(:disabled)': {
          boxShadow: '0 0 0 $$borderWidthInvalid $$borderColorInvalid',
        },
      },
    },
  },
});
