/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { OutboundProcess } from '@features/supplyChain/types';
import { useAuth } from '@context';
import { PalletCalculator } from '@components/PalletCalculator';
import { UserRoleService } from '@services';

interface DespatchNoteDetailsProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  primaryAction?: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function DespatchNoteDetails({
  selectedOutboundProcess,
  onClose,
  primaryAction,
  isDesktop,
  isTablet,
}: DespatchNoteDetailsProps) {
  const { despatchNote } = selectedOutboundProcess;
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();

  return (
    <>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Despatch note number
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.despatchNoteNumber}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.createdByName}
            </Text>
          </Stack>
          {isWarehouseManager(user) && (
            <Stack>
              <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
                Client name
              </Text>
              <Text size={isDesktop ? 'md' : 'sm'}>
                {selectedOutboundProcess.userBusinessName}
              </Text>
            </Stack>
          )}
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(despatchNote.createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Customer name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Delivery address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>1 Main St</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(despatchNote.requiredTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 1', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 3' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                Quantity
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                Pallets
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem'>
          {despatchNote.skus.map((sku, index) => (
            <>
              <Box
                key={sku.skuId}
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                columnGap='1rem'
                rowGap='1rem'
                alignItems={isDesktop ? 'center' : 'flex-start'}
              >
                {/* SKU index  */}
                <Stack gridColumn='1 / 2' justifyContent='center'>
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '2 / 4' : isTablet ? 'span 5' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU ID
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuIdentifier}
                  </Text>
                </Stack>
                {/* Product name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '4 / 7' : isTablet ? 'span 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Product name
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.productName}
                  </Text>
                </Stack>
                {/* Sku name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '7 / 9' : isTablet ? '2 / 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU name
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.name}
                  </Text>
                </Stack>
                {/* Sku quantity */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '9 / 11' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Quantity
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuQuantity}
                  </Text>
                </Stack>
                {/* Pallet type */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '11 / 14' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Pallets
                    </Text>
                  )}
                  {sku.packagingDetails.packagingInfo ? (
                    <PalletCalculator
                      palletName={sku.packagingDetails.packagingInfo.type}
                      skuQuantity={sku.skuQuantity}
                      amountOfSkusInLayer={
                        sku.packagingDetails.packagingInfo.amountOfSkusInLayer
                      }
                      amountOfLayersInPallet={
                        sku.packagingDetails.packagingInfo
                          .amountOfLayersInPallet
                      }
                    />
                  ) : (
                    <Text as='p' size='md'>
                      {sku.packagingDetails.palletisingType}
                    </Text>
                  )}
                </Stack>
                {/* Pallet description */}
                {sku.packagingDetails.palletisingDescription && (
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '9 / 14' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    <Text as='p' size='sm' weight='semibold'>
                      Pallet description
                    </Text>
                    <Text as='p' size='md'>
                      {sku.packagingDetails.palletisingDescription}
                    </Text>
                  </Stack>
                )}
              </Box>
              {index !== despatchNote.skus.length - 1 && (
                <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
              )}
            </>
          ))}
        </Stack>
      </Stack>
      {despatchNote.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {despatchNote.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction={isDesktop ? 'row' : 'column-reverse'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          size='large'
          variant='outlined'
          fullWidth={!isDesktop}
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Close
        </Button>
        {!selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking ||
          (selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking &&
            !selectedOutboundProcess?.pickingConfirmation &&
            primaryAction && (
              <Button
                size='large'
                variant='contained'
                fullWidth={!isDesktop}
                startIcon={<CheckIcon />}
                sx={{ textTransform: 'none' }}
                onClick={primaryAction}
              >
                {`${
                  !selectedOutboundProcess?.despatchNote?.isAuthorisedForPicking
                    ? 'Authorise For Picking'
                    : 'Confirm Picking'
                }`}
              </Button>
            ))}
      </Stack>
    </>
  );
}
