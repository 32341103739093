/* eslint-disable react/no-array-index-key */
import React, { useState, SyntheticEvent } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Stack,
  FormHelperText,
  Autocomplete,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Heading } from '@components/Heading';
import ProgressBar from './ProgressBar';
import { HERE_APIKEY } from '../../constants';

interface QuestionnaireProps {
  questions: Question[];
  onFinish: (
    answers: Record<string, string | string[] | Date | boolean>
  ) => void;
  questionSet: 'shipper' | 'lsp';
}

interface Question {
  id: string;
  questionText: string;
  type: string;
  options?: string[];
  page: number;
  mandatory?: boolean;
}

export function Questionnaire({
  questions,
  onFinish,
  questionSet,
}: QuestionnaireProps) {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [answers, setAnswers] = useState<
    Record<string, string | string[] | Date | boolean>
  >({});
  const [validationErrors, setValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    setAnswers({
      ...answers,
      [name]: type === 'date' ? new Date(value) : value,
    });
  };

  const handleMapInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = event.target;
    if (checked) {
      setAnswers({
        ...answers,
        [name]: answers[name]
          ? [...(answers[name] as string[]), value]
          : [value],
      });
    } else {
      const filteredAnswers = (answers[name] as string[]).filter(
        (answer) => answer !== value
      );
      setAnswers({
        ...answers,
        [name]: filteredAnswers,
      });
    }
  };

  const hubspotFormSubmit = async (funnelStageValue: string) => {
    try {
      const response = await fetch(
        'https://api.hsforms.com/submissions/v3/integration/submit/3810753/fd613e6a-cac7-450e-941e-2c05c8c69bc0',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            submittedAt: dayjs().valueOf(),
            fields: [
              {
                objectTypeId: '0-1',
                name: 'email',
                value: answers.email,
              },
              {
                objectTypeId: '0-1',
                name: 'firstname',
                value: answers.firstname,
              },
              {
                objectTypeId: '0-1',
                name: 'lastname',
                value: answers.lastname,
              },
              {
                objectTypeId: '0-1',
                name: 'client_type',
                value:
                  questionSet === 'shipper'
                    ? 'Buyer (Shipper)'
                    : 'Seller (LSP)',
              },
              {
                objectTypeId: '0-1',
                name: 'funnel_stage',
                value: funnelStageValue,
              },
            ],
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const jsonResponse = await response.json();
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleNextPage = async () => {
    const currentPageQuestions = questions.filter(
      (question) => question.page === currentPage + 1
    );

    const metersPopulated =
      answers.availableStorageCapacityMeters !== undefined &&
      answers.availableStorageCapacityMeters !== '';
    const palletsPopulated =
      answers.availableStorageCapacityPallets !== undefined &&
      answers.availableStorageCapacityPallets !== '';

    const isPage1 = currentPage === 0;

    const isPage2 = currentPage + 1 === 2;

    if (isPage1) {
      const missingMandatory = currentPageQuestions.some(
        (question) => question.mandatory && !answers[question.id]
      );

      if (!missingMandatory) {
        hubspotFormSubmit('Quiz incomplete');
        setCurrentPage(currentPage + 1);
        setValidationErrors({});
      } else {
        const errors: Record<string, boolean> = {};
        currentPageQuestions
          .filter((question) => question.mandatory && !answers[question.id])
          .forEach((question) => {
            errors[question.id] = true;
          });
        setValidationErrors(errors);
      }
    }

    if (isPage2) {
      if (!(metersPopulated || palletsPopulated)) {
        const missingMandatory = currentPageQuestions.some(
          (question) => question.mandatory && !answers[question.id]
        );

        if (!missingMandatory) {
          setCurrentPage(currentPage + 1);
          setValidationErrors({});
        } else {
          const errors: Record<string, boolean> = {};
          currentPageQuestions
            .filter((question) => question.mandatory && !answers[question.id])
            .forEach((question) => {
              errors[question.id] = true;
            });
          setValidationErrors(errors);
        }
      } else {
        const errors: Record<string, boolean> = {};

        currentPageQuestions
          .filter((question) => question.mandatory && !answers[question.id])
          .forEach((question) => {
            errors[question.id] = true;
          });

        if (metersPopulated) {
          errors.maxStorageCapacityPallets = false;
          errors.availableStorageCapacityPallets = false;
        }

        if (palletsPopulated) {
          errors.maxStorageCapacityMeters = false;
          errors.availableStorageCapacityMeters = false;
        }

        if (Object.values(errors).includes(true)) {
          setValidationErrors(errors);
        } else {
          setValidationErrors({});
          setCurrentPage(currentPage + 1);
        }
      }
    } else {
      const missingMandatory = currentPageQuestions.some(
        (question) => question.mandatory && !answers[question.id]
      );

      if (!missingMandatory) {
        setCurrentPage(currentPage + 1);
        setValidationErrors({});
      } else {
        const errors: Record<string, boolean> = {};
        currentPageQuestions
          .filter((question) => question.mandatory && !answers[question.id])
          .forEach((question) => {
            errors[question.id] = true;
          });
        setValidationErrors(errors);
      }
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFinish = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    answers: Record<string, string | string[] | Date | boolean>
  ) => {
    const currentPageQuestions = questions.filter(
      (question) => question.page === currentPage + 1
    );

    const missingMandatory = currentPageQuestions.some(
      (question) => !answers[question.id]
    );

    if (!missingMandatory) {
      hubspotFormSubmit('Quiz complete');
      onFinish(answers);
      setValidationErrors({});
    } else {
      const errors: Record<string, boolean> = {};
      currentPageQuestions
        .filter((question) => !answers[question.id])
        .forEach((question) => {
          errors[question.id] = true;
        });
      setValidationErrors(errors);
    }
  };

  const isLastPage = questions[questions.length - 1].page === currentPage + 1;

  const renderQuestion = (question: Question) => {
    const hasError = validationErrors[question.id];

    const handleFetchSuggestions = (input: string) => {
      fetch(
        `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.items) {
            const items = data.items.map(
              (item: { id: any; address: { label: any } }) => ({
                id: item.id,
                label: item.address.label,
              })
            );
            setOptions(items);
          }
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
        });
    };

    const handleOptionSelect = async (event: any, value: any) => {
      if (value && value.id) {
        try {
          const response = await fetch(
            `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
          );

          const data = await response.json();

          if (data) {
            const { position, address } = data;

            if (position) {
              const { lat, lng: lon } = position;

              const location = {
                lat,
                lon,
              };

              setAnswers({
                ...answers,
                location: address.label,
                lat: location.lat,
                lng: location.lon,
              });
            }
          }
        } catch (error) {
          console.error('Error fetching position information:', error);
        }
      }
    };

    return (
      <div key={question.id}>
        {question.type === 'text' && (
          <>
            {question.questionText === 'Email address *' && (
              <Stack
                sx={{
                  width: { xs: '100%', md: 'unset' },
                  maxWidth: { xs: '100%', md: '400px' },
                  marginBottom: '16px',
                  color: 'black',
                }}
              >
                <Heading as='h2' size='xs'>
                  Contact details
                </Heading>
              </Stack>
            )}
            {questionSet === 'lsp' &&
              question.questionText === 'Available capacity, in sq.m *' && (
                <Stack
                  sx={{
                    width: { xs: '100%', md: 'unset' },
                    maxWidth: { xs: '100%', md: '400px' },
                    marginBottom: '16px',
                    color: 'black',
                  }}
                >
                  <Heading as='h2' size='xs'>
                    Available Warehousing Capacity
                  </Heading>
                </Stack>
              )}
            {questionSet === 'lsp' &&
              question.questionText === 'Max capacity, in sq.m *' && (
                <Stack
                  sx={{
                    width: { xs: '100%', md: 'unset' },
                    maxWidth: { xs: '100%', md: '400px' },
                    marginBottom: '16px',
                    color: 'black',
                  }}
                  spacing={16}
                >
                  <Heading as='h2' size='xs'>
                    Warehousing
                  </Heading>
                  <Typography fontSize='0.8rem'>
                    Only one of the following &quot;Max capacity&quot; inputs
                    needs to be populated
                  </Typography>
                </Stack>
              )}
            {questionSet === 'lsp' &&
              question.questionText === 'Minimum service term in months *' && (
                <Stack
                  sx={{
                    width: { xs: '100%', md: 'unset' },
                    maxWidth: { xs: '100%', md: '400px' },
                    marginBottom: '16px',
                    color: 'black',
                  }}
                  spacing={16}
                >
                  <Heading as='h2' size='xs'>
                    Minimum service requirements
                  </Heading>
                </Stack>
              )}
            {((questionSet === 'lsp' &&
              question.questionText === 'Max capacity, in pallets *') ||
              question.questionText === 'Available capacity, in pallets *') && (
              <Stack
                sx={{
                  width: { xs: '100%', md: 'unset' },
                  maxWidth: { xs: '100%', md: '400px' },
                  marginBottom: '16px',
                  color: 'black',
                }}
                spacing={16}
              >
                <Typography fontSize='1rem' fontWeight='bold'>
                  OR
                </Typography>
              </Stack>
            )}
            <TextField
              label={question.questionText}
              variant='outlined'
              name={question.id}
              fullWidth
              onChange={handleInputChange}
              error={hasError}
              defaultValue={answers[question.id] ?? ''}
            />
          </>
        )}
        {question.type === 'radio' && (
          <FormControl component='fieldset'>
            <FormLabel
              component='legend'
              style={{
                color: hasError ? 'red' : 'black',
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
            >
              {question.questionText}
            </FormLabel>
            <RadioGroup
              name={question.id}
              value={answers[question.id] || ''}
              onChange={handleInputChange}
            >
              {question.options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
        {question.type === 'checkbox' && (
          <FormControl component='fieldset'>
            <FormLabel
              component='legend'
              style={{
                color: hasError ? 'red' : 'black',
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
            >
              {question.questionText}
            </FormLabel>
            {question.options?.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      (answers[question.id] as string[])?.includes(option) ||
                      false
                    }
                    onChange={handleCheckboxChange}
                    name={question.id}
                    value={option}
                  />
                }
                label={option}
              />
            ))}
          </FormControl>
        )}
        {question.type === 'date' && (
          <DatePicker
            label={question.questionText}
            onChange={(value) => {
              if (!value) return;
              setAnswers({
                ...answers,
                [question.id]: value,
              });
            }}
            value={answers[question.id] || null}
            format='DD/MM/YYYY'
            disablePast
          />
        )}
        {question.type === 'map' && (
          <Autocomplete
            freeSolo
            disablePortal
            options={options}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            filterOptions={(options, state) => options}
            onInputChange={(event, newInputValue) => {
              handleMapInputChange(event, newInputValue);
              handleFetchSuggestions(newInputValue);
            }}
            onChange={handleOptionSelect}
            renderInput={(params) => (
              <TextField {...params} label='Desired Location/Region' />
            )}
          />
        )}
        {hasError && (
          <FormHelperText style={{ color: 'red' }}>
            {question.questionText === 'Max capacity, in sq.m *' ||
            question.questionText === 'Max capacity, in pallets *' ||
            question.questionText === 'Available capacity, in pallets *' ||
            question.questionText === 'Available capacity, in sq.m *'
              ? 'One of these fields are required'
              : 'This field is required'}
          </FormHelperText>
        )}
      </div>
    );
  };

  const getCurrentPageQuestions = () => {
    return questions.filter((question) => question.page === currentPage + 1);
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' p={16}>
      <Stack
        sx={{
          width: { xs: '100%', md: 'unset' },
          minWidth: { xs: '100%', md: '400px' },
        }}
        spacing={20}
        mt={20}
      >
        {currentPage === 1 && questionSet === 'shipper' && (
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='xs'>
              Warehouse service requirements
            </Heading>
          </Stack>
        )}
        {currentPage === 1 && questionSet === 'lsp' && (
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <Heading as='h2' size='xs'>
              Service offering
            </Heading>
          </Stack>
        )}
        {questionSet === 'lsp' && (
          <ProgressBar
            currentPage={currentPage + 1}
            totalPages={questions[questions.length - 1].page}
          />
        )}
        {getCurrentPageQuestions().map((question) => (
          <div key={question.id}>
            <div>{renderQuestion(question)}</div>
          </div>
        ))}
        {isLastPage && questionSet === 'lsp' && (
          <Stack
            sx={{
              width: { xs: '100%', md: 'unset' },
              maxWidth: { xs: '100%', md: '400px' },
            }}
          >
            <p>We have pre-populated your service profile on FLOX.</p>
            <p>
              Kindly complete your registration, finalise your service
              specifications, and start receiving enquiries from prospective
              clients!
            </p>
          </Stack>
        )}
        <div>
          {currentPage > 0 && (
            <Button
              variant='contained'
              color='primary'
              onClick={handlePreviousPage}
              style={{ marginRight: '16px' }}
            >
              Previous
            </Button>
          )}
          {isLastPage ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleFinish(answers)}
            >
              {questionSet === 'shipper'
                ? 'View results'
                : 'Create account at Flox'}
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              onClick={handleNextPage}
            >
              Next
            </Button>
          )}
        </div>
        {currentPage === 0 && <Typography>* is required</Typography>}
      </Stack>
    </Box>
  );
}
