import QRCode from 'qrcode';

interface QrCodeProps {
  link: string;
}

export function QrCode({ link }: QrCodeProps) {
  const canvas = document.getElementById('canvas');
  if (link && canvas) {
    QRCode.toCanvas(canvas, link);
  }

  return <canvas id='canvas' />;
}
