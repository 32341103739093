import { RegisterErrorData } from '@views/Registration/types';
import { AxiosError } from 'axios';

export function isAxiosRegisterError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
): error is AxiosError<RegisterErrorData> {
  return error.isAxiosError === true;
}

export const getLocalAccessToken = () =>
  localStorage.getItem('accessToken') as string;

export const setLocalAccessToken = (accessToken: string): void =>
  localStorage.setItem('accessToken', accessToken);

export const getLocalRefreshToken = () =>
  localStorage.getItem('refreshToken') as string;

export const setLocalRefreshToken = (refreshToken: string): void =>
  localStorage.setItem('refreshToken', refreshToken);

export const getLocalTokenExpiry = () =>
  localStorage.getItem('tokenExpiry') as string;

export const setLocalTokenExpiry = (tokenExpiry: string): void =>
  localStorage.setItem('tokenExpiry', tokenExpiry);

export const getIsTokenExpired = (now: Date, tokenExpiry: Date) => {
  if (!(now instanceof Date)) {
    throw new Error('parameter now must be of type Date');
  }

  if (!(tokenExpiry instanceof Date)) {
    throw new Error('parameter tokenExpiry must be of type Date');
  }

  const nowTime = new Date(now.toISOString()).getTime();

  const tokenExpiryTime = new Date(tokenExpiry.toISOString()).getTime();

  const secondsUntilExpiry = Math.floor((tokenExpiryTime - nowTime) / 1000);

  // 10 second threshold for token expiry
  return secondsUntilExpiry <= 10;
};
