import { ComponentPropsWithoutRef, ReactNode } from 'react';
import type * as Stitches from '@stitches/react';
import {
  StyledEmptyState,
  StyledEmptyStateTitle,
  StyledEmptyStateSubtitle,
} from './EmptyState.styles';

interface EmptyStateProps extends ComponentPropsWithoutRef<'div'> {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  primaryAction?: ReactNode;
  css?: Stitches.CSS;
}

export function EmptyState({
  icon,
  title,
  subtitle,
  primaryAction,
  css,
}: EmptyStateProps) {
  return (
    <StyledEmptyState css={css}>
      {icon && icon}
      <StyledEmptyStateTitle>{title}</StyledEmptyStateTitle>
      {subtitle && (
        <StyledEmptyStateSubtitle>{subtitle}</StyledEmptyStateSubtitle>
      )}
      {primaryAction && primaryAction}
    </StyledEmptyState>
  );
}

EmptyState.displayName = 'EmptyState';
