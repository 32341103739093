import { useEffect, useState } from 'react';
import { useAuthHttp } from '@hooks';
import { clearSupplyChain } from '@features/supplyChain';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ErrorIcon from '@mui/icons-material/Error';
import MenuItem from '@mui/material/MenuItem';
import { Text } from '@components/Text';
import { FilterService } from '../../services';
import { WarehouseClientOption } from '../../hooks/types';
import { fetchWarehouseClients } from '../../api';
import { useAppDispatch } from '../../store';

export function WarehouseClientsSelector() {
  const allWarehouseClientsOption: WarehouseClientOption = {
    userBusinessId: 'All',
    userBusinessName: 'All',
  };

  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const { getFilterValue, setFilterValue } = FilterService();

  const [isLoadingWarehouseClients, setIsLoadingWarehouseClients] =
    useState(false);

  const [isWarehouseClientsError, setIsWarehouseClientsError] = useState(false);

  const [warehouseClientsOptions, setWarehouseClientsOptions] = useState<
    WarehouseClientOption[]
  >([allWarehouseClientsOption]);

  const [selectedWarehouseClientId, setSelectedWarehouseClientId] = useState(
    getFilterValue('noticeboardWarehouseClientId')
  );

  const fetchWarehouseClientsList = async () => {
    let shippers: WarehouseClientOption[] = [];
    shippers = await fetchWarehouseClients(authHttp);

    setIsLoadingWarehouseClients(true);
    try {
      setWarehouseClientsOptions([allWarehouseClientsOption, ...shippers]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsWarehouseClientsError(true);
    } finally {
      setIsLoadingWarehouseClients(false);
    }
  };

  const handleChangeWarehouseClient = async (event: SelectChangeEvent) => {
    dispatch(clearSupplyChain());
    const matchingOption = warehouseClientsOptions.find(
      (option) => option.userBusinessId === event.target.value
    );
    setFilterValue('noticeboardWarehouseClientId', event.target.value);
    if (matchingOption) {
      setSelectedWarehouseClientId(matchingOption.userBusinessId);
    }
  };

  useEffect(() => {
    fetchWarehouseClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedOption = warehouseClientsOptions.find(
    (option) => option.userBusinessId === selectedWarehouseClientId
  );

  if (isLoadingWarehouseClients) {
    return (
      <Stack
        direction='row'
        gap={16}
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '14.25rem',
        }}
      >
        <CircularProgress size={24} sx={{ fill: 'var(--colors-gray12)' }} />
      </Stack>
    );
  }

  if (isWarehouseClientsError) {
    return (
      <Stack
        direction='row'
        gap={16}
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '14.25rem',
        }}
      >
        <ErrorIcon
          sx={{ width: 24, height: 24, fill: 'var(--colors-tomato9)' }}
        />
        <Text size='sm' css={{ color: 'var(--colors-tomato9)' }}>
          Error fetching warehouse clients
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction='row' gap={16} alignItems='center'>
      {/* <HomeWorkIcon sx={{ fill: 'var(--colors-gray11)' }} /> */}
      <Select
        id='shipper-select'
        value={selectedOption?.userBusinessId || 'All'}
        onChange={handleChangeWarehouseClient}
        size='small'
        fullWidth
        variant='outlined'
        sx={{
          color: 'var(--colors-gray12)',
          width: '14.25rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--colors-gray7)',
          },
          '& .MuiSelect-icon': {
            color: 'var(--colors-gray12)',
          },
          '& .MuiList-root': {
            backgroundColor: 'var(--colors-gray1)',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: 'var(--colors-gray1)',
            },
          },
        }}
      >
        {warehouseClientsOptions.map((warehouseClientOption) => (
          <MenuItem
            key={warehouseClientOption.userBusinessId}
            value={warehouseClientOption.userBusinessId}
            sx={{
              '&.MuiMenuItem-root': {
                backgroundColor: 'var(--colors-gray1)',
                color: 'var(--colors-gray12)',
              },
              '&.MuiMenuItem-root:hover': {
                backgroundColor: 'var(--colors-gray3)',
              },
              '&.MuiMenuItem-root.Mui-selected': {
                backgroundColor: 'var(--colors-gray4)',
                color: 'var(--colors-gray12)',
              },
            }}
          >
            {warehouseClientOption.userBusinessName === 'All'
              ? 'All shippers'
              : warehouseClientOption.userBusinessName}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
