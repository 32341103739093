import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';

interface ShipmentExceptionsFilterProps {
  selected: boolean;
  onChange: () => void;
}

export function ShipmentExceptionsFilter({
  selected,
  onChange,
}: ShipmentExceptionsFilterProps) {
  return (
    <FormControl>
      <ToggleButton
        size='small'
        value='Exceptions'
        selected={selected}
        onChange={onChange}
        sx={{
          py: 8,
          color: 'var(--colors-gray12)',
          textTransform: 'none',
        }}
        fullWidth
      >
        {`Exceptions: ${selected ? 'ON' : 'OFF'}`}
      </ToggleButton>
    </FormControl>
  );
}
