import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export function Multistop() {
  const { control, getValues } = useFormContext();

  const DISTANCE_UOM = [
    {
      label: 'miles',
      value: 'miles',
    },
    {
      label: 'km',
      value: 'km',
    },
  ];

  return (
    <>
      <Label
        htmlFor='currency'
        css={{ fontSize: '$textmd', fontWeight: 'bold' }}
      >
        Multistop Rates
      </Label>
      <Controller
        name='baseCharge'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Base charge *'
            placeholder='Base charge *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='includedDistance'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Included distance *'
            placeholder='Included distance *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='distanceUoM'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Distance UoM
        </Label>
        <Controller
          name='distanceUoM'
          control={control}
          render={({ field }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`distanceUoM`) ?? 'km'}
                onBlur={field.onBlur}
                placeholder='Distance UoM'
                css={{ width: '100%' }}
              >
                {DISTANCE_UOM.map((mileageUnit) => (
                  <SelectItem key={mileageUnit.label} value={mileageUnit.value}>
                    {`${mileageUnit.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='chargePerAdditionalDistanceUnit'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Charge per additional distance unit *'
            placeholder='Charge per additional distance unit *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='chargePerAdditionalStop'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Charge per additional stop *'
            placeholder='Charge per additional stop *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='chargePerAdditionalHour'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Charge per additional hour *'
            placeholder='Charge per additional hour *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='chargePerOvertimeHour'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Charge per overtime hour *'
            placeholder='Charge per overtime hour *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='standardIncludedLoadingMinutesPerDrop'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Standard included loading minutes per drop *'
            placeholder='Standard included loading minutes per drop *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='standardIncludedUnloadingMinutesPerDrop'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Standard included unloading minutes per drop *'
            placeholder='Standard included unloading minutes per drop *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
    </>
  );
}
