import { styled } from '@stitchesConfig';

export const StyledWarningMessage = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '$space8',
  padding: '$space16',
  backgroundColor: '$tomato3',
  borderRadius: '$radii8',
  $$borderWidth: '$sizes$size2',
  $$borderColor: '$colors$tomato11',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  color: '$tomato11',
  fontSize: '$textSm',
  fontWeight: '$medium',
  lineHeight: '$textSM',
});
