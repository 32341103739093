import { styled } from '@stitchesConfig';

export const StyledSKUSummaryCard = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '0.0625rem',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  padding: '$space16',
  borderRadius: '$radii8',
  display: 'flex',
  flexDirection: 'column',
  gap: '$space16',
  mb: '$space16',
});

StyledSKUSummaryCard.displayName = 'StyledSKUSummaryCard';

export const StyledSKUSummaryCardHeader = styled('div', {
  display: 'flex',
  gap: '$space16',
});

StyledSKUSummaryCardHeader.displayName = 'StyledSKUSummaryCardHeader';

export const StyledSKUSummaryCardHeaderImageContainer = styled('div', {});

StyledSKUSummaryCardHeaderImageContainer.displayName =
  'StyledSKUSummaryCardHeaderImageContainer';

export const StyledSKUSummaryCardHeaderImage = styled('img', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '0.0625rem',
  width: '$size96',
  minWidth: '$size96',
  borderRadius: '$radii8',
  boxShadow: '0 0 0 $$borderWidth $$borderColor, $shadow',
});

StyledSKUSummaryCardHeaderImage.displayName = 'StyledSKUSummaryCardHeaderImage';

export const StyledSKUSummaryCardHeaderTitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space4',
});

StyledSKUSummaryCardHeaderTitleContainer.displayName =
  'StyledSKUSummaryCardHeaderTitleContainer';

export const StyledSKUSummaryCardDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space8',
});

StyledSKUSummaryCardDetailsContainer.displayName =
  'StyledSKUSummaryCardDetailsContainer';

export const StyledSKUSummaryCardVolumeAndWeightContainer = styled('div', {
  display: 'flex',
  gap: '$space16',
});

StyledSKUSummaryCardVolumeAndWeightContainer.displayName =
  'StyledSKUSummaryCardVolumeAndWeightContainer';

export const StyledSKUSummaryCardButtonsContainer = styled('div', {
  display: 'flex',
  gap: '$space16',
});

StyledSKUSummaryCardButtonsContainer.displayName =
  'StyledSKUSummaryCardButtonsContainer';
