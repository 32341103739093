import { ComponentPropsWithoutRef } from 'react';
import { StyledThumbnailGrid, StyledThumbnail } from './Thumbnail.styles';

type ThumbnailGridProps = ComponentPropsWithoutRef<'div'>;

export function ThumbnailGrid({ children }: ThumbnailGridProps) {
  return <StyledThumbnailGrid>{children}</StyledThumbnailGrid>;
}

interface ThumbnailProps extends ComponentPropsWithoutRef<'div'> {
  src: string;
}

export function Thumbnail({ children, src }: ThumbnailProps) {
  return (
    <StyledThumbnail
      css={{
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        aspectRatio: 1,
      }}
    >
      {children}
    </StyledThumbnail>
  );
}
