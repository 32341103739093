import { ReactNode, Dispatch, SetStateAction } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

interface DialogModalProps {
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  onClose: (
    event: MouseEvent | KeyboardEvent,
    reason: 'escapeKeyDown' | 'backdropClick'
  ) => void;
  title: string;
  id: string;
  fullWidth?: boolean | undefined;
}

export function DialogModal({
  children,
  open,
  setOpen,
  onClose,
  title,
  id,
  fullWidth = true,
}: DialogModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={id}
      fullWidth={fullWidth}
      maxWidth='xl'
    >
      <DialogTitle
        id={id}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1rem 1.875rem',
          fontWeight: 'bold',
          backgroundColor: 'var(--colors-gray1)',
          color: 'var(--colors-gray12)',
        }}
      >
        {title}
        <IconButton
          aria-label='close'
          onClick={() => setOpen(false)}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: 'var(--colors-gray1)',
          color: 'var(--colors-gray12)',
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}
