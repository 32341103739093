export const getNoticeColors = (
  level: 'warning' | 'error'
): {
  backgroundColor: string;
  fill: string;
  color: string;
  // eslint-disable-next-line consistent-return
} => {
  // eslint-disable-next-line default-case
  switch (level) {
    case 'warning':
      return {
        backgroundColor: 'var(--colors-amber3)',
        fill: 'var(--colors-amber9)',
        color: 'var(--colors-amber11)',
      };
    case 'error':
      return {
        backgroundColor: 'var(--colors-tomato3)',
        fill: 'var(--colors-tomato9)',
        color: 'var(--colors-tomato11)',
      };
  }
};
