import { useEffect, useState } from 'react';
import { ToggleTheme } from '@components/ToggleTheme';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Input } from '@components/Input';
import { ErrorMessage } from '@components/ErrorMessage';
import { WarningMessage } from '@components/WarningMessage';
import { Formik, Field, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
  LOGIN_WITH_CREDENTIALS_URL,
} from '@constants';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import { AxiosResponse, AxiosError } from 'axios';
import { useAuthHttp } from '@hooks';
import { useAuth } from '@context';
import { LoginWith2FaForm } from '@forms/LoginWith2FAForm/LoginWith2FaForm';
import { DialogModal } from '@components/DialogModal';
import { Stack } from '@mui/material';
import { LoginValues, LoginResponse, LoginErrorResponse } from './types';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
  LoginForm,
  FormSwitchContainer,
} from './Login.styles';

export function Login() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const navigate = useNavigate();
  const { logIn, setUser, forgotPassword } = useAuth();
  const authHttp = useAuthHttp();
  const [showloginError, setShowLoginError] = useState(false);
  const [loginErrorText, setLoginErrorText] = useState('');
  const [searchParams] = useSearchParams();
  const [is2FaModalOpen, setIs2faModalOpen] = useState(false);
  const [twoFactorId, setTwoFactorId] = useState('');

  useDocumentTitle('FLOX - Log in');

  const redirect = searchParams.get('redirect') || '';

  const initialValues = {
    email: '',
    password: '',
  };

  const loginValidationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address.')
      .required('An email address is required.'),
    password: Yup.string().required('A password is required.'),
  });

  useEffect(() => {
    setIs2faModalOpen(!!twoFactorId);
  }, [twoFactorId]);

  const handleLoginResponse = (loginResponse: LoginResponse): void => {
    if (loginResponse.twoFactorId) {
      setTwoFactorId(loginResponse.twoFactorId);
    } else {
      setIs2faModalOpen(false);
      setUser(loginResponse.user);
      logIn(
        loginResponse.user,
        {
          accessToken: loginResponse.accessToken,
          refreshToken: loginResponse.refreshToken,
          tokenExpiry: loginResponse.tokenExpiry,
        },
        redirect || '/noticeboard'
      );
    }
  };

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, resetForm }: FormikHelpers<LoginValues>
  ): void => {
    setLoginErrorText('');
    setShowLoginError(false);

    authHttp
      .post(LOGIN_WITH_CREDENTIALS_URL, {
        email: values.email,
        password: values.password,
      })
      .then((res: AxiosResponse<LoginResponse>) => {
        handleLoginResponse(res.data);
      })
      .catch((err: AxiosError<LoginErrorResponse>) => {
        if (err?.response?.status === 401) {
          resetForm();
          setLoginErrorText('Email and/or password was not recognised.');
        } else {
          setSubmitting(false);
          setLoginErrorText('Something went wrong.');
        }
        setShowLoginError(true);
      });
  };

  return (
    <Container>
      <ImagePanel variant='login' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          <Logo
            // src={darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC}
            src={FLOX_LOGO_BLUE_SRC}
            alt='Flox logo'
          />
          <Heading
            as='h1'
            size={isTablet ? 'md' : 'sm'}
            css={{ textAlign: isTablet ? 'right' : 'center' }}
          >
            Log in
          </Heading>
        </LogoTitleContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={loginValidationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleReset,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
          }) => (
            <LoginForm>
              {showloginError && (
                <WarningMessage>{loginErrorText}</WarningMessage>
              )}
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='email' visuallyHidden>
                  Email
                </Label>
                <Field
                  as={Input}
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Enter your email...'
                  isInvalid={(errors.email && touched.email) as boolean}
                  onFocus={() => {
                    if (showloginError) {
                      setLoginErrorText('');
                      setShowLoginError(false);
                    }
                  }}
                  autoComplete='email'
                  disabled={isSubmitting}
                  css={{ mb: errors.email && touched.email && '$space8' }}
                />
                {errors.email && touched.email && (
                  <ErrorMessage>{errors.email}</ErrorMessage>
                )}
              </InputGroup>
              <InputGroup css={{ gap: '$space0' }}>
                <Label htmlFor='password' visuallyHidden>
                  Password
                </Label>
                <Field
                  as={Input}
                  type='password'
                  id='password'
                  name='password'
                  placeholder='Enter your password...'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldTouched('password');
                    setFieldValue('password', e.target.value);
                  }}
                  isInvalid={(errors.password && touched.password) as boolean}
                  autoComplete='password'
                  disabled={isSubmitting}
                />
                {errors.password && touched.password && (
                  <ErrorMessage css={{ mt: '$space8' }}>
                    {errors.password}
                  </ErrorMessage>
                )}
              </InputGroup>
              <Button
                type='submit'
                variant='primary'
                action='cta'
                size='lg'
                isLoading={isSubmitting}
                loadingIcon={<Icon name='loading' />}
                loadingText='Logging in...'
                isFullWidth
                disabled={!isValid || isSubmitting}
                css={{ mt: '$space16' }}
              >
                Log in
              </Button>
              <FormSwitchContainer>
                <p>No account?</p>
                <Button
                  variant='link'
                  size={isMobile ? 'md' : 'sm'}
                  onClick={() => {
                    handleReset();
                    navigate('/register');
                  }}
                >
                  Create an account
                </Button>
              </FormSwitchContainer>
              <Button
                variant='link'
                size={isMobile ? 'md' : 'sm'}
                onClick={() => forgotPassword()}
                css={{ mt: '$space16' }}
              >
                Forgotten password?
              </Button>
            </LoginForm>
          )}
        </Formik>
      </FormPanel>
      <DialogModal
        open={is2FaModalOpen}
        setOpen={setIs2faModalOpen}
        onClose={() => setIs2faModalOpen(false)}
        title='Login using 2 Factor Authentication'
        id='2fa-login-modal'
        fullWidth={false}
      >
        <LoginWith2FaForm
          twoFactorId={twoFactorId}
          handleLoginResponse={handleLoginResponse}
        />
      </DialogModal>
    </Container>
  );
}
