import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InboundProcess,
  OutboundProcess,
  PaginationQuery,
  OutboundProcessWithShipmentNote,
  OutboundShipmentsPaginationQuery,
  InboundShipmentsPaginationQuery,
} from './types';

interface SupplyChainState {
  supplyChain: {
    inboundProcesses: InboundProcess[];
    updatedInboundProcesses: string[];
    outboundProcesses: OutboundProcess[];
    updatedOutboundProcesses: string[];
    inboundCurrentQuery: PaginationQuery;
    outboundCurrentQuery: PaginationQuery;
    hasMoreInboundData: null | boolean;
    hasMoreOutboundData: null | boolean;
    selectedOutboundProcess: OutboundProcess | null;
    selectedInboundProcess: InboundProcess | null;
    selectedProcessChildType: string | null;
    inboundShipments: InboundProcess[];
    inboundShipmentsCurrentQuery: InboundShipmentsPaginationQuery;
    hasMoreInboundShipmentsData: null | boolean;
    outboundShipments: OutboundProcessWithShipmentNote[];
    outboundShipmentsCurrentQuery: OutboundShipmentsPaginationQuery;
    hasMoreOutboundShipmentsData: null | boolean;
  };
}

export const initialState: SupplyChainState = {
  supplyChain: {
    inboundProcesses: [],
    updatedInboundProcesses: [],
    outboundProcesses: [],
    updatedOutboundProcesses: [],
    inboundCurrentQuery: {
      searchQuery: null,
      type: null,
      pagination: {
        lastItem: null,
        itemsPerPage: null,
      },
    },
    outboundCurrentQuery: {
      searchQuery: null,
      type: null,
      pagination: {
        lastItem: null,
        itemsPerPage: null,
      },
    },
    hasMoreInboundData: null,
    hasMoreOutboundData: null,
    selectedOutboundProcess: null,
    selectedInboundProcess: null,
    selectedProcessChildType: null,
    inboundShipments: [],
    inboundShipmentsCurrentQuery: {
      searchQuery: null,
      type: 'Inbound',
      hasShipmentNote: true,
      pagination: {
        lastItem: null,
        itemsPerPage: null,
      },
    },
    hasMoreInboundShipmentsData: null,
    outboundShipments: [],
    outboundShipmentsCurrentQuery: {
      searchQuery: null,
      type: 'Outbound',
      hasShipmentNote: true,
      pagination: {
        lastItem: null,
        itemsPerPage: null,
      },
    },
    hasMoreOutboundShipmentsData: null,
  },
};

const supplyChainSlice = createSlice({
  name: 'supplyChain',
  initialState,
  reducers: {
    addInboundProcesses(
      state,
      action: PayloadAction<{
        supplyChainProcesses: InboundProcess[];
        currentQuery: PaginationQuery;
        hasMoreInboundData: null | boolean;
      }>
    ) {
      state.supplyChain.inboundProcesses.push(
        ...action.payload.supplyChainProcesses.filter(
          (supplyChainProcess) =>
            !state.supplyChain.updatedInboundProcesses.includes(
              supplyChainProcess.id
            )
        )
      );

      state.supplyChain.inboundCurrentQuery = action.payload.currentQuery;

      if (state.supplyChain.inboundCurrentQuery.pagination) {
        state.supplyChain.inboundCurrentQuery.pagination.lastItem =
          action.payload.supplyChainProcesses[
            action.payload.supplyChainProcesses.length - 1
          ];
      }

      state.supplyChain.hasMoreInboundData = action.payload.hasMoreInboundData;
    },
    addInboundShipments(
      state,
      action: PayloadAction<{
        supplyChainProcesses: InboundProcess[];
        currentQuery: InboundShipmentsPaginationQuery;
        hasMoreInboundShipmentsData: null | boolean;
      }>
    ) {
      state.supplyChain.inboundShipments = action.payload.supplyChainProcesses;

      state.supplyChain.inboundShipmentsCurrentQuery =
        action.payload.currentQuery;

      if (state.supplyChain.inboundShipmentsCurrentQuery.pagination) {
        state.supplyChain.inboundShipmentsCurrentQuery.pagination.lastItem =
          action.payload.supplyChainProcesses[
            action.payload.supplyChainProcesses.length - 1
          ];
      }

      state.supplyChain.hasMoreInboundShipmentsData =
        action.payload.hasMoreInboundShipmentsData;
    },
    upsertSingleInboundProcess(state, action: PayloadAction<InboundProcess>) {
      const exists =
        state.supplyChain.inboundProcesses.find(
          (inboundProcess) => inboundProcess.id === action.payload.id
        ) !== undefined;

      if (exists) {
        state.supplyChain.inboundProcesses =
          state.supplyChain.inboundProcesses.map((inboundProcess) => {
            if (inboundProcess.id === action.payload.id) {
              return action.payload;
            }
            return inboundProcess;
          });
      } else {
        state.supplyChain.inboundProcesses.unshift(action.payload);
      }

      if (
        !state.supplyChain.updatedInboundProcesses.includes(action.payload.id)
      ) {
        state.supplyChain.updatedInboundProcesses.push(action.payload.id);
      }
    },
    addOutboundProcesses(
      state,
      action: PayloadAction<{
        supplyChainProcesses: OutboundProcess[];
        currentQuery: PaginationQuery;
        hasMoreOutboundData: null | boolean;
      }>
    ) {
      state.supplyChain.outboundProcesses.push(
        ...action.payload.supplyChainProcesses.filter(
          (supplyChainProcess) =>
            !state.supplyChain.updatedOutboundProcesses.includes(
              supplyChainProcess.id
            )
        )
      );

      state.supplyChain.outboundCurrentQuery = action.payload.currentQuery;

      if (state.supplyChain.outboundCurrentQuery.pagination) {
        state.supplyChain.outboundCurrentQuery.pagination.lastItem =
          action.payload.supplyChainProcesses[
            action.payload.supplyChainProcesses.length - 1
          ];
      }

      state.supplyChain.hasMoreOutboundData =
        action.payload.hasMoreOutboundData;
    },
    addOutboundShipments(
      state,
      action: PayloadAction<{
        supplyChainProcesses: OutboundProcessWithShipmentNote[];
        currentQuery: OutboundShipmentsPaginationQuery;
        hasMoreOutboundShipmentsData: null | boolean;
      }>
    ) {
      state.supplyChain.outboundShipments = action.payload.supplyChainProcesses;

      state.supplyChain.outboundShipmentsCurrentQuery =
        action.payload.currentQuery;

      if (state.supplyChain.outboundShipmentsCurrentQuery.pagination) {
        state.supplyChain.outboundShipmentsCurrentQuery.pagination.lastItem =
          action.payload.supplyChainProcesses[
            action.payload.supplyChainProcesses.length - 1
          ];
      }

      state.supplyChain.hasMoreOutboundShipmentsData =
        action.payload.hasMoreOutboundShipmentsData;
    },
    upsertSingleOutboundProcess(state, action: PayloadAction<OutboundProcess>) {
      const exists =
        state.supplyChain.outboundProcesses.find(
          (outboundProcess) => outboundProcess.id === action.payload.id
        ) !== undefined;

      if (exists) {
        state.supplyChain.outboundProcesses =
          state.supplyChain.outboundProcesses.map((outboundProcess) => {
            if (outboundProcess.id === action.payload.id) {
              return action.payload;
            }
            return outboundProcess;
          });
      } else {
        state.supplyChain.outboundProcesses.unshift(action.payload);
      }

      if (
        !state.supplyChain.updatedOutboundProcesses.includes(action.payload.id)
      ) {
        state.supplyChain.updatedOutboundProcesses.push(action.payload.id);
      }
    },
    clearSupplyChain() {
      return initialState;
    },
    setSelectedOutboundProcess(
      state,
      action: PayloadAction<OutboundProcess | null>
    ) {
      state.supplyChain.selectedOutboundProcess = action.payload;
    },
    setSelectedInboundProcess(
      state,
      action: PayloadAction<InboundProcess | null>
    ) {
      state.supplyChain.selectedInboundProcess = action.payload;
    },
    setSelectedProcessChildType(state, action: PayloadAction<string | null>) {
      state.supplyChain.selectedProcessChildType = action.payload;
    },
  },
});

export const {
  addInboundProcesses,
  addInboundShipments,
  upsertSingleInboundProcess,
  addOutboundProcesses,
  addOutboundShipments,
  upsertSingleOutboundProcess,
  clearSupplyChain,
  setSelectedOutboundProcess,
  setSelectedInboundProcess,
  setSelectedProcessChildType,
} = supplyChainSlice.actions;
export default supplyChainSlice.reducer;
