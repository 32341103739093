import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Stack } from '@mui/material';
import { Heading } from '@components/Heading';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UPDATE_BANK_ACCOUNT_CONSENT } from '../../constants';
import { useAuthHttp } from '../../hooks';

export function UpdateFinancialInformation() {
  const authHttp = useAuthHttp();
  const [isUpdatingBankAccountConsent, setIsUpdatingBankAccountConsent] =
    useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleUpdateBankAccountConsent = async () => {
    try {
      const { data } = await authHttp.post(
        UPDATE_BANK_ACCOUNT_CONSENT,
        {
          oneTimeToken: searchParams.get('one-time-token'),
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data.businessProfileWithFinancialData) {
        navigate('/profile');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdatingBankAccountConsent(false);
    }
  };

  useEffect(() => {
    handleUpdateBankAccountConsent();
  }, []);

  if (isUpdatingBankAccountConsent) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Stack alignItems='center' spacing={16}>
          <Heading as='h3' size='xs'>
            Authorising bank account consent...
          </Heading>
          <CircularProgress size={80} />
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <Heading as='h3' size='xs'>
        Failed to update bank account consent. Please try again.
      </Heading>
    </Box>
  );
}
