import { useAuth } from '@context';
import { Outlet, useSearchParams } from 'react-router-dom';
import { NavigationItemList } from '@components/Navigation/types';
import { useEnableWebSockets, UserRoleService } from '@services';
import {
  clientNavigation,
  managerNavigation,
} from '@views/RequireAuth/navigationItems';
import { Navigation } from '@components/Navigation';
import { useSetAtom } from 'jotai';
import { isPublicPageAtom } from '../../store/jotai';

export function PublicPage() {
  const setIsPublicPage = useSetAtom(isPublicPageAtom);
  const { isWarehouseManager } = UserRoleService();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  useEnableWebSockets();

  const role = searchParams.get('role') || '';

  const buildNavigationItems = (): NavigationItemList =>
    isWarehouseManager(user) || role === 'logistics-provider'
      ? managerNavigation
      : clientNavigation;

  setIsPublicPage(true);

  return (
    <Navigation items={buildNavigationItems()}>
      <Outlet />
    </Navigation>
  );
}
