import { styled } from '@stitchesConfig';

export const StyledHeading = styled('h1', {
  fontFamily: '$primary',

  variants: {
    size: {
      xs: {
        fontSize: '$headingXs',
        lineHeight: '$headingXs',
      },
      sm: {
        fontSize: '$headingSm',
        lineHeight: '$headingSm',
      },
      md: {
        fontSize: '$headingMd',
        lineHeight: '$headingMd',
      },
      lg: {
        fontSize: '$headingLg',
        lineHeight: '$headingLg',
      },
      xl: {
        fontSize: '$headingXl',
        lineHeight: '$headingXl',
      },
      '2xl': {
        fontSize: '$heading2xl',
        lineHeight: '$heading2xl',
      },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      medium: {
        fontWeight: '$medium',
      },
      semibold: {
        fontWeight: '$semibold',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
  },
});
