import { styled } from '@stitchesConfig';
import * as Progress from '@radix-ui/react-progress';

export const StyledFormProgress = styled('div', {
  // padding: '$space16',
  backgroundColor: '$gray1',
  borderLeft: '$sizes$size1 solid $colors$gray7',
  borderRight: '$sizes$size1 solid $colors$gray7',
});

StyledFormProgress.displayName = 'StyledFormProgress';

export const StyledFormProgressStep = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 3fr 1fr',
  padding: '$space10 $space16',
});

export const StyledProgressRoot = styled(Progress.Root, {
  width: '100%',
  backgroundColor: '$gray3',
});

StyledProgressRoot.displayName = 'StyledProgressRoot';

export const StyledProgressIndicator = styled(Progress.Indicator, {
  backgroundColor: '$gray8',
  height: '$size4',
});

StyledProgressIndicator.displayName = 'StyledProgressIndicator';
