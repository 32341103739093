import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { Text } from '@components/Text';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { FLEET_IMAGES_URL } from '@constants';
import { Stack } from '@mui/material';
import {
  StyledRatecardList,
  StyledRatecardCard,
  StyledRatecardCardImageContainer,
  StyledRatecardCardInfo,
  StyledRatecardLoading,
  StyledRatecardLoadingIndicator,
} from './RatecardList.styles';

interface RatecardListProps extends ComponentPropsWithoutRef<'div'> {
  id?: string;
}

export type RatecardType =
  | 'Postcode'
  | 'Day Rate'
  | 'Multistop'
  | 'Additional FTL';

export const RatecardList = forwardRef<HTMLDivElement, RatecardListProps>(
  ({ children, id = 'ratecard-list' }, forwardedRef) => {
    return (
      <StyledRatecardList id={id} ref={forwardedRef}>
        {children}
      </StyledRatecardList>
    );
  }
);

RatecardList.displayName = 'RatecardList';

interface RatecardCardProps extends ComponentPropsWithoutRef<'div'> {
  type: RatecardType;
  baseAddress?: string;
  active?: boolean;
  orientation: 'vertical' | 'horizontal';
  onClick: () => void;
}

export const Ratecard = forwardRef<HTMLDivElement, RatecardCardProps>(
  (
    { type, baseAddress, active, orientation, onClick, ...props },
    forwardedRef
  ) => {
    const isLargeDesktop = useMediaQuery('(min-width: 75em)');
    const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
    const RatecardImagePlaceholder = `/images/${type.replace(/\s+/g, '_')}.svg`;

    return (
      <StyledRatecardCard
        orientation={orientation}
        active={active}
        onClick={onClick}
        {...props}
        ref={forwardedRef}
      >
        <StyledRatecardCardImageContainer
          css={{
            backgroundImage: `url(${RatecardImagePlaceholder})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
            margin: '0 10px',
          }}
        />
        <StyledRatecardCardInfo orientation={orientation} active={active}>
          {/* <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Vehicle Type:{' '}
            </Text>
            {RatecardMapping[vehicleSummary.vehicleType as Ratecard] ||
              vehicleSummary.vehicleType}
          </Text> */}
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Pricing type:{' '}
            </Text>
            {type}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Transport base:{' '}
            </Text>
            {baseAddress}
            {/* {type} */}
          </Text>
        </StyledRatecardCardInfo>
      </StyledRatecardCard>
    );
  }
);

Ratecard.displayName = 'Ratecard';

export function RatecardsLoading() {
  return (
    <StyledRatecardLoading>
      <StyledRatecardLoadingIndicator />
      <Text
        size='xl'
        weight='bold'
        css={{ color: 'inherit', textAlign: 'center' }}
      >
        Fetching Ratecards...
      </Text>
    </StyledRatecardLoading>
  );
}

RatecardsLoading.displayName = 'RatecardsLoading';
