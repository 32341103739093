import { styled } from '@stitchesConfig';

export const StyledInput = styled('input', {
  appearance: 'none',
  width: '100%',
  padding: '$space12 $space16',
  border: 'none',
  fontSize: '$textMd',
  fontWeight: '$regular',
  lineHeight: '$textMd',
  color: '$gray12',
  borderRadius: '$radii8',
  backgroundColor: '$gray1',
  $$borderColor: '$colors$gray7',
  $$borderColorHover: '$colors$gray8',
  $$borderColorInvalid: '$colors$tomato9',
  $$borderWidth: '$sizes$size1',
  $$borderWidthInvalid: '$sizes$size2',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  transition:
    'background-color 150ms ease-in-out, box-shadow 150ms ease-in-out',

  '&::placeholder': {
    color: '$gray11',
  },

  '&:focus': {
    outlineColor: 'transparent',
  },

  '&:focus-visible': {
    outline: '0.125rem solid $gray12',
    outlineOffset: '0.125rem',
  },

  '&:disabled': {
    opacity: '0.5',
  },

  '&:hover:not(:disabled)': {
    boxShadow: 'inset 0 0 0 $$borderWidth $$borderColorHover',
  },

  variants: {
    isInvalid: {
      true: {
        boxShadow: 'inset 0 0 0 $$borderWidthInvalid $$borderColorInvalid',

        '&:hover:not(:disabled)': {
          boxShadow: 'inset 0 0 0 $$borderWidthInvalid $$borderColorInvalid',
        },
      },
    },
  },
});

StyledInput.displayName = 'StyledInput';
