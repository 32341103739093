import { styled } from '@stitchesConfig';
import * as Label from '@radix-ui/react-label';

export const StyledLabel = styled(Label.Root, {
  fontSize: '$textMd',
  fontWeight: '$medium',
  color: '$gray12',
});

StyledLabel.displayName = 'StyledLabel';
