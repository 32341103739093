import { OutboundProcess } from '@features/supplyChain/types';
import * as Yup from 'yup';
import { OUTBOUND_SHIPMENT_TYPE } from '@constants';
import dayjs from 'dayjs';

export const validationSchema = Yup.object({
  shipmentNoteNumber: Yup.string().required(
    'A shipment note number is required.'
  ),
  supplyChainProcessId: Yup.string().required(
    'A supply chain process ID is required.'
  ),
  shipmentDate: Yup.string().required('A shipment date is required.'),
  shipmentType: Yup.string().required('A shipment type is required.'),
  comment: Yup.string(),
  estimatedTimeOfArrival: Yup.string().required('An ETA is required.'),
  warehouseId: Yup.string().required('A warehouse ID is required.'),
  skus: Yup.array().of(
    Yup.object({
      productId: Yup.string().required('A product ID is required'),
      skuId: Yup.string().required('A SKU ID is required'),
      skuIdentifier: Yup.string().required('A SKU identifier is required'),
      productName: Yup.string().required('A product name is required'),
      name: Yup.string().required('A SKU name is required'),
      pickedQuantity: Yup.number().required(
        'A despatched quantity is required'
      ),
      despatchedQuantity: Yup.number()
        .typeError('A SKU quantity is required.')
        .required('A despatched quantity is required'),
      discrepancyReason: Yup.string()

        .nullable()
        .when(['pickedQuantity', 'despatchedQuantity'], {
          is: (pickedQuantity: number, despatchedQuantity: number) =>
            despatchedQuantity !== pickedQuantity,
          then: () =>
            Yup.string().required('A discrepancy reason is required.'),
        }),
    })
  ),
});

export const makeDefaultValues = (selectedOutboundProcess: OutboundProcess) => {
  if (!selectedOutboundProcess) {
    return {
      shipmentNoteNumber: '',
      supplyChainProcessId: '',
      shipmentType: '',
      customerName: '',
      supplierName: '',
      comment: '',
      estimatedTimeOfArrival: '',
      warehouseId: '',
      skus: [],
      shipmentDate: '',
    };
  }

  const { despatchNote, pickingConfirmation } = selectedOutboundProcess;
  return {
    shipmentNoteNumber: despatchNote.despatchNoteNumber,
    supplyChainProcessId: selectedOutboundProcess.id,
    shipmentType: OUTBOUND_SHIPMENT_TYPE,
    customerName: despatchNote.customerName,
    supplierName: selectedOutboundProcess.userBusinessName,
    comment: '',
    estimatedTimeOfArrival: despatchNote.requiredTimeOfArrival,
    warehouseId: despatchNote?.warehouse?.id,
    skus: pickingConfirmation?.skus.map((sku) => ({
      productId: sku.productId,
      skuId: sku.skuId,
      skuIdentifier: sku.skuIdentifier,
      productName: sku.productName,
      name: sku.name,
      pickedQuantity: sku.pickedQuantity,
      despatchedQuantity: sku.pickedQuantity,
      discrepancyReason: '',
      packagingDetails: despatchNote.skus.find((s) => s.skuId === sku.skuId)
        ?.packagingDetails,
    })),
    shipmentDate: dayjs().format(),
  };
};
