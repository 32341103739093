import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { Text } from '@components/Text';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { FLEET_IMAGES_URL } from '@constants';
import { Stack } from '@mui/material';
import { VehicleSummary } from '@views/FleetMaster/types';
import {
  StyledFleetList,
  StyledFleetCard,
  StyledFleetCardImageContainer,
  StyledFleetCardInfo,
  StyledFleetLoading,
  StyledFleetLoadingIndicator,
} from './FleetList.styles';

interface FleetListProps extends ComponentPropsWithoutRef<'div'> {
  id?: string;
}

type Fleet =
  | 'VanLessThan1AndHalft'
  | 'LgvLessThan3AndHalft'
  | 'TwoAxleRigidLessThan7AndHalf5t'
  | 'TwoAxleRigid18t'
  | 'TwoAxleRigidLessThan26t'
  | 'MultiAxleArticMoreThan26t'
  | 'SpecialOrOther';

export const FleetList = forwardRef<HTMLDivElement, FleetListProps>(
  ({ children, id = 'fleet-list' }, forwardedRef) => {
    return (
      <StyledFleetList id={id} ref={forwardedRef}>
        {children}
      </StyledFleetList>
    );
  }
);

FleetList.displayName = 'FleetList';

interface FleetCardProps extends ComponentPropsWithoutRef<'div'> {
  vehicleSummary: VehicleSummary;
  orientation: 'vertical' | 'horizontal';
  onClick: () => void;
}

const fleetImagePlaceholder = `/images/vehicle_placeholder.png`;

export const FleetCard = forwardRef<HTMLDivElement, FleetCardProps>(
  ({ vehicleSummary, orientation, onClick, ...props }, forwardedRef) => {
    const isLargeDesktop = useMediaQuery('(min-width: 75em)');
    const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
    const fleetMapping = {
      VanLessThan1AndHalft: 'Van < 1.5t',
      LgvLessThan3AndHalft: 'LDVs < 3.5t',
      TwoAxleRigidLessThan7AndHalf5t: 'Rigids < 7.5t',
      TwoAxleRigid18t: 'Rigids 18t',
      TwoAxleRigidLessThan26t: 'Rigids < 26t',
      MultiAxleArticMoreThan26t: 'Artics > 26t',
      SpecialOrOther: 'Special/Other',
    };
    return (
      <StyledFleetCard
        orientation={orientation}
        onClick={onClick}
        {...props}
        ref={forwardedRef}
      >
        <StyledFleetCardImageContainer
          css={{
            backgroundImage: `url(${
              vehicleSummary.images && vehicleSummary.images.length
                ? `${FLEET_IMAGES_URL}/${vehicleSummary.images[0].imageLocation}/small/${vehicleSummary.images[0].remoteName}`
                : fleetImagePlaceholder
            })`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
            margin: '0 10px',
          }}
        />
        <StyledFleetCardInfo orientation={orientation}>
          {/* <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Vehicle Type:{' '}
            </Text>
            {fleetMapping[vehicleSummary.vehicleType as Fleet] ||
              vehicleSummary.vehicleType}
          </Text> */}
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Model:{' '}
            </Text>
            {vehicleSummary.model}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Registration Number:{' '}
            </Text>
            {vehicleSummary.registrationNumber}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Vehicle capacity:{' '}
            </Text>
            <Stack gap={6} direction='column'>
              <div>{vehicleSummary?.vehicleCapacity?.maxPallets} pallets</div>
              <div>{vehicleSummary?.vehicleCapacity?.maxKilograms} kg</div>
              <div>
                Width:{' '}
                {vehicleSummary?.vehicleCapacity?.maxCapacityDimensions?.width}{' '}
                {
                  vehicleSummary?.vehicleCapacity.maxCapacityDimensions
                    ?.dimensionsUnitOfMeasure
                }
              </div>
              <div>
                Height:{' '}
                {vehicleSummary?.vehicleCapacity?.maxCapacityDimensions?.height}{' '}
                {
                  vehicleSummary?.vehicleCapacity.maxCapacityDimensions
                    ?.dimensionsUnitOfMeasure
                }
              </div>
              <div>
                Length:{' '}
                {vehicleSummary?.vehicleCapacity?.maxCapacityDimensions?.length}{' '}
                {
                  vehicleSummary?.vehicleCapacity.maxCapacityDimensions
                    ?.dimensionsUnitOfMeasure
                }
              </div>
            </Stack>
          </Text>
        </StyledFleetCardInfo>
      </StyledFleetCard>
    );
  }
);

FleetCard.displayName = 'FleetCard';

export function FleetLoading() {
  return (
    <StyledFleetLoading>
      <StyledFleetLoadingIndicator />
      <Text
        size='xl'
        weight='bold'
        css={{ color: 'inherit', textAlign: 'center' }}
      >
        Fetching Fleet details...
      </Text>
    </StyledFleetLoading>
  );
}

FleetLoading.displayName = 'FleetLoading';
