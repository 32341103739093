import { ReactNode } from 'react';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

interface ShipmentFiltersContainerProps {
  children: ReactNode;
  isDesktop: boolean;
  isOpen: boolean;
}

export function ShipmentFiltersContainer({
  children,
  isDesktop,
  isOpen,
}: ShipmentFiltersContainerProps) {
  return isDesktop ? (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gap: '1rem',
        mb: isDesktop ? '1rem' : 0,
      }}
    >
      {children}
    </Box>
  ) : (
    <Collapse in={isOpen}>
      <Stack gap={16}>{children}</Stack>
    </Collapse>
  );
}
