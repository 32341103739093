import { useState } from 'react';
import { CreateInboundShipmentNoteFormValues } from '@forms/client/CreateInboundShipmentNoteForm/types';
import {
  CREATE_INBOUND_SHIPMENT_NOTE_URL,
  INBOUND_SHIPMENT_TYPE,
} from '@constants';
import { useAppDispatch } from '@store';
import { upsertSingleInboundProcess } from '@features/supplyChain';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { InboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';

export const useInboundShipmentNote = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const [
    isCreateInboundShipmentNoteFormOpen,
    setIsCreateInboundShipmentNoteFormOpen,
  ] = useState(false);

  const handleCloseCreateInboundShipmentNoteForm = useCloseDialog(
    setIsCreateInboundShipmentNoteFormOpen
  );

  const [supplyChainProcessResult, setSupplyChainProcessResult] =
    useState<InboundProcess | null>(null);

  const [isShipmentNoteSuccessOpen, setIsShipmentNoteSuccessOpen] =
    useState(false);

  const handleCloseShipmentNoteSuccess = useCloseDialog(
    setIsShipmentNoteSuccessOpen
  );

  const handleSubmitInboundShipmentNote = async (
    values: CreateInboundShipmentNoteFormValues
  ) => {
    try {
      const formValues = {
        shipmentNote: {
          shipmentNoteNumber: values.shipmentNoteNumber,
          shipmentType: INBOUND_SHIPMENT_TYPE,
          supplierName: values.supplierName,
          supplierID: values.supplierId,
          skus: values.skus.map((sku) => {
            return {
              palletising: {
                palletisingId: sku.palletisingId,
              },
              skuId: sku.skuId,
              productId: sku.productId,
              skuQuantity: sku.skuQuantity,
            };
          }),
          comment: values.comment,
          estimatedTimeOfArrival: values.estimatedTimeOfArrival,
          warehouseId: values.warehouseId,
          sourceLocationId: crypto.randomUUID(),
          shipmentDate: dayjs(),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(CREATE_INBOUND_SHIPMENT_NOTE_URL, formValues);

      dispatch(upsertSingleInboundProcess(supplyChainProcess));
      setIsCreateInboundShipmentNoteFormOpen(false);
      setSupplyChainProcessResult(supplyChainProcess);
      setIsShipmentNoteSuccessOpen(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isCreateInboundShipmentNoteFormOpen,
    setIsCreateInboundShipmentNoteFormOpen,
    handleCloseCreateInboundShipmentNoteForm,
    handleSubmitInboundShipmentNote,
    supplyChainProcessResult,
    isShipmentNoteSuccessOpen,
    setIsShipmentNoteSuccessOpen,
    handleCloseShipmentNoteSuccess,
  };
};
