import * as DialogPrimitive from '@radix-ui/react-dialog';
import type * as Stitches from '@stitches/react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { MdClose } from 'react-icons/md';
import {
  StyledModalOverlay,
  StyledModalContainer,
  StyledModalHeader,
  StyledModalHeaderTitle,
  StyledModalCloseButton,
  StyledModalContent,
  StyledModalFooter,
} from './Modal.styles';

export const Modal = DialogPrimitive.Root;
export const ModalTrigger = DialogPrimitive.Trigger;
export const ModalClose = DialogPrimitive.Close;

interface ModalContainerProps
  extends ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  preventCloseOnOutsideClick?: boolean;
}

export const ModalContainer = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ModalContainerProps
>(
  (
    { children, preventCloseOnOutsideClick = false, ...props },
    forwardedRef
  ) => (
    <DialogPrimitive.Portal>
      <StyledModalOverlay />
      <StyledModalContainer
        {...(preventCloseOnOutsideClick && {
          onPointerDownOutside: (e) => e.preventDefault(),
        })}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </StyledModalContainer>
    </DialogPrimitive.Portal>
  )
);

ModalContainer.displayName = 'ModalContainer';

interface ModalHeaderProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
}

export function ModalHeader({ title }: ModalHeaderProps) {
  return (
    <StyledModalHeader>
      <StyledModalHeaderTitle>{title}</StyledModalHeaderTitle>
      <ModalClose asChild>
        <StyledModalCloseButton>
          <MdClose size='1.5rem' />
        </StyledModalCloseButton>
      </ModalClose>
    </StyledModalHeader>
  );
}

ModalHeader.displayName = 'ModalHeader';

interface ModalContentProps extends ComponentPropsWithoutRef<'div'> {
  css?: Stitches.CSS;
}

export function ModalContent({ children, css, id }: ModalContentProps) {
  return (
    <StyledModalContent css={css} id={id}>
      {children}
    </StyledModalContent>
  );
}

ModalContent.displayName = 'ModalContent';

type ModalFooterProps = ComponentPropsWithoutRef<'div'>;

export function ModalFooter({ children }: ModalFooterProps) {
  return <StyledModalFooter>{children}</StyledModalFooter>;
}

ModalFooter.displayName = 'ModalFooter';
