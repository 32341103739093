import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { VisuallyHidden } from '@components/VisuallyHidden';
import { StyledLabel } from './Label.styles';

interface LabelProps extends ComponentPropsWithoutRef<'label'> {
  htmlFor: string;
  visuallyHidden?: boolean;
  css?: Stitches.CSS;
}

export function Label({
  children,
  htmlFor,
  visuallyHidden = false,
  css,
  ...props
}: LabelProps) {
  return visuallyHidden ? (
    <VisuallyHidden asChild>
      <StyledLabel htmlFor={htmlFor} css={css} {...props}>
        {children}
      </StyledLabel>
    </VisuallyHidden>
  ) : (
    <StyledLabel htmlFor={htmlFor} css={css} {...props}>
      {children}
    </StyledLabel>
  );
}

Label.displayName = 'Label';
