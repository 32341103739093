import { useState } from 'react';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { AuthoriseForPickingFormValues } from '@forms/manager/AuthoriseForPickingForm/types';
import { AUTHORISE_FOR_PICKING } from '@constants';
import { upsertSingleOutboundProcess } from '@features/supplyChain/supplyChainSlice';
import { useAppDispatch } from '@store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useAuthoriseForPicking = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const [isAuthoriseForPickingFormOpen, setIsAuthoriseForPickingFormOpen] =
    useState(false);

  const handleCloseAuthoriseForPickingForm = useCloseDialog(
    setIsAuthoriseForPickingFormOpen
  );

  const handleSubmitAuthoriseForPickingForm = async (
    values: AuthoriseForPickingFormValues
  ) => {
    try {
      const formValues = {
        despatchNote: {
          supplyChainProcessId: values.supplyChainProcessId,
          despatchNoteId: values.despatchNoteId,
          requiredPickingDate: dayjs(values.pickingDateConfirmation)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(AUTHORISE_FOR_PICKING, formValues);
      dispatch(upsertSingleOutboundProcess(supplyChainProcess));
      setIsAuthoriseForPickingFormOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isAuthoriseForPickingFormOpen,
    setIsAuthoriseForPickingFormOpen,
    handleCloseAuthoriseForPickingForm,
    handleSubmitAuthoriseForPickingForm,
  };
};
