import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { StyledText } from './Text.styles';

interface TextProps extends ComponentPropsWithoutRef<'p'> {
  as?: keyof JSX.IntrinsicElements;
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  weight?: 'regular' | 'medium' | 'semibold' | 'bold';
  css?: Stitches.CSS;
}

export function Text({
  children,
  as = 'p',
  size = 'md',
  weight = 'regular',
  css,
  ...props
}: TextProps) {
  return (
    <StyledText as={as} size={size} weight={weight} css={css} {...props}>
      {children}
    </StyledText>
  );
}

Text.displayName = 'Text';
