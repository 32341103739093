import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { store } from '../../store';

interface RequestQueueState {
  requestQueue: Array<() => Promise<AxiosResponse>>;
  isRequestInProgress: boolean;
}

const initialState: RequestQueueState = {
  requestQueue: [],
  isRequestInProgress: false,
};
const requestQueueSlice = createSlice({
  name: 'requestQueue',
  initialState,
  reducers: {
    addRequestToQueue(state, action: PayloadAction<() => Promise<any>>) {
      state.requestQueue.push(action.payload);
    },
    removeRequestFromQueue(state) {
      state.requestQueue.shift();
    },
    setRequestInProgress(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.isRequestInProgress = action.payload;
    },
  },
});

export const {
  addRequestToQueue,
  removeRequestFromQueue,
  setRequestInProgress,
} = requestQueueSlice.actions;

export const getState = () => store.getState();
export const selectRequestQueue = (state: {
  requestQueue: RequestQueueState;
}) => state.requestQueue.requestQueue;
export const selectIsRequestInProgress = (state: {
  requestQueue: RequestQueueState;
}) => state.requestQueue.isRequestInProgress;

export default requestQueueSlice.reducer;
