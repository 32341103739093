import { styled, keyframes } from '@stitchesConfig';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const StyledIconContainer = styled('span', {
  display: 'inline-flex',
  transformOrigin: 'center',

  variants: {
    isLoading: {
      true: {
        animation: `${spin} 512ms linear infinite`,
      },
    },
  },
});

StyledIconContainer.displayName = 'StyledIconContainer';
