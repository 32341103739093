import { useState, useEffect } from 'react';
import { useAuthHttp } from '@hooks';
import { AVAILABLE_WAREHOUSES_URL } from '@constants';
import { AvailableWarehouse } from '../forms/client/CreateInboundShipmentNoteForm/types';

export const useAvailableWarehouses = () => {
  const authHttp = useAuthHttp();
  const [availableWarehousesQuery, setAvailableWarehousesQuery] = useState('');
  const [openAvailableWarehouseOptions, setOpenAvailableWarehouseOptions] =
    useState(false);
  const [availableWarehouseOptions, setAvailableWarehouseOptions] = useState<
    AvailableWarehouse[]
  >([]);
  const [loadingAvailableWarehouses, setLoadingAvailableWarehouses] =
    useState(false);
  const [singleContractedWarehouse, setSingleContractedWarehouse] =
    useState<AvailableWarehouse | null>(null);

  useEffect(() => {
    let active = true;

    const getAvailableWarehouses = async () => {
      setLoadingAvailableWarehouses(true);
      try {
        if (active) {
          const {
            data: { matches },
          } = await authHttp.post(AVAILABLE_WAREHOUSES_URL, {
            pagination: {
              lastItem: null,
              itemsPerPage: 25,
            },
          });
          setAvailableWarehouseOptions(matches);
          if (matches.length === 1) {
            setSingleContractedWarehouse(matches[0]);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        if (active) {
          setLoadingAvailableWarehouses(false);
        }
      }
    };

    getAvailableWarehouses();

    return () => {
      active = false;
    };
  }, [openAvailableWarehouseOptions]);

  useEffect(() => {
    if (!openAvailableWarehouseOptions) {
      setAvailableWarehouseOptions([]);
    }
  }, [openAvailableWarehouseOptions]);

  return {
    availableWarehouseOptions,
    loadingAvailableWarehouses,
    openAvailableWarehouseOptions,
    setOpenAvailableWarehouseOptions,
    availableWarehousesQuery,
    setAvailableWarehousesQuery,
    singleContractedWarehouse,
  };
};
