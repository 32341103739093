import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoardList } from '@components/Board';

interface SupplyChainProcessEventListProps
  extends ComponentPropsWithoutRef<'div'> {
  id: 'inbound-list' | 'outbound-list';
}

const SupplyChainProcessEventList = forwardRef<
  HTMLDivElement,
  SupplyChainProcessEventListProps
>(({ children, id }, forwardedRef) => {
  return (
    <BoardList id={id} ref={forwardedRef}>
      {children}
    </BoardList>
  );
});

SupplyChainProcessEventList.displayName = 'SupplyChainProcessEventList';

type InboundSupplyChainProcessEventListProps = ComponentPropsWithoutRef<'div'>;

export const InboundSupplyChainProcessEventList = forwardRef<
  HTMLDivElement,
  InboundSupplyChainProcessEventListProps
>(({ children }, forwardedRef) => {
  return (
    <SupplyChainProcessEventList id='inbound-list' ref={forwardedRef}>
      {children}
    </SupplyChainProcessEventList>
  );
});

InboundSupplyChainProcessEventList.displayName =
  'InboundSupplyChainProcessEventList';

type OutboundSupplyChainProcessEventListProps = ComponentPropsWithoutRef<'div'>;

export const OutboundSupplyChainProcessEventList = forwardRef<
  HTMLDivElement,
  OutboundSupplyChainProcessEventListProps
>(({ children }, forwardedRef) => {
  return (
    <SupplyChainProcessEventList id='outbound-list' ref={forwardedRef}>
      {children}
    </SupplyChainProcessEventList>
  );
});

OutboundSupplyChainProcessEventList.displayName =
  'OutboundSupplyChainProcessEventList';
