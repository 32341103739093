import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { StyledIconContainer } from './Icon.styles';

const iconSizes = {
  24: '1.5rem',
};

const iconPaths = {
  chevron_left: 'M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z',
  email:
    'M4 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 2 18V6c0-.55.196-1.02.588-1.412A1.923 1.923 0 0 1 4 4h16c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 20 20H4Zm8-7L4 8v10h16V8l-8 5Zm0-2 8-5H4l8 5ZM4 8V6v12V8Z',
  loading:
    'M12.05 20C9.81667 20 7.91667 19.225 6.35 17.675C4.78333 16.125 4 14.2333 4 12V11.825L2.4 13.425L1 12.025L5 8.025L9 12.025L7.6 13.425L6 11.825V12C6 13.6667 6.58767 15.0833 7.763 16.25C8.93767 17.4167 10.3667 18 12.05 18C12.4833 18 12.9083 17.95 13.325 17.85C13.7417 17.75 14.15 17.6 14.55 17.4L16.05 18.9C15.4167 19.2667 14.7667 19.5417 14.1 19.725C13.4333 19.9083 12.75 20 12.05 20ZM19 15.975L15 11.975L16.4 10.575L18 12.175V12C18 10.3333 17.4127 8.91667 16.238 7.75C15.0627 6.58333 13.6333 6 11.95 6C11.5167 6 11.0917 6.05 10.675 6.15C10.2583 6.25 9.85 6.4 9.45 6.6L7.95 5.1C8.58333 4.73333 9.23333 4.45833 9.9 4.275C10.5667 4.09167 11.25 4 11.95 4C14.1833 4 16.0833 4.775 17.65 6.325C19.2167 7.875 20 9.76667 20 12V12.175L21.6 10.575L23 11.975L19 15.975Z',
  light_mode:
    'M11.09 4.727V2h1.82v2.727h-1.82Zm0 17.273v-2.727h1.82V22h-1.82Zm8.183-9.09v-1.82H22v1.82h-2.727ZM2 12.91v-1.82h2.727v1.82H2Zm16.09-5.728-1.272-1.273 1.591-1.636 1.318 1.318-1.636 1.59ZM5.59 19.727 4.274 18.41l1.636-1.59 1.273 1.272-1.591 1.636Zm12.82 0-1.592-1.636 1.273-1.273 1.636 1.591-1.318 1.318ZM5.91 7.182 4.272 5.59 5.59 4.273l1.59 1.636L5.91 7.182ZM12 17.454c-1.515 0-2.803-.53-3.864-1.59-1.06-1.061-1.59-2.349-1.59-3.864 0-1.515.53-2.803 1.59-3.864 1.061-1.06 2.349-1.59 3.864-1.59 1.515 0 2.803.53 3.864 1.59 1.06 1.061 1.59 2.349 1.59 3.864 0 1.515-.53 2.803-1.59 3.864-1.061 1.06-2.349 1.59-3.864 1.59Zm0-1.818c1.015 0 1.875-.352 2.58-1.057.704-.704 1.056-1.564 1.056-2.579 0-1.015-.352-1.875-1.056-2.58-.705-.704-1.565-1.056-2.58-1.056-1.015 0-1.875.352-2.58 1.056-.704.705-1.056 1.565-1.056 2.58 0 1.015.352 1.875 1.057 2.58.704.704 1.564 1.056 2.579 1.056Z',
  dark_mode:
    'M12 22c-2.778 0-5.139-.972-7.083-2.917C2.972 17.14 2 14.778 2 12c0-2.778.972-5.139 2.917-7.083C6.86 2.972 9.222 2 12 2c.26 0 .514.01.764.028.25.018.495.046.736.083a5.876 5.876 0 0 0-1.819 2.097A5.898 5.898 0 0 0 11 7c0 1.667.583 3.083 1.75 4.25C13.917 12.417 15.333 13 17 13a5.87 5.87 0 0 0 2.806-.681 5.908 5.908 0 0 0 2.083-1.819A10.368 10.368 0 0 1 22 12c0 2.778-.972 5.139-2.917 7.083C17.14 21.028 14.778 22 12 22Zm0-2.222c1.63 0 3.093-.45 4.389-1.347a7.795 7.795 0 0 0 2.833-3.514c-.37.092-.74.166-1.11.222-.371.055-.742.083-1.112.083-2.278 0-4.218-.8-5.82-2.402C9.579 11.218 8.778 9.278 8.778 7c0-.37.028-.74.083-1.111.056-.37.13-.74.222-1.111A7.789 7.789 0 0 0 5.57 7.61C4.671 8.907 4.222 10.371 4.222 12c0 2.148.76 3.982 2.278 5.5 1.519 1.518 3.352 2.278 5.5 2.278Z',
};

interface IconProps extends ComponentPropsWithoutRef<'svg'> {
  name: keyof typeof iconPaths;
  size?: keyof typeof iconSizes;
  color?: string;
}

export function Icon({
  name,
  size = 24,
  color = 'currentColor',
  ...props
}: IconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ minWidth: `${iconSizes[size]}`, height: `${iconSizes[size]}` }}
      data-icon-name={name}
      aria-hidden
      {...props}
    >
      <path fill={color} d={iconPaths[name]} />
    </svg>
  );
}

Icon.displayName = 'Icon';

interface IconContainerProps extends ComponentPropsWithoutRef<'span'> {
  isLoading?: boolean;
  css?: Stitches.CSS;
}

export function IconContainer({
  children,
  isLoading = false,
  css,
  ...props
}: IconContainerProps) {
  return (
    <StyledIconContainer isLoading={isLoading} css={css} aria-hidden {...props}>
      {children}
    </StyledIconContainer>
  );
}

IconContainer.displayName = 'IconContainer';
