import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useAppDispatch } from '@store';
import { upsertSingleInventoryItem, upsertSkus } from '@features/inventory';
import {
  setSelectedInboundProcess,
  setSelectedOutboundProcess,
  upsertSingleInboundProcess,
  upsertSingleOutboundProcess,
} from '@features/supplyChain/supplyChainSlice';
import { ProductSummary } from '@views/Noticeboard/types';
import { InboundProcess, OutboundProcess } from '@features/supplyChain/types';
import { InventorySKUListing } from '@views/StockBySKU/components/types';
import {
  useInboundProcess,
  useOutboundProcess,
  useRefreshAuthTokens,
} from '@hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Match } from '@views/WarehouseDetails/WarehouseDetails';
import { NotificationType } from '@components/Notification/Notification';
import {
  isInboundSidebarOpenAtom,
  isOutboundSidebarOpenAtom,
  matchAtom,
  notificationsAtom,
  selectedInboundProcessIdAtom,
  selectedOutboundProcessIdAtom,
  showNotificationAtom,
} from '../store/jotai';
import {
  getLocalAccessToken,
  getIsTokenExpired,
  getLocalTokenExpiry,
} from '../utils';

export const useEnableWebSockets = () => {
  // console.log('useEnableWebSockets');
  const dispatch = useAppDispatch();
  // const { refreshAuthTokens } = useRefreshAuthTokens();

  const isInboundSidebarOpen = useAtomValue(isInboundSidebarOpenAtom);
  const isOutboundSidebarOpen = useAtomValue(isOutboundSidebarOpenAtom);
  const selectedInboundProcessId = useAtomValue(selectedInboundProcessIdAtom);
  const selectedOutboundProcessId = useAtomValue(selectedOutboundProcessIdAtom);

  const setMatch = useSetAtom(matchAtom);
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const setShowNotification = useSetAtom(showNotificationAtom);

  const connection = new HubConnectionBuilder()
    .withUrl('/signals/updates', {
      accessTokenFactory: async () => {
        const token = getLocalAccessToken();
        if (token) {
          const tokenHasExpired = getIsTokenExpired(
            new Date(),
            new Date(getLocalTokenExpiry())
          );

          // if (tokenHasExpired) {
          //   await refreshAuthTokens();
          // }
        }
        return getLocalAccessToken();
      },
    })
    .configureLogging(LogLevel.Warning)
    .build();

  async function start() {
    // console.log('starting connection');
    try {
      await connection.start();

      connection
        .invoke('JoinGroup')
        .then(() => {
          // console.log('joing group');
          // Notifications
          connection.on('ReceiveNotificationId', (id: any) => {
            connection
              .invoke('GetNotification', id)
              .then((response: NotificationType) => {
                // console.log(response);
                // console.log('ws notifs', notifications);
                setNotifications((prevNotifications) => {
                  if (
                    prevNotifications.some((notif) => notif.id === response.id)
                  ) {
                    return prevNotifications;
                  }
                  return [response, ...prevNotifications];
                });
                if (!response.isSilent) setShowNotification(true);
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          });
          // Inventory
          connection.on(
            'ReceiveInventoryProductId',
            (id: ProductSummary['id']) => {
              connection
                .invoke('GetProduct', id)
                .then((response: ProductSummary) => {
                  if (response !== null) {
                    dispatch(upsertSingleInventoryItem({ product: response }));
                  }
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });

              connection
                .invoke('GetSkus', id)
                .then((response: InventorySKUListing[]) => {
                  dispatch(upsertSkus(response));
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });
            }
          );
          // Supply Chain
          connection.on(
            'ReceiveSupplyChainProcessId',
            (id: InboundProcess['id'] | OutboundProcess['id']) => {
              connection
                .invoke('GetSupplyChainProcess', id)
                .then((response: InboundProcess | OutboundProcess) => {
                  // dont forget to match the id with the selected process id in the side panel
                  if (response.type === 'Inbound') {
                    dispatch(upsertSingleInboundProcess(response));
                    if (
                      isInboundSidebarOpen &&
                      selectedInboundProcessId === id
                    ) {
                      dispatch(setSelectedInboundProcess(response));
                    }
                  }
                  if (response.type === 'Outbound') {
                    dispatch(upsertSingleOutboundProcess(response));
                    if (
                      isOutboundSidebarOpen &&
                      selectedOutboundProcessId === id
                    ) {
                      dispatch(setSelectedOutboundProcess(response));
                    }
                  }
                })
                .catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });
            }
          );
          // Handshake
          connection.on('ReceiveWarehouseMatchId', (id: string) => {
            connection
              .invoke('GetWarehouseMatch', id)
              .then((response: Match) => {
                if (response !== null) {
                  setMatch(response);
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      setTimeout(start, 1500);
    }
  }

  connection.onclose(async () => {
    console.log('closed connection');
    await start();
  });

  // Start the connection.
  start();

  const markNotificationAsRead = async (id: string) => {
    if (connection) {
      try {
        await connection.invoke('MarkNotificationAsRead', id);
      } catch (err) {
        console.error('Mark notification as read failed: ', err);
      }
    }
  };

  const markNotificationAsArchived = async (id: string) => {
    if (connection) {
      try {
        await connection.invoke('MarkNotificationAsArchived', id);
      } catch (err) {
        console.error('Mark notification as archived failed: ', err);
      }
    }
  };

  return { markNotificationAsRead, markNotificationAsArchived };
};
