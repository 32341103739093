import { useState } from 'react';
import { Button } from '@components/Button';
import { FormProgress } from '@components/FormProgress';
import { ModalContent, ModalFooter } from '@components/Modal';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { DevTool } from '@hookform/devtools';
import {
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdDelete,
} from 'react-icons/md';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@components/Icon';
import { StyledAddProductForm } from './AddProductForm.styles';
import { StepOne, StepTwo, StepThree } from './steps';
import { AddProductFormValues, Product } from './types';
import { useValidSteps } from './hooks';

interface AddProductFormProps {
  selectedProduct?: Omit<Product, 'imagesJson' | 'costPricesJson' | 'skusJson'>;
  onClose: () => void;
  onSubmit: (values: AddProductFormValues) => Promise<void>;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export function AddProductForm({
  selectedProduct,
  onClose,
  onSubmit,
  isSmall,
  isTablet,
  isDesktop,
}: AddProductFormProps) {
  const [currentStep, setCurrentStep] = useState(0);

  const validationSchema = Yup.object<AddProductFormValues>({
    product: Yup.object({
      skus: Yup.array().of(
        Yup.object({
          inventorySkuId: Yup.string().nullable(),
          skuId: Yup.string()
            .required('A SKU id is required.')
            .min(3, 'SKU id must be at least 3 characters long')
            .max(25, 'SKU id must be 25 characters or less'),
          unitOfMeasure: Yup.string().required(
            'A unit of measure is required.'
          ),
          amountOfProductInSku: Yup.number()
            .typeError('Amount of product in SKU is required.')
            .required('Amount of product in SKU is required.')
            .positive('Amount of product must be more than 0'),
          dimensions: Yup.object({
            length: Yup.number()
              .typeError('SKU length is required.')
              .required('SKU length is required.')
              .positive('Length must be more than 0'),
            width: Yup.number()
              .typeError('SKU length is required.')
              .required('SKU width is required.')
              .positive('Width must be more than 0'),
            height: Yup.number()
              .typeError('SKU length is required.')
              .required('SKU height is required.')
              .positive('Height must be more than 0'),
          }),
          grossWeight: Yup.number()
            .typeError('Gross weight is required.')
            .required('Gross weight is required.')
            .positive('Gross weight must be more than 0'),
          listPrices: Yup.array().of(
            Yup.object({
              amount: Yup.number()
                .typeError('Amount is required.')
                .required('Amount is required.')
                .positive('Amount must be more than 0'),
              currency: Yup.string().required('Currency is required.'),
            })
          ),
          purchasePrices: Yup.array().of(
            Yup.object({
              amount: Yup.number()
                .typeError('Amount is required.')
                .required('Amount is required.')
                .positive('Amount must be more than 0'),
              currency: Yup.string().required('Currency is required.'),
            })
          ),
          palletising: Yup.array().of(
            Yup.object({
              type: Yup.string().required('Pallet type is required.'),
              amountOfSkusInLayer: Yup.number()
                .typeError('Number of SKUs in layer is required.')
                .required('Number of SKUs in layer is required.')
                .positive('Number of SKUs must be more than 0'),
              amountOfLayersInPallet: Yup.number()
                .typeError('Number of layers in pallet is required.')
                .required('Number of layers in pallet is required.')
                .positive('Number of layers must be more than 0'),
            })
          ),
        })
      ),
      productId: Yup.string().required('A product id is required.'),
      name: Yup.string().required('A product name is required.'),
      netVolume: Yup.number()
        .typeError('Net volume is required.')
        .required('Net volume is required.')
        .positive('Net volume must be a positive number'),
      costPrices: Yup.array().of(
        Yup.object({
          amount: Yup.number()
            .typeError('Amount is required.')
            .required('Amount is required.')
            .positive('Must be a positive number'),
          currency: Yup.string().required('Currency is required.'),
        })
      ),
    }),
  });

  const methods = useForm<AddProductFormValues>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      product: {
        images: selectedProduct?.images || [],
        costPrices: selectedProduct?.costPrices || [],
        skus: selectedProduct?.skus || [],
        productId: selectedProduct?.productId || '',
        name: selectedProduct?.name || '',
        brand: selectedProduct?.brand || '',
        category: selectedProduct?.category || '',
        subCategory: selectedProduct?.subCategory || '',
        description: selectedProduct?.description || '',
        netVolume: selectedProduct?.netVolume || null,
        netVolumeUnitOfMeasure:
          selectedProduct?.netVolumeUnitOfMeasure || 'cm3',
      },
      productImages: [],
      skuImages: [],
      deletedProductImageIds: [],
      deletedSkuImageIds: [],
    },
    resolver: yupResolver(validationSchema as any),
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    getFieldState,
    setFocus,
    watch,
    formState: { errors, touchedFields, isSubmitting },
  } = methods;

  const [stepOneValid, stepTwoValid, stepThreeValid] = useValidSteps({
    errors,
    watch,
  });

  const {
    fields: costPricesFields,
    append: appendCostPrice,
    remove: removeCostPrice,
  } = useFieldArray({
    control,
    name: `product.costPrices`,
  });

  const {
    fields: skusFields,
    append: appendSku,
    remove: removeSku,
  } = useFieldArray({
    control,
    name: 'product.skus',
  });

  const steps = [
    <StepOne
      key={0}
      watch={watch}
      register={register}
      errors={errors}
      touchedFields={touchedFields}
      getValues={getValues}
      setValue={setValue}
      setFocus={setFocus}
      fields={costPricesFields}
      append={appendCostPrice}
      remove={removeCostPrice}
      control={control}
      isSmall={isSmall}
      isTablet={isTablet}
      isDesktop={isDesktop}
    />,
    <StepTwo
      key={1}
      watch={watch}
      register={register}
      errors={errors}
      touchedFields={touchedFields}
      getValues={getValues}
      setValue={setValue}
      setFocus={setFocus}
      getFieldState={getFieldState}
      skusFields={skusFields}
      appendSku={appendSku}
      removeSku={removeSku}
      control={control}
      isSmall={isSmall}
      isTablet={isTablet}
      isDesktop={isDesktop}
    />,
    <StepThree
      key={2}
      register={register}
      control={control}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      setFocus={setFocus}
      getFieldState={getFieldState}
      isSmall={isSmall}
      isTablet={isTablet}
      isDesktop={isDesktop}
    />,
  ];

  const stepNames: { [key: number]: string } = {
    0: 'Product',
    1: 'SKU',
    2: 'Palletising',
  };

  const [currentStepName, setCurrentStepName] = useState('Product');
  const [previousStepName, setPreviousStepName] = useState<string | null>(null);

  const handleNextStep = () => {
    if (currentStep === 0) {
      setCurrentStepName(stepNames[1]);
      setPreviousStepName(stepNames[0]);
    }

    if (currentStep === 1) {
      setCurrentStepName(stepNames[2]);
      setPreviousStepName(stepNames[1]);
    }

    if (currentStep === 2) {
      return;
    }

    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    if (currentStep === 2) {
      setCurrentStepName(stepNames[1]);
      setPreviousStepName(stepNames[0]);
    }

    if (currentStep === 1) {
      setCurrentStepName(stepNames[0]);
      setPreviousStepName(null);
    }

    setCurrentStep((prev) => prev - 1);
  };

  const nextStep = currentStep + 1;

  return (
    <>
      <FormProgress
        totalSteps={3}
        currentStep={currentStep + 1}
        currentStepName={currentStepName}
        {...(currentStep > 0 &&
          previousStepName !== null && {
            previousStepName,
          })}
        nextStepName={stepNames[nextStep]}
      />
      <StyledAddProductForm onSubmit={handleSubmit(onSubmit)}>
        <ModalContent
          css={{
            maxHeight: 'calc(85vh - 12.5rem)',
            minHeight: 'calc(85vh - 12.5rem)',
          }}
        >
          {steps[currentStep]}
        </ModalContent>
        <ModalFooter>
          <Button
            variant='secondary'
            size={isTablet ? 'md' : 'sm'}
            onClick={onClose}
          >
            Close
          </Button>
          {/* {selectedProduct && (
            <Button
              size={isTablet ? 'md' : 'sm'}
              leftIcon={<MdDelete size='1.5rem' />}
              action='danger'
            >
              Delete product
            </Button>
          )} */}
          <Button
            leftIcon={<MdChevronLeft size='1.5rem' />}
            size={isTablet ? 'md' : 'sm'}
            onClick={handlePrevStep}
            disabled={currentStep === 0}
          >
            Prev
          </Button>
          {currentStep < 2 && (
            <Button
              type='button'
              leftIcon={
                <MdChevronRight size={isTablet ? '1.25rem' : '1.5rem'} />
              }
              size={isTablet ? 'md' : 'sm'}
              onClick={handleNextStep}
              disabled={
                (currentStep === 0 && !stepOneValid) ||
                (currentStep === 1 && !stepTwoValid)
              }
            >
              Next
            </Button>
          )}
          {currentStep === 2 && (
            <Button
              type='submit'
              rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
              isLoading={isSubmitting}
              loadingIcon={<Icon name='loading' />}
              loadingText='Saving...'
              size={isTablet ? 'md' : 'sm'}
              action='cta'
              disabled={!stepThreeValid}
            >
              {selectedProduct ? 'Save' : 'Submit'}
            </Button>
          )}
        </ModalFooter>
      </StyledAddProductForm>
      {/* <DevTool control={control} /> */}
    </>
  );
}
