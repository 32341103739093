import { Heading } from '@components/Heading';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToggleTheme } from '@components/ToggleTheme';
import { useAuthHttp } from '@hooks';
import {
  CONFIRM_EMAIL_URL,
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
} from '@constants';
import { WarningMessage } from '@components/WarningMessage';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
} from '@views/Login/Login.styles';
import { useEffect, useState } from 'react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';

export function ConfirmEmail() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const navigate = useNavigate();
  const authHttp = useAuthHttp();

  const [searchParams] = useSearchParams();

  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function confirmEmail() {
      if (!searchParams.keys) return;
      setIsLoading(true);
      try {
        const { status } = await authHttp.post(CONFIRM_EMAIL_URL, {
          token: searchParams.get('token'),
          email: searchParams.get('email'),
        });

        if (status === 200) setEmailConfirmed(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      } finally {
        setIsLoading(false);
      }
    }
    confirmEmail();
  }, []);

  useDocumentTitle('FLOX - Confirm Email');

  return (
    <Container>
      <ImagePanel variant='login' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          {isLoading ? (
            <Stack
              direction='row'
              gap={16}
              justifyContent='center'
              alignItems='center'
              sx={{
                width: '14.25rem',
              }}
            >
              <CircularProgress
                size={24}
                sx={{ fill: 'var(--colors-gray12)' }}
              />
            </Stack>
          ) : (
            <>
              <Logo
                src={
                  darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC
                }
                alt='Flox logo'
              />
              <Heading
                as='h1'
                size={isTablet ? 'md' : 'sm'}
                css={{ textAlign: isTablet ? 'right' : 'center' }}
              >
                {emailConfirmed
                  ? 'Your registration is complete!'
                  : 'Something went wrong'}
              </Heading>
              {emailConfirmed && (
                <>
                  <Typography
                    variant='body1'
                    textAlign={isTablet ? 'right' : 'center'}
                  >
                    Thank you for confirming your email. You can navigate back
                    to Flox by clicking the button below
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={() => navigate('/Noticeboard')}
                  >
                    Back to Flox
                  </Button>
                </>
              )}
            </>
          )}
        </LogoTitleContainer>
      </FormPanel>
    </Container>
  );
}
