import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const STORAGE_CAPABILITIES = [
  'Bulk Powder',
  'Bulk Liquid',
  'Hazardous Chemicals',
  'Hazardous Other',
  'High Value',
  'LME',
  'Wet Bonded',
  'Dry Bonded',
  'Container',
  'Outdoor',
  'RF Technology',
];

const MATERIAL_HANDLING_EQUIPMENT = [
  'Counter Balance',
  'Crane',
  'Reach Truck',
  'Trailer Parking',
];

const CAPACITY_UOM = [
  {
    label: 'ft',
    value: 'Foot',
  },
  {
    label: 'm',
    value: 'Metre',
  },
];

export function Facility() {
  const { control, getValues, setValue, watch } = useFormContext();
  const watchIsRackingAvailable = watch('isRackingAvailable');
  const watchStructuralUoM = watch('structuralUoM');

  const handleStorageCapabilitiesCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const storageCapabilities = getValues('storageCapabilities');
    if (checked) {
      setValue('storageCapabilities', [...storageCapabilities, value]);
    } else {
      const filteredAnswers = (storageCapabilities as string[]).filter(
        (answer) => answer !== value
      );

      setValue('storageCapabilities', filteredAnswers);
    }
  };

  const handleMaterialHandlingEquipmentCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, value } = event.target;
    const materialHandlingEquipment = getValues('materialHandlingEquipment');
    if (checked) {
      setValue('materialHandlingEquipment', [
        ...materialHandlingEquipment,
        value,
      ]);
    } else {
      const filteredAnswers = (materialHandlingEquipment as string[]).filter(
        (answer) => answer !== value
      );

      setValue('materialHandlingEquipment', filteredAnswers);
    }
  };

  useEffect(() => {
    if (watchIsRackingAvailable) return;
    setValue('individualRackHeight', null);
    setValue('individualRackWidth', null);
    setValue('individualRackDepth', null);
    setValue('totalRackHeight', null);
  }, [watchIsRackingAvailable]);

  return (
    <Stack spacing={16}>
      <Heading as='h2' size='sm'>
        Facility
      </Heading>
      <Heading as='h3' size='xs'>
        Structural
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='structuralUoM'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Unit of Measure
        </Label>
        <Controller
          name='structuralUoM'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`structuralUoM`)}
                onBlur={field.onBlur}
                placeholder='Please select'
                css={{ width: '25%' }}
                isInvalid={Boolean(error)}
              >
                {CAPACITY_UOM.map((capacityUoM) => (
                  <SelectItem key={capacityUoM.label} value={capacityUoM.value}>
                    {`${capacityUoM.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='constructionYear'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Construction year'
            placeholder='Construction year'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='minEavesHeightFeet'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Min eaves height (${
              watchStructuralUoM === 'Foot' ? 'Feet' : 'Metres'
            })`}
            placeholder={`Min eaves height (${
              watchStructuralUoM === 'Foot' ? 'Feet' : 'Metres'
            })`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='grossInternalAreaSquareFeet'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Gross internal area (${
              watchStructuralUoM === 'Foot' ? 'Square Feet' : 'Square Metres'
            })`}
            placeholder={`Gross internal area (${
              watchStructuralUoM === 'Foot' ? 'Square Feet' : 'Square Metres'
            })`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='mezzanineAreaSquareFeet'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Mezzanine area (${
              watchStructuralUoM === 'Foot' ? 'Square Feet' : 'Square Metres'
            })`}
            placeholder={`Mezzanine area (${
              watchStructuralUoM === 'Foot' ? 'Square Feet' : 'Square Metres'
            })`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Heading as='h3' size='xs'>
        Racking
      </Heading>
      <InputGroup
        direction='row'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='isRackingAvailable'
          control={control}
          render={({ field }) => {
            return (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(
                        event: ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        setValue('isRackingAvailable', checked, {
                          shouldValidate: true,
                        });
                      }}
                      name={field.name}
                    />
                  }
                  label='Is racking available?'
                />
              </FormGroup>
            );
          }}
        />
      </InputGroup>
      {watchIsRackingAvailable && (
        <>
          <Controller
            name='individualRackHeight'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='Individual rack height (mm) *'
                placeholder='Individual rack height (mm) *'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='individualRackWidth'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='Individual rack width (mm) *'
                placeholder='Individual rack width (mm) *'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='individualRackDepth'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='Individual rack depth (mm) *'
                placeholder='Individual rack depth (mm) *'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
          <Controller
            name='totalRackHeight'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={Boolean(error)}
                helperText={Boolean(error) && error?.message}
                variant='outlined'
                label='Total rack height (pallet racks) *'
                placeholder='Total rack height (pallet racks) *'
                sx={{
                  width: '100%',
                }}
              />
            )}
          />
        </>
      )}
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space2',
        }}
      >
        <Controller
          name='storageCapabilities'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Label
                  htmlFor='storageCapabilities'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                    color: error ? '#d32f2f' : 'initial',
                  }}
                >
                  Special capabilities
                </Label>
                {Boolean(error) && (
                  <Typography color='error' fontSize='0.75rem' paddingTop={4}>
                    {error?.message}
                  </Typography>
                )}
                <FormGroup>
                  {STORAGE_CAPABILITIES.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleStorageCapabilitiesCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='materialHandlingEquipment'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Label
                  htmlFor='materialHandlingEquipment'
                  css={{
                    fontSize: '$textmd',
                    fontWeight: 'bold',
                    color: error ? '#d32f2f' : 'initial',
                  }}
                >
                  Material handling equipment
                </Label>
                {Boolean(error) && (
                  <Typography color='error' fontSize='0.75rem' paddingTop={4}>
                    {error?.message}
                  </Typography>
                )}
                <FormGroup>
                  {MATERIAL_HANDLING_EQUIPMENT.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={
                            handleMaterialHandlingEquipmentCheckboxChange
                          }
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
    </Stack>
  );
}
