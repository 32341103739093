import { Product, SelectedProduct } from '@forms/client/AddProductForm/types';

export const mapSelectedProductToProduct = (
  selectedProduct: SelectedProduct
): Omit<Product, 'imagesJson' | 'costPricesJson' | 'skusJson'> => {
  return {
    images: selectedProduct.product.images,
    costPrices: selectedProduct.product.costPrices,
    skus: selectedProduct.skus.map((sku) => ({
      inventorySkuId: sku.id,
      skuId: sku.skuId,
      name: sku.name,
      description: sku.description,
      amountOfProductInSku: sku.amountOfProductInSku,
      productImageId: sku.images[0] ? sku.images[0].id : null,
      images: sku.images,
      dimensions: sku.dimensions,
      grossWeight: sku.grossWeight,
      grossWeightUnitOfMeasure: sku.grossWeightUnitOfMeasure,
      listPrices: sku.listPrices,
      purchasePrices: sku.purchasePrices,
      palletising: sku.palletising,
      unitOfMeasure: sku.unitOfMeasure,
    })),
    id: selectedProduct.product.id,
    userId: selectedProduct.product.userId,
    createdAt: selectedProduct.product.createdAt,
    modifiedAt: selectedProduct.product.modifiedAt,
    deletedAt: selectedProduct.product.deletedAt,
    productId: selectedProduct.product.productId,
    name: selectedProduct.product.name,
    brand: selectedProduct.product.brand,
    category: selectedProduct.product.category,
    subCategory: selectedProduct.product.subCategory,
    description: selectedProduct.product.description,
    netVolume: selectedProduct.product.netVolume,
    netVolumeUnitOfMeasure: selectedProduct.product.netVolumeUnitOfMeasure,
  };
};
