import { View } from '@views/View';
import { Fragment, useEffect, useState } from 'react';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { Stack, Typography } from '@mui/material';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { useAtom } from 'jotai';
import { MdAdd, MdOutlineInfo } from 'react-icons/md';
import { useMediaQuery } from 'usehooks-ts';
import {
  CustomerForm,
  CustomerFormValues,
} from '@forms/AddCustomerForm/CustomerForm';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { StyledCustomerHeaderContainer } from './CustomerMasterStyles';
import { isCustomerModalOpenAtom } from '../../store/jotai';
import { useAuthHttp } from '../../hooks';
import { CUSTOMER_CREATE, CUSTOMER_GET } from '../../constants';

export type Customer = {
  customerId: string;
  name: string;
  type: string;
  contactName: string;
  contactEmail: string;
  hqId: string;
  hqName: string;
  address: string;
  country: string;
  postcode: string;
  operatingHoursFrom: string;
  operatingHoursTo: string;
  notes: string;
  id: string;
};

export function CustomerMaster() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isCustomersLoading, setIsCustomersLoading] = useState(true);
  const authHttp = useAuthHttp();
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useAtom(
    isCustomerModalOpenAtom
  );

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const fetchCustomers = async () => {
    try {
      const { data } = await authHttp.post(CUSTOMER_GET, {
        pagination: {
          itemsPerPage: isDesktop ? 100 : 25,
        },
      });

      if (data) setCustomers(data.customers);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsCustomersLoading(false);
      setIsAddCustomerModalOpen(false);
    }
  };

  const handleOnSubmit = async (data: CustomerFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: createCustomerData } = await authHttp.post(
        CUSTOMER_CREATE,
        {
          customer: {
            ...data,
            operatingHoursFrom: `${data.operatingHoursFrom}:00`,
            operatingHoursTo: `${data.operatingHoursTo}:00`,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (createCustomerData) {
        fetchCustomers();
        // setIsAddCustomerModalOpen(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const groupByHqName = (customerList: Customer[]) => {
    const grouped = customerList.reduce((groupedCustomers, customer) => {
      const key = customer.hqName;
      const groupedCustomersCopy = { ...groupedCustomers };
      if (!groupedCustomersCopy[key]) {
        groupedCustomersCopy[key] = [];
      }
      groupedCustomersCopy[key].push(customer);
      return groupedCustomersCopy;
    }, {} as Record<string, Customer[]>);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return Object.entries(grouped).map(([hqName, customers]) => ({
      hqName,
      customers,
    }));
  };

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <StyledCustomerHeaderContainer>
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
            Customer master
          </Heading>
          <Stack sx={{ flexDirection: isDesktop ? 'row' : 'column' }} gap={12}>
            {/* <Button
              variant='primary'
              action='cta'
              size={isDesktop ? 'md' : 'sm'}
              leftIcon={<MdAdd size='1.5rem' />}
              //   onClick={() => setIsUploadFleetModalOpen(true)}
            >
              Upload Customer Master
            </Button> */}
            <Button
              variant='primary'
              action='cta'
              size={isDesktop ? 'md' : 'sm'}
              leftIcon={<MdAdd size='1.5rem' />}
              onClick={() => setIsAddCustomerModalOpen(true)}
            >
              Add Customer
            </Button>
          </Stack>
        </StyledCustomerHeaderContainer>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                <th
                  scope='col'
                  className='sticky py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Customer ID
                </th>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Customer Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Customer Type
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Contact Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Contact Email
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  HQ ID
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  HQ Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Address
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Postcode
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Country
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Operating Hours From
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Operating Hours To
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Notes
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
                  <span className='sr-only'>Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isCustomersLoading ? (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='customer-loader'
                      loadingMessage='Loading customers...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              ) : (
                groupByHqName(customers).map((group) => (
                  <Fragment key={group.hqName}>
                    <tr className='border-t border-gray-200'>
                      <th
                        colSpan={5}
                        scope='colgroup'
                        className='bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                      >
                        {group.hqName}
                      </th>
                    </tr>
                    {group.customers.map((customer) => (
                      <tr
                        key={customer.customerId}
                        className={classNames('border-gray-200', 'border-t')}
                      >
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                          {customer.customerId}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                          {customer.name}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.type}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.contactName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.contactEmail}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.hqId}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.hqName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.address}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.postcode}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.country}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.operatingHoursFrom.slice(0, -3)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.operatingHoursTo.slice(0, -3)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {customer.notes}
                        </td>
                        {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                            <button
                              type='button'
                              className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            >
                              Edit
                              <span className='sr-only'>, {person.name}</span>
                            </button>
                          </td> */}
                      </tr>
                    ))}
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={isAddCustomerModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsAddCustomerModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Add Customer' />
          <CustomerForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        </ModalContainer>
      </Modal>
    </View>
  );
}
