import { Heading } from '@components/Heading';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToggleTheme } from '@components/ToggleTheme';
import { useAuthHttp } from '@hooks';
import {
  JOIN_URL,
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
} from '@constants';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
} from '@views/Login/Login.styles';
import { useEffect, useState } from 'react';
import { CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Button } from '@components/Button';
import * as Yup from 'yup';
import {
  PasswordStrengthBar,
  getPasswordStrength,
} from '@components/PasswordStrengthBar';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object({
  firstName: Yup.string()
    .min(3, 'First name must have at least 3 characters.')
    .required('A first name is required.'),
  lastName: Yup.string()
    .min(3, 'Last name must have at least 3 characters.')
    .required('A last name is required.'),
  mobilePhoneNumber: Yup.string()
    .matches(/^(?:0|\+?44)(?:\d\s?){9,10}$/g, {
      message: 'Please enter a valid mobile phone number',
    })
    .required('A mobile phone number is required.'),
  password: Yup.string()
    .min(8, 'Password must have at least 8 characters.')
    .required('A password is required.')
    .test({
      name: 'password-strength-test',
      test: (value, ctx) => {
        if (!(getPasswordStrength(value as string) > 1)) {
          return ctx.createError({
            message: 'Password is not strong enough.',
          });
        }
        return true;
      },
    }),
});

type FormData = Yup.InferType<typeof schema>;

export function Join() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const navigate = useNavigate();
  const authHttp = useAuthHttp();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const [accountCreated, setAccountCreated] = useState(false);

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordFocus, setPasswordFocus] = useState(false);

  useDocumentTitle('FLOX - Join');

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      mobilePhoneNumber: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, formState, setValue } = form;

  const { errors } = formState;

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    try {
      const { status } = await authHttp.post(JOIN_URL, {
        token: searchParams.get('token'),
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhoneNumber: data.mobilePhoneNumber,
        password: data.password,
      });

      if (status === 200) {
        setAccountCreated(true);
        setTimeout(() => navigate('/Noticeboard'), 2000);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (accountCreated)
    return (
      <Container>
        <ImagePanel variant='login' />
        <FormPanel>
          <ToggleTheme
            css={{ position: 'absolute', top: '$space16', right: '$space32' }}
          />
          <LogoTitleContainer>
            {isLoading ? (
              <Stack
                direction='row'
                gap={16}
                justifyContent='center'
                alignItems='center'
                sx={{
                  width: '14.25rem',
                }}
              >
                <CircularProgress
                  size={24}
                  sx={{ fill: 'var(--colors-gray12)' }}
                />
              </Stack>
            ) : (
              <>
                <Logo
                  src={
                    darkMode
                      ? FLOX_LOGO_STANDARD_REVERSE_SRC
                      : FLOX_LOGO_BLUE_SRC
                  }
                  alt='Flox logo'
                />
                <Heading
                  as='h1'
                  size={isTablet ? 'md' : 'sm'}
                  css={{ textAlign: isTablet ? 'right' : 'center' }}
                >
                  Account Created!
                </Heading>
                <div>
                  <Typography
                    variant='h6'
                    textAlign={isTablet ? 'right' : 'center'}
                    style={{ marginBottom: '1rem' }}
                  >
                    Redirecting you to Flox...
                  </Typography>
                  <Typography
                    variant='body2'
                    textAlign={isTablet ? 'right' : 'center'}
                    style={{ marginBottom: '1rem' }}
                  >
                    If you are not redirected within a few seconds, click the
                    button below.
                  </Typography>
                  <Button
                    variant='primary'
                    onClick={() => navigate('/Noticeboard')}
                  >
                    Start using Flox
                  </Button>
                </div>
              </>
            )}
          </LogoTitleContainer>
        </FormPanel>
      </Container>
    );

  return (
    <Container>
      <ImagePanel variant='login' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          {isLoading ? (
            <Stack
              direction='row'
              gap={16}
              justifyContent='center'
              alignItems='center'
              sx={{
                width: '14.25rem',
              }}
            >
              <CircularProgress
                size={24}
                sx={{ fill: 'var(--colors-gray12)' }}
              />
            </Stack>
          ) : (
            <>
              <Logo
                src={
                  darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC
                }
                alt='Flox logo'
              />
              <Heading
                as='h1'
                size={isTablet ? 'md' : 'sm'}
                css={{ textAlign: isTablet ? 'right' : 'center' }}
              >
                Join
              </Heading>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                style={{ width: '100%' }}
              >
                <Stack spacing={16}>
                  <TextField
                    label='First name'
                    type='text'
                    {...register('firstName')}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                  <TextField
                    label='Last name'
                    type='text'
                    {...register('lastName')}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                  <TextField
                    label='Mobile phone number'
                    type='tel'
                    {...register('mobilePhoneNumber')}
                    error={!!errors.mobilePhoneNumber}
                    helperText={errors.mobilePhoneNumber?.message}
                  />
                  <TextField
                    label='Password'
                    type='password'
                    {...register('password')}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setValue('password', e.target.value, {
                        shouldValidate: true,
                      });
                      setPasswordStrength(getPasswordStrength(e.target.value));
                    }}
                    onBlur={() => setPasswordFocus(false)}
                    onFocus={() => setPasswordFocus(true)}
                  />
                  <PasswordStrengthBar
                    score={passwordStrength}
                    css={{
                      display: passwordFocus ? 'flex' : 'none',
                      mb: '$space8',
                      px: '$space16',
                    }}
                  />
                  <Button
                    type='submit'
                    variant='primary'
                    action='cta'
                    size='lg'
                    // isLoading={isSubmitting}
                    // loadingIcon={<Icon name='loading' />}
                    loadingText='Creating account...'
                    isFullWidth
                    css={{ mt: '$space16' }}
                  >
                    Create account
                  </Button>
                </Stack>
              </form>
            </>
          )}
        </LogoTitleContainer>
      </FormPanel>
    </Container>
  );
}
