import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ProductPaginationQuery,
  ProductSummary,
  SkusPaginationQuery,
} from '@views/Noticeboard/types';
import { InventorySKUListing } from '@views/StockBySKU/components/types';

interface InventoryState {
  inventory: {
    products: ProductSummary[];
    skus: InventorySKUListing[];
    inventoryCurrentQuery: ProductPaginationQuery;
    inventoryHasMoreData: null | boolean;
    skusCurrentQuery: SkusPaginationQuery;
    skusHasMoreData: null | boolean;
  };
}

const initialState: InventoryState = {
  inventory: {
    products: [],
    skus: [],
    inventoryCurrentQuery: {
      pagination: null,
      searchQuery: null,
    },
    inventoryHasMoreData: null,
    skusCurrentQuery: {
      pagination: null,
      isPalletisingRequired: null,
      searchQuery: null,
    },
    skusHasMoreData: null,
  },
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    addInventory(
      state,
      action: PayloadAction<{
        products: ProductSummary[];
        currentQuery: ProductPaginationQuery;
        hasMoreData: null | boolean;
      }>
    ) {
      state.inventory.products.push(...action.payload.products);
      state.inventory.inventoryCurrentQuery = action.payload.currentQuery;
      if (state.inventory.inventoryCurrentQuery.pagination) {
        state.inventory.inventoryCurrentQuery.pagination.lastItem =
          action.payload.products[action.payload.products.length - 1];
      }
      state.inventory.inventoryHasMoreData = action.payload.hasMoreData;
    },
    upsertSingleInventoryItem(
      state,
      action: PayloadAction<{
        product: ProductSummary;
      }>
    ) {
      const exists =
        state.inventory.products.find(
          (product) => product.id === action.payload.product.id
        ) !== undefined;

      if (exists) {
        state.inventory.products = state.inventory.products.map((product) => {
          if (product.id === action.payload.product.id) {
            return action.payload.product;
          }
          return product;
        });
      } else {
        state.inventory.products.unshift(action.payload.product);
      }
    },
    refreshInventory(
      state,
      action: PayloadAction<{
        products: ProductSummary[];
        currentQuery: ProductPaginationQuery;
        hasMoreData: null | boolean;
      }>
    ) {
      state.inventory.products = action.payload.products;
      state.inventory.inventoryCurrentQuery = action.payload.currentQuery;
      if (state.inventory.inventoryCurrentQuery.pagination) {
        state.inventory.inventoryCurrentQuery.pagination.lastItem =
          action.payload.products[action.payload.products.length - 1];
      }
      state.inventory.inventoryHasMoreData = action.payload.hasMoreData;
    },
    clearInventory() {
      return initialState;
    },
    addSkus(
      state,
      action: PayloadAction<{
        skus: InventorySKUListing[];
        currentQuery: SkusPaginationQuery;
        hasMoreData: null | boolean;
      }>
    ) {
      state.inventory.skus.push(...action.payload.skus);
      state.inventory.skusCurrentQuery = action.payload.currentQuery;
      if (state.inventory.skusCurrentQuery.pagination) {
        state.inventory.skusCurrentQuery.pagination.lastItem =
          action.payload.skus[action.payload.skus.length - 1];
      }
      state.inventory.skusHasMoreData = action.payload.hasMoreData;
    },
    upsertSkus(state, action: PayloadAction<InventorySKUListing[]>) {
      action.payload.forEach((sku) => {
        const exists =
          state.inventory.skus.find(
            (inventorySku) => inventorySku.id === sku.id
          ) !== undefined;

        if (exists) {
          state.inventory.skus = state.inventory.skus.map((inventorySku) => {
            if (inventorySku.id === sku.id) {
              return sku;
            }
            return inventorySku;
          });
        } else {
          state.inventory.skus.unshift(sku);
        }
      });
    },
    clearSkus(state) {
      state.inventory.skus = [];
      state.inventory.skusCurrentQuery = {
        pagination: null,
        isPalletisingRequired: null,
        searchQuery: null,
      };
      state.inventory.skusHasMoreData = null;
    },
  },
});

export const {
  addInventory,
  upsertSingleInventoryItem,
  refreshInventory,
  clearInventory,
  addSkus,
  upsertSkus,
  clearSkus,
} = inventorySlice.actions;
export default inventorySlice.reducer;
