import { ComponentPropsWithoutRef } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Text } from '@components/Text';
import { InboundProcess, OutboundProcess } from '@features/supplyChain/types';
import dayjs from 'dayjs';
import { useAuth } from '@context';
import { getNoticeColors } from '@views/Noticeboard/utils';
import { UserRoleService, User } from '@services';

interface SupplyChainProcessShipmentNoteCardProps {
  inboundProcess: InboundProcess;
  user: User | null;
  isWarehouseManager: (user: User | null) => boolean;
}

export function SupplyChainProcessShipmentNoteCard({
  inboundProcess,
  user,
  isWarehouseManager,
}: SupplyChainProcessShipmentNoteCardProps) {
  const { userBusinessName, status, shipmentNote, goodsReceivedNote, notices } =
    inboundProcess;
  const {
    shipmentNoteNumber,
    supplierName,
    estimatedTimeOfArrival,
    skus,
    totalSkuQuantity,
  } = shipmentNote;

  const isReceivedSkusDiscrepancy =
    goodsReceivedNote?.finalisedAt &&
    totalSkuQuantity !== goodsReceivedNote?.totalReceivedSkuQuantity;

  const receivedSkusDiscrepancyAmount =
    // eslint-disable-next-line no-unsafe-optional-chaining, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    totalSkuQuantity - goodsReceivedNote?.totalReceivedSkuQuantity!;

  const isNegativeReceivedSkusDiscrepancy = receivedSkusDiscrepancyAmount > 0;

  const hasIssues = (notices?.length ?? 0) > 0;
  const isProcessComplete = !!goodsReceivedNote?.finalisedAt;
  const hasIssuesBeforeProcessIsComplete = hasIssues && !isProcessComplete;
  const hasIssuesWhenProcessIsComplete = hasIssues && isProcessComplete;
  const hasNoIssuesWhenProcessIsComplete = !hasIssues && isProcessComplete;

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>{shipmentNoteNumber}</Text>
        {goodsReceivedNote?.finalisedAt ? (
          <Text size='2xs'>
            Received date:{' '}
            {dayjs(goodsReceivedNote?.finalisedAt).format('DD/MM/YYYY')}
          </Text>
        ) : (
          <Text size='2xs'>
            ETA: {dayjs(estimatedTimeOfArrival).format('DD/MM/YYYY')}
          </Text>
        )}
      </Stack>
      {isWarehouseManager(user) && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Customer</Text>
          <Text size='2xs'>{userBusinessName}</Text>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>Supplier</Text>
        <Text size='2xs'>{supplierName}</Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>SKUs</Text>
        <Text size='2xs'>{skus.length}</Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        {goodsReceivedNote?.finalisedAt ? (
          <>
            <Text size='2xs'>Delivered Q-ty (Items)</Text>
            <Text
              size='2xs'
              css={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}
            >
              {goodsReceivedNote.totalReceivedSkuQuantity}
              {isReceivedSkusDiscrepancy && (
                <>
                  {' '}
                  <Text
                    as='span'
                    size='xs'
                    css={{
                      color: isNegativeReceivedSkusDiscrepancy
                        ? 'var(--colors-tomato9)'
                        : 'var(--colors-gray12)',
                    }}
                  >
                    {`(${
                      isNegativeReceivedSkusDiscrepancy ? '-' : '+'
                    }${receivedSkusDiscrepancyAmount})`}
                  </Text>
                </>
              )}
            </Text>
          </>
        ) : (
          <>
            <Text size='2xs'>Total Q-ty (Items)</Text>
            <Text size='2xs'>{totalSkuQuantity}</Text>
          </>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs' weight='semibold'>
          {status}
        </Text>
        {hasIssuesBeforeProcessIsComplete && (
          <ErrorIcon
            sx={{
              width: 16,
              height: 16,
              fill: getNoticeColors('warning').fill,
            }}
          />
        )}
        {hasIssuesWhenProcessIsComplete && (
          <ErrorIcon
            sx={{ width: 16, height: 16, fill: getNoticeColors('error').fill }}
          />
        )}
        {hasNoIssuesWhenProcessIsComplete && (
          <CheckCircleIcon
            sx={{ width: 16, height: 16, fill: 'var(--colors-green9)' }}
          />
        )}
      </Stack>
    </>
  );
}

SupplyChainProcessShipmentNoteCard.displayName =
  'SupplyChainProcessShipmentNoteCard';

interface SupplyChainProcessDespatchNoteCardProps {
  outboundProcess: OutboundProcess;
  user: User | null;
  isWarehouseManager: (user: User | null) => boolean;
}

export function SupplyChainProcessDespatchNoteCard({
  outboundProcess,
  user,
  isWarehouseManager,
}: SupplyChainProcessDespatchNoteCardProps) {
  const {
    processNumber,
    userBusinessName,
    status,
    despatchNote,
    goodsReceivedNote,
    notices,
  } = outboundProcess;

  // Despatch note

  const { requiredTimeOfArrival, skus, totalSkuQuantity } = despatchNote;

  const isReceivedSkusDiscrepancy =
    goodsReceivedNote?.finalisedAt &&
    totalSkuQuantity !== goodsReceivedNote?.totalReceivedSkuQuantity;

  const receivedSkusDiscrepancyAmount =
    // eslint-disable-next-line no-unsafe-optional-chaining, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    totalSkuQuantity - goodsReceivedNote?.totalReceivedSkuQuantity!;

  const isNegativeReceivedSkusDiscrepancy = receivedSkusDiscrepancyAmount > 0;

  const hasIssues = (notices?.length ?? 0) > 0;
  const isProcessComplete = !!goodsReceivedNote?.finalisedAt;
  const hasIssuesBeforeProcessIsComplete = hasIssues && !isProcessComplete;
  const hasIssuesWhenProcessIsComplete = hasIssues && isProcessComplete;
  const hasNoIssuesWhenProcessIsComplete = !hasIssues && isProcessComplete;

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Text size='2xs'>{processNumber}</Text>
        {goodsReceivedNote?.finalisedAt ? (
          <Text size='2xs'>
            POD: {dayjs(goodsReceivedNote?.finalisedAt).format('DD/MM/YYYY')}
          </Text>
        ) : (
          <Text size='2xs'>
            RTA: {dayjs(requiredTimeOfArrival).format('DD/MM/YYYY')}
          </Text>
        )}
      </Stack>
      {isWarehouseManager(user) && (
        <Stack direction='row' justifyContent='space-between'>
          <Text size='2xs'>Customer</Text>
          <Text size='2xs'>{userBusinessName}</Text>
        </Stack>
      )}
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs'>SKUs</Text>
        <Text size='2xs'>{skus.length}</Text>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        {goodsReceivedNote?.finalisedAt ? (
          <>
            <Text size='2xs'>Delivered Q-ty (Items)</Text>
            <Text
              size='2xs'
              css={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}
            >
              {goodsReceivedNote.totalReceivedSkuQuantity}
              {isReceivedSkusDiscrepancy && (
                <>
                  {' '}
                  <Text
                    as='span'
                    size='xs'
                    css={{
                      color: isNegativeReceivedSkusDiscrepancy
                        ? 'var(--colors-tomato9)'
                        : 'var(--colors-gray12)',
                    }}
                  >
                    {`(${
                      isNegativeReceivedSkusDiscrepancy ? '-' : '+'
                    }${receivedSkusDiscrepancyAmount})`}
                  </Text>
                </>
              )}
            </Text>
          </>
        ) : (
          <>
            <Text size='2xs'>Total Q-ty (Items)</Text>
            <Text size='2xs'>{totalSkuQuantity}</Text>
          </>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Text size='2xs' weight='semibold'>
          {status}
        </Text>
        {hasIssuesBeforeProcessIsComplete && (
          <ErrorIcon
            sx={{
              width: 16,
              height: 16,
              fill: getNoticeColors('warning').fill,
            }}
          />
        )}
        {hasIssuesWhenProcessIsComplete && (
          <ErrorIcon
            sx={{ width: 16, height: 16, fill: getNoticeColors('error').fill }}
          />
        )}
        {hasNoIssuesWhenProcessIsComplete && (
          <CheckCircleIcon
            sx={{ width: 16, height: 16, fill: 'var(--colors-green9)' }}
          />
        )}
      </Stack>
    </Box>
  );
}

SupplyChainProcessShipmentNoteCard.displayName =
  'SupplyChainProcessShipmentNoteCard';

interface SupplyChainProcessEventCardProps
  extends ComponentPropsWithoutRef<'div'> {
  inboundProcess?: InboundProcess;
  outboundProcess?: OutboundProcess;
  onClick: () => void;
}

export function SupplyChainProcessEventCard({
  inboundProcess,
  outboundProcess,
  onClick,
}: SupplyChainProcessEventCardProps) {
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();

  return (
    <Stack
      direction='column'
      useFlexGap
      sx={{
        backgroundColor: 'var(--colors-gray1)',
        transition: 'background-color var(--transitions-fast)',
        p: 8,
        borderRadius: '0.5rem',
        gap: 4,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--colors-gray2)',
        },
        overflow: 'hidden',
        flexShrink: '0',
      }}
      onClick={onClick}
    >
      {inboundProcess && (
        <SupplyChainProcessShipmentNoteCard
          inboundProcess={inboundProcess}
          user={user}
          isWarehouseManager={isWarehouseManager}
        />
      )}
      {outboundProcess && (
        <SupplyChainProcessDespatchNoteCard
          outboundProcess={outboundProcess}
          user={user}
          isWarehouseManager={isWarehouseManager}
        />
      )}
    </Stack>
  );
}

SupplyChainProcessEventCard.displayName = 'SupplyChainProcessEventCard';
