import { useAuthHttp, useReactHookForm } from '@hooks';
import { LoginResponse } from '@views/Login/types';
import { useCallback, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { Text } from '@components/Text';
import { ErrorMessage } from '@components/ErrorMessage';
import { Controller } from 'react-hook-form';
import { Button } from '@components/Button';
import { defaultValues, validationSchema } from './form';
import { LOGIN_WITH_TWO_FACTOR_AUTH_URL } from '../../constants';

interface LoginWith2FaFormProps {
  twoFactorId: string;
  handleLoginResponse: (loginResponse: LoginResponse) => void;
}

export type LoginWith2FaFormValues = {
  twoFaCode: string;
};

export function LoginWith2FaForm({
  twoFactorId,
  handleLoginResponse,
}: LoginWith2FaFormProps) {
  const authHttp = useAuthHttp();
  const [showFailureResponseMessage, setShowFailureResponseMessage] =
    useState(false);

  const { handleSubmit, control, isSubmitting, getValues, trigger, formState } =
    useReactHookForm<LoginWith2FaFormValues>({
      defaultValues,
      validationSchema,
    });

  const focusTextFieldCallbackRef = useCallback(
    (inputElement: HTMLDivElement) => {
      setTimeout(() => {
        inputElement?.querySelector('input')?.focus();
      }, 1);
    },
    []
  );

  const onSubmit = async (values: LoginWith2FaFormValues) => {
    // eslint-disable-next-line no-console
    console.log('Submitted form');
    // eslint-disable-next-line no-console
    console.log(`2FA code: ${values.twoFaCode}`);

    setShowFailureResponseMessage(false);

    const { errors } = formState;

    // eslint-disable-next-line no-console
    console.log(getValues(), errors);
    const isValid = await trigger();

    if (isValid) {
      try {
        console.log(`Valid: ${values}`);

        const { status, data } = await authHttp.post(
          LOGIN_WITH_TWO_FACTOR_AUTH_URL,
          {
            twoFactorId,
            code: values.twoFaCode,
          }
        );

        if (status === 200) {
          console.log('Status: 200');
          setShowFailureResponseMessage(false);
          handleLoginResponse(data);
        } else {
          console.log(`Status: ${status}`);
          setShowFailureResponseMessage(true);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
        setShowFailureResponseMessage(true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={24} p={6}>
        <Text>To login, enter the code in your Authenticator App below.</Text>
        <Controller
          name='twoFaCode'
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <TextField
              {...field}
              name={field.name}
              error={Boolean(error && isTouched)}
              helperText={Boolean(error && isTouched) && error?.message}
              placeholder='Authenticator App Code (required)'
              ref={focusTextFieldCallbackRef}
            />
          )}
        />
        {showFailureResponseMessage && (
          <ErrorMessage>Something went wrong. Please try again</ErrorMessage>
        )}
        <Button type='submit' size='sm'>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Stack>
    </form>
  );
}
