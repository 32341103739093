import { View } from '@views/View';
import {
  Match,
  WarehouseMatchList,
} from '@views/WarehouseDetails/WarehouseDetails';
import { useEffect, useState } from 'react';
import { BillingCycle, VAT_RATE } from './invoiceTypes';
import { InvoiceTable } from './InvoiceTable';
import { MATCH_LIST } from '../../constants';
import { useAuthHttp } from '../../hooks';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoicePaymentDetails } from './InvoicePaymentDetails';

export function ShipperInvoice({
  billingCycle,
}: {
  billingCycle: BillingCycle;
}) {
  const authHttp = useAuthHttp();
  const [match, setMatch] = useState<Match>();
  const [hasPaid, setHasPaid] = useState<boolean>(false); // TODO: update this to use value from BE when implemented

  const fetchWarehouseMatch = async () => {
    try {
      const { data } = await authHttp.post<WarehouseMatchList>(
        MATCH_LIST,
        {
          warehouseId: billingCycle.warehouseId,
          matchId: billingCycle.warehouseMatchId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data.matches.length > 0) {
        setMatch(data.matches[0].match);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWarehouseMatch();
  }, []);

  const subTotalAmount = billingCycle.invoice.lines.reduce(
    (sum, line) => sum + line.amount,
    0
  );
  const subTotalVatAmount = billingCycle.invoice.lines.reduce(
    (sum, line) => sum + (line.amount * VAT_RATE) / 100,
    0
  );

  return (
    <View>
      <InvoiceSummary
        hasConfirmed
        hasPaid={hasPaid}
        match={match}
        billingCycle={billingCycle}
      />
      <InvoiceTable
        billingCycle={billingCycle}
        invoiceLines={billingCycle.invoice.lines}
        match={match}
        subTotalAmount={subTotalAmount}
        subTotalVatAmount={subTotalVatAmount}
        editSectionProps={null}
      />
      <InvoicePaymentDetails
        hasConfirmed
        hasPaid={hasPaid}
        billingCycle={billingCycle}
        invoiceNotes={billingCycle.invoice.notes}
        setHasPaid={setHasPaid}
        dueDate={billingCycle.invoice.dueDate}
      />
    </View>
  );
}
