// Shared navigation

import {
  NavigationItem,
  NavigationItemList,
  NavigationItemWithSublinks,
} from '@components/Navigation/types';

const noticeboardNavigation: NavigationItem = {
  id: 'noticeboard',
  label: 'Noticeboard',
  href: '/noticeboard',
};

const teamMembersNavigation: NavigationItemWithSublinks = {
  id: 'team',
  label: 'Team',
  href: '/team',
};

const financeNavigation: NavigationItemWithSublinks = {
  id: 'finance',
  label: 'Finance',
  href: '#',
  sublinks: [
    {
      id: 'invoice',
      label: 'Invoices',
      href: '/invoices',
    },
    {
      id: 'payments',
      label: 'Payments',
      href: '/payments',
    },
  ],
};

// Warehouse Client navigation

const clientShipmentsNavigation: NavigationItemWithSublinks = {
  id: 'shipments',
  label: 'Shipments',
  href: '/shipments',
};

const clientStockNavigation: NavigationItemWithSublinks = {
  id: 'stock',
  label: 'Stock',
  href: '/stock',
  // sublinks: [
  //   {
  //     id: 'stockReclassificationNotes',
  //     label: 'Stock reclassification notes',
  //     href: '/stock-reclassification-notes',
  //     image: 'https://unsplash.it/500',
  //     description:
  //       'View all of your stock reclassification notes in once place.',
  //   },
  //   {
  //     id: 'stockDiscrepancyReconciliationNotes',
  //     label: 'Stock discrepancy reconciliation notes',
  //     href: '/stock-discrepancy-reconciliation-notes',
  //     image: 'https://unsplash.it/500',
  //     description:
  //       'View all of your stock discrepancy reconciliation notes in once place.',
  //   },
  //   {
  //     id: 'stockActivityLog',
  //     label: 'Stock activity log',
  //     href: '/stock-activity-log',
  //     image: 'https://unsplash.it/500',
  //     description: 'View your stock activity.',
  //   },
  // ],
};

const clientMasterDataNavigation: NavigationItemWithSublinks = {
  id: 'masterData',
  label: 'Master data',
  href: '#',
  sublinks: [
    {
      id: 'productSKUItemsMaster',
      label: 'Product/SKU master',
      href: '/product-sku-master',
      description: 'Create, view and edit Product and SKU lists.',
    },
    {
      id: 'customerMaster',
      label: 'Customer Master',
      href: '/customer-master',
      description: 'View and edit your customers.',
    },
    {
      id: 'supplierMaster',
      label: 'Supplier Master',
      href: '/supplier-master',
      description: 'View and edit your suppliers.',
    },
  ],
};

const clientWarehouseSearchNavigation: NavigationItemWithSublinks = {
  id: 'warehousing',
  label: 'New warehouse',
  href: '#',
  sublinks: [
    {
      id: 'warehouseSearch',
      label: 'Warehouse Search',
      href: '/warehouse-search',
      description: 'Search for warehouses.',
    },
    {
      id: 'warehouseShortlist',
      label: 'Warehouse Shortlist',
      href: '/warehouse-shortlist',
      description: 'View and edit your warehouse shortlist.',
    },
  ],
};

// Warehouse Manager navigation

const managerShipmentsNavigation: NavigationItemWithSublinks = {
  id: 'shipments',
  label: 'Shipments',
  href: '/shipments',
};

const managerStockNavigation: NavigationItemWithSublinks = {
  id: 'stock',
  label: 'Stock',
  href: '/stock',
};

const warehouseMasterNavigation: NavigationItemWithSublinks = {
  id: 'masterData',
  label: 'Master Data',
  href: '#',
  sublinks: [
    {
      id: 'warehouseMaster',
      label: 'Warehouse Master',
      href: '/warehouse-master',
      description: 'View and edit warehouses.',
    },
    {
      id: 'fleetMaster',
      label: 'Fleet Master',
      href: '/fleet-master',
      description: 'View and edit your fleet.',
    },
    {
      id: 'priceMaster',
      label: 'Transport Price Master',
      href: '/transport-price-master',
      description: 'View and edit your ratecards.',
    },
  ],
};

const managerFleetAvailability: NavigationItemWithSublinks = {
  id: 'fleetAvailability',
  label: 'Fleet Availability',
  href: '/fleet-availability',
};

const managerCustomers: NavigationItemWithSublinks = {
  id: 'customers',
  label: 'Customers',
  href: '/customers',
};

export const clientNavigation: NavigationItemList = [
  noticeboardNavigation,
  clientShipmentsNavigation,
  clientStockNavigation,
  financeNavigation,
  clientMasterDataNavigation,
  clientWarehouseSearchNavigation,
];

export const managerNavigation: NavigationItemList = [
  noticeboardNavigation,
  managerShipmentsNavigation,
  managerStockNavigation,
  financeNavigation,
  warehouseMasterNavigation,
  managerFleetAvailability,
  managerCustomers,
];
