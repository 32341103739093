import { useState } from 'react';
import { useAuthHttp, useCloseDialog } from '@hooks';
import {
  APPROVED_INBOUND_SHIPMENT_NOTE_URL,
  FINALISE_GOODS_RECEIVED_NOTE_URL,
} from '@constants';
import { useAppDispatch } from '@store';
import { upsertSingleInboundProcess } from '@features/supplyChain';
import { CreatDraftGoodsReceivedNoteFormValues } from '@forms/manager/CreateDraftGoodsReceivedNoteForm/types';
import { SignOffGoodsReceivedNoteFormValues } from '@forms/manager/SignOffGoodsReceivedNoteForm/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useGoodsReceivedNote = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const [
    isCreateDraftGoodsReceivedNoteFormOpen,
    setIsCreateDraftGoodsReceivedNoteFormOpen,
  ] = useState(false);

  const handleCloseCreateDraftGoodsReceivedNoteForm = useCloseDialog(
    setIsCreateDraftGoodsReceivedNoteFormOpen
  );

  const handleSubmitCreateDraftGoodsReceivedNote = async (
    values: CreatDraftGoodsReceivedNoteFormValues
  ) => {
    try {
      const formValues = {
        goodsReceivedNote: {
          supplyChainProcessId: values.supplyChainProcessId,
          shipmentNoteId: values.shipmentNoteId,
          comment: values.comment,
          skus: values.skus.map((sku) => ({
            productId: sku.productId,
            skuId: sku.skuId,
            receivedSkuQuantity: sku.receivedSkuQuantity,
            discrepancyReason: sku.discrepancyReason,
          })),
          dateOfArrival: dayjs(values.actualDateOfArrival)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(APPROVED_INBOUND_SHIPMENT_NOTE_URL, formValues);

      dispatch(upsertSingleInboundProcess(supplyChainProcess));
      setIsCreateDraftGoodsReceivedNoteFormOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [
    isSignOffGoodsReceivedNoteFormOpen,
    setIsSignOffGoodsReceivedNoteFormOpen,
  ] = useState(false);

  const handleCloseSignOffGoodsReceivedNoteForm = useCloseDialog(
    setIsSignOffGoodsReceivedNoteFormOpen
  );

  const handleSubmitSignOffGoodsReceivedNote = async (
    values: SignOffGoodsReceivedNoteFormValues
  ) => {
    try {
      const formValues = {
        goodsReceivedNote: {
          supplyChainProcessId: values.supplyChainProcessId,
          goodsReceivedNoteId: values.goodsReceivedNoteId,
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(FINALISE_GOODS_RECEIVED_NOTE_URL, formValues);

      dispatch(upsertSingleInboundProcess(supplyChainProcess));
      setIsSignOffGoodsReceivedNoteFormOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    isCreateDraftGoodsReceivedNoteFormOpen,
    setIsCreateDraftGoodsReceivedNoteFormOpen,
    handleCloseCreateDraftGoodsReceivedNoteForm,
    handleSubmitCreateDraftGoodsReceivedNote,
    isSignOffGoodsReceivedNoteFormOpen,
    setIsSignOffGoodsReceivedNoteFormOpen,
    handleCloseSignOffGoodsReceivedNoteForm,
    handleSubmitSignOffGoodsReceivedNote,
  };
};
