import moment from 'moment';
import { Match } from '@views/WarehouseDetails/WarehouseDetails';
import { BillingCycle } from './invoiceTypes';

export function InvoiceSummary({
  isLsp,
  hasConfirmed,
  hasPaid,
  match,
  billingCycle,
}: {
  isLsp?: boolean;
  hasConfirmed?: boolean;
  hasPaid?: boolean;
  match: Match | undefined;
  billingCycle: BillingCycle;
}) {
  const startDate = moment(billingCycle.startDate);
  const endDate = moment(billingCycle.endDate);
  const billingCycleTerms = endDate.diff(startDate, 'days');

  return (
    <div className='grid grid-cols-2 gap-4'>
      <div className='space-y-4'>
        {!hasConfirmed && isLsp && (
          <div className='font-bold'>PROFORMA INVOICE</div>
        )}
        {hasConfirmed && !hasPaid && (
          <div className='font-bold'>
            INVOICE {isLsp && '(pending payment)'}
          </div>
        )}
        {hasPaid && <div className='font-bold'>INVOICE (paid)</div>}
        <div>{billingCycle.invoice.invoiceNumber}</div>
        <div>To{isLsp && ' FLOX for the services provided to'}:</div>
        <div className='font-bold'>{billingCycle.shipperBusinessName}</div>
        <div>{billingCycle.shipperBusinessAddress}</div>
        {!hasConfirmed && (
          <div>
            <span className='font-bold'>Date</span>:{' '}
            {moment(billingCycle.invoice.createdAt).format('DD/MM/YYYY')}
          </div>
        )}
        {hasConfirmed && !hasPaid && (
          <div>
            <span className='font-bold'>Date</span>:{' '}
            {moment(billingCycle.invoice.approvedAt).format('DD/MM/YYYY')}
          </div>
        )}
        {hasPaid && (
          <div>
            <span className='font-bold'>Date</span>:{' '}
            {moment(billingCycle.invoice.createdAt).format('DD/MM/YYYY')}
          </div>
        )}
      </div>
      <div className='space-y-4'>
        <div>FROM{!isLsp && ' FLOX for the services provided by'}:</div>
        <div className='font-bold'>{billingCycle.lspBusinessName}</div>
        <div>{billingCycle.lspBusinessAddress}</div>
        <div>Company No: {billingCycle.lspCompanyNumber}</div>
        {isLsp && <div>VAT No: {billingCycle.lspVatNumber}</div>}

        {hasConfirmed ? (
          <div>Terms: {match?.paymentTermsInDays} days</div>
        ) : (
          <div>Terms: {billingCycleTerms} days</div>
        )}
        <div>
          Currency: {billingCycle.invoice.lines[0].currencyCode} (
          {billingCycle.invoice.lines[0].currencySymbol})
        </div>
      </div>
    </div>
  );
}
