/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import NoteAdd from '@mui/icons-material/NoteAdd';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { OutboundProcess } from '@features/supplyChain/types';
import { PalletCalculator } from '@components/PalletCalculator';

interface OutboundShipmentNoteDetailsProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  primaryAction?: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function OutboundShipmentNoteDetails({
  selectedOutboundProcess,
  onClose,
  primaryAction,
  isDesktop,
  isTablet,
}: OutboundShipmentNoteDetailsProps) {
  const { pickingConfirmation, shipmentNote } = selectedOutboundProcess;
  return (
    <>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipment note #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNote?.shipmentNoteNumber}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Customer name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess.despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Customer address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>123 Main St</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipped with
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>ABC Haulage</Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required time of arrival
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(
                selectedOutboundProcess.despatchNote.requiredTimeOfArrival
              ).format('DD/MM/YYYY, H:mm')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(selectedOutboundProcess.despatchNote.requiredTimeOfArrival)
                .add(1, 'day')
                .format('DD/MM/YYYY, H:mm')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
      {/* SKUS */}
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '1 / 2', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '2 / 4' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '4 / 6' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '6 / 8' }}
              >
                UoM
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '8 / 10' }}
              >
                Picked <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '10 / 12' }}
              >
                Despatched <br />
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='sm'
                weight='semibold'
                css={{ gridColumn: '12 / 14' }}
              >
                Despatched <br />
                Pallet Q-ty
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        {shipmentNote?.skus.map((sku, index) => {
          const isDiscrepancy = sku.discrepancyReason;
          const pickingConfirmationSku = pickingConfirmation?.skus.find(
            (pickingSku) => pickingSku.skuId === sku.skuId
          );
          const pickedQuantity = pickingConfirmationSku?.pickedQuantity;

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const discrepancyAmount = pickedQuantity! - sku.skuQuantity;
          const isNegativeDiscrepancy = discrepancyAmount > 0;

          const hasPackagingInfo = sku.packagingDetails.packagingInfo;

          let amountOfSkusInLayer: number | undefined;
          let amountOfLayersInPallet: number | undefined;
          let packagingInfoType: string | undefined;
          let palletisingType: string | null | undefined;

          if (hasPackagingInfo) {
            amountOfSkusInLayer =
              sku.packagingDetails.packagingInfo?.amountOfSkusInLayer;

            amountOfLayersInPallet =
              sku.packagingDetails.packagingInfo?.amountOfLayersInPallet;

            packagingInfoType = sku.packagingDetails.packagingInfo?.type;
          } else {
            palletisingType = sku.packagingDetails.palletisingType;
          }

          return (
            <>
              <Box
                key={sku.skuId}
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                gridTemplateRows={
                  isDesktop
                    ? `repeat(${isDiscrepancy ? '2' : '1'}, min-content)`
                    : isDiscrepancy
                    ? 'repeat(3, min-content)'
                    : 'repeat(2, min-content)'
                }
                columnGap='1rem'
                rowGap='1rem'
              >
                {/* SKU index  */}
                <Stack gridColumn='1 / 2' sx={{ alignSelf: 'center' }}>
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Stack
                  gridColumn={
                    isDesktop ? '2 / 4' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '1' : '1'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      SKU ID
                    </Text>
                  )}
                  <Text>{sku.skuIdentifier}</Text>
                </Stack>
                {/* Product name */}
                <Stack
                  gridColumn={
                    isDesktop ? '4 / 6' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '2' : '2'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      Product name
                    </Text>
                  )}
                  <Text>{sku.productName}</Text>
                </Stack>
                {/* UoM */}
                <Stack
                  gridColumn={
                    isDesktop ? '6 / 8' : isTablet ? '2 / 8' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '3' : '3'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      UoM
                    </Text>
                  )}
                  <Text>{sku.name}</Text>
                </Stack>
                {/* Picked SKU Quantity */}
                <Stack
                  gridColumn={
                    isDesktop ? '8 / 10' : isTablet ? 'span 6' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '1' : '4'}
                  spacing={8}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      Picked SKU Q-ty
                    </Text>
                  )}
                  <Text>{pickedQuantity}</Text>
                </Stack>
                {/* Despatched SKU Quantity */}
                <Box
                  gridColumn={
                    isDesktop ? '10 / 12' : isTablet ? '8 / 14' : '2 / 14'
                  }
                  gridRow={isDesktop ? '1' : isTablet ? '2' : '5'}
                  sx={{ alignSelf: 'center' }}
                >
                  {!isDesktop && (
                    <Text size='sm' weight='bold'>
                      Despatched SKU Q-ty
                    </Text>
                  )}
                  <Text>
                    {sku.skuQuantity}
                    {sku.discrepancyReason && (
                      <>
                        {' '}
                        <Text
                          as='span'
                          size='md'
                          css={{
                            color: 'var(--colors-tomato9)',
                          }}
                        >
                          {`(${isNegativeDiscrepancy ? '-' : '+'}${
                            isNegativeDiscrepancy
                              ? discrepancyAmount
                              : discrepancyAmount.toString().substring(1)
                          })`}
                        </Text>
                      </>
                    )}
                  </Text>
                </Box>
                {/* Despatched Pallet Quantity */}
                {hasPackagingInfo ? (
                  <Stack
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Despatched Q-ty (Pallets)
                      </Text>
                    )}

                    <Text css={{ whiteSpace: 'nowrap' }}>
                      <PalletCalculator
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        palletName={packagingInfoType!}
                        skuQuantity={sku.skuQuantity}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        amountOfSkusInLayer={amountOfSkusInLayer!}
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        amountOfLayersInPallet={amountOfLayersInPallet!}
                      />
                    </Text>
                  </Stack>
                ) : (
                  <Stack
                    gridColumn={
                      isDesktop ? '12 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '1' : isTablet ? '3' : '6'}
                    spacing={8}
                    sx={{ alignSelf: 'center' }}
                  >
                    {!isDesktop && (
                      <Text size='sm' weight='bold'>
                        Despatched Q-ty (Pallets)
                      </Text>
                    )}

                    <Text as='p' size='md'>
                      {palletisingType}
                    </Text>
                  </Stack>
                )}
                {/* Discrepancy Reason */}
                {isDiscrepancy && (
                  <Stack
                    gridColumn={
                      isDesktop ? '10 / 14' : isTablet ? '8 / 14' : '2 / 14'
                    }
                    gridRow={isDesktop ? '2' : isTablet ? '4' : '7'}
                    sx={{ alignSelf: 'center' }}
                  >
                    <Text size='sm' weight='bold'>
                      Discrepancy reason
                    </Text>
                    <Text>{sku.discrepancyReason}</Text>
                  </Stack>
                )}
              </Box>
              {index !== shipmentNote.skus.length - 1 && (
                <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
              )}
            </>
          );
        })}
      </Stack>
      {shipmentNote?.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {shipmentNote.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction={isDesktop ? 'row' : 'column-reverse'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          size='large'
          variant='outlined'
          fullWidth={!isDesktop}
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Close
        </Button>
        {primaryAction && (
          <Button
            size='large'
            variant='contained'
            fullWidth={!isDesktop}
            startIcon={<NoteAdd />}
            sx={{ textTransform: 'none' }}
            onClick={primaryAction}
          >
            Create Proof of Delivery
          </Button>
        )}
      </Stack>
    </>
  );
}
