import { Label } from '@components/Label';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export function Daily() {
  const { control } = useFormContext();
  return (
    <>
      <Label
        htmlFor='currency'
        css={{ fontSize: '$textmd', fontWeight: 'bold' }}
      >
        Day Rates
      </Label>
      <Controller
        name='chargeForEightHourDay'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Charge for a standard 8 hour day *'
            placeholder='Charge for a standard 8 hour day *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='hourlyChargeOverEightHours'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Hourly charge for an over 8 hour day  *'
            placeholder='Hourly charge for an over 8 hour day  *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='hourlyChargeOverTehnHours'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Hourly charge for an over 10 hour day *'
            placeholder='Hourly charge for an over 10 hour day *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
    </>
  );
}
