import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthHttp } from '../../../hooks';
import { INVITE_TEAM_MEMBER_URL } from '../../../constants';
import { UserRoleService } from '../../../services';
import { useAuth } from '../../../context';

export default function InviteTeamMemberDialog({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) {
  const authHttp = useAuthHttp();
  const emailRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInviteSuccessful, setIsInviteSuccessful] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();

  const handleSendInvite = async () => {
    if (emailRef.current?.value) {
      setIsEmailError(false);
      setEmailErrorMessage('');
      setIsLoading(true);
      try {
        const { status } = await authHttp.post(INVITE_TEAM_MEMBER_URL, {
          email: emailRef.current?.value,
          role: isWarehouseManager(user)
            ? 'WarehouseManager'
            : 'WarehouseClient',
        });

        if (status === 200) setIsInviteSuccessful(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        setIsEmailError(true);
        setEmailErrorMessage('Must be an email address');
        console.error('error: ', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsEmailError(true);
      setEmailErrorMessage('Email is required');
    }
  };

  const resetInviteDialog = () => {
    setIsInviteSuccessful(false);
    if (emailRef.current) emailRef.current.value = '';
  };

  const handleDialogClose = () => {
    handleClose();
    resetInviteDialog();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>Invite Team Member</DialogTitle>
        <DialogContent>
          {isInviteSuccessful ? (
            <DialogContentText>
              An invite has been sent to {emailRef.current?.value}
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                To invite a team member, please enter their email address here.
                They will receive an email with instructions on how to join.
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Email Address'
                type='email'
                fullWidth
                variant='standard'
                inputRef={emailRef}
                error={isEmailError}
                helperText={emailErrorMessage}
                onFocus={() => {
                  setIsEmailError(false);
                  setEmailErrorMessage('');
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            {isInviteSuccessful ? 'Close' : 'Cancel'}
          </Button>
          {isInviteSuccessful ? (
            <Button onClick={resetInviteDialog}>Invite another</Button>
          ) : (
            <Button onClick={handleSendInvite}>Invite</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
