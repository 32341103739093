import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { ProductSummary } from '@views/Noticeboard/types';
import { Text } from '@components/Text';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { INVENTORY_IMAGES_URL } from '@constants';
import {
  StyledProductList,
  StyledProductCard,
  StyledProductCardImageContainer,
  StyledProductCardInfo,
  StyledProductLoading,
  StyledProductLoadingIndicator,
} from './ProductList.styles';

interface ProductListProps extends ComponentPropsWithoutRef<'div'> {
  id?: string;
}

export const ProductList = forwardRef<HTMLDivElement, ProductListProps>(
  ({ children, id = 'product-list' }, forwardedRef) => {
    return (
      <StyledProductList id={id} ref={forwardedRef}>
        {children}
      </StyledProductList>
    );
  }
);

ProductList.displayName = 'ProductList';

interface ProductCardProps extends ComponentPropsWithoutRef<'div'> {
  productSummary: ProductSummary;
  orientation: 'vertical' | 'horizontal';
  onClick: () => void;
}

export const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  ({ productSummary, orientation, onClick, ...props }, forwardedRef) => {
    const isLargeDesktop = useMediaQuery('(min-width: 75em)');
    const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
    return (
      <StyledProductCard
        orientation={orientation}
        onClick={onClick}
        {...props}
        ref={forwardedRef}
      >
        <StyledProductCardImageContainer
          css={{
            backgroundImage: `url(${
              productSummary.images && productSummary.images.length
                ? `${INVENTORY_IMAGES_URL}/${productSummary.images[0].imageLocation}/small/${productSummary.images[0].remoteName}`
                : `/images/product-placeholder-${
                    darkMode ? 'dark' : 'light'
                  }.svg`
            })`,

            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
          }}
        />
        <StyledProductCardInfo orientation={orientation}>
          <Text size={isLargeDesktop ? 'sm' : 'xs'} weight='bold'>
            {productSummary.name}
          </Text>
          <Text size='xs'>
            <Text as='span' size='xs' weight='bold'>
              Product ID:{' '}
            </Text>
            {productSummary.productId}
          </Text>
          {productSummary.netVolume && (
            <Text size='xs'>
              <Text as='span' size='xs' weight='bold'>
                Volume:{' '}
              </Text>
              {productSummary.netVolume}
              {productSummary.netVolumeUnitOfMeasure}
            </Text>
          )}
        </StyledProductCardInfo>
      </StyledProductCard>
    );
  }
);

ProductCard.displayName = 'ProductCard';

export function ProductLoading() {
  return (
    <StyledProductLoading>
      <StyledProductLoadingIndicator />
      <Text
        size='xl'
        weight='bold'
        css={{ color: 'inherit', textAlign: 'center' }}
      >
        Fetching Product details...
      </Text>
    </StyledProductLoading>
  );
}

ProductLoading.displayName = 'ProductLoading';
