/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import NoteAdd from '@mui/icons-material/NoteAdd';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { InboundProcess } from '@features/supplyChain/types';
import { PalletCalculator } from '@components/PalletCalculator';
import { useAuth } from '@context';
import { UserRoleService } from '@services';

interface ShipmentNoteDetailsProps {
  selectedInboundProcess: InboundProcess;
  onClose: () => void;
  primaryAction?: () => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function ShipmentNoteDetails({
  selectedInboundProcess,
  onClose,
  primaryAction,
  isDesktop,
  isTablet,
}: ShipmentNoteDetailsProps) {
  const { shipmentNote, goodsReceivedNote } = selectedInboundProcess;
  const { user } = useAuth();
  const { isWarehouseManager } = UserRoleService();

  return (
    <>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipment note number
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNote.shipmentNoteNumber}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNote.createdByName}
            </Text>
          </Stack>
          {isWarehouseManager(user) && (
            <Stack>
              <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
                Client name
              </Text>
              <Text size={isDesktop ? 'md' : 'sm'}>
                {selectedInboundProcess.userBusinessName}
              </Text>
            </Stack>
          )}

          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(shipmentNote.createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Warehouse Name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNote.warehouse?.name}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Warehouse Address
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {shipmentNote.warehouse?.address}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(shipmentNote.estimatedTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          {goodsReceivedNote?.finalisedAt && (
            <Stack>
              <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
                Date of arrival
              </Text>
              <Text size={isDesktop ? 'md' : 'sm'}>
                {dayjs(goodsReceivedNote.finalisedAt).format('DD/MM/YYYY')}
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 1', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 3' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                UoM
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU Q-ty
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                Pallets
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem'>
          {shipmentNote.skus.map((sku, index) => (
            <>
              <Box
                key={sku.skuId}
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                columnGap='1rem'
                rowGap='1rem'
                alignItems={isDesktop ? 'center' : 'flex-start'}
              >
                {/* SKU index  */}
                <Stack gridColumn='1 / 2' justifyContent='center'>
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '2 / 4' : isTablet ? 'span 5' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU ID
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuIdentifier}
                  </Text>
                </Stack>
                {/* Product name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '4 / 7' : isTablet ? 'span 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Product name
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.productName}
                  </Text>
                </Stack>
                {/* Sku name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '7 / 9' : isTablet ? '2 / 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      UoM
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.name}
                  </Text>
                </Stack>
                {/* Despatched quantity */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '9 / 11' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU Q-ty
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuQuantity}
                  </Text>
                </Stack>
                {/* Pallets */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? '11 / 14' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Pallets
                    </Text>
                  )}
                  <PalletCalculator
                    palletName={sku.packagingDetails.packagingInfo.type}
                    skuQuantity={sku.skuQuantity}
                    amountOfSkusInLayer={
                      sku.packagingDetails.packagingInfo.amountOfSkusInLayer
                    }
                    amountOfLayersInPallet={
                      sku.packagingDetails.packagingInfo.amountOfLayersInPallet
                    }
                  />
                </Stack>
              </Box>
              {index !== shipmentNote.skus.length - 1 && (
                <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
              )}
            </>
          ))}
        </Stack>
      </Stack>
      {shipmentNote.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {shipmentNote.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction={isDesktop ? 'row' : 'column-reverse'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          size='large'
          variant='outlined'
          fullWidth={!isDesktop}
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Close
        </Button>
        {!selectedInboundProcess?.goodsReceivedNote && primaryAction && (
          <Button
            size='large'
            variant='contained'
            fullWidth={!isDesktop}
            startIcon={<NoteAdd />}
            sx={{ textTransform: 'none' }}
            onClick={primaryAction}
          >
            Create draft goods received note
          </Button>
        )}
      </Stack>
    </>
  );
}
