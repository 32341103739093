/* eslint-disable no-nested-ternary */
import { useAuth } from '@context';
import { UserRoleService } from '@services';
import {
  WarehouseClientNoticeboard,
  WarehouseManagerNoticeboard,
} from './components';

export function Noticeboard() {
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();

  return isWarehouseClient(user) ? (
    <WarehouseClientNoticeboard />
  ) : isWarehouseManager(user) ? (
    <WarehouseManagerNoticeboard />
  ) : null;
}
