import { Heading } from '@components/Heading';
import { InboundProcess } from '@features/supplyChain/types';
import { NoteAdd, Check, Search } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '@components/Text';
import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import {
  SidebarAccordion,
  SidebarAccordionContainer,
  SidebarAccordionDetails,
  SidebarAccordionSummary,
} from '@components/SidebarAccordion';
import { Stack } from '@mui/material';
import { DraftGoodsReceivedNoteSummary } from './DraftGoodsReceivedNoteSummary';
import { GoodsReceivedNoteSummary } from './GoodsReceivedNoteSummary';
import { getNoticeColors } from '../utils';

interface WarehouseManagerInboundSideDrawerProps {
  selectedInboundProcess: InboundProcess;
  setSelectedInboundProcess: (payload: InboundProcess | null) => void;
  selectedInboundProcessHasDescription: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  setIsViewShipmentNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsSignOffGoodsReceivedNoteFormOpen: Dispatch<SetStateAction<boolean>>;
  setIsCreateDraftGoodsReceivedNoteFormOpen: Dispatch<SetStateAction<boolean>>;
  setIsViewGoodsReceivedNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  selectedProcessChildType: string | null;
  setSelectedProcessChildType: (payload: string | null) => void;
}

export function WarehouseManagerInboundSideDrawer({
  selectedInboundProcess,
  setSelectedInboundProcess,
  selectedInboundProcessHasDescription,
  isOpen,
  setIsOpen,
  isDesktop,
  setIsViewShipmentNoteDialogOpen,
  setIsSignOffGoodsReceivedNoteFormOpen,
  setIsCreateDraftGoodsReceivedNoteFormOpen,
  setIsViewGoodsReceivedNoteDialogOpen,
  selectedProcessChildType,
  setSelectedProcessChildType,
}: WarehouseManagerInboundSideDrawerProps) {
  const { notices, goodsReceivedNote, shipmentNote } = selectedInboundProcess;

  const totalItemsQuantity = shipmentNote.skus.reduce(
    (prev, current) => prev + current.skuQuantity,
    0
  );

  const totalReceivedItemsQuantity = goodsReceivedNote?.skus.reduce(
    (prev, current) => prev + current.receivedSkuQuantity,
    0
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isDiscrepancy = totalReceivedItemsQuantity! !== totalItemsQuantity;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const discrepancyAmount = totalItemsQuantity - totalReceivedItemsQuantity!;
  const isNegativeDiscrepancy = discrepancyAmount > 0;

  const hasIssues = (notices?.length ?? 0) > 0;
  const isProcessComplete = !!goodsReceivedNote?.finalisedAt;
  const hasIssuesBeforeProcessIsComplete = hasIssues && !isProcessComplete;

  const onClose = () => {
    setIsOpen(false);
    setSelectedInboundProcess(null);
    setSelectedProcessChildType(null);
  };

  return (
    <Drawer
      hideBackdrop
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={onClose}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: isDesktop ? '32rem' : '100%',
          p: 16,
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          size='medium'
          sx={{
            height: '2.5rem',
            color: (theme) => theme.palette.grey[500],
            mb: '1rem',
            ...(!isDesktop && {
              alignSelf: 'flex-end',
            }),
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ p: 8, width: '100%' }}>
          <Heading as='h2' size='sm' css={{ mb: '$space32' }}>
            Inbound Shipment Note
          </Heading>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Status: </strong>
            </Text>
            <Text as='span' size='md'>
              {selectedInboundProcess?.status}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Customer: </strong>
            </Text>
            <Text as='span' size='md'>
              {selectedInboundProcess?.userBusinessName}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Supplier: </strong>
            </Text>
            <Text as='span' size='md'>
              {selectedInboundProcess?.shipmentNote.supplierName}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Shipment note #: </strong>
            </Text>
            <Text as='span' size='md'>
              {selectedInboundProcess?.shipmentNote?.shipmentNoteNumber}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Creation date: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(selectedInboundProcess?.shipmentNote?.createdAt).format(
                'DD/MM/YYYY'
              )}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>ETA: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(
                selectedInboundProcess?.shipmentNote.estimatedTimeOfArrival
              ).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>SKUs: </strong>
            </Text>
            <Text as='span' size='md'>
              {selectedInboundProcess?.shipmentNote.skus.length}
            </Text>
          </Stack>
          {selectedInboundProcessHasDescription && (
            <Stack gap={8} sx={{ mb: hasIssues ? 16 : 32 }}>
              <Text as='h3' size='md'>
                <strong>Description</strong>
              </Text>
              <Text size='md'>{selectedInboundProcess?.description}</Text>
            </Stack>
          )}
          {hasIssues && (
            <>
              <Text as='h3' size='md' css={{ mb: 16 }}>
                <strong>Issues</strong>
              </Text>
              <Stack
                gap={8}
                sx={{
                  mb: 32,
                }}
              >
                {selectedInboundProcess?.notices?.map((notice) => (
                  <Stack
                    key={notice}
                    direction='row'
                    alignItems='center'
                    gap={8}
                    sx={{
                      backgroundColor: getNoticeColors(
                        hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                      ).backgroundColor,
                      p: 8,
                      borderRadius: 2,
                    }}
                  >
                    <ErrorIcon
                      sx={{
                        width: 16,
                        height: 16,
                        fill: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).fill,
                      }}
                    />
                    <Text
                      size='sm'
                      css={{
                        color: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).color,
                      }}
                    >
                      {notice}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          <Button
            variant='contained'
            size='large'
            startIcon={<Search />}
            fullWidth
            sx={{ textTransform: 'none', mb: 16 }}
            onClick={() => {
              setIsOpen(false);
              setIsViewShipmentNoteDialogOpen(true);
            }}
          >
            View Shipment Note
          </Button>
          {(selectedInboundProcess?.goodsReceivedNote?.finalisedAt === null ||
            selectedInboundProcess?.goodsReceivedNote?.finalisedAt) && (
            <SidebarAccordionContainer sx={{ mb: 16 }}>
              <SidebarAccordion
                expanded={selectedProcessChildType === 'goodsReceivedNote'}
                setSelectedProcessChildType={setSelectedProcessChildType}
              >
                <SidebarAccordionSummary
                  title='Goods Received Note'
                  ariaControls='goods-received-note-content'
                  id='goods-received-note-header'
                />
                <SidebarAccordionDetails>
                  <Stack gap={8}>
                    {selectedInboundProcess?.goodsReceivedNote?.finalisedAt ===
                      null && (
                      <>
                        <DraftGoodsReceivedNoteSummary
                          selectedInboundProcess={selectedInboundProcess}
                          totalReceivedItemsQuantity={
                            totalReceivedItemsQuantity
                          }
                          isDiscrepancy={isDiscrepancy}
                          discrepancyAmount={discrepancyAmount}
                          isNegativeDiscrepancy={isNegativeDiscrepancy}
                        />
                        <Button
                          variant='contained'
                          size='large'
                          startIcon={<Search />}
                          fullWidth
                          sx={{ textTransform: 'none', mt: 8 }}
                          onClick={() => {
                            setIsOpen(false);
                            setIsSignOffGoodsReceivedNoteFormOpen(true);
                          }}
                        >
                          View Draft Goods Received Note
                        </Button>
                        <Button
                          variant='contained'
                          size='large'
                          startIcon={<Check />}
                          fullWidth
                          sx={{ textTransform: 'none', mt: 8 }}
                          onClick={() => {
                            setIsSignOffGoodsReceivedNoteFormOpen(true);
                          }}
                        >
                          Sign Off Goods Received Note
                        </Button>
                      </>
                    )}
                    {selectedInboundProcess?.goodsReceivedNote?.finalisedAt && (
                      <>
                        <GoodsReceivedNoteSummary
                          selectedInboundProcess={selectedInboundProcess}
                          totalReceivedItemsQuantity={
                            totalReceivedItemsQuantity
                          }
                          isDiscrepancy={isDiscrepancy}
                          discrepancyAmount={discrepancyAmount}
                          isNegativeDiscrepancy={isNegativeDiscrepancy}
                        />
                        <Button
                          variant='contained'
                          size='large'
                          startIcon={<Search />}
                          fullWidth
                          sx={{ textTransform: 'none', mt: 8 }}
                          onClick={() => {
                            setIsOpen(false);
                            setIsViewGoodsReceivedNoteDialogOpen(true);
                          }}
                        >
                          View Goods Received Note
                        </Button>
                      </>
                    )}
                  </Stack>
                </SidebarAccordionDetails>
              </SidebarAccordion>
            </SidebarAccordionContainer>
          )}
          {(selectedInboundProcess?.goodsReceivedNote === null ||
            selectedInboundProcess?.goodsReceivedNote?.finalisedAt ===
              null) && (
            <Text size='lg' weight='bold' css={{ mb: 16 }}>
              Quick Actions
            </Text>
          )}
          <Stack gap={16}>
            {selectedInboundProcess?.goodsReceivedNote === null && (
              <Button
                variant='contained'
                size='large'
                startIcon={<NoteAdd />}
                fullWidth
                sx={{
                  textTransform: 'none',
                }}
                onClick={() => {
                  setIsCreateDraftGoodsReceivedNoteFormOpen(true);
                }}
              >
                Create Draft Goods Received Note
              </Button>
            )}
            {selectedInboundProcess?.goodsReceivedNote?.finalisedAt ===
              null && (
              <Button
                variant='contained'
                size='large'
                startIcon={<Check />}
                fullWidth
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  setIsSignOffGoodsReceivedNoteFormOpen(true);
                }}
              >
                Sign Off Goods Received Note
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}
