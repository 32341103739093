import { Heading } from '@components/Heading';
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Modal, ModalContainer, ModalHeader } from '@components/Modal';
import { View } from '@views/View';
import { useEffect, useState } from 'react';
import { Icon, IconContainer } from '@components/Icon';
import { useAtom, useSetAtom } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import moment from 'moment';
import { Vehicle, VehiclesTable } from './VehicleTable';
import {
  createdVehicleAvailabilityIdsAtom,
  fullCapacityVehiclesDayAfterTomorrowAtom,
  fullCapacityVehiclesTomorrowAtom,
  isPartialAvailabilityModalOpenAtom,
  partialAvaiabilityVehicleIdAtom,
  tomorrowOrDayAfterAtom,
  vehicleAvailabilityIdsAtom,
} from '../../store/jotai';
import {
  PartialAvailabilityForm,
  PartialAvailabilityFormValues,
} from './PartialAvailabilityForm';
import { useAuthHttp } from '../../hooks';
import {
  FLEET_AVAILABILITY,
  FLEET_AVAILABILITY_CREATE,
  FLEET_AVAILABILITY_UPDATE,
} from '../../constants';

export function FleetAvailability() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const authHttp = useAuthHttp();
  const authHttpFormData = useAuthHttp('multipart/form-data');
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [vehicleAvailabilities, setVehicleAvailabilities] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isPartialAvailabilityModalOpen, setIsPartialAvailabilityModalOpen] =
    useAtom(isPartialAvailabilityModalOpenAtom);

  const [partialAvaiabilityVehicleId, setPartialAvailabilityVehicleId] =
    useAtom(partialAvaiabilityVehicleIdAtom);

  const [tomorrowOrDayAfter, setTomorrowOrDayAfter] = useAtom(
    tomorrowOrDayAfterAtom
  );

  const setFullCapacityVehiclesTomorrow = useSetAtom(
    fullCapacityVehiclesTomorrowAtom
  );

  const setFullCapacityVehiclesDayAfterTomorrow = useSetAtom(
    fullCapacityVehiclesDayAfterTomorrowAtom
  );

  const [createdVehicleAvailabilityIds, setCreatedVehicleAvailabilityIds] =
    useAtom(createdVehicleAvailabilityIdsAtom);

  const [vehicleAvailabilitiesIds, setVehicleAvailabilityIds] = useAtom(
    vehicleAvailabilityIdsAtom
  );

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newDay: string
  ) => {
    setTomorrowOrDayAfter(newDay);
  };

  const fetchFleet = async () => {
    try {
      const { data } = await authHttp.post(FLEET_AVAILABILITY, {
        date: moment()
          .add(tomorrowOrDayAfter === 'tomorrow' ? 1 : 2, 'days')
          .format('YYYY-MM-DD'),
      });

      if (data) {
        setVehicles(data.vehiclesAvailable);
        setVehicleAvailabilities(data.vehicleAvailabilities);

        // eslint-disable-next-line array-callback-return
        data.vehicleAvailabilities.map((vehicleAvailability: any) => {
          setCreatedVehicleAvailabilityIds((oldArray) => [
            ...oldArray,
            {
              vehicleId: vehicleAvailability.vehicle.vehicleId,
              date: vehicleAvailability.availabilityDate,
            },
          ]);
          setVehicleAvailabilityIds((oldArray) => [
            ...oldArray,
            {
              availabilityId: vehicleAvailability.availabilityId,
              vehicleId: vehicleAvailability.vehicle.vehicleId,
              date: vehicleAvailability.availabilityDate,
            },
          ]);
          if (vehicleAvailability.isFullyAvailable) {
            const availabilityDate = moment(
              vehicleAvailability.availabilityDate
            );
            const tomorrow = moment().add(1, 'days');
            const dayAfterTomorrow = moment().add(2, 'days');

            if (availabilityDate.isSame(tomorrow, 'day')) {
              setFullCapacityVehiclesTomorrow((oldArray) => [
                ...oldArray,
                vehicleAvailability.vehicle.vehicleId,
              ]);
            } else if (availabilityDate.isSame(dayAfterTomorrow, 'day')) {
              setFullCapacityVehiclesDayAfterTomorrow((oldArray) => [
                ...oldArray,
                vehicleAvailability.vehicle.vehicleId,
              ]);
            }
          }
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleOnSubmit = async (data: PartialAvailabilityFormValues) => {
    const tomorrow = moment().add(1, 'days');
    const dayAfterTomorrow = moment().add(2, 'days');
    setIsSubmitting(true);
    try {
      const { data: vehicleAvailabilitiesData } = await authHttp.post(
        createdVehicleAvailabilityIds.find(
          (availability) =>
            availability.vehicleId === partialAvaiabilityVehicleId &&
            moment(availability.date).isSame(
              tomorrowOrDayAfter === 'tomorrow' ? tomorrow : dayAfterTomorrow,
              'day'
            )
        )
          ? FLEET_AVAILABILITY_UPDATE
          : FLEET_AVAILABILITY_CREATE,
        {
          vehicleAvailability: {
            availabilityId:
              vehicleAvailabilitiesIds.find(
                (availability) =>
                  availability.vehicleId === partialAvaiabilityVehicleId &&
                  moment(availability.date).isSame(
                    tomorrowOrDayAfter === 'tomorrow'
                      ? tomorrow
                      : dayAfterTomorrow,
                    'day'
                  )
              )?.availabilityId ?? undefined,
            vehicleId: partialAvaiabilityVehicleId,
            availabilityDate: moment()
              .add(tomorrowOrDayAfter === 'tomorrow' ? 1 : 2, 'days')
              .format('YYYY-MM-DD'),
            isFullyAvailable: false,
            partialAvailabilityPickUps: data.pickUpDetails.map((detail) => ({
              locationAddress: detail.location,
              geoLocation: {
                lat: detail.geoLocation.lat,
                lon: detail.geoLocation.lon,
              },
              departureTime: `${detail.departureTime}:00`,
              loadSizeInPallets: detail.loadSize.pallets,
              loadSizeInKilograms: detail.loadSize.weight,
            })),
            partialAvailabilityDropOffs: data.dropOffDetails.map((detail) => ({
              locationAddress: detail.location,
              geoLocation: {
                lat: detail.geoLocation.lat,
                lon: detail.geoLocation.lon,
              },
              latestDropOffTime: `${detail.latestDropOffTime}:00`,
              loadSizeInPallets: detail.loadSize.pallets,
              loadSizeInKilograms: detail.loadSize.weight,
            })),
            additionalDiscountPercentage: data.additionalDiscount,
          },
        }
      );

      if (vehicleAvailabilitiesData) {
        fetchFleet();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setIsPartialAvailabilityModalOpen(false);
      setPartialAvailabilityVehicleId('');
    }
  };

  useEffect(() => {
    fetchFleet();
  }, [tomorrowOrDayAfter]);

  return (
    <View>
      <Stack
        gap={16}
        direction={isTablet ? 'row' : 'column'}
        justifyContent='space-between'
        alignItems={isTablet ? 'center' : 'flex-start'}
      >
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Fleet Availability
        </Heading>
        <Stack direction='row' gap={16} width={isTablet ? 'auto' : '100%'}>
          <ToggleButtonGroup
            value={tomorrowOrDayAfter}
            exclusive
            onChange={handleToggleChange}
            aria-label='Platform'
          >
            <ToggleButton value='tomorrow'>
              Tomorrow ({`${moment().add(1, 'days').format('DD/MM')}`})
            </ToggleButton>
            <ToggleButton value='dayAfterTomorrow'>
              Day after tomorrow ({`${moment().add(2, 'days').format('DD/MM')}`}
              )
            </ToggleButton>
          </ToggleButtonGroup>
          {/* <Stack direction='row' gap={16} width={isTablet ? 'auto' : '100%'}>
            <Button
              variant='contained'
              size='large'
              startIcon={isSubmitting ? undefined : <UploadIcon />}
              endIcon={
                isSubmitting ? (
                  <IconContainer aria-hidden isLoading>
                    <Icon name='loading' />
                  </IconContainer>
                ) : undefined
              }
              onClick={handleOnSubmit}
              sx={{ textTransform: 'none' }}
              fullWidth
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </Button>
          </Stack> */}
        </Stack>
      </Stack>
      {vehicles.length > 0 ? (
        <VehiclesTable
          vehicles={vehicles}
          vehicleAvailabilities={vehicleAvailabilities}
          fetchFleet={fetchFleet}
        />
      ) : (
        <Stack
          gap={12}
          sx={{ width: isDesktop ? '20%' : '100%' }}
          marginX='auto'
        >
          <Typography variant='body1' align='center'>
            No vehicles available
          </Typography>
          <Button variant='contained' href='/fleet-master'>
            Go to Fleet Master
          </Button>
        </Stack>
      )}

      <Modal
        open={isPartialAvailabilityModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsPartialAvailabilityModalOpen(isOpen);
          setPartialAvailabilityVehicleId('');
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader
            title={
              tomorrowOrDayAfter === 'tomorrow'
                ? `Add Partial Availability: for Tomorrow (${moment()
                    .add(1, 'days')
                    .format('DD/MM')})`
                : `Add Partial Availability: for Day after tomorrow (${moment()
                    .add(2, 'days')
                    .format('DD/MM')})`
            }
          />
          <PartialAvailabilityForm
            vehicles={vehicles}
            vehicleAvailabilities={vehicleAvailabilities}
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        </ModalContainer>
      </Modal>
    </View>
  );
}
