import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { View } from '@views/View';
import { Heading } from '@components/Heading';
import { Box, IconButton, Stack } from '@mui/material';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Warehouse } from '@views/WarehouseDetails/WarehouseDetails';
import { getNoticeColors } from '@views/Noticeboard/utils';
import ErrorIcon from '@mui/icons-material/Error';
import { classNames } from '../../utils/classes';
import {
  DELETE_WAREHOUSE_MATCH,
  MATCH_LIST,
  SUPPORTED_CURRENCY_GROUPS,
  WAREHOUSE_IMAGES_URL,
} from '../../constants';
import { useAuthHttp } from '../../hooks';

type WarehouseMatch = Warehouse & {
  matchStatus: string;
  matchId: string;
  isActionRequiredByShipper: boolean;
};

export function WarehouseShortlist() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [warehouses, setWarehouses] = useState<WarehouseMatch[]>([]);
  const [isWarehousesLoading, setIsWarehousesLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const authHttp = useAuthHttp();
  const navigate = useNavigate();

  const fetchWarehouseShortlist = async () => {
    setIsWarehousesLoading(true);
    try {
      const { data: warehouseShortlist } = await authHttp.post(
        MATCH_LIST,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (warehouseShortlist.matches.length > 0) {
        const newWarehousesMatches = warehouseShortlist.matches.map(
          (match: any) => ({
            ...match.warehouse,
            matchStatus: match.match.matchStatus,
            matchId: match.match.id,
            isActionRequiredByShipper: match.match.isActionRequiredByShipper,
          })
        );

        setWarehouses(newWarehousesMatches);
      } else {
        setWarehouses([]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsWarehousesLoading(false);
    }
  };

  const handleDeleteMatch = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    matchId: string
  ) => {
    e.stopPropagation();
    setIsWarehousesLoading(true);
    try {
      const { data: matchDeleted } = await authHttp.post(
        DELETE_WAREHOUSE_MATCH,
        {
          match: {
            matchId,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (matchDeleted.match) {
        fetchWarehouseShortlist();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsWarehousesLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouseShortlist();
  }, []);

  const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
    (currencyGroup) => currencyGroup.currencies
  );

  let currencySymbol: string | undefined;

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Shortlisted & contracted LSPs
        </Heading>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                <th
                  scope='col'
                  className='sticky py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  <span className='sr-only'>Expand</span>
                </th>
                <th
                  scope='col'
                  className='sticky py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  <span className='sr-only'>Image</span>
                </th>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Location
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Size/Available (in UoMs)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Rates (per UoM)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Own fleet
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Status
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
                  <span className='sr-only'>Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isWarehousesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='warehouse-loader'
                      loadingMessage='Loading warehouses...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              )}
              {warehouses.length === 0 && !isWarehousesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <Stack
                      direction='column'
                      alignItems='center'
                      spacing={2}
                      sx={{ gridColumn: '1 / -1' }}
                    >
                      <span className='text-gray-900 text-md font-semibold'>
                        No warehouses found. Find a warehouse using our{' '}
                        <a
                          href='/warehouse-search'
                          className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                        >
                          search
                        </a>{' '}
                        tool
                      </span>
                    </Stack>
                  </td>
                </tr>
              )}
              {warehouses.map((warehouse, index) => {
                if (warehouse.currency) {
                  currencySymbol = currencies.find(
                    (currency) => currency.code === warehouse.currency
                  )?.symbol;
                }

                let vans = 0;
                let ldv = 0;
                let hdv = 0;

                if (Object.keys(warehouse.fleet).length !== 0) {
                  Object.keys(warehouse.fleet).forEach((key) => {
                    if (
                      key === 'MultiAxleArticMoreThan26t' ||
                      key === 'TwoAxleRigidLessThan26t'
                    ) {
                      hdv += warehouse.fleet[key];
                    } else if (
                      key === 'LgvLessThan3AndHalft' ||
                      key === 'VanLessThan1AndHalft'
                    ) {
                      vans += warehouse.fleet[key];
                    } else if (
                      key === 'TwoAxleRigid18t' ||
                      key === 'TwoAxleRigidLessThan7AndHalf5t' ||
                      key === 'SpecialOrOther'
                    ) {
                      ldv += warehouse.fleet[key];
                    } else {
                      return null;
                    }

                    return null;
                  });
                }

                const setRowOpen = (
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  const newExpandedRows = new Set(expandedRows);
                  if (expandedRows.has(index)) {
                    newExpandedRows.delete(index);
                  } else {
                    newExpandedRows.add(index);
                  }
                  setExpandedRows(newExpandedRows);
                };
                return (
                  <>
                    <tr
                      key={warehouse.name}
                      className={classNames(
                        'border-gray-200',
                        'border-t',
                        'hover:bg-gray-50',
                        'transition-colors',
                        'duration-200',
                        'ease-in-out',
                        'cursor-pointer',
                        expandedRows.has(index) ? 'bg-gray-50' : ''
                      )}
                      onClick={() => {
                        navigate(`/warehouse/${warehouse.warehouseId}`);
                      }}
                    >
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                        <IconButton
                          aria-label='expand row'
                          size='small'
                          onClick={(e) => setRowOpen(e)}
                          sx={{ color: 'var(--colors-gray11)' }}
                        >
                          {expandedRows.has(index) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </td>
                      <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                        {warehouse.images.length > 0 ? (
                          <Box
                            component='img'
                            sx={{
                              height: 100,
                              display: 'block',
                              maxWidth: 400,
                              overflow: 'hidden',
                            }}
                            src={`${WAREHOUSE_IMAGES_URL}/${warehouse.images[0].imageLocation}/small/${warehouse.images[0].remoteName}`}
                          />
                        ) : (
                          <span className='sr-only' />
                        )}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                        {warehouse.name}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        {warehouse.address}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        {warehouse.maxStorageCapacity} /{' '}
                        {warehouse.currentlyAvailableCapacity}{' '}
                        {warehouse.storageCapacityUnitOfMeasure}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        Storage: {currencySymbol}
                        {warehouse.palletStoragePricePerWeek} per{' '}
                        {warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                          ? 'pallet-week'
                          : warehouse.storageCapacityUnitOfMeasure.toLowerCase()}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        {Object.entries(warehouse.fleet).length
                          ? `${Object.values(warehouse.fleet).reduce(
                              (a, b) => a + b,
                              0
                            )} vehicles`
                          : 'None'}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        {warehouse.matchStatus}{' '}
                        {warehouse.isActionRequiredByShipper && (
                          <ErrorIcon
                            sx={{
                              width: 16,
                              height: 16,
                              fill: getNoticeColors('warning').fill,
                            }}
                          />
                        )}
                      </td>
                      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                        {warehouse.matchStatus === 'Not engaged' ||
                        warehouse.matchStatus === 'Negotiating' ? (
                          <button
                            type='button'
                            className='inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                            onClick={(e) =>
                              handleDeleteMatch(e, warehouse.matchId)
                            }
                          >
                            <MdDelete
                              className='-ml-0.5 h-5 w-5'
                              aria-hidden='true'
                            />
                            Delete
                          </button>
                        ) : (
                          <span className='sr-only' />
                        )}
                      </td>
                    </tr>
                    {expandedRows.has(index) && (
                      <tr>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                          <span className='sr-only' />
                        </td>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                          <span className='sr-only' />
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {warehouse.warehouseOperations
                            .slice(0, 5)
                            .map((operation) => (
                              <div key={operation}>{operation}</div>
                            ))}
                          {warehouse.warehouseOperations.length > 5 && (
                            <div>Other</div>
                          )}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                          <span className='sr-only' />
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          <div>
                            {warehouse.isRackingAvailable &&
                              `${warehouse.individualRackDepth}mm Rack depth`}
                          </div>
                          <div>
                            {warehouse.isRackingAvailable &&
                              `${warehouse.totalRackHeight}mm Rack height`}
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          <div>
                            Pallet picking: {currencySymbol}
                            {warehouse.palletPickingAndDespatchPrice || 0} per
                            pallet
                          </div>
                          <div>
                            Case picking: {currencySymbol}
                            {warehouse.casePickingAndDespatchPrice || 0} per
                            case
                          </div>
                          <div>
                            Man-hour: {currencySymbol}
                            {warehouse.avgPricePerManHour || 0} per man hour
                          </div>
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {`${vans} Van${vans > 1 ? 's' : ''}, ${ldv} LDV${
                            ldv > 1 ? 's' : ''
                          }, ${hdv} HDV${hdv > 1 ? 's' : ''}`}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                          <span className='sr-only' />
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                          <span className='sr-only' />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </View>
  );
}
