import { useState } from 'react';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { CreateDespatchNotesFormValues } from '@forms/client/CreateDespatchNoteForm/types';
import { CREATE_DESPATCH_NOTE } from '@constants';
import { upsertSingleOutboundProcess } from '@features/supplyChain/supplyChainSlice';
import { useAppDispatch } from '@store';

export const useDespatchNote = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const [isCreateDespatchNoteFormOpen, setIsCreateDespatchNoteFormOpen] =
    useState(false);

  const handleCloseCreateDespatchNoteForm = useCloseDialog(
    setIsCreateDespatchNoteFormOpen
  );

  const handleSubmitDespatchNote = async (
    values: CreateDespatchNotesFormValues
  ) => {
    try {
      const formValues = {
        despatchNote: {
          ...values,
          skus: values.skus.map((sku) => ({
            palletising: {
              palletisingId: sku.palletising.palletisingId,
              palletisingType: sku.palletising.palletisingType,
              description: sku.palletising.description,
              hasAgreedToLspShipmentOption:
                sku.palletising.hasAgreedToLspShipmentOption,
            },
            skuId: sku.skuId,
            productId: sku.productId,
            skuQuantity: sku.skuQuantity,
          })),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(CREATE_DESPATCH_NOTE, formValues);
      dispatch(upsertSingleOutboundProcess(supplyChainProcess));
      setIsCreateDespatchNoteFormOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isCreateDespatchNoteFormOpen,
    setIsCreateDespatchNoteFormOpen,
    handleCloseCreateDespatchNoteForm,
    handleSubmitDespatchNote,
  };
};
