export const getPallets = (
  quantity: number,
  amountOfSkusInLayer: number,
  amountOfLayersInPallet: number
) => {
  const result = quantity / (amountOfSkusInLayer * amountOfLayersInPallet);
  return result.toFixed(1) === '0.0' ? 'less than 1' : result.toFixed(1);
};

export const getTotalPallets = (
  quantity: number,
  amountOfSkusInLayer: number,
  amountOfLayersInPallet: number
) => Math.ceil(quantity / (amountOfSkusInLayer * amountOfLayersInPallet));
