import { View } from '@views/View';
import { Fragment, useEffect, useState } from 'react';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { Stack, Typography } from '@mui/material';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { useAtom } from 'jotai';
import { MdAdd, MdOutlineInfo } from 'react-icons/md';
import { useMediaQuery } from 'usehooks-ts';
import {
  SupplierForm,
  SupplierFormValues,
} from '@forms/AddSupplierForm/SupplierForm';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { StyledSupplierHeaderContainer } from './SupplierMasterStyles';
import { isSupplierModalOpenAtom } from '../../store/jotai';
import { useAuthHttp } from '../../hooks';
import { SUPPLIER_CREATE, SUPPLIER_GET } from '../../constants';

export type Supplier = {
  supplierId: string;
  name: string;
  type: string;
  contactName: string;
  contactEmail: string;
  hqId: string;
  hqName: string;
  address: string;
  country: string;
  postcode: string;
  id: string;
};

export function SupplierMaster() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(true);
  const authHttp = useAuthHttp();
  const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] = useAtom(
    isSupplierModalOpenAtom
  );

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  const fetchSuppliers = async () => {
    try {
      const { data } = await authHttp.post(SUPPLIER_GET, {
        pagination: {
          itemsPerPage: isDesktop ? 100 : 25,
        },
      });

      if (data) setSuppliers(data.suppliers);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSuppliersLoading(false);
      setIsAddSupplierModalOpen(false);
    }
  };

  const handleOnSubmit = async (data: SupplierFormValues) => {
    setIsSubmitting(true);
    try {
      const { data: createSupplierData } = await authHttp.post(
        SUPPLIER_CREATE,
        {
          supplier: data,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (createSupplierData) {
        fetchSuppliers();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const groupByHqName = (supplierList: Supplier[]) => {
    const grouped = supplierList.reduce((groupedSuppliers, supplier) => {
      const key = supplier.hqName;
      const groupedSuppliersCopy = { ...groupedSuppliers };
      if (!groupedSuppliersCopy[key]) {
        groupedSuppliersCopy[key] = [];
      }
      groupedSuppliersCopy[key].push(supplier);
      return groupedSuppliersCopy;
    }, {} as Record<string, Supplier[]>);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return Object.entries(grouped).map(([hqName, suppliers]) => ({
      hqName,
      suppliers,
    }));
  };

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <StyledSupplierHeaderContainer>
          <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
            Supplier master
          </Heading>
          <Stack sx={{ flexDirection: isDesktop ? 'row' : 'column' }} gap={12}>
            {/* <Button
              variant='primary'
              action='cta'
              size={isDesktop ? 'md' : 'sm'}
              leftIcon={<MdAdd size='1.5rem' />}
              //   onClick={() => setIsUploadFleetModalOpen(true)}
            >
              Upload Customer Master
            </Button> */}
            <Button
              variant='primary'
              action='cta'
              size={isDesktop ? 'md' : 'sm'}
              leftIcon={<MdAdd size='1.5rem' />}
              onClick={() => setIsAddSupplierModalOpen(true)}
            >
              Add Supplier
            </Button>
          </Stack>
        </StyledSupplierHeaderContainer>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                <th
                  scope='col'
                  className='sticky py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Supplier ID
                </th>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Supplier Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Supplier Type
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Contact Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Contact Email
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  HQ ID
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  HQ Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Address
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Postcode
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Country
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
                  <span className='sr-only'>Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isSuppliersLoading ? (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='supplier-loader'
                      loadingMessage='Loading suppliers...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              ) : (
                groupByHqName(suppliers).map((group) => (
                  <Fragment key={group.hqName}>
                    <tr className='border-t border-gray-200'>
                      <th
                        colSpan={5}
                        scope='colgroup'
                        className='bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                      >
                        {group.hqName}
                      </th>
                    </tr>
                    {group.suppliers.map((supplier) => (
                      <tr
                        key={supplier.supplierId}
                        className={classNames('border-gray-200', 'border-t')}
                      >
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                          {supplier.supplierId}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                          {supplier.name}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.type}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.contactName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.contactEmail}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.hqId}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.hqName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.address}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.postcode}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {supplier.country}
                        </td>
                        {/* <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                                                  <button
                                                    type='button'
                                                    className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                                  >
                                                    Edit
                                                    <span className='sr-only'>, {person.name}</span>
                                                  </button>
                                                </td> */}
                      </tr>
                    ))}
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={isAddSupplierModalOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsAddSupplierModalOpen(isOpen);
        }}
      >
        <ModalContainer preventCloseOnOutsideClick>
          <ModalHeader title='Add Supplier' />
          <SupplierForm
            handleOnSubmit={handleOnSubmit}
            isSubmitting={isSubmitting}
          />
        </ModalContainer>
      </Modal>
    </View>
  );
}
