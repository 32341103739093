/* eslint-disable no-nested-ternary */
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { SxProps } from '@mui/material';

type BoardProps = ComponentPropsWithoutRef<'div'>;

export function Board({ children, ...props }: BoardProps) {
  return (
    <Box
      component='div'
      sx={{
        flex: 1,
        maxHeight: {
          xs: 'calc(100vh - 9rem)',
          lg: 'calc(100vh - 11.375rem)',
        },
        overflowX: 'auto',
        overflowY: 'hidden',
        borderRadius: 'var(--radii-radii8)',
        display: 'flex',
        gap: 16,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

Board.displayName = 'Board';

type BoardLaneProps = ComponentPropsWithoutRef<'div'>;

export function BoardLane({ children, ...props }: BoardLaneProps) {
  return (
    <Box
      component='div'
      sx={{
        width: '100%',
        minWidth: 208,
        backgroundColor: 'var(--colors-gray3)',
        borderRadius: 'var(--radii-radii8)',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

BoardLane.displayName = 'BoardLane';

type BoardLaneHeaderProps = ComponentPropsWithoutRef<'div'>;

export function BoardLaneHeader({ children, ...props }: BoardLaneHeaderProps) {
  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

BoardLaneHeader.displayName = 'BoardLaneHeader';

interface BoardLaneTitleContainerProps extends ComponentPropsWithoutRef<'div'> {
  sx?: SxProps;
}

export function BoardLaneTitleContainer({
  children,
  sx,
  ...props
}: BoardLaneTitleContainerProps) {
  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

BoardLaneTitleContainer.displayName = 'BoardLaneTitleContainer';

type BoardLaneTitleButtonProps = ButtonProps;

export function BoardLaneTitleButton({
  children,
  ...props
}: BoardLaneTitleButtonProps) {
  return (
    <Button
      variant='text'
      size='small'
      fullWidth
      sx={{
        textTransform: 'none',
        fontSize: 'var(--fontSizes-textSm)',
        lineHeight: 'var(--lineHeights-textSm)',
        fontWeight: 'regular',
        color: 'var(--colors-gray-12)',
        p: 0,
        width: 'auto',
        minWidth: 0,
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

BoardLaneTitleButton.displayName = 'BoardLaneTitleButton';

interface BoardLaneItemCountProps extends ComponentPropsWithoutRef<'p'> {
  itemCount: number;
}

export function BoardLaneItemCount({ itemCount }: BoardLaneItemCountProps) {
  return (
    <p
      style={{
        fontSize: 'var(--fontSizes-textXs)',
        lineHeight: 'var(--lineHeights-textXs',
      }}
    >
      {itemCount === 0
        ? 'No items'
        : itemCount === 1
        ? '1 item'
        : `${itemCount} items`}
    </p>
  );
}

BoardLaneItemCount.displayName = 'BoardLaneItemCount';

type BoardListProps = ComponentPropsWithoutRef<'div'>;

export const BoardList = forwardRef<HTMLDivElement, BoardListProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          borderRadius: 'var(--radii-radii8)',
        }}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Box>
    );
  }
);

BoardList.displayName = 'BoardList';
