import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { useAuthHttp, useCloseDialog } from '@hooks';
import {
  LIST_SUPPLY_CHAIN_PROCESSES_URL,
  OUTBOUND_SHIPMENT_TYPE,
} from '@constants';
import { OutboundProcess } from '@features/supplyChain/types';
import {
  addOutboundProcesses,
  setSelectedOutboundProcess as _setSelectedOutboundProcess,
} from '@features/supplyChain';
import { useAuth } from '@context';
import { FilterService } from '@services';

export const useOutboundProcess = () => {
  const authHttp = useAuthHttp();
  const { getFilterValue } = FilterService();
  const noticeboardWarehouseId = getFilterValue('noticeboardWarehouseId');
  const noticeboardWarehouseClientId = getFilterValue(
    'noticeboardWarehouseClientId'
  );
  const dispatch = useAppDispatch();

  const {
    supplyChain: { outboundProcesses },
  } = useAppSelector((state) => state.supplyChain);

  const lastOutboundItem = useAppSelector(
    (state) =>
      state.supplyChain.supplyChain.outboundCurrentQuery.pagination?.lastItem
  );

  const hasMoreOutboundData = useAppSelector(
    (state) => state.supplyChain.supplyChain.hasMoreOutboundData
  );

  const [isLoadingOutboundProcesses, setIsLoadingOutboundProcesses] =
    useState(false);

  const [isLoadingOutboundProcessesError, setIsLoadingOutboundProcessesError] =
    useState(false);

  const [isInitialOutboundProcessesFetch, setIsInitialOutboundProcessesFetch] =
    useState(import.meta.env.MODE === 'development');

  // const [selectedOutboundProcess, setSelectedOutboundProcess] =
  //   useState<OutboundProcess | null>(null);

  const {
    supplyChain: { selectedOutboundProcess },
  } = useAppSelector((state) => state.supplyChain);

  const setSelectedOutboundProcess = (payload: OutboundProcess | null) => {
    dispatch(_setSelectedOutboundProcess(payload));
  };

  const selectedOutboundProcessHasDescription = Boolean(
    selectedOutboundProcess &&
      selectedOutboundProcess?.description &&
      selectedOutboundProcess?.description?.length > 0
  );

  const fetchOutboundSupplyChainProcesses = async () => {
    if (
      isInitialOutboundProcessesFetch ||
      isLoadingOutboundProcesses ||
      isLoadingOutboundProcessesError
    ) {
      setIsInitialOutboundProcessesFetch(false);
      return;
    }

    setIsLoadingOutboundProcesses(true);

    try {
      const { data } = await authHttp.post(LIST_SUPPLY_CHAIN_PROCESSES_URL, {
        searchQuery: '',
        warehouseId:
          noticeboardWarehouseId === 'All' ? null : noticeboardWarehouseId,
        shipperBusinessId:
          noticeboardWarehouseClientId === 'All'
            ? null
            : noticeboardWarehouseClientId,
        type: OUTBOUND_SHIPMENT_TYPE,
        pagination: {
          lastItem: lastOutboundItem,
          itemsPerPage: 25,
        },
      });

      data.hasMoreOutboundData = !(data.supplyChainProcesses.length < 25);
      dispatch(addOutboundProcesses(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error: ', error);
      setIsLoadingOutboundProcessesError(true);
    } finally {
      setIsLoadingOutboundProcesses(false);
    }
  };

  const getOutboundScrollParent = () =>
    document.getElementById('outbound-list') as HTMLElement;

  const handleScrollToTopOfOutbound = () => {
    getOutboundScrollParent().scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [isViewDespatchNoteDialogOpen, setIsViewDespatchNoteDialogOpen] =
    useState(false);

  const handleCloseViewDespatchNote = useCloseDialog(
    setIsViewDespatchNoteDialogOpen
  );

  const [
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewGoodsReceivedNote = useCloseDialog(
    setIsViewGoodsReceivedNoteDialogOpen
  );

  const [
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
  ] = useState(false);

  const handleCloseViewPickingConfirmation = useCloseDialog(
    setIsViewPickingConfirmationDialogOpen
  );

  const [
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
  ] = useState(false);

  const handleCloseViewOutboundShipmentNote = useCloseDialog(
    setIsViewOutboundShipmentNoteDialogOpen
  );

  const [isViewProofOfDeliveryDialogOpen, setIsViewProofOfDeliveryDialogOpen] =
    useState(false);

  const handleCloseViewProofOfDelivery = useCloseDialog(
    setIsViewProofOfDeliveryDialogOpen
  );

  return {
    outboundProcesses,
    fetchOutboundSupplyChainProcesses,
    hasMoreOutboundData,
    isLoadingOutboundProcessesError,
    selectedOutboundProcess,
    setSelectedOutboundProcess,
    selectedOutboundProcessHasDescription,
    getOutboundScrollParent,
    handleScrollToTopOfOutbound,
    isViewDespatchNoteDialogOpen,
    setIsViewDespatchNoteDialogOpen,
    handleCloseViewDespatchNote,
    isViewGoodsReceivedNoteDialogOpen,
    setIsViewGoodsReceivedNoteDialogOpen,
    handleCloseViewGoodsReceivedNote,
    isViewPickingConfirmationDialogOpen,
    setIsViewPickingConfirmationDialogOpen,
    handleCloseViewPickingConfirmation,
    isViewOutboundShipmentNoteDialogOpen,
    setIsViewOutboundShipmentNoteDialogOpen,
    handleCloseViewOutboundShipmentNote,
    isViewProofOfDeliveryDialogOpen,
    setIsViewProofOfDeliveryDialogOpen,
    handleCloseViewProofOfDelivery,
  };
};
