import { useState, useEffect } from 'react';
import { useDebounce } from 'usehooks-ts';
import { DEBOUNCE_TIME_IN_MS } from '@constants';

export const useDebouncedQuery = () => {
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce<string>(query, DEBOUNCE_TIME_IN_MS);

  useEffect(() => {
    setQuery(debouncedQuery);
  }, [debouncedQuery, setQuery]);

  return {
    debouncedQuery,
    setDebouncedQuery: setQuery,
  };
};
