/* eslint-disable no-nested-ternary */
import { Button } from '@components/Button';
import { Dropzone } from '@components/Dropzone';
import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { Text } from '@components/Text';
import { Thumbnail, ThumbnailGrid } from '@components/Thumbnail';
import { Icon } from '@components/Icon';
import { ModalContent, ModalFooter } from '@components/Modal';
import { useEffect, useState } from 'react';
import { MdCheck, MdAdd, MdDelete } from 'react-icons/md';
import { useSetAtom } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import { Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import {
  FormProvider,
  useForm,
  Controller,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserImage } from '@forms/client/AddProductForm/types';
import {
  editVehicleIdAtom,
  isVehicleModalOpenAtom,
} from '../../../store/jotai';
import { useAuth } from '../../../context';
import { FLEET_IMAGES_URL } from '../../../constants';

const FLEET_CATEGORIES = [
  {
    label: 'Van < 1.5t',
    value: 'VanLessThan1AndHalft',
  },
  {
    label: 'LDV < 3.5t',
    value: 'LgvLessThan3AndHalft',
  },
  {
    label: '2 Axle Rigid < 7.5t',
    value: 'TwoAxleRigidLessThan7AndHalf5t',
  },
  {
    label: '2 Axle Rigid < 18t',
    value: 'TwoAxleRigid18t',
  },
  {
    label: '3 Axle Rigid < 26t',
    value: 'ThreeAxleRigidLessThan26t',
  },
  {
    label: 'Multi-Axle Artic > 26t',
    value: 'MultiAxleArticMoreThan26t',
  },
  {
    label: 'Special/Other',
    value: 'SpecialOrOther',
  },
];

const FUEL_TYPES = [
  {
    label: 'Diesel',
    value: 'Diesel',
  },
  {
    label: 'Gas (CNG/LNG)',
    value: 'Gas (CNG / LNG)',
  },
  {
    label: 'Hybrid',
    value: 'Hybrid',
  },
  {
    label: 'Electric',
    value: 'Electric',
  },
  {
    label: 'Hydrogen',
    value: 'Hydrogen',
  },
];

const EMISSION_STANDARDS = [
  {
    label: 'Euro 1',
    value: 'Euro1',
  },
  {
    label: 'Euro 2',
    value: 'Euro2',
  },
  {
    label: 'Euro 3',
    value: 'Euro3',
  },
  {
    label: 'Euro 4',
    value: 'Euro4',
  },
  {
    label: 'Euro 5',
    value: 'Euro5',
  },
  {
    label: 'Euro 6',
    value: 'Euro6',
  },
];

const VEHICLE_CATEGORIES = [
  {
    label: 'Curtain Side / Fixed Box',
    value: 'Curtain Side / Fixed Box',
  },
  {
    label: 'Longer semi-trailer (LST)',
    value: 'Longer semi-trailer (LST)',
  },
  {
    label: 'Refrigerated',
    value: 'Refrigerated',
  },
  {
    label: 'Flatbed',
    value: 'Flatbed',
  },
  {
    label: 'Container Trailer',
    value: 'Container Trailer',
  },
  {
    label: 'Draw-bar Trailer',
    value: 'Draw-bar Trailer',
  },
  {
    label: 'Tanker / Silo',
    value: 'Tanker / Silo',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const AVERAGE_ANNUAL_MILEAGE_UNITS = [
  {
    label: 'km',
    value: 'km',
  },
  {
    label: 'miles',
    value: 'miles',
  },
];

const DIMENSIONS_UNITS_OF_MEASURE = [
  {
    label: 'mm',
    value: 'mm',
  },
  {
    label: 'cm',
    value: 'cm',
  },
  {
    label: 'dm',
    value: 'dm',
  },
  {
    label: 'm',
    value: 'm',
  },
];

const SPECIAL_EQUIPMENT = [
  {
    label: 'Double deck (fixed)',
    value: 'Double deck (fixed)',
  },
  {
    label: 'Double deck (moving)',
    value: 'Double deck (moving)',
  },
  {
    label: 'High Cube Step deck',
    value: 'High Cube Step deck',
  },
  {
    label: 'Swap Body',
    value: 'Swap Body',
  },
  {
    label: 'Chilled (2 - 8C)',
    value: 'Chilled (2 - 8C)',
  },
  {
    label: 'Freezer (< -10C)',
    value: 'Freezer (< -10C)',
  },
  {
    label: 'Multi-temperature',
    value: 'Multi-temperature',
  },
  {
    label: 'Tail lift',
    value: 'Tail lift',
  },
  {
    label: 'Morffett',
    value: 'Morffett',
  },
  {
    label: 'Roller bed',
    value: 'Roller bed',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const schema = Yup.object().shape({
  averageAnnualMileage: Yup.number()
    .required('Average annual mileage is required')
    .typeError('Average annual mileage must be a number')
    .moreThan(0, 'Average annual mileage must be greater than 0'),
  averageAnnualMileageUnits: Yup.string().required(
    'Average annual mileage units are required'
  ),
  emissionStandard: Yup.string().required('Emission standard is required'),
  fuelType: Yup.string().required('Fuel type is required'),
  vehicleCapacity: Yup.object().shape({
    maxCapacityDimensions: Yup.object().shape({
      width: Yup.number()
        .required('Width is required')
        .typeError('Width must be a number')
        .moreThan(0, 'Width must be greater than 0'),
      height: Yup.number()
        .required('Height is required')
        .typeError('Height must be a number')
        .moreThan(0, 'Height must be greater than 0'),
      length: Yup.number()
        .required('Length is required')
        .typeError('Length must be a number')
        .moreThan(0, 'Length must be greater than 0'),
      dimensionsUnitOfMeasure: Yup.string().required(
        'Average annual mileage units are required'
      ),
    }),
    maxPallets: Yup.number()
      .required('Pallets is required')
      .typeError('Pallets must be a number')
      .moreThan(0, 'Pallets must be greater than 0'),
    maxKilograms: Yup.number()
      .required('Kilograms is required')
      .typeError('Kilograms must be a number')
      .moreThan(0, 'Kilograms must be greater than 0'),
  }),
  model: Yup.string().required('Model is required'),
  registrationNumber: Yup.string().required('Registration number is required'),
  vehicleType: Yup.string().required('Fleet category is required'),
  vehicleCategory: Yup.string().required('Vehicle category is required'),
  specialEquipment: Yup.string().required('Special equipment is required'),
});

export type VehicleFormValues = {
  averageAnnualMileage: number;
  averageAnnualMileageUnits: string;
  emissionStandard: string;
  fuelType: string;
  vehicleCapacity: {
    maxCapacityDimensions: {
      width: number;
      height: number;
      length: number;
      dimensionsUnitOfMeasure: string;
    };
    maxPallets: number;
    maxKilograms: number;
  };
  model: string;
  registrationNumber: string;
  vehicleType: string;
  vehicleCategory: string;
  specialEquipment: string;
  vehicleImages: UserImage[];
  images: UserImage[];
  deletedImagesIds: string[];
};

export function VehicleForm({
  prefilledValues,
  handleOnSubmit,
  isSubmitting,
}: {
  handleOnSubmit: (data: VehicleFormValues) => unknown;
  prefilledValues?: object;
  isSubmitting?: boolean;
}) {
  const setIsAddVehicleModalOpen = useSetAtom(isVehicleModalOpenAtom);
  const setEditVehicleId = useSetAtom(editVehicleIdAtom);
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');

  const { user } = useAuth();

  const defaultValues = {
    averageAnnualMileage: 0,
    averageAnnualMileageUnits: 'km',
    emissionStandard: 'Euro1',
    fuelType: 'Diesel',
    vehicleCapacity: {
      maxCapacityDimensions: {
        width: 0,
        height: 0,
        length: 0,
        dimensionsUnitOfMeasure: 'm',
      },
      maxPallets: 0,
      maxKilograms: 0,
    },
    model: '',
    registrationNumber: '',
    vehicleType: 'VanLessThan1AndHalft',
    vehicleCategory: 'Curtain Side / Fixed Box',
    specialEquipment: 'Double deck (fixed)',
    vehicleImages: [],
    deletedImagesIds: [],
  };

  const methods = useForm<VehicleFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { ...defaultValues, ...prefilledValues },
  });
  const {
    handleSubmit,
    trigger,
    formState,
    getValues,
    control,
    setValue,
    register,
    watch,
  } = methods;

  // For some reason we need a watch for vehicleImages to actually react in the UI. Dont delete
  const watchVehicleImages = watch('vehicleImages');
  const watchImages = watch('images');

  const { errors } = formState;

  const onClose = () => {
    setEditVehicleId('');
    setIsAddVehicleModalOpen(false);
  };

  const onSubmit = async (data: VehicleFormValues) => {
    console.log(getValues(), errors);
    const isValid = await trigger();
    if (isValid) {
      try {
        handleOnSubmit(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: '100%' }}
      >
        <ModalContent
          css={{
            maxHeight: 'calc(85vh - 12.5rem)',
            minHeight: 'calc(85vh - 12.5rem)',
          }}
          id='warehouse-form-modal'
        >
          <Stack gap={12}>
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
                padding: '$space12 $space0',
              }}
            >
              <Label
                htmlFor='fleetCategory'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Fleet category *
              </Label>
              <Controller
                name='vehicleType'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        getValues(`vehicleType`) ?? 'VanLessThan1AndHalft'
                      }
                      onBlur={field.onBlur}
                      placeholder='Fleet category'
                      css={{ width: '25%' }}
                    >
                      {FLEET_CATEGORIES.map((fleetCategory) => (
                        <SelectItem
                          key={fleetCategory.label}
                          value={fleetCategory.value}
                        >
                          {`${fleetCategory.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <Label
              htmlFor='fleetCategory'
              css={{ fontSize: '$textmd', fontWeight: 'bold' }}
            >
              Vehicle Details *
            </Label>
            <Controller
              name='model'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Make and model *'
                  placeholder='eg SCANIA P320'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <Controller
              name='registrationNumber'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Registration number *'
                  placeholder='Registration number *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
              }}
            >
              <Controller
                name='vehicleCategory'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        getValues(`vehicleCategory`) ??
                        'Curtain Side / Fixed Box'
                      }
                      onBlur={field.onBlur}
                      placeholder='Vehicle category'
                      css={{ width: '25%' }}
                    >
                      {VEHICLE_CATEGORIES.map((vehicleCategory) => (
                        <SelectItem
                          key={vehicleCategory.label}
                          value={vehicleCategory.value}
                        >
                          {`${vehicleCategory.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
              }}
            >
              <Controller
                name='specialEquipment'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        getValues(`specialEquipment`) ?? 'Double deck (fixed)'
                      }
                      onBlur={field.onBlur}
                      placeholder='Special equipment'
                      css={{ width: '25%' }}
                    >
                      {SPECIAL_EQUIPMENT.map((specialEquipment) => (
                        <SelectItem
                          key={specialEquipment.label}
                          value={specialEquipment.value}
                        >
                          {`${specialEquipment.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <Label
              htmlFor='fleetCategory'
              css={{ fontSize: '$textmd', fontWeight: 'bold' }}
            >
              Max capacity *
            </Label>
            <Controller
              name='vehicleCapacity.maxPallets'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Max Pallets *'
                  placeholder='Max Pallets'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <Controller
              name='vehicleCapacity.maxKilograms'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Max Kilograms *'
                  placeholder='Max Kilograms *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <Controller
              name='vehicleCapacity.maxCapacityDimensions.width'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Width *'
                  placeholder='Width *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <Controller
              name='vehicleCapacity.maxCapacityDimensions.height'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Height *'
                  placeholder='Height *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <Controller
              name='vehicleCapacity.maxCapacityDimensions.length'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Length *'
                  placeholder='Length *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
                padding: '$space12 $space0',
              }}
            >
              {/* <Label
                htmlFor='fuelType'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Fuel Type *
              </Label> */}
              <Controller
                name='vehicleCapacity.maxCapacityDimensions.dimensionsUnitOfMeasure'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        getValues(
                          `vehicleCapacity.maxCapacityDimensions.dimensionsUnitOfMeasure`
                        ) ?? 'm'
                      }
                      onBlur={field.onBlur}
                      placeholder='Dimensions UoM'
                      css={{ width: '25%' }}
                    >
                      {DIMENSIONS_UNITS_OF_MEASURE.map((dimensionsUoM) => (
                        <SelectItem
                          key={dimensionsUoM.label}
                          value={dimensionsUoM.value}
                        >
                          {`${dimensionsUoM.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
                padding: '$space12 $space0',
              }}
            >
              <Label
                htmlFor='fuelType'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Fuel Type *
              </Label>
              <Controller
                name='fuelType'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={getValues(`fuelType`) ?? 'Diesel'}
                      onBlur={field.onBlur}
                      placeholder='Fuel Type'
                      css={{ width: '25%' }}
                    >
                      {FUEL_TYPES.map((fuelType) => (
                        <SelectItem key={fuelType.label} value={fuelType.value}>
                          {`${fuelType.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
                padding: '$space12 $space0',
              }}
            >
              <Label
                htmlFor='emissionStandard'
                css={{ fontSize: '$textmd', fontWeight: 'bold' }}
              >
                Emission Standard *
              </Label>
              <Controller
                name='emissionStandard'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={getValues(`emissionStandard`) ?? 'Euro1'}
                      onBlur={field.onBlur}
                      placeholder='Emission Standard'
                      css={{ width: '25%' }}
                    >
                      {EMISSION_STANDARDS.map((emissionStandard) => (
                        <SelectItem
                          key={emissionStandard.label}
                          value={emissionStandard.value}
                        >
                          {`${emissionStandard.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <Label
              htmlFor='emissionStandard'
              css={{ fontSize: '$textmd', fontWeight: 'bold' }}
            >
              Average annual mileage *
            </Label>
            <Controller
              name='averageAnnualMileage'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={Boolean(error)}
                  helperText={Boolean(error) && error?.message}
                  variant='outlined'
                  label='Average annual mileage *'
                  placeholder='Average annual mileage *'
                  sx={{
                    width: '100%',
                  }}
                />
              )}
            />
            <InputGroup
              direction='column'
              css={{
                gap: '$space16',
                padding: '$space12 $space0',
              }}
            >
              <Controller
                name='averageAnnualMileageUnits'
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={
                        getValues(`averageAnnualMileageUnits`) ?? 'km'
                      }
                      onBlur={field.onBlur}
                      placeholder='Average annual mileage units '
                      css={{ width: '25%' }}
                    >
                      {AVERAGE_ANNUAL_MILEAGE_UNITS.map((mileageUnit) => (
                        <SelectItem
                          key={mileageUnit.label}
                          value={mileageUnit.value}
                        >
                          {`${mileageUnit.label}`}
                        </SelectItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </InputGroup>
            <div style={{ width: '100%' }}>
              <Text as='h4' size='lg' weight='bold' css={{ mb: '$space16' }}>
                Vehicle images
              </Text>
              <ThumbnailGrid>
                {(getValues().vehicleImages.length > 0 ||
                  getValues().images?.length > 0) && (
                  <>
                    {getValues()
                      .vehicleImages.map((img: UserImage) => ({
                        preview: URL.createObjectURL(img.image),
                        title: img.title,
                        id: img.id,
                      }))
                      .concat(
                        getValues().images
                          ? getValues().images.map((img: any) => ({
                              preview: `${FLEET_IMAGES_URL}/${img.imageLocation}/small/${img.remoteName}`,
                              title: img.title,
                              id: img.id,
                            }))
                          : []
                      )
                      .map((img: any, index: number) => (
                        <Thumbnail
                          key={`${img.title}${index.toString()}`}
                          src={img.preview}
                        >
                          <Button
                            size='xs'
                            action='danger'
                            leftIcon={<MdDelete size='1rem' />}
                            onClick={() => {
                              if (
                                getValues().vehicleImages.some(
                                  (i: any) => i.id === img.id
                                )
                              ) {
                                setValue(
                                  'vehicleImages',
                                  getValues().vehicleImages.filter(
                                    (i: any) => i.id !== img.id
                                  )
                                );
                              }

                              if (
                                getValues().images.some(
                                  (i: any) => i.id === img.id
                                )
                              ) {
                                setValue(
                                  'images',
                                  getValues().images.filter(
                                    (i: any) => i.id !== img.id
                                  )
                                );

                                setValue(
                                  'deletedImagesIds',
                                  getValues().deletedImagesIds.concat(img.id)
                                );
                              }
                            }}
                            css={{
                              position: 'absolute',
                              bottom: '$space8',
                              right: '$space8',
                            }}
                          >
                            {/* Delete */}
                          </Button>
                        </Thumbnail>
                      ))}
                  </>
                )}
                <InputGroup css={{ gap: '$space0', mb: '$space16' }}>
                  <>
                    <Label htmlFor='images' visuallyHidden>
                      Images
                    </Label>
                    <Controller
                      name='vehicleImages'
                      control={control}
                      render={() => {
                        return (
                          <Dropzone
                            {...register('vehicleImages')}
                            id='images'
                            primaryAction={
                              <Button
                                size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                                leftIcon={
                                  <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                                }
                              >
                                Add image
                              </Button>
                            }
                            onDrop={(acceptedFiles: any) => {
                              setValue('vehicleImages', [
                                ...getValues().vehicleImages,
                                ...acceptedFiles.map(
                                  (file: File): UserImage => {
                                    return {
                                      id: crypto.randomUUID(),
                                      title: file.name,
                                      image: file,
                                    };
                                  }
                                ),
                              ]);
                            }}
                          />
                        );
                      }}
                    />
                  </>
                </InputGroup>
              </ThumbnailGrid>
            </div>
          </Stack>
        </ModalContent>

        <ModalFooter>
          <Button
            variant='secondary'
            size={isTablet ? 'md' : 'sm'}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type='submit'
            rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
            isLoading={isSubmitting}
            loadingIcon={<Icon name='loading' />}
            loadingText='Submitting...'
            size={isTablet ? 'md' : 'sm'}
            action='cta'
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
}
