/* eslint-disable no-nested-ternary */
import { Button } from '@components/Button';
import { Dropzone } from '@components/Dropzone';
import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { Text } from '@components/Text';
import { Icon } from '@components/Icon';
import { ModalContent, ModalFooter } from '@components/Modal';
import { SyntheticEvent, useMemo, useState } from 'react';
import {
  MdCheck,
  MdAdd,
  MdDelete,
  MdDownload,
  MdUploadFile,
} from 'react-icons/md';
import { useAtom, useSetAtom } from 'jotai';
import { useMediaQuery } from 'usehooks-ts';
import {
  Autocomplete,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserImage } from '@forms/client/AddProductForm/types';
import { Fleet, fleetMapping } from '@views/PriceMaster/types';
import {
  isRatecardEditModalOpenAtom,
  isRatecardModalOpenAtom,
  ratecardPricingTypeAtom,
} from '../../../store/jotai';
import { FLEET_IMAGES_URL, HERE_APIKEY } from '../../../constants';
import { Daily } from './subforms/Daily';
import { Multistop } from './subforms/Multistop';

const CURRENCY = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

const schema = (pricingType: string) => {
  let baseSchema = Yup.object().shape({
    transportBaseAddress: Yup.string()
      .required('Transport base address is required')
      .min(3, 'Transport base address has to be at least 3 characters long'),
    location: Yup.object().shape({
      lon: Yup.number(),
      lat: Yup.number(),
    }),
    currency: Yup.string(),
  });

  if (pricingType === 'Day Rate') {
    baseSchema = baseSchema.shape({
      chargeForEightHourDay: Yup.number()
        .required('Charge for an 8 hour day is required')
        .typeError('Charge for an 8 hour day must be a number')
        .moreThan(0, 'Charge for an 8 hour day must be greater than 0'),
      hourlyChargeOverEightHours: Yup.number()
        .required('Hourly charge for an over 8 hour day is required')
        .typeError('Hourly charge for an over 8 hour day must be a number')
        .moreThan(
          0,
          'Hourly charge for an over 8 hour day must be greater than 0'
        ),
      hourlyChargeOverTehnHours: Yup.number()
        .required('Hourly charge for an over 10 hour day is required')
        .typeError('Hourly charge for an over 10 hour day must be a number')
        .moreThan(
          0,
          'Hourly charge for an over 10 hour day must be greater than 0'
        ),
    });
  } else if (pricingType === 'Postcode' || pricingType === 'Additional FTL') {
    baseSchema = baseSchema.shape({
      uploadFile: Yup.mixed(),
    });
  } else if (pricingType === 'Multistop') {
    baseSchema = baseSchema.shape({
      baseCharge: Yup.number()
        .required('Included distance is required')
        .typeError('Included distance must be a number')
        .moreThan(0, 'Included distance must be greater than 0'),
      includedDistance: Yup.number()
        .required('Included distance is required')
        .typeError('Included distance must be a number')
        .moreThan(0, 'Included distance must be greater than 0'),
      distanceUoM: Yup.string(),
      chargePerAdditionalDistanceUnit: Yup.number()
        .required('Charge per additional distance unit is required')
        .typeError('Charge per additional distance unit must be a number')
        .moreThan(
          0,
          'Charge per additional distance unit must be greater than 0'
        ),
      chargePerAdditionalStop: Yup.number()
        .required('Charge per additional stop is required')
        .typeError('Charge per additional stop must be a number')
        .moreThan(0, 'Charge per additional stop must be greater than 0'),
      chargePerAdditionalHour: Yup.number()
        .required('Charge per additional hour is required')
        .typeError('Charge per additional hour must be a number')
        .moreThan(0, 'Charge per additional hour must be greater than 0'),
      chargePerOvertimeHour: Yup.number()
        .required('Charge per overtime hour is required')
        .typeError('Charge per overtime hour must be a number')
        .moreThan(0, 'Charge per overtime hour must be greater than 0'),
      standardIncludedLoadingMinutesPerDrop: Yup.number()
        .required('Standard included loading minutes per drop is required')
        .typeError(
          'Standard included loading minutes per drop must be a number'
        )
        .moreThan(
          0,
          'Standard included loading minutes per drop must be greater than 0'
        ),
      standardIncludedUnloadingMinutesPerDrop: Yup.number()
        .required('Standard included unloading minutes per drop is required')
        .typeError(
          'Standard included unloading minutes per drop must be a number'
        )
        .moreThan(
          0,
          'Standard included unloading minutes per drop must be greater than 0'
        ),
    });
  }

  return baseSchema;
};

export type RatecardPricingFormValues = {
  transportBaseAddress: string;
  location: { lon: number; lat: number };
  currency: string;
  baseCharge: number;
  includedDistance: number;
  distanceUoM: string;
  chargePerAdditionalDistanceUnit: number;
  chargePerAdditionalStop: number;
  chargePerAdditionalHour: number;
  chargePerOvertimeHour: number;
  standardIncludedLoadingMinutesPerDrop: number;
  standardIncludedUnloadingMinutesPerDrop: number;
  chargeForEightHourDay: number;
  hourlyChargeOverEightHours: number;
  hourlyChargeOverTehnHours: number;
  uploadFile: UserImage;
};

export function RatecardPricingForm({
  prefilledValues,
  handleOnSubmit,
  isSubmitting,
}: {
  handleOnSubmit: (data: RatecardPricingFormValues) => unknown;
  prefilledValues?: {
    transportBaseAddress: string;
    vehicleType: string;
  };
  isSubmitting?: boolean;
}) {
  const setIsRatecardModalOpen = useSetAtom(isRatecardModalOpenAtom);
  const setIsRatecardEditModalOpen = useSetAtom(isRatecardEditModalOpenAtom);
  const [ratecardPricingType, setRatecardPricingType] = useAtom(
    ratecardPricingTypeAtom
  );
  const isTablet = useMediaQuery('(min-width: 48em)');
  const isSmall = useMediaQuery('(min-width: 36em)');
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [options, setOptions] = useState([]);
  const [uploadFile, setUploadFile] = useState<any>(undefined);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<
    { message: string; row: number }[]
  >([]);

  const fileDownloadPath = `${ratecardPricingType
    .toLowerCase()
    .replace(/\s+/g, '_')}_${prefilledValues?.vehicleType
    .toLowerCase()
    .replace(/\s+/g, '_')}`;

  const defaultValues = useMemo(() => {
    const pricingType = ratecardPricingType;
    const baseDefaultValues = {
      transportBaseAddress: '',
      location: { lon: 0, lat: 0 },
      currency: 'GBP',
    };

    let pricingTypeDefaultValues = {};

    if (pricingType === 'Day Rate') {
      pricingTypeDefaultValues = {
        chargeForEightHourDay: 0,
        hourlyChargeOverEightHours: 0,
        hourlyChargeOverTehnHours: 0,
      };
    } else if (pricingType === 'Postcode' || pricingType === 'Additional FTL') {
      pricingTypeDefaultValues = {
        uploadFile: undefined,
      };
    } else if (pricingType === 'Multistop') {
      pricingTypeDefaultValues = {
        baseCharge: 0,
        includedDistance: 0,
        distanceUoM: 'km',
        chargePerAdditionalDistanceUnit: 0,
        chargePerAdditionalStop: 0,
        chargePerAdditionalHour: 0,
        chargePerOvertimeHour: 0,
        standardIncludedLoadingMinutesPerDrop: 0,
        standardIncludedUnloadingMinutesPerDrop: 0,
      };
    }

    return { ...baseDefaultValues, ...pricingTypeDefaultValues };
  }, [ratecardPricingType]);

  const methods = useForm<RatecardPricingFormValues>({
    shouldUnregister: false,
    resolver: yupResolver(schema(ratecardPricingType)),
    mode: 'onChange',
    defaultValues: { ...defaultValues, ...prefilledValues },
  });
  const {
    handleSubmit,
    trigger,
    formState,
    getValues,
    control,
    setValue,
    register,
    watch,
  } = methods;

  const { errors } = formState;

  const handleInputChange = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleFetchSuggestions = (input: string) => {
    fetch(
      `https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${HERE_APIKEY}&q=${input}&at=52.93175,12.77165`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          const items = data.items.map(
            (item: {
              id: any;
              address: { label: string; city: string; countryName: string };
            }) => ({
              id: item.id,
              label: item.address.label,
              city: item.address.city,
              country: item.address.countryName,
            })
          );
          setOptions(items);
        }
      })
      .catch((error) => {
        console.error('Error fetching suggestions:', error);
      });
  };

  const handleOptionSelect = async (event: any, value: any) => {
    if (value && value.id) {
      setValue('transportBaseAddress', value.label);
      try {
        const response = await fetch(
          `https://lookup.search.hereapi.com/v1/lookup?id=${value.id}&apiKey=${HERE_APIKEY}`
        );

        const data = await response.json();

        if (data) {
          const { position } = data;

          if (position) {
            const { lat, lng: lon } = position;

            const location = {
              lat,
              lon,
            };

            setValue('location', location);
          }
        }
      } catch (error) {
        console.error('Error fetching position information:', error);
      }
    }
  };

  const onClose = () => {
    setRatecardPricingType('');
    setIsRatecardModalOpen(false);
    setIsRatecardEditModalOpen(false);
    setInputValue(null);
    setValue('transportBaseAddress', '');
    setValue('location', { lon: 0, lat: 0 });
    setValue('currency', 'GBP');
  };

  const onSubmit = async (data: RatecardPricingFormValues) => {
    console.log(getValues(), errors);
    const isValid = await trigger();
    if (isValid) {
      try {
        handleOnSubmit(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error: ', error);
        const errorResponse = error as any;
        // console.log('errors', errorResponse.response.data.errors);
        setHasUploadError(true);
        setUploadErrors(errorResponse.response.data.errors);
      }
      // } finally {
      //   setValue('transportBaseAddress', '');
      //   setValue('location', { lon: 0, lat: 0 });
      //   setValue('currency', 'GBP');
      // }
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: '100%' }}
      >
        <ModalContent
          css={{
            maxHeight: 'calc(85vh - 12.5rem)',
            minHeight: 'calc(85vh - 12.5rem)',
          }}
          id='warehouse-form-modal'
        >
          <Stack gap={24}>
            <Text size='md'>
              <Text as='span' size='md' weight='bold'>
                Pricing type:{' '}
              </Text>
              {ratecardPricingType} Tariff for{' '}
              {fleetMapping[prefilledValues?.vehicleType as Fleet] ||
                prefilledValues?.vehicleType}
            </Text>
            <Text size='md'>
              {ratecardPricingType === 'Postcode' &&
                'The tariff covers prices for Point-to-Point deliveries, from the origin location in the region of the specified Transport Base to the destinations outlined in the postcode matrix below.'}
              {ratecardPricingType === 'Additional FTL' &&
                'The tariff provides pricing for additional co-shipment deliveries, on top of an existing pre-planned route, and as per the load size matrix below.'}
              {ratecardPricingType === 'Day Rate' &&
                'The tariff includes a charge for a full working day, starting and ending in the region of the specified Transport Base, with unlimited mileage included.'}
              {ratecardPricingType === 'Multistop' &&
                'The tariff offers a pricing model for multi pick-up/drop-off routes starting from the region of the specified Transport Base, and with charges per additional stop, distance travelled, and waiting time.'}
            </Text>
            <Controller
              name='transportBaseAddress'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  freeSolo
                  disablePortal
                  options={options}
                  // eslint-disable-next-line @typescript-eslint/no-shadow
                  filterOptions={(options, state) => options}
                  inputValue={inputValue ?? field.value}
                  onInputChange={(event, newInputValue) => {
                    handleInputChange(event, newInputValue);
                    handleFetchSuggestions(newInputValue);
                  }}
                  onChange={(e, value) => {
                    handleOptionSelect(e, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Transport Base Address *'
                      error={Boolean(error)}
                      helperText={Boolean(error) && error?.message}
                    />
                  )}
                />
              )}
            />
            <Stack sx={{ flexDirection: isTablet ? 'row' : 'column' }} gap={12}>
              <InputGroup
                direction='column'
                css={{
                  gap: '$space16',
                  padding: '$space12 $space0',
                }}
              >
                <Label
                  htmlFor='currency'
                  css={{ fontSize: '$textmd', fontWeight: 'bold' }}
                >
                  Currency
                </Label>
                <Controller
                  name='currency'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={getValues(`currency`) ?? 'GBP'}
                        onBlur={field.onBlur}
                        placeholder='Currency'
                        css={{ width: '100%' }}
                      >
                        {CURRENCY.map((currency) => (
                          <SelectItem
                            key={currency.label}
                            value={currency.value}
                          >
                            {`${currency.label}`}
                          </SelectItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </InputGroup>
              {/* <InputGroup
                direction='column'
                css={{
                  gap: '$space16',
                  padding: '$space12 $space0',
                }}
              >
                <Label
                  htmlFor='currency'
                  css={{ fontSize: '$textmd', fontWeight: 'bold' }}
                >
                  Distance UoM
                </Label>
                <Controller
                  name='distanceUoM'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={getValues(`distanceUoM`) ?? 'km'}
                        onBlur={field.onBlur}
                        placeholder='Distance UoM'
                        css={{ width: '100%' }}
                      >
                        {DISTANCE_UOM.map((mileageUnit) => (
                          <SelectItem
                            key={mileageUnit.label}
                            value={mileageUnit.value}
                          >
                            {`${mileageUnit.label}`}
                          </SelectItem>
                        ))}
                      </Select>
                    );
                  }}
                />
              </InputGroup> */}
            </Stack>
            {(ratecardPricingType === 'Postcode' ||
              ratecardPricingType === 'Additional FTL') && (
              <Stack gap={24}>
                <Stack>
                  <Label
                    htmlFor=''
                    css={{ fontSize: '$textmd', fontWeight: 'bold' }}
                  >
                    {ratecardPricingType === 'Postcode' && 'Postcode matrix'}
                    {ratecardPricingType === 'Additional FTL' &&
                      'Load size matrix'}
                  </Label>
                  <Text size='md'>
                    {ratecardPricingType === 'Postcode' &&
                      'Please use the provided template to input prices for the served postcode areas and per load size respectively.'}
                    {ratecardPricingType === 'Additional FTL' &&
                      'Please use the provided template to enter prices for the load size bands, and the charges for additional drops and labour time.'}
                  </Text>
                </Stack>
                <Stack justifyContent='center' flexDirection='row'>
                  <Stack
                    maxHeight='400px'
                    gap={12}
                    alignItems='center'
                    sx={{
                      maxWidth: { xs: '100%', md: '50%' },
                      minWidth: { xs: '100%', md: '50%' },
                    }}
                  >
                    <Link href={`/files/${fileDownloadPath}.xlsx`} width='100%'>
                      <Button
                        variant='primary'
                        action='cta'
                        size={isDesktop ? 'md' : 'sm'}
                        leftIcon={<MdDownload size='1.5rem' />}
                        isFullWidth
                      >
                        Download excel template
                      </Button>
                    </Link>
                    <Typography variant='body2' sx={{ fontWeight: 600 }}>
                      OR
                    </Typography>
                    {uploadFile ? (
                      <Stack gap={12} width='100%'>
                        <Stack direction='row' gap={6} alignItems='center'>
                          <MdUploadFile size='1rem' />
                          <Typography
                            variant='body2'
                            sx={{ fontWeight: 600, fontSize: '1rem' }}
                          >
                            {uploadFile.name}
                          </Typography>
                        </Stack>
                        <Button
                          variant='primary'
                          action='danger'
                          leftIcon={<MdDelete size='1rem' />}
                          size={isDesktop ? 'md' : 'sm'}
                          onClick={() => {
                            setHasUploadError(false);
                            setUploadFile(undefined);
                          }}
                        >
                          Clear selected file
                        </Button>
                        {hasUploadError && (
                          <Stack gap={2}>
                            <Typography
                              variant='body2'
                              sx={{ color: 'red', fontWeight: 600 }}
                            >
                              Error uploading file. Please fix the following
                              errors and try again.
                            </Typography>
                            <>
                              {uploadErrors.map((error) => (
                                <Typography
                                  key={error.message}
                                  variant='body2'
                                  sx={{ color: 'red' }}
                                >
                                  Row {error.row}: {error.message}
                                </Typography>
                              ))}
                            </>
                          </Stack>
                        )}
                      </Stack>
                    ) : (
                      <Dropzone
                        id='fleet-master-dropzone'
                        acceptAnyFile
                        primaryAction={
                          <Button
                            size={isDesktop ? 'xs' : isSmall ? 'sm' : 'xs'}
                            leftIcon={
                              <MdAdd size={isSmall ? '1.25rem' : '1rem'} />
                            }
                          >
                            Upload Ratecard Pricing
                          </Button>
                        }
                        onDrop={(acceptedFiles: any) => {
                          setUploadFile(acceptedFiles[0]);
                          setValue('uploadFile', acceptedFiles[0]);
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
            {ratecardPricingType === 'Day Rate' && <Daily />}
            {ratecardPricingType === 'Multistop' && <Multistop />}
          </Stack>
        </ModalContent>

        <ModalFooter>
          <Button
            variant='secondary'
            size={isTablet ? 'md' : 'sm'}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type='submit'
            rightIcon={<MdCheck size={isTablet ? '1.25rem' : '1.5rem'} />}
            isLoading={isSubmitting}
            loadingIcon={<Icon name='loading' />}
            loadingText='Submitting...'
            size={isTablet ? 'md' : 'sm'}
            action='cta'
            disabled={!!prefilledValues?.transportBaseAddress}
          >
            Submit
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
}
