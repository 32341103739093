import { useState } from 'react';
import { CreateOutboundShipmentNoteFormValues } from '@forms/manager/CreateOutboundShipmentNoteForm/types';
import { CREATE_OUTBOUND_SHIPMENT_NOTE_URL } from '@constants';
import { useAppDispatch } from '@store';
import { upsertSingleOutboundProcess } from '@features/supplyChain';
import { useAuthHttp, useCloseDialog } from '@hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const useOutboundShipmentNote = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();
  const [
    isCreateOutboundShipmentNoteFormOpen,
    setIsCreateOutboundShipmentNoteFormOpen,
  ] = useState(false);

  const handleCloseCreateOutboundShipmentNoteForm = useCloseDialog(
    setIsCreateOutboundShipmentNoteFormOpen
  );

  const handleSubmitOutboundShipmentNote = async (
    values: CreateOutboundShipmentNoteFormValues
  ) => {
    try {
      const formValues = {
        shipmentNote: {
          shipmentNoteNumber: values.shipmentNoteNumber,
          supplyChainProcessId: values.supplyChainProcessId,
          shipmentType: values.shipmentType,
          customerName: values.customerName,
          supplierName: values.supplierName,
          comment: values.comment,
          estimatedTimeOfArrival: values.estimatedTimeOfArrival,
          warehouseId: values.warehouseId,
          sourceLocationId: values.sourceLocationId,
          shipmentDate: dayjs(values.shipmentDate)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss[Z]'),
          skus: values.skus.map((sku) => {
            return {
              skuId: sku.skuId,
              productId: sku.productId,
              skuQuantity: sku.despatchedQuantity,
              discrepancyReason: sku.discrepancyReason,
            };
          }),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(CREATE_OUTBOUND_SHIPMENT_NOTE_URL, formValues);

      dispatch(upsertSingleOutboundProcess(supplyChainProcess));
      setIsCreateOutboundShipmentNoteFormOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isCreateOutboundShipmentNoteFormOpen,
    setIsCreateOutboundShipmentNoteFormOpen,
    handleCloseCreateOutboundShipmentNoteForm,
    handleSubmitOutboundShipmentNote,
  };
};
