/* eslint-disable no-nested-ternary */
import Stack from '@mui/material/Stack';
import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { Text } from '@components/Text';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { OutboundProcess } from '@features/supplyChain/types';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { useReactHookFormWithFieldArray } from '@hooks';
import { useAuth } from '@context';
import { PalletCalculator } from '@components/PalletCalculator';
import { ConfirmPickingNoteNoteFormValues } from './types';
import { makeDefaultValues, validationSchema } from './form';

function QuantitySpacer() {
  return <span>&nbsp;</span>;
}

interface ConfirmPickingNoteFormProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  onSubmit: (values: ConfirmPickingNoteNoteFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}
export function ConfirmPickingNoteForm({
  selectedOutboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: ConfirmPickingNoteFormProps) {
  const {
    handleSubmit,
    control,
    setValue,
    isSubmitting,
    isValid,
    fields: skusFields,
    errors,
    watch,
  } = useReactHookFormWithFieldArray<ConfirmPickingNoteNoteFormValues>({
    defaultValues: makeDefaultValues(selectedOutboundProcess),
    validationSchema,
    fieldArrayName: 'skus',
  });

  const { user } = useAuth();

  if (!selectedOutboundProcess) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking note #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess?.despatchNote?.despatchNoteNumber.replace(
                '-DN',
                '-P'
              )}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipper name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>{user?.businessName}</Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess.despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Text size={isDesktop ? 'md' : 'sm'}>
                {`${dayjs(
                  selectedOutboundProcess.despatchNote?.requiredTimeOfArrival
                ).format('DD/MM/YYYY')}, by ${dayjs(
                  selectedOutboundProcess.despatchNote?.requiredTimeOfArrival
                ).format('HH:mm')}`}
              </Text>
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking Date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Controller
                name='pickingDateConfirmation'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      inputRef={field.ref}
                      onChange={(value) => {
                        setValue('pickingDateConfirmation', dayjs(value), {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={dayjs(
                        selectedOutboundProcess.despatchNote.requiredPickingDate
                      )}
                      slotProps={{
                        textField: {
                          onBlur: field.onBlur,
                          name: field.name,
                          multiline: !isDesktop,
                          ...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          }),
                          error: Boolean(error),
                          helperText: error?.message,
                        },
                      }}
                    />
                  );
                }}
              />
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        <Stack gap={isDesktop ? '1.5rem' : '3rem'}>
          {skusFields.map((skusField, index) => {
            const skuQuantity = watch(`skus.${index}.skuQuantity`);

            const pickedQuantity = watch(`skus.${index}.pickedQuantity`);

            const isDiscrepancy =
              pickedQuantity !== undefined && pickedQuantity !== skuQuantity;

            return (
              <>
                <Stack
                  spacing={16}
                  p={6}
                  direction={isTablet ? 'row' : 'column'}
                  sx={{ mb: 16 }}
                >
                  <Stack spacing={8}>
                    {/* SKU index  */}
                    <Stack justifyContent='center' width='32px'>
                      <Chip label={index + 1} />
                    </Stack>
                  </Stack>
                  <Stack spacing={8} flex='1'>
                    {/* SKU ID */}
                    <Box>
                      <Text size='sm' weight='bold'>
                        SKU ID
                      </Text>
                      <Text>{skusField.skuIdentifier}</Text>
                    </Box>
                    {/* Product name */}
                    <Box
                      gridColumn={
                        isDesktop ? '6 / 10' : isTablet ? 'span 6' : 'span 13'
                      }
                    >
                      <Text size='sm' weight='bold'>
                        Product name
                      </Text>
                      <Text>
                        {skusField.productName} {`(${skusField.skuName})`}
                      </Text>
                    </Box>
                  </Stack>
                  <Stack spacing={8} flex='1'>
                    {/* Ordered Sku quantity */}
                    <Box
                      gridColumn={
                        isDesktop ? '10 / 14' : isTablet ? '2 / 8' : 'span 13'
                      }
                    >
                      <Text size='sm' weight='bold'>
                        Ordered SKU Q-ty
                      </Text>
                      <Text>{skusField.skuQuantity}</Text>
                    </Box>
                    {/* Palletising ID */}
                    <Box
                      gridColumn={
                        isDesktop ? '6 / 10' : isTablet ? '2 / 8' : 'span 13'
                      }
                    >
                      <Text size='sm' weight='bold'>
                        Pallet type
                      </Text>
                      <Text>
                        {skusField.packagingDetails.packagingInfo
                          ? skusField.packagingDetails.packagingInfo.type
                          : skusField.packagingDetails.palletisingType}
                      </Text>
                      {skusField.packagingDetails.palletisingType ===
                        'Other' && (
                        <Stack sx={{ mt: 16 }}>
                          <Text size='sm' weight='bold'>
                            Pallet description
                          </Text>
                          <Text>
                            {skusField.packagingDetails.palletisingDescription}
                          </Text>
                        </Stack>
                      )}
                    </Box>
                    {/* Picked Pallet Quantity */}
                    {skusField.packagingDetails.packagingInfo && (
                      <Stack
                        gridColumn={
                          isDesktop ? '10 /14' : isTablet ? '8 / 14' : '1 / 14'
                        }
                        gridRow={isDesktop ? '2' : isTablet ? '3' : 'auto'}
                      >
                        <Text size='sm' weight='bold'>
                          Picked Pallet Q-ty
                        </Text>
                        <Text css={{ whiteSpace: 'nowrap' }}>
                          {pickedQuantity ? (
                            <PalletCalculator
                              palletName={
                                skusField.packagingDetails.packagingInfo.type
                              }
                              skuQuantity={pickedQuantity}
                              amountOfSkusInLayer={
                                skusField.packagingDetails.packagingInfo
                                  .amountOfSkusInLayer
                              }
                              amountOfLayersInPallet={
                                skusField.packagingDetails.packagingInfo
                                  .amountOfLayersInPallet
                              }
                            />
                          ) : (
                            <QuantitySpacer />
                          )}
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  <Stack spacing={isDesktop ? 8 : 24} flex='1'>
                    {/* Picked quantity */}
                    <Box
                      gridColumn={
                        isDesktop ? '2 / 6' : isTablet ? '8 / 14' : 'span 13'
                      }
                    >
                      <Controller
                        name={`skus.${index}.pickedQuantity`}
                        control={control}
                        render={({
                          field,
                          fieldState: { error, isTouched },
                        }) => {
                          return (
                            <TextField
                              size='small'
                              inputProps={{
                                min: 0,
                                type: 'number',
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              name={field.name}
                              onBlur={field.onBlur}
                              inputRef={field.ref}
                              value={field.value}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = parseInt(event.target.value, 10);
                                field.onChange(value);
                                if (value === pickedQuantity) {
                                  setValue(
                                    `skus.${index}.discrepancyReason`,
                                    null
                                  );
                                }
                              }}
                              sx={{ minWidth: '12rem' }}
                              error={Boolean(error && isTouched)}
                              helperText={
                                Boolean(error && isTouched) && error?.message
                              }
                              variant='outlined'
                              label='Picked SKU Q-ty (required)'
                              fullWidth
                              placeholder='Picked SKU Q-ty (required)'
                              InputLabelProps={{ shrink: true }}
                              color='primary'
                            />
                          );
                        }}
                      />
                    </Box>
                    {/* Discrepancy reason */}
                    {isDiscrepancy && (
                      <Box
                        gridColumn={
                          isDesktop ? '6 / 14' : isTablet ? '8 / 14' : 'span 13'
                        }
                        gridRow={isDesktop ? '3' : 'auto'}
                      >
                        <Controller
                          name={`skus.${index}.discrepancyReason`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => {
                            return (
                              <TextField
                                size='small'
                                name={field.name}
                                onBlur={field.onBlur}
                                inputRef={field.ref}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  field.onChange(event.target.value);
                                }}
                                multiline
                                minRows={2}
                                error={Boolean(error && isTouched)}
                                helperText={
                                  Boolean(error && isTouched) && error?.message
                                }
                                label='Discrepancy reason (required)'
                                fullWidth
                                placeholder='Discrepancy reason (required)'
                                {...(!isDesktop && {
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                })}
                                variant='outlined'
                                sx={{
                                  '.Mui-disabled': { cursor: 'not-allowed' },
                                }}
                              />
                            );
                          }}
                        />
                      </Box>
                    )}
                    {/* Packaging comment */}
                    <Controller
                      name={`skus.${index}.packagingComment`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={Boolean(error)}
                          helperText={Boolean(error) && error?.message}
                          variant='outlined'
                          label='Packaging comment (optional)'
                          placeholder='Packaging comment (optional)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          inputProps={{
                            max: 500,
                          }}
                          sx={{
                            width: '100%',
                            gridColumn: isDesktop
                              ? '6 / 14'
                              : isTablet
                              ? '2 / 14'
                              : '1 / 14',
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                {index !== skusFields.length - 1 && (
                  <Divider sx={{ borderColor: 'var(--colors-gray7)' }} />
                )}
              </>
            );
          })}
          {/* Comment */}
          <Stack spacing={16} p={6} sx={{ mb: 16 }}>
            <Text as='h3' size='lg' weight='bold'>
              Comment
            </Text>
            <Controller
              name='comment'
              control={control}
              render={({ field, fieldState: { error, isTouched } }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  error={Boolean(error && isTouched)}
                  helperText={Boolean(error && isTouched) && error?.message}
                  label='Comment (optional)'
                  placeholder='Comment'
                  {...(!isDesktop && {
                    InputLabelProps: {
                      shrink: true,
                    },
                  })}
                  variant='outlined'
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
