import { styled } from '@stitchesConfig';

export const StyledContainer = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$space8',
});

StyledContainer.displayName = 'StyledContainer';

export const StyledPasswordStrengthBarContainer = styled('div', {
  width: '100%',
  height: '$size4',
  backgroundColor: '$gray4',
});

StyledPasswordStrengthBarContainer.displayName =
  'StyledPasswordStrengthBarContainer';

export const StyledPasswordStrengthBar = styled('div', {
  height: '$size4',
  transition: 'width 300ms ease-in-out, background-color 300ms ease-in-out',

  variants: {
    score: {
      '0': {
        width: '0%',
        backgroundColor: '$gray11',
      },
      '1': {
        width: '25%',
        backgroundColor: '$tomato9',
      },
      '2': {
        width: '50%',
        backgroundColor: '$blue11',
      },
      '3': {
        width: '75%',
        backgroundColor: '$green10',
      },
      '4': {
        width: '100%',
        backgroundColor: '$green10',
      },
    },
  },
});

StyledPasswordStrengthBar.displayName = 'StyledPasswordStrengthBar';

export const StyledTextContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '$space2',
});

StyledTextContainer.displayName = 'StyledTextContainer';

export const StyledText = styled('span', {
  fontSize: '$textXs',
  fontWeight: '$medium',

  variants: {
    score: {
      '0': {
        color: '$gray11',
      },
      '1': {
        color: '$tomato9',
      },
      '2': {
        color: '$blue11',
      },
      '3': {
        color: '$green10',
      },
      '4': {
        color: '$green10',
      },
    },
  },
});

StyledText.displayName = 'StyledText';
