import { css } from '@stitchesConfig';
import { useMemo, forwardRef, ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineImage } from 'react-icons/md';

interface DropzoneProps {
  id: string;
  onDrop: (acceptedFiles: any) => void;
  primaryAction?: ReactNode;
  acceptAnyFile?: boolean;
}

export const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>(
  ({ id, onDrop, primaryAction, acceptAnyFile }) => {
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      multiple: true,
      accept: acceptAnyFile ? {} : { 'image/*': [] },
      onDrop,
    });

    const action = primaryAction || (
      <p>Drag &apos;n&apos; drop, or click to select files</p>
    );

    const style = useMemo(
      () =>
        css({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '$space8',
          borderWidth: '$size1',
          borderRadius: '$radii8',
          borderColor: '$gray7',
          borderStyle: 'dashed',
          backgroundColor: '$gray1',
          color: '$gray11',
          textAlign: 'center',
          outline: 'none',
          cursor: 'pointer',
          transition: 'border .24s ease-in-out',
          width: '100%',
          height: 'auto',
          aspectRatio: 1,

          '&:hover:not(:disabled)': {
            borderColor: '$gray8',
          },

          '.dark-theme &': {},

          ...(isFocused
            ? {
                borderColor: '$gray12',
              }
            : {}),
          ...(isDragAccept
            ? {
                borderColor: '$green9',
              }
            : {}),
          ...(isDragReject
            ? {
                borderColor: '$tomato9',
              }
            : {}),
        }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div {...getRootProps({ className: style() })}>
        <input id={id} {...getInputProps()} />
        <MdOutlineImage size='2.75rem' />
        {action}
      </div>
    );
  }
);

Dropzone.displayName = 'Dropzone';
