import { useState } from 'react';
import { Text } from '@components/Text';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Input } from '@components/Input';
import { ErrorMessage } from '@components/ErrorMessage';
import { Formik, Field, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import {
  useMediaQuery,
  useDocumentTitle,
  useReadLocalStorage,
} from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { ToggleTheme } from '@components/ToggleTheme';
import { useAuthHttp } from '@hooks';
import {
  FLOX_LOGO_BLUE_SRC,
  FLOX_LOGO_STANDARD_REVERSE_SRC,
  FORGOTTEN_PASSWORD_URL,
} from '@constants';
import { WarningMessage } from '@components/WarningMessage';
import {
  Container,
  ImagePanel,
  FormPanel,
  LogoTitleContainer,
  Logo,
  LoginForm as ForgottenPasswordForm,
} from '@views/Login/Login.styles';

interface ForgotPasswordValues {
  email: string;
}

export function ForgottenPassword() {
  const isMobile = useMediaQuery('(min-width: 23.4375em)');
  const isTablet = useMediaQuery('(min-width: 48em)');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const navigate = useNavigate();
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [recoveryEmailSuccess, setRecoveryEmailSuccess] = useState(false);
  const [recoveryEmailErrorText, setRecoveryEmailErrorText] = useState('');
  const [recoveryEmailSendError, setRecoveryEmailSendError] = useState(false);
  const authHttp = useAuthHttp();

  useDocumentTitle('FLOX - Forgotten Password');

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address.')
      .required('An email address is required.'),
  });

  const handleSubmit = (
    values: FormikValues,
    { resetForm }: FormikHelpers<ForgotPasswordValues>
  ) => {
    authHttp
      .post(FORGOTTEN_PASSWORD_URL, {
        email: values.email,
      })
      .then((res) => {
        resetForm();
        setRecoveryEmail(values.email);
        setRecoveryEmailSuccess(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        resetForm();
        setRecoveryEmailErrorText('Something went wrong.');
        setRecoveryEmailSendError(true);
      });
  };

  return (
    <Container>
      <ImagePanel variant='login' />
      <FormPanel>
        <ToggleTheme
          css={{ position: 'absolute', top: '$space16', right: '$space32' }}
        />
        <LogoTitleContainer>
          <Logo
            src={darkMode ? FLOX_LOGO_STANDARD_REVERSE_SRC : FLOX_LOGO_BLUE_SRC}
            alt='Flox logo'
          />
          <Heading
            as='h1'
            size={isTablet ? 'md' : 'sm'}
            css={{ textAlign: isTablet ? 'right' : 'center' }}
          >
            {!recoveryEmailSuccess ? 'Forgotten password?' : 'Success!'}
          </Heading>
        </LogoTitleContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <ForgottenPasswordForm>
              {recoveryEmailSendError && (
                <WarningMessage>{recoveryEmailErrorText}</WarningMessage>
              )}
              {!recoveryEmailSuccess ? (
                <>
                  <InputGroup css={{ gap: '$space0' }}>
                    <Label htmlFor='email' visuallyHidden>
                      Email
                    </Label>
                    <Field
                      as={Input}
                      type='email'
                      id='email'
                      name='email'
                      placeholder='Enter your account email address...'
                      isInvalid={(errors.email && touched.email) as boolean}
                      onFocus={() => {
                        if (recoveryEmailSendError) {
                          setRecoveryEmailErrorText('');
                          setRecoveryEmailSendError(false);
                        }
                      }}
                      autoComplete='email'
                      disabled={isSubmitting}
                      css={{ mb: errors.email && '$space8' }}
                    />
                    {errors.email && touched.email && (
                      <ErrorMessage>{errors.email}</ErrorMessage>
                    )}
                  </InputGroup>
                  <Button
                    type='submit'
                    variant='primary'
                    action='cta'
                    size='lg'
                    rightIcon={<Icon name='email' />}
                    isLoading={isSubmitting}
                    loadingIcon={<Icon name='loading' />}
                    loadingText='Sending recovery email...'
                    isFullWidth
                    disabled={!isValid || isSubmitting}
                    css={{ mt: '$space16' }}
                  >
                    {isSubmitting
                      ? 'Sending recovery link...'
                      : 'Send recovery link email'}
                  </Button>
                </>
              ) : (
                <Text css={{ textAlign: 'left' }}>
                  A recovery link email was successfully sent to{' '}
                  <strong>{recoveryEmail}</strong>.
                </Text>
              )}
              <Button
                variant='link'
                size={isMobile ? 'md' : 'sm'}
                leftIcon={<Icon name='chevron_left' />}
                onClick={() => navigate('/login')}
                css={{ mt: '$space16' }}
              >
                Log in
              </Button>
            </ForgottenPasswordForm>
          )}
        </Formik>
      </FormPanel>
    </Container>
  );
}
