/* eslint-disable no-nested-ternary */
import { Button } from '@components/Button';
import { InputGroup } from '@components/InputGroup';
import { Text } from '@components/Text';
import {
  AddProductFormValues,
  Pallet,
  ProductImage,
  SKU,
  UserImage,
} from '@forms/client/AddProductForm/types';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useReadLocalStorage } from 'usehooks-ts';
import { Input } from '@components/Input';
import {
  DIMENSION_UNITS,
  INVENTORY_IMAGES_URL,
  PALLET_TYPES,
  PALLET_WEIGHT_UNITS,
  STANDARD_PALLET_HEIGHT_IN_METRES,
  WEIGHT_UNITS,
} from '@constants';
import { ChangeEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@components/ErrorMessage';
import {
  UnitOfWeight,
  convertUnitToMetres,
  formatPalletWeight,
} from '@forms/client/AddProductForm/utils/format';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  StyledPalletisingCard,
  StyledPalletisingCardHeader,
  StyledPalletisingCardHeaderImageContainer,
  StyledPalletisingCardHeaderTitleContainer,
  StyledAccordionRoot,
  StyledAccordionItem,
  StyledAccordionTrigger,
  StyledAccordionTriggerChevron,
  StyledAccordionContent,
  StyledPalletTotalsGrid,
} from './Palletising.styles';
import {
  UnitOfMeasure,
  formatPalletCube,
  formatPalletHeight,
  getStandardPalletLengthInMetres,
  getStandardPalletWidthInMetres,
  numberToLocale,
} from '../../utils';
import {
  getStandardPalletWeightInKg,
  getStandardPalletWeightInLb,
} from '../../utils/pallet';

interface PalletisingProps {
  sku: SKU;
  skuIndex: number;
  register: UseFormRegister<AddProductFormValues>;
  control: Control<AddProductFormValues>;
  getValues: UseFormGetValues<AddProductFormValues>;
  setValue: UseFormSetValue<AddProductFormValues>;
  setFocus: UseFormSetFocus<AddProductFormValues>;
  getFieldState: UseFormGetFieldState<AddProductFormValues>;
  isSmall: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  watch: UseFormWatch<AddProductFormValues>;
}

export function Palletising({
  sku,
  skuIndex,
  register,
  control,
  getValues,
  setValue,
  setFocus,
  getFieldState,
  isSmall,
  isTablet,
  isDesktop,
  watch,
}: PalletisingProps) {
  const { productImageId } = getValues().product.skus[skuIndex];
  const [openIndex, setOpenIndex] = useState('0');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');

  const { fields, append, remove } = useFieldArray<
    AddProductFormValues,
    `product.skus.${number}.palletising`,
    'id'
  >({
    control,
    name: `product.skus.${skuIndex}.palletising`,
  });

  const skuImage = getValues().skuImages.find(
    (_skuImage) => _skuImage.id === productImageId
  );

  useEffect(() => {
    const currentIndex = fields.length - 1;
    setFocus(`product.skus.${skuIndex}.palletising.${currentIndex}.type`);
    setOpenIndex((fields.length - 1).toString());
  }, [append, skuIndex, fields.length, setFocus]);

  const skuHeight = sku.dimensions.height;
  const skuHeightDimensionsUnitOfMeasure =
    sku.dimensions.dimensionsUnitOfMeasure;
  const skuWeight = sku.grossWeight;
  const skuWeightUnitOfMeasure = sku.grossWeightUnitOfMeasure;

  return (
    <StyledPalletisingCard>
      <StyledPalletisingCardHeader>
        <StyledPalletisingCardHeaderImageContainer
          css={{
            backgroundImage: `url(${
              skuImage
                ? URL.createObjectURL(skuImage.image)
                : `/images/product-placeholder-${
                    darkMode ? 'dark' : 'light'
                  }.svg`
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            aspectRatio: 1,
          }}
        />
        <StyledPalletisingCardHeaderTitleContainer>
          <Text size='sm' weight='bold'>
            {getValues().product.name}
          </Text>
          <Text size='sm' weight='bold'>
            {sku.name && (
              <>
                {`${sku.name?.charAt(0).toUpperCase()}${sku.name?.substring(
                  1
                )}`}
                <Text as='span' size='sm' weight='regular'>
                  {' - '}
                </Text>
              </>
            )}
            <Text as='span' size='sm' weight='regular'>
              {sku.skuId}
            </Text>
          </Text>
          <Text size='sm' weight='bold'>
            Product Qty:
            <Text as='span' size='sm' weight='regular'>
              {' '}
              {sku.amountOfProductInSku || 0}
            </Text>
          </Text>
        </StyledPalletisingCardHeaderTitleContainer>
      </StyledPalletisingCardHeader>
      {fields.length > 0 && (
        <StyledAccordionRoot
          type='single'
          value={openIndex}
          onValueChange={(value: string) => setOpenIndex(value)}
          collapsible
        >
          {fields.map((item, index) => {
            const typeOfPallet: Pallet['type'] = watch(
              `product.skus.${skuIndex}.palletising.${index}.type`
            );

            const hasPalletTypeError = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.type`
            ).error;

            const isPalletTypeTouched = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.type`
            ).isTouched;

            const amountOfSkusInLayerError = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.amountOfSkusInLayer`
            ).error;

            const amountOfSkusInLayerTouched = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.amountOfSkusInLayer`
            ).isTouched;

            const amountOfLayersInPalletError = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.amountOfLayersInPallet`
            ).error;

            const amountOfLayersInPalletTouched = getFieldState(
              `product.skus.${skuIndex}.palletising.${index}.amountOfLayersInPallet`
            ).isTouched;

            const amountOfSkusInLayer = getValues(
              `product.skus.${skuIndex}.palletising.${index}.amountOfSkusInLayer`
            );

            const amountOfLayersInPallet = getValues(
              `product.skus.${skuIndex}.palletising.${index}.amountOfLayersInPallet`
            );

            const amountOfProductInSku = getValues(
              `product.skus.${skuIndex}.amountOfProductInSku`
            );

            const customPalletSpecificationError =
              (getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
                getFieldState(
                  `product.skus.${skuIndex}.palletising.${index}.customPalletSpecification`
                ).error) ||
              Number.isNaN(
                getValues(
                  `product.skus.${skuIndex}.palletising.${index}.customPalletSpecification`
                )
              );

            const customPalletSpecificationTouched =
              getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
              getFieldState(
                `product.skus.${skuIndex}.palletising.${index}.customPalletSpecification`
              ).isTouched;

            const customPalletLengthError =
              (getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
                getFieldState(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.length`
                ).error) ||
              Number.isNaN(
                getValues(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.length`
                )
              );

            const customPalletLengthTouched =
              getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
              getFieldState(
                `product.skus.${skuIndex}.palletising.${index}.dimensions.length`
              ).isTouched;

            const customPalletWidthError =
              (getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
                getFieldState(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.width`
                ).error) ||
              Number.isNaN(
                getValues(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.width`
                )
              );

            const customPalletWidthTouched =
              getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
              getFieldState(
                `product.skus.${skuIndex}.palletising.${index}.dimensions.width`
              ).isTouched;

            const customPalletHeightError =
              (getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
                getFieldState(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.height`
                ).error) ||
              Number.isNaN(
                getValues(
                  `product.skus.${skuIndex}.palletising.${index}.dimensions.height`
                )
              );

            const customPalletHeightTouched =
              getValues(
                `product.skus.${skuIndex}.palletising.${index}.type`
              ) === 'Custom Pallet' &&
              getFieldState(
                `product.skus.${skuIndex}.palletising.${index}.dimensions.height`
              ).isTouched;

            const showNonStandardPalletTotals =
              typeOfPallet === 'Custom Pallet' &&
              !customPalletLengthError &&
              !customPalletWidthError &&
              !customPalletHeightError &&
              amountOfSkusInLayer !== null &&
              !Number.isNaN(amountOfSkusInLayer) &&
              amountOfLayersInPallet !== null &&
              !Number.isNaN(amountOfLayersInPallet);

            const showStandardPalletTotals =
              typeOfPallet !== 'Custom Pallet' &&
              amountOfSkusInLayer !== null &&
              !Number.isNaN(amountOfSkusInLayer) &&
              amountOfLayersInPallet !== null &&
              !Number.isNaN(amountOfLayersInPallet);

            const showTotals =
              showNonStandardPalletTotals || showStandardPalletTotals;

            const isImperialUnitOfWeight =
              skuWeightUnitOfMeasure === 'lb' ||
              skuWeightUnitOfMeasure === 'oz';

            const palletWeightUnit = getValues(
              `product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`
            ) as UnitOfWeight;

            const palletWeight = isImperialUnitOfWeight
              ? getStandardPalletWeightInLb(
                  getValues(
                    `product.skus.${skuIndex}.palletising.${index}.type`
                  ) as
                    | 'GB Pallet'
                    | 'GB Half Pallet'
                    | 'EU Pallet'
                    | 'EU Half Pallet'
                )
              : getStandardPalletWeightInKg(
                  getValues(
                    `product.skus.${skuIndex}.palletising.${index}.type`
                  ) as
                    | 'GB Pallet'
                    | 'GB Half Pallet'
                    | 'EU Pallet'
                    | 'EU Half Pallet'
                );

            const convertPalletWeightToSkuWeight = () => {
              const customPalletWeight = getValues(
                `product.skus.${skuIndex}.palletising.${index}.customPalletWeight`
              ) as number;

              let convertedCustomPalletWeight;

              if (isImperialUnitOfWeight) {
                convertedCustomPalletWeight = customPalletWeight * 2.205;
              } else {
                convertedCustomPalletWeight = customPalletWeight / 2.205;
              }

              return convertedCustomPalletWeight;
            };

            const getCustomPalletWeight =
              skuWeightUnitOfMeasure !== palletWeightUnit
                ? convertPalletWeightToSkuWeight()
                : (getValues(
                    `product.skus.${skuIndex}.palletising.${index}.customPalletWeight`
                  ) as number);

            const getCustomPalletWeightUnit =
              skuWeightUnitOfMeasure !== palletWeightUnit
                ? isImperialUnitOfWeight
                  ? 'lb'
                  : 'Kg'
                : (getValues(
                    `product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`
                  ) as UnitOfWeight);

            return (
              <StyledAccordionItem key={item.id} value={index.toString()}>
                <StyledAccordionTrigger
                  position={
                    index === 0
                      ? 'first'
                      : index === fields.length - 1
                      ? 'last'
                      : 'center'
                  }
                >
                  {typeOfPallet || `Pallet ${index + 1}`}
                  <StyledAccordionTriggerChevron aria-hidden />
                </StyledAccordionTrigger>
                <StyledAccordionContent
                  position={index === fields.length - 1 ? 'last' : 'center'}
                >
                  <InputGroup
                    css={{
                      padding: '$space16',
                      gap: '$space0',
                    }}
                  >
                    <Label
                      htmlFor={`skus.${skuIndex}.palletising.${index}.type`}
                      visuallyHidden
                    >
                      Name
                    </Label>
                    <Controller
                      name={`product.skus.${skuIndex}.palletising.${index}.type`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            ref={field.ref}
                            onValueChange={(value: string) => {
                              field.onChange(value);

                              const palletLayersAmount =
                                getValues().product.skus[skuIndex].palletising[
                                  index
                                ].amountOfLayersInPallet;

                              if (value !== 'Custom Pallet') {
                                setValue(
                                  `product.skus.${skuIndex}.palletising.${index}.dimensions`,
                                  {
                                    length: getStandardPalletLengthInMetres(
                                      value as
                                        | 'GB Pallet'
                                        | 'GB Half Pallet'
                                        | 'EU Pallet'
                                        | 'EU Half Pallet'
                                    ),
                                    width: getStandardPalletWidthInMetres(
                                      value as
                                        | 'GB Pallet'
                                        | 'GB Half Pallet'
                                        | 'EU Pallet'
                                        | 'EU Half Pallet'
                                    ),
                                    // this height is total product height
                                    // plus the standard pallet height
                                    // IF the palletLayersAmount exists
                                    // other wise it's null metres
                                    height: palletLayersAmount
                                      ? (skuHeight as number) *
                                          palletLayersAmount +
                                        STANDARD_PALLET_HEIGHT_IN_METRES
                                      : null,
                                    dimensionsUnitOfMeasure: 'm',
                                  }
                                );
                              }

                              if (value === 'Custom Pallet') {
                                setValue(
                                  `product.skus.${skuIndex}.palletising.${index}.dimensions`,
                                  {
                                    width: null,
                                    height: null,
                                    length: null,
                                    dimensionsUnitOfMeasure: 'm',
                                  }
                                );
                                if (
                                  !getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`
                                  )?.length
                                ) {
                                  setValue(
                                    `product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`,
                                    'Kg'
                                  );
                                }
                              }
                            }}
                            {...(getValues().product.skus[skuIndex].palletising[
                              index
                            ].type !== '' && {
                              defaultValue:
                                getValues().product.skus[skuIndex].palletising[
                                  index
                                ].type,
                            })}
                            isInvalid={
                              hasPalletTypeError && isPalletTypeTouched
                            }
                            onBlur={field.onBlur}
                            placeholder='Select pallet type (required)'
                            css={{
                              gap: '$space0',
                              mb:
                                hasPalletTypeError &&
                                isPalletTypeTouched &&
                                '$space8',
                            }}
                          >
                            {PALLET_TYPES.map((palletType) => (
                              <SelectItem
                                key={palletType.label}
                                value={palletType.value}
                              >
                                {`${palletType.label}`}
                              </SelectItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    {hasPalletTypeError && isPalletTypeTouched && (
                      <ErrorMessage>{hasPalletTypeError.message}</ErrorMessage>
                    )}
                  </InputGroup>
                  {getValues().product.skus[skuIndex].palletising[index]
                    .type === 'Custom Pallet' && (
                    <>
                      <Stack sx={{ padding: '0 1rem 1rem' }}>
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.customPalletSpecification`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => {
                            return (
                              <TextField
                                {...field}
                                error={Boolean(error && isTouched)}
                                helperText={Boolean(error) && error?.message}
                                variant='outlined'
                                label='Custom Pallet Specification (required)'
                                placeholder='Custom Pallet Specification (required)'
                                multiline={!isDesktop}
                                {...(!isDesktop && {
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                })}
                                sx={{
                                  width: '100%',
                                }}
                              />
                            );
                          }}
                        />
                      </Stack>
                      <InputGroup
                        direction='row'
                        css={{
                          gap: '$space16',
                          padding: '$space0 $space16 $space16',
                        }}
                      >
                        <Label
                          htmlFor={`product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`}
                          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
                        >
                          Pallet dimensions
                        </Label>
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                onValueChange={field.onChange}
                                defaultValue='m'
                                onBlur={field.onBlur}
                                placeholder='Unit of measure'
                                css={{ width: '7rem' }}
                              >
                                {DIMENSION_UNITS.map((dimensionUnit) => (
                                  <SelectItem
                                    key={dimensionUnit.label}
                                    value={dimensionUnit.value}
                                  >
                                    {`${dimensionUnit.label}`}
                                  </SelectItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </InputGroup>
                      <InputGroup
                        direction='row'
                        css={{
                          gap: '$space16',
                          padding: '$space0 $space16 $space16',
                        }}
                      >
                        <Label
                          htmlFor={`product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`}
                          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
                        >
                          Pallet weight
                        </Label>
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`}
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                onValueChange={field.onChange}
                                defaultValue={
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.customPalletWeightUnit`
                                  ) ?? 'Kg'
                                }
                                onBlur={field.onBlur}
                                placeholder='Unit of measure'
                                css={{ width: '7rem' }}
                              >
                                {PALLET_WEIGHT_UNITS.map((weightUnit) => (
                                  <SelectItem
                                    key={weightUnit.label}
                                    value={weightUnit.value}
                                  >
                                    {`${weightUnit.label}`}
                                  </SelectItem>
                                ))}
                              </Select>
                            );
                          }}
                        />
                      </InputGroup>
                      <Stack spacing={16} sx={{ padding: '0 1rem 1rem' }}>
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.dimensions.length`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => (
                            <TextField
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              {...field}
                              error={Boolean(error && isTouched)}
                              helperText={Boolean(error) && error?.message}
                              variant='outlined'
                              label='Length (required)'
                              placeholder='Length (required)'
                              multiline={!isDesktop}
                              {...(!isDesktop && {
                                InputLabelProps: {
                                  shrink: true,
                                },
                              })}
                              sx={{
                                width: '100%',
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.dimensions.width`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => (
                            <TextField
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              {...field}
                              error={Boolean(error && isTouched)}
                              helperText={Boolean(error) && error?.message}
                              variant='outlined'
                              label='Width (required)'
                              placeholder='Width (required)'
                              multiline={!isDesktop}
                              {...(!isDesktop && {
                                InputLabelProps: {
                                  shrink: true,
                                },
                              })}
                              sx={{
                                width: '100%',
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.dimensions.height`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => (
                            <TextField
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              {...field}
                              error={Boolean(error && isTouched)}
                              helperText={Boolean(error) && error?.message}
                              variant='outlined'
                              label='Height (required)'
                              placeholder='Height (required)'
                              multiline={!isDesktop}
                              {...(!isDesktop && {
                                InputLabelProps: {
                                  shrink: true,
                                },
                              })}
                              sx={{
                                width: '100%',
                              }}
                            />
                          )}
                        />
                        <Controller
                          name={`product.skus.${skuIndex}.palletising.${index}.customPalletWeight`}
                          control={control}
                          render={({
                            field,
                            fieldState: { error, isTouched },
                          }) => (
                            <TextField
                              inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                              }}
                              {...field}
                              error={Boolean(error && isTouched)}
                              helperText={Boolean(error) && error?.message}
                              variant='outlined'
                              label='Weight (required)'
                              placeholder='Weight (required)'
                              multiline={!isDesktop}
                              {...(!isDesktop && {
                                InputLabelProps: {
                                  shrink: true,
                                },
                              })}
                              sx={{
                                width: '100%',
                              }}
                            />
                          )}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack spacing={16} sx={{ padding: '0 1rem 1rem' }}>
                    <Controller
                      name={`product.skus.${skuIndex}.palletising.${index}.amountOfSkusInLayer`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          {...field}
                          error={Boolean(error && isTouched)}
                          helperText={Boolean(error) && error?.message}
                          variant='outlined'
                          label='Number of SKUs in layer (required)'
                          placeholder='Number of SKUs in layer (required)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          sx={{
                            width: '100%',
                          }}
                        />
                      )}
                    />
                    <Controller
                      name={`product.skus.${skuIndex}.palletising.${index}.amountOfLayersInPallet`}
                      control={control}
                      render={({ field, fieldState: { error, isTouched } }) => (
                        <TextField
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          {...field}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const palletLayersAmount =
                              getValues().product.skus[skuIndex].palletising[
                                index
                              ].amountOfLayersInPallet;

                            if (typeOfPallet !== 'Custom Pallet') {
                              setValue(
                                `product.skus.${skuIndex}.palletising.${index}.dimensions.height`,
                                e.target.value
                                  ? (skuHeight as number) *
                                      Number(e.target.value) +
                                      STANDARD_PALLET_HEIGHT_IN_METRES
                                  : null
                              );
                            }

                            field.onChange(e.target.value);
                          }}
                          error={Boolean(error && isTouched)}
                          helperText={error?.message}
                          variant='outlined'
                          label='Number of layers (required)'
                          placeholder='Number of layers (required)'
                          multiline={!isDesktop}
                          {...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          })}
                          sx={{
                            width: '100%',
                          }}
                        />
                      )}
                    />
                  </Stack>
                  {showTotals && (
                    <StyledPalletTotalsGrid>
                      <Text size={isSmall ? 'md' : 'xs'} weight='bold'>
                        Total SKU/UoM:
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'}>
                        {numberToLocale(
                          amountOfSkusInLayer * amountOfLayersInPallet
                        )}
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'} weight='bold'>
                        Total products:
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'}>
                        {numberToLocale(
                          (amountOfProductInSku as number) *
                            amountOfSkusInLayer *
                            amountOfLayersInPallet
                        )}
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'} weight='bold'>
                        Total height:
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'}>
                        {typeOfPallet === 'Custom Pallet'
                          ? numberToLocale(
                              formatPalletHeight(
                                skuHeight as number,
                                skuHeightDimensionsUnitOfMeasure as UnitOfMeasure,
                                amountOfLayersInPallet,
                                getValues(
                                  `product.skus.${skuIndex}.palletising.${index}.dimensions.height`
                                ) as number,
                                getValues(
                                  `product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`
                                ) as UnitOfMeasure
                              )
                            )
                          : numberToLocale(
                              formatPalletHeight(
                                skuHeight as number,
                                skuHeightDimensionsUnitOfMeasure as UnitOfMeasure,
                                amountOfLayersInPallet
                              )
                            )}
                        m
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'} weight='bold'>
                        Total weight:
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'}>
                        {typeOfPallet === 'Custom Pallet'
                          ? numberToLocale(
                              formatPalletWeight(
                                skuWeight as number,
                                skuWeightUnitOfMeasure as UnitOfWeight,
                                amountOfSkusInLayer,
                                amountOfLayersInPallet,
                                getCustomPalletWeight,
                                getCustomPalletWeightUnit
                              )
                            )
                          : numberToLocale(
                              formatPalletWeight(
                                skuWeight as number,
                                skuWeightUnitOfMeasure as UnitOfWeight,
                                amountOfSkusInLayer,
                                amountOfLayersInPallet,
                                palletWeight,
                                isImperialUnitOfWeight ? 'lb' : 'Kg'
                              )
                            )}{' '}
                        {isImperialUnitOfWeight ? `ton` : `tonne`}
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'} weight='bold'>
                        Total cube:
                      </Text>
                      <Text size={isSmall ? 'md' : 'xs'}>
                        {typeOfPallet === 'Custom Pallet'
                          ? // 'x'
                            numberToLocale(
                              formatPalletCube(
                                // pallet length in metres
                                convertUnitToMetres(
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.length`
                                  ) as number,
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`
                                  ) as UnitOfMeasure
                                ),
                                // pallet width in metres
                                convertUnitToMetres(
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.width`
                                  ) as number,
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`
                                  ) as UnitOfMeasure
                                ),
                                // total height in metres - skuHeight + Custom Pallet height
                                formatPalletHeight(
                                  skuHeight as number,
                                  skuHeightDimensionsUnitOfMeasure as UnitOfMeasure,
                                  amountOfLayersInPallet,
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.height`
                                  ) as number,
                                  getValues(
                                    `product.skus.${skuIndex}.palletising.${index}.dimensions.dimensionsUnitOfMeasure`
                                  ) as UnitOfMeasure
                                )
                              )
                            )
                          : numberToLocale(
                              formatPalletCube(
                                // pallet length in metres
                                getStandardPalletLengthInMetres(
                                  typeOfPallet as
                                    | 'GB Pallet'
                                    | 'GB Half Pallet'
                                    | 'EU Pallet'
                                    | 'EU Half Pallet'
                                ),
                                // pallet width in metres
                                getStandardPalletWidthInMetres(
                                  typeOfPallet as
                                    | 'GB Pallet'
                                    | 'GB Half Pallet'
                                    | 'EU Pallet'
                                    | 'EU Half Pallet'
                                ),
                                // total pallet height in metres including the standard pallet height itself
                                formatPalletHeight(
                                  skuHeight as number,
                                  skuHeightDimensionsUnitOfMeasure as UnitOfMeasure,
                                  amountOfLayersInPallet
                                )
                              )
                            )}
                        m<sup>3</sup>
                      </Text>
                    </StyledPalletTotalsGrid>
                  )}
                  <InputGroup
                    css={{
                      padding: '$space0 $space16 $space16',
                      gap: '$space0',
                    }}
                  >
                    <Button
                      size='sm'
                      leftIcon={<MdDelete size='1.5rem' />}
                      action='danger'
                      isFullWidth
                      onClick={() => remove(index)}
                    >
                      Delete pallet
                    </Button>
                  </InputGroup>
                </StyledAccordionContent>
              </StyledAccordionItem>
            );
          })}
        </StyledAccordionRoot>
      )}
      <Button
        size='md'
        action='cta'
        leftIcon={<MdAdd size='1.5rem' />}
        isFullWidth
        onClick={() => {
          append(
            {
              type: '',
              customPalletSpecification: '',
              customPalletWeight: null,
              customPalletWeightUnit: null,
              amountOfSkusInLayer: null,
              amountOfLayersInPallet: null,
              dimensions: {
                width: null,
                height: null,
                length: null,
                dimensionsUnitOfMeasure: '',
              },
            },
            {
              shouldFocus: true,
              focusIndex: 0,
              focusName: `product.skus.${skuIndex}.palletising${
                fields.length - 1
              }`,
            }
          );
        }}
      >
        Add pallet type
      </Button>
    </StyledPalletisingCard>
  );
}

Palletising.displayName = 'Palletising';
