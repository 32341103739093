import { ComponentPropsWithoutRef } from 'react';
import type * as Stitches from '@stitches/react';
import { StyledInfoMessage } from './InfoMessage.styles';

interface InfoMessageProps extends ComponentPropsWithoutRef<'span'> {
  css?: Stitches.CSS;
}

export function InfoMessage({ children, css, ...props }: InfoMessageProps) {
  return (
    <StyledInfoMessage css={css} {...props}>
      {children}
    </StyledInfoMessage>
  );
}

InfoMessage.displayName = 'InfoMessage';
