import { styled } from '@stitchesConfig';
import { Form } from 'formik';

export const Container = styled('main', {
  display: 'flex',
  width: '100vw',
  minHeight: '100vh',
  backgroundColor: '$gray1',
});

Container.displayName = 'Container';

export const ImagePanel = styled('section', {
  display: 'none',
  minHeight: '100vh',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right',
  transition:
    'background-image 300ms ease-in-out, max-width 300ms ease-in-out, min-width 300ms ease-in-out',

  '@md': {
    display: 'block',
    minWidth: '18.25rem',
    maxWidth: '18.25rem',
  },

  '@lg': {
    minWidth: '23.6875rem',
    maxWidth: '23.6875rem',
  },

  '@xl': {
    minWidth: '28.625rem',
    maxWidth: '28.625rem',
  },

  '@2xl': {
    minWidth: '33.4375rem',
    maxWidth: '33.4375rem',
  },

  '@3xl': {
    minWidth: '45%',
    maxWidth: '45%',
  },

  variants: {
    variant: {
      login: {
        backgroundImage: 'url(/images/bg-login.png)',
      },
      register: {
        backgroundImage: 'url(/images/bg-register.png)',
      },
    },
  },
});

ImagePanel.displayName = 'ImagePanel';

export const FormPanel = styled('section', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space32',
  px: '$space16',
  py: '$space32',
  position: 'relative',

  '@md': {
    px: '$space48',
  },
});

FormPanel.displayName = 'FormPanel';

export const LogoTitleContainer = styled('div', {
  width: '100%',
  maxWidth: '27.125rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '$space32',

  '@md': {
    alignItems: 'flex-end',
    gap: '$space96',
  },
});

LogoTitleContainer.displayName = 'LogoTitleContainer';

export const Logo = styled('img', {
  width: '8rem',

  '@md': {
    width: '12.5rem',
  },
});

Logo.displayName = 'Logo';

export const LoginForm = styled(Form, {
  width: '100%',
  maxWidth: '27.125rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '$space16',
});

LoginForm.displayName = 'Form';

export const FormSwitchContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space8',
  mt: '$space16',
});

FormSwitchContainer.displayName = 'FormSwitchContainer';
