import * as VisuallyHiddenPrimitive from '@radix-ui/react-visually-hidden';
import { ReactNode } from 'react';

interface VisuallyHiddenProps {
  children: ReactNode;
  asChild?: boolean;
}

export function VisuallyHidden({
  children,
  asChild = false,
}: VisuallyHiddenProps) {
  return (
    <VisuallyHiddenPrimitive.Root asChild={asChild}>
      {children}
    </VisuallyHiddenPrimitive.Root>
  );
}

VisuallyHidden.displayName = 'VisuallyHidden';
