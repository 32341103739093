import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { View } from '@views/View';
import { Heading } from '@components/Heading';
import { IconButton, Stack } from '@mui/material';
import { useMediaQuery } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getNoticeColors } from '@views/Noticeboard/utils';
import ErrorIcon from '@mui/icons-material/Error';
import { useAtom } from 'jotai';
import { classNames } from '../../utils/classes';
import { useAuthHttp } from '../../hooks';
import {
  DELETE_WAREHOUSE_MATCH,
  GET_NOTIFICATIONS,
  MATCH_LIST,
} from '../../constants';
import { notificationsAtom } from '../../store/jotai';

export function CustomerShortlist() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [notifications, setNotifications] = useAtom(notificationsAtom);
  const [customers, setCustomers] = useState<any[]>([]);
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const authHttp = useAuthHttp();
  const navigate = useNavigate();

  const fetchCustomerShortlist = async () => {
    setIsCustomersLoading(true);
    try {
      const { data: customerShortlist } = await authHttp.post(
        MATCH_LIST,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (customerShortlist.matches.length > 0) {
        setCustomers(customerShortlist.matches);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsCustomersLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerShortlist();
  }, []);

  useEffect(() => {
    const notification = notifications[0];
    if (
      notification &&
      [
        'WarehouseMatch.Accepted',
        'WarehouseMatch.Proposed',
        'WarehouseMatch.Created',
        'WarehouseMatch.Deleted',
        'WarehouseMatch.DocumentUpdated',
      ].includes(notification.notificationType)
    ) {
      fetchCustomerShortlist();
    }
  }, [notifications]);
  const handleDeleteMatch = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    matchId: string
  ) => {
    e.stopPropagation();
    setIsCustomersLoading(true);
    try {
      const { data: matchDeleted } = await authHttp.post(
        DELETE_WAREHOUSE_MATCH,
        {
          match: {
            matchId,
          },
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (matchDeleted.match) {
        fetchCustomerShortlist();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsCustomersLoading(false);
    }
  };

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Customer Requests & Contracts
        </Heading>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                {/* <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  <span className='sr-only'>Expand</span>
                </th> */}
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Industry
                </th>
                {/* <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Required service
                </th> */}
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Needed Storage (in UoMs)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Start Date
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Duration (months)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Inbound (in UoMs)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Outbound (in UoMs)
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Status
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-3'>
                  <span className='sr-only'>Delete</span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isCustomersLoading ? (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='customer-loader'
                      loadingMessage='Loading customers...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              ) : (
                customers.map((customer, index) => {
                  const setRowOpen = (
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    const newExpandedRows = new Set(expandedRows);
                    if (expandedRows.has(index)) {
                      newExpandedRows.delete(index);
                    } else {
                      newExpandedRows.add(index);
                    }
                    setExpandedRows(newExpandedRows);
                  };
                  return (
                    <>
                      <tr
                        key={customer.match.userBusinessName}
                        className={classNames(
                          'border-gray-200',
                          'border-t',
                          'hover:bg-gray-50',
                          'transition-colors',
                          'duration-200',
                          'ease-in-out',
                          'cursor-pointer',
                          expandedRows.has(index) ? 'bg-gray-50' : ''
                        )}
                        onClick={() => {
                          navigate(
                            `/warehouse/${customer.match.warehouseId}?matchId=${customer.match.id}`
                          );
                        }}
                      >
                        {/* <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={(e) => setRowOpen(e)}
                            sx={{ color: 'var(--colors-gray11)' }}
                          >
                            {expandedRows.has(index) ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </td> */}
                        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                          {customer.match.userBusinessName} -{' '}
                          {customer.match.warehouseName}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.shipperIndustry}
                        </td>
                        {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.requestedServices}
                        </td> */}
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.averageStorageQuantityPerWeek}{' '}
                          {customer.warehouse.storageCapacityUnitOfMeasure}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.desiredStartDate}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.estimatedDurationInMonths}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {
                            customer.match
                              .averageNumberOfInboundShipmentsPerWeek
                          }{' '}
                          {customer.warehouse.storageCapacityUnitOfMeasure}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {
                            customer.match
                              .averageNumberOfOutboundShipmentsPerWeek
                          }{' '}
                          {customer.warehouse.storageCapacityUnitOfMeasure}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                          {customer.match.matchStatus}{' '}
                          {customer.match.isActionRequiredByLsp &&
                            customer.match.matchStatus === 'Negotiating' && (
                              <ErrorIcon
                                sx={{
                                  width: 16,
                                  height: 16,
                                  fill: getNoticeColors('warning').fill,
                                }}
                              />
                            )}
                        </td>
                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                          {customer.match.matchStatus === 'Not engaged' ||
                          customer.match.matchStatus === 'Negotiating' ? (
                            <button
                              type='button'
                              className='inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                              onClick={(e) =>
                                handleDeleteMatch(e, customer.match.id)
                              }
                            >
                              <MdDelete
                                className='-ml-0.5 h-5 w-5'
                                aria-hidden='true'
                              />
                              Delete
                            </button>
                          ) : (
                            <span className='sr-only' />
                          )}
                        </td>
                      </tr>
                      {expandedRows.has(index) && (
                        <tr>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                            <span className='sr-only' />
                          </td>
                          <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3 flex justify-center'>
                            <span className='sr-only' />
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </View>
  );
}
