import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const ACCESS_SPACE_UOM = [
  {
    label: 'ft',
    value: 'Foot',
  },
  {
    label: 'm',
    value: 'Metre',
  },
];

export function Access() {
  const { control, getValues, watch } = useFormContext();
  const watchAccessSpaceUoM = watch('accessSpaceUoM');
  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Access
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='accessSpaceUoM'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Access space UoM (Unit of Measure)
        </Label>
        <Controller
          name='accessSpaceUoM'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`accessSpaceUoM`)}
                onBlur={field.onBlur}
                placeholder='Please select'
                css={{ width: '25%' }}
                isInvalid={Boolean(error)}
              >
                {ACCESS_SPACE_UOM.map((accessSpaceUoM) => (
                  <SelectItem
                    key={accessSpaceUoM.label}
                    value={accessSpaceUoM.value}
                  >
                    {`${accessSpaceUoM.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      <Controller
        name='yardSpace'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Yard space (in ${
              watchAccessSpaceUoM === 'Foot'
                ? 'Square Feet'
                : 'Square Metres' ?? 'sq.ft or sq.m'
            })`}
            placeholder={`Yard space (in ${
              watchAccessSpaceUoM ?? 'sq.ft or sq.m'
            })`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='vehicleAccessHeight'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label={`Vehicle access height (in ${
              watchAccessSpaceUoM ?? 'sq.ft or sq.m'
            })`}
            placeholder={`Vehicle access height (in ${
              watchAccessSpaceUoM ?? 'sq.ft or sq.m'
            })`}
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='leveledLoadingDocks'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of Levelled loading docks (if any)'
            placeholder='# of Levelled loading docks (if any)'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='doubleHeightLoadingDocks'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of Double height docks (if any)'
            placeholder='# of Double height docks (if any)'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='lorryParkingSpaces'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of Lorry parking spaces *'
            placeholder='# of Lorry parking spaces *'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='carParkingSpaces'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='# of Car parking spaces (if any)'
            placeholder='# of Car parking spaces (if any)'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
    </Stack>
  );
}
