import { useState } from 'react';
import { useAuthHttp, useCloseDialog } from '@hooks';
import { ConfirmPickingNoteNoteFormValues } from '@forms/manager/ConfirmPickingNoteForm/types';
import { CONFIRM_PICKING_NOTE } from '@constants';
import { upsertSingleOutboundProcess } from '@features/supplyChain/supplyChainSlice';
import { useAppDispatch } from '@store';

export const usePickingNote = () => {
  const authHttp = useAuthHttp();
  const dispatch = useAppDispatch();

  const [isConfirmPickingNoteFormOpen, setIsConfirmPickingNoteFormOpen] =
    useState(false);

  const handleCloseConfirmPickingNoteForm = useCloseDialog(
    setIsConfirmPickingNoteFormOpen
  );

  const handleSubmitConfirmPickingNote = async (
    values: ConfirmPickingNoteNoteFormValues
  ) => {
    try {
      const formValues = {
        pickingNote: {
          supplyChainProcessId: values.supplyChainProcessId,
          despatchNoteId: values.despatchNoteId,
          comment: values.comment,
          actualPickingDate: values.pickingDateConfirmation,
          skus: values.skus.map((sku) => ({
            productId: sku.productId,
            skuId: sku.skuId,
            packagingComment: sku.packagingComment,
            pickedQuantity: sku.pickedQuantity,
            discrepancyReason: sku.discrepancyReason,
          })),
        },
      };

      const {
        data: { supplyChainProcess },
      } = await authHttp.post(CONFIRM_PICKING_NOTE, formValues);
      dispatch(upsertSingleOutboundProcess(supplyChainProcess));
      setIsConfirmPickingNoteFormOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return {
    isConfirmPickingNoteFormOpen,
    setIsConfirmPickingNoteFormOpen,
    handleCloseConfirmPickingNoteForm,
    handleSubmitConfirmPickingNote,
  };
};
