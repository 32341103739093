import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Dispatch, SetStateAction } from 'react';

// const CORE_SERVICES = [
//   { label: 'Leasable storage space only', value: 'leasableStorageSpaceOnly' },
//   {
//     label: 'Storage space and transportation',
//     value: 'storageSpaceAndTransportation',
//   },
//   { label: 'Pallet storage only', value: 'palletStorageOnly' },
//   {
//     label: 'Pallet storage and transportation',
//     value: 'palletStorageAndTransportation',
//   },
//   // 'Transportation only',
// ];

const SPECIAL_CAPABILITIES = [
  'Bulk Powder',
  'Bulk Liquid',
  'Hazardous Chemicals',
  'Hazardous Other',
  'High Value',
  'LME',
  'Wet Bonded',
  'Dry Bonded',
  'Container',
  'Outdoor',
];

const VALUE_ADDING_SERVICES = [
  'Import/Export Documentation',
  'Container Devanning and Sub-sorting',
  'Shrink Wrapping',
  'Waste Disposal',
  'Flexible Operations Schedule',
  'Re-packaging, Customised Labelling, Light Assembly & Documentation',
  'Re-processing and Quality Control Programs',
  'Return processing and Management',
  'Contract Packing',
  'Procurement and Management of All Packaging Materials',
  'Product Kitting and Repackaging',
  'Contract Manufacturing',
  'Hazardous Goods handling',
  'Special Projects',
  'Domestic fulfilment',
  'International fulfilment',
];

const SYSTEMS_AND_SECURITY = [
  'WMS',
  'TMS',
  'WiFi',
  'API',
  'EDI',
  'Other',
  '24/7 Security',
  '24/7 Access',
  'Bunded',
  'Caged',
  'Sprinklered',
  'Alarmed',
  'Patrolled',
  'Fenced Yard',
  'CCTV',
  'ESFR Sprinkler System',
];

const CURRENCY = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export function FiltersSideDrawer({
  isOpen,
  setIsOpen,
  isDesktop,
  currency,
  specialCapabilities,
  setSpecialCapabilities,
  valueAddingServices,
  setValueAddingServices,
  systemsAndSecurity,
  setSystemsAndSecurity,
  fromPrice,
  setFromPrice,
  toPrice,
  setToPrice,
  setCurrency,
  handleFilterClick,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  currency: string;
  specialCapabilities: string[];
  setSpecialCapabilities: Dispatch<SetStateAction<string[]>>;
  valueAddingServices: string[];
  setValueAddingServices: Dispatch<SetStateAction<string[]>>;
  systemsAndSecurity: string[];
  setSystemsAndSecurity: Dispatch<SetStateAction<string[]>>;
  fromPrice: string;
  setFromPrice: Dispatch<SetStateAction<string>>;
  toPrice: string;
  setToPrice: Dispatch<SetStateAction<string>>;
  setCurrency: Dispatch<SetStateAction<string>>;
  handleFilterClick: () => void;
}) {
  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Drawer
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={onClose}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: isDesktop ? '32rem' : '100%',
          p: 16,
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          size='medium'
          sx={{
            height: '2.5rem',
            color: (theme) => theme.palette.grey[500],
            mb: '1rem',
            ...(!isDesktop && {
              alignSelf: 'flex-end',
            }),
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ p: 8, width: '100%' }}>
          <Stack flexDirection='column' gap={24}>
            {/* <Select
            value={coreService}
            onValueChange={(value) => setCoreService(value)}
            placeholder='Core services'
            css={{ width: '25%' }}
          >
            {CORE_SERVICES.map((service) => (
              <SelectItem key={service.label} value={service.value}>
                {`${service.label}`}
              </SelectItem>
            ))}
          </Select> */}
            <Autocomplete
              multiple
              limitTags={2}
              id='checkboxes-tags-demo'
              options={SPECIAL_CAPABILITIES}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              value={specialCapabilities}
              onChange={(event, value) => setSpecialCapabilities(value)}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Special and bulk goods'
                  placeholder='Special and bulk goods'
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={2}
              id='checkboxes-tags-demo'
              options={VALUE_ADDING_SERVICES}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              value={valueAddingServices}
              onChange={(event, value) => setValueAddingServices(value)}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Value adding services'
                  placeholder='Value adding services'
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={2}
              id='checkboxes-tags-demo'
              options={SYSTEMS_AND_SECURITY}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              value={systemsAndSecurity}
              onChange={(event, value) => setSystemsAndSecurity(value)}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Systems and security'
                  placeholder='Systems and security'
                />
              )}
            />
            <Typography>Storage space price per week</Typography>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Currency</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={currency}
                label='Currency'
                onChange={(event) => setCurrency(event.target.value)}
              >
                {CURRENCY.map((_currency) => (
                  <MenuItem key={_currency.label} value={_currency.value}>
                    {`${_currency.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant='outlined'
              label='FROM'
              placeholder='FROM'
              sx={{
                width: '100%',
              }}
              defaultValue={fromPrice}
              onChange={(event) => setFromPrice(event.target.value)}
            />
            <TextField
              variant='outlined'
              label='TO'
              placeholder='TO'
              sx={{
                width: '100%',
              }}
              defaultValue={toPrice}
              onChange={(event) => setToPrice(event.target.value)}
            />
            <Button variant='contained' onClick={handleFilterClick}>
              Filter
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}
