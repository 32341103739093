import { Text } from '@components/Text';
import { getTotalPallets, getPallets } from '@utils';

interface PalletCalculatorProps {
  palletName: string;
  skuQuantity: number;
  amountOfSkusInLayer: number;
  amountOfLayersInPallet: number;
}

export function PalletCalculator({
  palletName,
  skuQuantity,
  amountOfSkusInLayer,
  amountOfLayersInPallet,
}: PalletCalculatorProps) {
  const pallets = getPallets(
    skuQuantity,
    amountOfSkusInLayer,
    amountOfLayersInPallet
  );

  const totalPallets = getTotalPallets(
    skuQuantity,
    amountOfSkusInLayer,
    amountOfLayersInPallet
  );
  return (
    <Text as='p' size='md'>
      <Text as='span' size='md' css={{ display: 'block' }}>
        {totalPallets} {palletName}
        {totalPallets > 1 ? 's' : ''}
      </Text>
      <Text
        as='span'
        size='sm'
        css={{
          display: 'block',
          color: 'var(--colors-gray11)',
        }}
      >
        {pallets} pallet
        {parseFloat(pallets) > 1 ? 's' : ''}
      </Text>
    </Text>
  );
}
