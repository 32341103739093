import { ComponentPropsWithoutRef } from 'react';
import { StyledWarningMessage } from './WarningMessage.styles';

function WarningIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      style={{ minWidth: '1.5rem', height: '1.5rem' }}
    >
      <path
        fill='currentColor'
        d='M12 17a.968.968 0 0 0 .713-.288A.967.967 0 0 0 13 16a.97.97 0 0 0-.287-.713A.97.97 0 0 0 12 15a.967.967 0 0 0-.712.287A.968.968 0 0 0 11 16c0 .283.096.52.288.712A.965.965 0 0 0 12 17Zm-1-4h2V7h-2v6Zm1 9a9.733 9.733 0 0 1-3.9-.788 10.092 10.092 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.733 9.733 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.092 10.092 0 0 1 2.137-3.175c.9-.9 1.958-1.613 3.175-2.138A9.743 9.743 0 0 1 12 2c1.383 0 2.683.262 3.9.787a10.105 10.105 0 0 1 3.175 2.138c.9.9 1.612 1.958 2.137 3.175A9.733 9.733 0 0 1 22 12a9.733 9.733 0 0 1-.788 3.9 10.092 10.092 0 0 1-2.137 3.175c-.9.9-1.958 1.612-3.175 2.137A9.733 9.733 0 0 1 12 22Zm0-2c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z'
      />
    </svg>
  );
}

type WarningMessageProps = ComponentPropsWithoutRef<'div'>;

export function WarningMessage({ children }: WarningMessageProps) {
  return (
    <StyledWarningMessage>
      <WarningIcon />
      {children}
    </StyledWarningMessage>
  );
}

WarningMessage.displayName = 'WarningMessageProps';
