import { MouseEvent, ChangeEvent, useState, useEffect } from 'react';
import { Heading } from '@components/Heading';
import { View } from '@views/View';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import {
  useMediaQuery,
  useReadLocalStorage,
  useDocumentTitle,
} from 'usehooks-ts';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useAuthHttp,
  useSkus,
  WarehouseClientOption,
  WarehouseOption,
} from '@hooks';
import { FilterService, UserRoleService } from '@services';
import InfiniteScroll from 'react-infinite-scroller';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import {
  fetchLspWarehouses,
  fetchShipperWarehouses,
  fetchWarehouseClients,
} from '@api';
import { useAppDispatch } from '@store';
import { clearSkus } from '@features/inventory';
import { useAuth } from '@context';
import { StockBySKUTablePaginationActions } from './components';
import { CollapsibleRow } from './components/CollapsibleRow';

export function StockBySKU() {
  const { user } = useAuth();
  const { isWarehouseClient, isWarehouseManager } = UserRoleService();
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [selectedSKUName, setSelectedSKUName] = useState('Case');
  const darkMode = useReadLocalStorage('usehooks-ts-dark-mode');
  const { getFilterValue, setFilterValue } = FilterService();

  const [skuStockWarehouseId, setSkuStockWarehouseId] = useState(
    getFilterValue('skuStockWarehouseId')
  );

  const [skuStockShipperId, setSkuStockShipperId] = useState(
    getFilterValue('skuStockShipperId')
  );

  const dispatch = useAppDispatch();

  useDocumentTitle('FLOX - Stock');

  const {
    skus,
    fetchSkus,
    isLoadingSkus,
    itemsPerPage,
    setItemsPerPage,
    totalCount,
    page,
    setPage,
    highestPage,
    setHighestPage,
    searchQuery,
    handleChangeSearchQuery,
  } = useSkus({
    minTotalStock: 1,
    isPalletisingRequired: false,
    warehouseId: skuStockWarehouseId,
    ...(isWarehouseManager(user) && {
      shipperBusinessId: skuStockShipperId,
    }),
  });

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > highestPage) {
      await fetchSkus();
      setHighestPage((prevHighestPage) => prevHighestPage + 1);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
  };

  const authHttp = useAuthHttp();

  const [warehouseOptions, setWarehousesOptions] = useState<WarehouseOption[]>(
    []
  );
  const [isLoadingWarehouses, setIsLoadingWarehouses] = useState(false);
  const [isWarehousesError, setIsWarehousesError] = useState(false);

  const fetchWarehouses = async () => {
    let warehouses: WarehouseOption[];

    setIsLoadingWarehouses(true);
    try {
      if (isWarehouseClient(user)) {
        warehouses = await fetchShipperWarehouses(authHttp);
        setWarehousesOptions(warehouses);
      } else {
        warehouses = await fetchLspWarehouses(authHttp);
        setWarehousesOptions(warehouses);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsWarehousesError(true);
    } finally {
      setIsLoadingWarehouses(false);
    }
  };

  const [warehouseClientOptions, setWarehouseClientOptions] = useState<
    WarehouseClientOption[]
  >([]);
  const [isLoadingWarehouseClients, setIsLoadingWarehouseClients] =
    useState(false);
  const [isWarehouseClientsError, setIsWarehouseClientsError] = useState(false);

  const fetchClients = async () => {
    let clients: WarehouseClientOption[];

    setIsLoadingWarehouseClients(true);
    try {
      clients = await fetchWarehouseClients(authHttp);
      setWarehouseClientOptions(clients);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsWarehouseClientsError(true);
    } finally {
      setIsLoadingWarehouseClients(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchWarehouses();
      if (isWarehouseManager(user)) {
        await fetchClients();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeWarehouse = (event: SelectChangeEvent) => {
    dispatch(clearSkus());
    setSkuStockWarehouseId(event.target.value);
    setFilterValue('skuStockWarehouseId', event.target.value);
  };

  const handleChangeClient = (event: SelectChangeEvent) => {
    dispatch(clearSkus());
    setSkuStockShipperId(event.target.value);
    setFilterValue('skuStockShipperId', event.target.value);
  };

  const tableHeadHeight = '2.2813rem';
  const tableRowHeight = '3.3125rem';

  return (
    <View sx={{ gap: 24 }}>
      <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
        {`Stock by SKU - ${selectedSKUName}`}
      </Heading>
      {/* SKU table filters */}
      <Box
        display='grid'
        gridTemplateColumns={
          isDesktop
            ? `repeat(${isWarehouseManager(user) ? '5' : '4'}, 1fr)`
            : 'auto'
        }
        gap={16}
      >
        {/* Search */}
        <FormControl
          sx={{
            width: '100%',
            gridColumn: isDesktop
              ? `${isWarehouseManager(user) ? '5 / 6' : '4 / 5'}`
              : '1 / -1',
            gridRow: isDesktop ? '1 / -1' : '1 / 2',
          }}
          variant='outlined'
        >
          <OutlinedInput
            size='small'
            startAdornment={
              <InputAdornment position='start'>
                <IconButton edge='start'>
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            placeholder='Search...'
            value={searchQuery}
            onChange={handleChangeSearchQuery}
          />
        </FormControl>
        {/* Warehouse filter */}
        <FormControl
          sx={{ width: '100%', gridColumn: isDesktop ? '1 / 2' : '1 / -1' }}
        >
          <InputLabel
            id='select-helper-label-warehouse'
            size='small'
            sx={{ zIndex: 'unset' }}
          >
            Warehouse
          </InputLabel>
          <Select
            size='small'
            labelId='select-helper-label-warehouse'
            label='Warehouse'
            value={skuStockWarehouseId || 'All'}
            onChange={handleChangeWarehouse}
          >
            <MenuItem value='All'>All</MenuItem>
            {warehouseOptions.map((option) => (
              <MenuItem key={option.warehouseId} value={option.warehouseId}>
                {option.warehouseName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* Client filter */}
        {isWarehouseManager(user) && (
          <FormControl
            sx={{
              width: '100%',
              gridColumn: isDesktop ? '2 / 3' : '1 / -1',
            }}
          >
            <InputLabel
              id='select-helper-label-client'
              size='small'
              sx={{ zIndex: 'unset' }}
            >
              Client
            </InputLabel>
            <Select
              size='small'
              labelId='select-helper-label-client'
              label='Client'
              value={skuStockShipperId || 'All'}
              onChange={handleChangeClient}
            >
              <MenuItem value='All'>All</MenuItem>
              {warehouseClientOptions.map((option) => (
                <MenuItem
                  key={option.userBusinessId}
                  value={option.userBusinessId}
                >
                  {option.userBusinessName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* Category filter */}
        <FormControl
          sx={{
            width: '100%',
            gridColumn: isDesktop
              ? `${isWarehouseManager(user) ? '3 / 4' : '2 / 3'}`
              : '1 / -1',
          }}
        >
          <InputLabel
            id='select-helper-label-category'
            size='small'
            sx={{ zIndex: 'unset' }}
          >
            Category
          </InputLabel>
          <Select
            size='small'
            labelId='select-helper-label-category'
            label='Category'
            disabled
          >
            <MenuItem value='Item'>Category 1</MenuItem>
            <MenuItem value='Case'>Category 2</MenuItem>
          </Select>
        </FormControl>
        {/* Sku name filter */}
        <FormControl
          sx={{
            width: '100%',
            gridColumn: isDesktop
              ? `${isWarehouseManager(user) ? '4 / 5' : '3 / 4'}`
              : '1 / -1',
          }}
        >
          <InputLabel
            id='select-helper-label-sku-name'
            size='small'
            sx={{ zIndex: 'unset' }}
          >
            SKU name
          </InputLabel>
          <Select
            size='small'
            labelId='select-helper-label-sku-name'
            label='SKU name'
            value={selectedSKUName}
            onChange={(event: SelectChangeEvent) =>
              setSelectedSKUName(event.target.value)
            }
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ width: '100%' }}
            disabled
          >
            <MenuItem value='Item'>Item</MenuItem>
            <MenuItem value='Case'>Case</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {/* SKU table */}
      <TableContainer
        component={Paper}
        sx={{
          position: 'relative',
          backgroundColor: 'var(--colors-gray1)',
          border: darkMode ? '0.0625rem solid var(--colors-gray7)' : 'none',
        }}
      >
        <Table aria-label='collapsible table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                  width: '4.5rem',
                }}
              />
              <TableCell
                sx={{
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              />
              <TableCell
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                SKU ID
              </TableCell>
              <TableCell
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Name
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '13.5rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                In Transit
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Available
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Allocated
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Blocked
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Total {`(${selectedSKUName}s)`}
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Total (££)
              </TableCell>
              <TableCell
                align='center'
                sx={{
                  minWidth: '9rem',
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              >
                Discrepancy
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((row) => (
              <CollapsibleRow
                key={row.id}
                row={row}
                warehouseId={skuStockWarehouseId}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                // colSpan={10}
                count={totalCount as number}
                rowsPerPage={itemsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={StockBySKUTablePaginationActions}
                sx={{
                  color: 'var(--colors-gray12)',
                  borderBottomColor: 'var(--colors-gray7)',
                  backgroundColor: 'var(--colors-gray2)',
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {isLoadingSkus && (
          <Box
            sx={{
              position: 'absolute',
              top: '2.2813rem',
              left: '0',
              width: '100%',
              height: `calc(100% - ${tableHeadHeight} - ${tableRowHeight})`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size='var(--sizes-size32)' />
          </Box>
        )}
      </TableContainer>
    </View>
  );
}
