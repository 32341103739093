import { styled, keyframes } from '@stitchesConfig';
import { MdAutorenew } from 'react-icons/md';

export const StyledProductList = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridAutoRows: 'min-content',
  gap: '$space16',
  position: 'relative',
  height: 'calc(100vh - 9.25rem)',
  borderRadius: '$radii8',
  overflowY: 'auto',
  padding: '$space1',

  // '& .infinite-scroll-component__outerdiv, & .infinite-scroll-component': {
  //   display: 'contents',
  // },

  // '& > div > div > div:first-child': {
  //   display: 'contents',
  //   position: 'unset',
  //   gridColumn: '1 / -1',
  // },

  '& > div:first-child': {
    // display: 'contents',
    // display: 'grid',
    // gridTemplateColumns: 'repeat(2, 1fr)',
    // gridAutoRows: 'min-content',
    // gap: '$space16',
    // position: 'relative',
    // height: 'calc(100vh - 9.25rem)',
    // borderRadius: '$radii8',
    // overflowY: 'auto',
    // padding: '$space1',
  },
  '@xs': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@sm': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  '@lg': {
    height: 'calc(100vh - 12rem)',
  },

  '@xl': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '@2xl': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },

  '@3xl': {
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
});

StyledProductList.displayName = 'StyledProductList';

export const StyledProductCard = styled('div', {
  $$borderWidth: '$sizes$size1',
  $$borderColor: '$colors$gray7',
  boxShadow: '0 0 0 $$borderWidth $$borderColor, $md',
  display: 'flex',
  borderRadius: '$radii8',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'box-shadow $fast, transform $fast',

  '&:hover': {
    boxShadow: '0 0 0 $$borderWidth $$borderColor, $shadow',
    transform: 'scale(0.985)',
    // transform: 'scale(1.015)',
  },

  variants: {
    orientation: {
      vertical: {
        flexDirection: 'column',
        // width: '$size128',
        // height: '$size224',
      },
      horizontal: {
        flexDirection: 'row',
        // height: '$size128',

        // '@md': {
        //   maxHeight: '$size96',
        // },
      },
    },
  },
});

StyledProductCard.displayName = 'StyledProductCard';

export const StyledProductCardImageContainer = styled('div', {
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',

  '@md': {
    width: '$size112',
  },
});

StyledProductCardImageContainer.displayName = 'StyledProductCardImageContainer';

export const StyledProductCardInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space8',
  width: '100%',
  padding: '$space16',
  backgroundColor: '$gray1',

  variants: {
    orientation: {
      vertical: {
        borderTopWidth: '$sizes$size1',
        borderTopStyle: 'solid',
        borderTopColor: '$gray7',
      },
      horizontal: {
        borderLeftWidth: '$sizes$size1',
        borderLeftStyle: 'solid',
        borderLeftColor: '$gray7',
      },
    },
  },
});

StyledProductCardInfo.displayName = 'StyledProductCardInfo';

export const StyledProductLoading = styled('div', {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$space16',
  borderRadius: '$radii8',
  backgroundColor: '$grayA11',
  color: '$gray1',

  '.dark-theme &': {
    $$borderWidth: '$sizes$size1',
    $$borderColor: '$colors$gray7',
    boxShadow: '0 0 0 $$borderWidth $$borderColor',
    backgroundColor: 'rgba(0 0 0 / 0.75)',
    color: '$gray12',
  },
});

StyledProductLoading.displayName = 'StyledProductLoading';

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export const StyledProductLoadingIndicator = styled(MdAutorenew, {
  width: '4rem',
  height: '4rem',
  transform: 'rotate(-90deg)',
  animation: `${spin} 700ms linear infinite`,
});

StyledProductLoadingIndicator.displayName = 'StyledProductLoadingIndicator';
