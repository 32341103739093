import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { MdExpandMore, MdExpandLess, MdCheck } from 'react-icons/md';
import { Noop } from 'react-hook-form';
import * as Stitches from '@stitches/react';
import {
  StyledSelectTrigger,
  StyledSelectIcon,
  StyledSelectContent,
  StyledScrollUpButton,
  StyledScrollDownButton,
  StyledSelectItem,
  StyledSelectItemIndicator,
  StyledSelectGroup,
  StyledSelectSeparator,
  StyledSelectLabel,
  StyledSelectPortal,
} from './Select.styles';

interface SelectProps
  extends ComponentPropsWithoutRef<typeof SelectPrimitive.Root> {
  placeholder?: string | ReactNode;
  isInvalid?: boolean;
  onBlur?: Noop;
  css?: Stitches.CSS;
}

export const Select = forwardRef<
  ElementRef<typeof SelectPrimitive.Root>,
  SelectProps
>(({ children, placeholder, isInvalid, css, ...props }, forwardedRef) => {
  return (
    <SelectPrimitive.Root {...props}>
      <StyledSelectTrigger
        isInvalid={isInvalid}
        ref={forwardedRef}
        {...(props.onBlur && { onBlur: props.onBlur })}
        css={css}
      >
        <SelectPrimitive.Value placeholder={placeholder} />
        <StyledSelectIcon>
          <MdExpandMore size='1.5rem' />
        </StyledSelectIcon>
      </StyledSelectTrigger>
      <StyledSelectPortal>
        <StyledSelectContent>
          <StyledScrollUpButton>
            <MdExpandLess size='1.5rem' />
          </StyledScrollUpButton>
          <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
          <StyledScrollDownButton>
            <MdExpandMore size='1.5rem' />
          </StyledScrollDownButton>
        </StyledSelectContent>
      </StyledSelectPortal>
    </SelectPrimitive.Root>
  );
});

Select.displayName = 'Select';

export const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, ...props }, forwardedRef) => {
  return (
    <StyledSelectItem {...props} ref={forwardedRef}>
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
      <StyledSelectItemIndicator>
        <MdCheck size='1.5rem' />
      </StyledSelectItemIndicator>
    </StyledSelectItem>
  );
});

SelectItem.displayName = 'SelectItem';

interface SelectGroupProps
  extends ComponentPropsWithoutRef<typeof SelectPrimitive.Group> {
  css?: Stitches.CSS;
}

export const SelectGroup = forwardRef<
  ElementRef<typeof SelectPrimitive.Group>,
  SelectGroupProps
>(({ children, css, ...props }, forwardedRef) => (
  <StyledSelectGroup css={css} {...props} ref={forwardedRef}>
    {children}
  </StyledSelectGroup>
));

SelectGroup.displayName = 'SelectGroup';

interface SelectSeparatorProps
  extends ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> {
  css?: Stitches.CSS;
}

export const SelectSeparator = forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  SelectSeparatorProps
>(({ css, ...props }, forwardedRef) => (
  <StyledSelectSeparator css={css} {...props} ref={forwardedRef} />
));

SelectSeparator.displayName = 'SelectSeparator';

interface SelectLabelProps
  extends ComponentPropsWithoutRef<typeof SelectPrimitive.Label> {
  css?: Stitches.CSS;
}

export const SelectLabel = forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  SelectLabelProps
>(({ children, css, ...props }, forwardedRef) => (
  <StyledSelectLabel css={css} {...props} ref={forwardedRef}>
    {children}
  </StyledSelectLabel>
));

SelectLabel.displayName = 'SelectLabel';
