import { styled } from '@stitchesConfig';

export const StyledSKUEditor = styled('div', {
  $$borderColor: '$colors$gray7',
  $$borderWidth: '$space$space1',
  padding: '$space16',
  boxShadow: 'inset 0 0 0 $$borderWidth $$borderColor',
  borderRadius: '$radii8',
  marginBottom: '$space16',
});

StyledSKUEditor.displayName = 'StyledSKU';
