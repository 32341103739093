import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';

interface ShipmentDateFromFilterProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
}

export function ShipmentDateFromFilter({
  value,
  onChange,
}: ShipmentDateFromFilterProps) {
  return (
    <FormControl>
      <DatePicker
        label='From'
        slotProps={{ textField: { size: 'small' } }}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
}
