/* eslint-disable no-nested-ternary */
import { OutboundProcess } from '@features/supplyChain/types';
import { useReactHookForm } from '@hooks';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { Text } from '@components/Text';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import CheckIcon from '@mui/icons-material/Check';
import { PalletCalculator } from '@components/PalletCalculator';
import { AuthoriseForPickingFormValues } from './types';
import { makeDefaultValues, validationSchema } from './form';

interface AuthoriseForPickingFormProps {
  selectedOutboundProcess: OutboundProcess;
  onClose: () => void;
  onSubmit: (values: AuthoriseForPickingFormValues) => void;
  isDesktop: boolean;
  isTablet: boolean;
}

export function AuthoriseForPickingForm({
  selectedOutboundProcess,
  onClose,
  onSubmit,
  isDesktop,
  isTablet,
}: AuthoriseForPickingFormProps) {
  const { handleSubmit, isSubmitting, isValid, control, setValue } =
    useReactHookForm<AuthoriseForPickingFormValues>({
      defaultValues: makeDefaultValues(selectedOutboundProcess),
      validationSchema,
    });

  if (!selectedOutboundProcess) {
    return null;
  }

  const { despatchNote } = selectedOutboundProcess;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={16}
        p={6}
        direction={isTablet ? 'row' : 'column'}
        sx={{ mb: 16 }}
      >
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking note #
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.despatchNoteNumber.replace('-DN', '-P')}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Shipper name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {selectedOutboundProcess.userBusinessName}
            </Text>
          </Stack>

          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Creation date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {dayjs(despatchNote.createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
        </Stack>
        <Stack spacing={8} flex='1'>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Created by
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.createdByName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Recipient name
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {despatchNote.customerName}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Required ETA
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              {`${dayjs(despatchNote.requiredTimeOfArrival).format(
                'DD/MM/YYYY'
              )}, by ${dayjs(despatchNote.requiredTimeOfArrival).format(
                'HH:mm'
              )}`}
            </Text>
          </Stack>
          <Stack>
            <Text size={isDesktop ? 'md' : 'sm'} weight='semibold'>
              Picking Date
            </Text>
            <Text size={isDesktop ? 'md' : 'sm'}>
              <Controller
                name='pickingDateConfirmation'
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <DatePicker
                      format='DD/MM/YYYY'
                      minDate={dayjs(new Date())}
                      inputRef={field.ref}
                      onChange={(value) => {
                        setValue(
                          'pickingDateConfirmation',
                          dayjs(value).format(),
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                      defaultValue={dayjs()}
                      slotProps={{
                        textField: {
                          onBlur: field.onBlur,
                          name: field.name,
                          multiline: !isDesktop,
                          ...(!isDesktop && {
                            InputLabelProps: {
                              shrink: true,
                            },
                          }),
                          error: Boolean(error),
                          helperText: error?.message,
                        },
                      }}
                    />
                  );
                }}
              />
            </Text>
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing={16} p={6} sx={{ mb: 16 }}>
        <Text as='h3' size='lg' weight='bold'>
          SKU / items
        </Text>
        {isDesktop && (
          <>
            <Box
              display='grid'
              gridTemplateColumns='2rem repeat(12, 1fr)'
              columnGap='1rem'
              rowGap='1rem'
              alignItems='center'
            >
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 1', textAlign: 'center' }}
              >
                #
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU ID
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 3' }}
              >
                Product name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                SKU name
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 2' }}
              >
                Quantity
              </Text>
              <Text
                as='h3'
                size='md'
                weight='semibold'
                css={{ gridColumn: 'span 3' }}
              >
                Pallets
              </Text>
            </Box>
            <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
          </>
        )}
        <Stack gap='1rem'>
          {despatchNote.skus.map((sku, index) => (
            <>
              <Box
                key={sku.skuId}
                display='grid'
                gridTemplateColumns='2rem repeat(12, 1fr)'
                columnGap='1rem'
                rowGap='1rem'
                alignItems={isDesktop ? 'center' : 'flex-start'}
              >
                {/* SKU index  */}
                <Stack
                  gridColumn={isDesktop ? 'span 1' : '1 / 2'}
                  justifyContent='center'
                >
                  <Chip label={index + 1} />
                </Stack>
                {/* SKU ID */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? 'span 2' : isTablet ? 'span 5' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU ID
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuIdentifier}
                  </Text>
                </Stack>
                {/* Product name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? 'span 3' : isTablet ? 'span 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Product name
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.productName}
                  </Text>
                </Stack>
                {/* Sku name */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? 'span 2' : isTablet ? '2 / 7' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      SKU name
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.name}
                  </Text>
                </Stack>
                {/* Sku quantity */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? 'span 2' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Quantity
                    </Text>
                  )}
                  <Text as='p' size='md'>
                    {sku.skuQuantity}
                  </Text>
                </Stack>
                {/* Pallet type */}
                <Stack
                  gap={4}
                  gridColumn={
                    isDesktop ? 'span 3' : isTablet ? '7 / 14' : '2 / 14'
                  }
                >
                  {!isDesktop && (
                    <Text as='p' size='sm' weight='semibold'>
                      Pallets
                    </Text>
                  )}
                  {sku.packagingDetails.packagingInfo ? (
                    <PalletCalculator
                      palletName={sku.packagingDetails.packagingInfo.type}
                      skuQuantity={sku.skuQuantity}
                      amountOfSkusInLayer={
                        sku.packagingDetails.packagingInfo.amountOfSkusInLayer
                      }
                      amountOfLayersInPallet={
                        sku.packagingDetails.packagingInfo
                          .amountOfLayersInPallet
                      }
                    />
                  ) : (
                    <Text as='p' size='md'>
                      {sku.packagingDetails.palletisingType}
                    </Text>
                  )}
                </Stack>
                {/* Pallet description */}
                {sku.packagingDetails.palletisingDescription && (
                  <Stack
                    gap={4}
                    gridColumn={
                      isDesktop ? '9 / 14' : isTablet ? '7 / 14' : '2 / 14'
                    }
                  >
                    <Text as='p' size='sm' weight='semibold'>
                      Pallet description
                    </Text>
                    <Text as='p' size='md'>
                      {sku.packagingDetails.palletisingDescription}
                    </Text>
                  </Stack>
                )}
              </Box>
              {index !== despatchNote.skus.length - 1 && (
                <Divider sx={{ borderColor: 'var(--colors-gray8)' }} />
              )}
            </>
          ))}
        </Stack>
      </Stack>
      {despatchNote.comment && (
        <Stack spacing={8} p={6} sx={{ mb: 16 }}>
          <Text as='h3' size='lg' weight='bold'>
            Comments
          </Text>
          <Text as='p' size='md' css={{ mb: '$space16' }}>
            {despatchNote.comment}
          </Text>
        </Stack>
      )}
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        justifyContent='flex-end'
        alignItems='center'
        spacing={16}
        pt={32}
        px={8}
        pb={8}
      >
        <Button
          variant='outlined'
          size='large'
          sx={{ textTransform: 'none' }}
          fullWidth={!isDesktop}
          onClick={onClose}
        >
          Discard
        </Button>
        <LoadingButton
          type='submit'
          size='large'
          variant='contained'
          loading={isSubmitting}
          loadingPosition='start'
          startIcon={<CheckIcon />}
          disabled={!isValid}
          fullWidth={!isDesktop}
          sx={{ textTransform: 'none' }}
        >
          {isSubmitting ? 'Authorising...' : 'Authorise For Picking'}
        </LoadingButton>
      </Stack>
    </form>
  );
}
