import { useEffect } from 'react';
import { useSelectWarehouse } from '@hooks';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ErrorIcon from '@mui/icons-material/Error';
import MenuItem from '@mui/material/MenuItem';
import { Text } from '@components/Text';

export function WarehouseSelector() {
  const {
    fetchWarehouses,
    warehousesOptions,
    selectedWarehouseId,
    isLoadingWarehouses,
    isWarehousesError,
    handleChangeWarehouse,
  } = useSelectWarehouse();

  useEffect(() => {
    fetchWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedOption = warehousesOptions.find(
    (option) => option.warehouseId === selectedWarehouseId
  );

  if (isLoadingWarehouses) {
    return (
      <Stack
        direction='row'
        gap={16}
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '14.25rem',
        }}
      >
        <CircularProgress size={24} sx={{ fill: 'var(--colors-gray12)' }} />
      </Stack>
    );
  }

  if (isWarehousesError) {
    return (
      <Stack
        direction='row'
        gap={16}
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '14.25rem',
        }}
      >
        <ErrorIcon
          sx={{ width: 24, height: 24, fill: 'var(--colors-tomato9)' }}
        />
        <Text size='sm' css={{ color: 'var(--colors-tomato9)' }}>
          Error fetching warehouses.
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction='row' gap={16} alignItems='center'>
      <HomeWorkIcon sx={{ fill: 'var(--colors-gray11)' }} />
      <Select
        id='warehouse-select'
        value={selectedOption?.warehouseId || 'All'}
        onChange={handleChangeWarehouse}
        size='small'
        fullWidth
        variant='outlined'
        sx={{
          color: 'var(--colors-gray12)',
          width: '14.25rem',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--colors-gray7)',
          },
          '& .MuiSelect-icon': {
            color: 'var(--colors-gray12)',
          },
          '& .MuiList-root': {
            backgroundColor: 'var(--colors-gray1)',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: 'var(--colors-gray1)',
            },
          },
        }}
      >
        {warehousesOptions.map((warehousesOption) => (
          <MenuItem
            key={warehousesOption.warehouseId}
            value={warehousesOption.warehouseId}
            sx={{
              '&.MuiMenuItem-root': {
                backgroundColor: 'var(--colors-gray1)',
                color: 'var(--colors-gray12)',
              },
              '&.MuiMenuItem-root:hover': {
                backgroundColor: 'var(--colors-gray3)',
              },
              '&.MuiMenuItem-root.Mui-selected': {
                backgroundColor: 'var(--colors-gray4)',
                color: 'var(--colors-gray12)',
              },
            }}
          >
            {warehousesOption.warehouseName === 'All'
              ? 'All warehouses'
              : warehousesOption.warehouseName}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
