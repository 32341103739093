import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const SECTOR_EXPERIENCE = [
  'Accommodation and Food Services',
  'Arts and Entertainment',
  'Agriculture, Forestry and Fishing',
  'Construction and Real Estate',
  'Professional Services',
  'IT and Telecom',
  'Mining and Quarrying',
  'Utilities',
  'Wholesale and Retail',
  'Medical and Healthcare',
  'Consumer Goods Manufacturing',
  'Industrial Manufacturing',
  'Specialised Manufacturing',
  'Other',
];

export function Experience() {
  const { control, getValues, setValue, watch } = useFormContext();

  const watchSectorExperience = watch('sectorExperience');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const sectorExperience = getValues('sectorExperience');
    if (checked) {
      setValue('sectorExperience', [...sectorExperience, value]);
    } else {
      const filteredAnswers = (sectorExperience as string[]).filter(
        (answer) => answer !== value
      );

      setValue('sectorExperience', filteredAnswers);
    }
  };

  // useEffect(() => {
  //   if (watchSectorExperience?.includes('Other')) return;
  //   setValue('notableCustomer1', '');
  //   setValue('notableCustomer2', '');
  //   setValue('notableCustomer3', '');
  //   setValue('notableCustomer4', '');
  // }, [watchSectorExperience]);

  return (
    <Stack spacing={16}>
      <Heading as='h3' size='sm'>
        Sector experience
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space2',
        }}
      >
        <Controller
          name='sectorExperience'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                {Boolean(error) && (
                  <Typography color='error' fontSize='0.75rem' paddingTop={4}>
                    {error?.message}
                  </Typography>
                )}
                <FormGroup>
                  {SECTOR_EXPERIENCE.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={
                            (field.value as string[])?.includes(option) || false
                          }
                          onChange={handleCheckboxChange}
                          name={field.name}
                          value={option}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </>
            );
          }}
        />
      </InputGroup>
      {watchSectorExperience?.includes('Other') && (
        <>
          <Controller
            name='notableCustomer1'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label=''
                variant='outlined'
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='notableCustomer2'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label=''
                variant='outlined'
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='notableCustomer3'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label=''
                variant='outlined'
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name='notableCustomer4'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                label=''
                variant='outlined'
                fullWidth
                error={Boolean(error)}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        </>
      )}
    </Stack>
  );
}
