import { Dispatch, SetStateAction } from 'react';
import { Heading } from '@components/Heading';
import { OutboundProcess } from '@features/supplyChain/types';
import { Search } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { Text } from '@components/Text';
import {
  SidebarAccordionContainer,
  SidebarAccordion,
  SidebarAccordionSummary,
  SidebarAccordionDetails,
  SidebarAccordionDivider,
} from '@components/SidebarAccordion';
import dayjs from 'dayjs';
import { PickingConfirmationSummary } from './PickingConfimationSummary';
import { OutboundShipmentNoteSummary } from './OutboundShipmentNoteSummary';
import { ProofOfDeliverySummary } from './ProofOfDeliverySummary';
import { getNoticeColors } from '../utils';

interface WarehouseClientOutboundSideDrawerProps {
  selectedOutboundProcess: OutboundProcess;
  setSelectedOutboundProcess: (payload: OutboundProcess | null) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDesktop: boolean;
  selectedOutboundProcessHasDescription: boolean;
  setIsViewDespatchNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsViewPickingConfirmationDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsViewOutboundShipmentNoteDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsViewProofOfDeliveryDialogOpen: Dispatch<SetStateAction<boolean>>;
  selectedProcessChildType: string | null;
  setSelectedProcessChildType: (payload: string | null) => void;
}

export function WarehouseClientOutboundSideDrawer({
  selectedOutboundProcess,
  setSelectedOutboundProcess,
  isOpen,
  setIsOpen,
  isDesktop,
  selectedOutboundProcessHasDescription,
  setIsViewDespatchNoteDialogOpen,
  setIsViewPickingConfirmationDialogOpen,
  setIsViewOutboundShipmentNoteDialogOpen,
  setIsViewProofOfDeliveryDialogOpen,
  selectedProcessChildType,
  setSelectedProcessChildType,
}: WarehouseClientOutboundSideDrawerProps) {
  const {
    createdAt,
    status,
    description,
    notices,
    despatchNote,
    pickingConfirmation,
    shipmentNote,
    goodsReceivedNote,
  } = selectedOutboundProcess;

  const totalItemsQuantity = despatchNote.skus.reduce(
    (prev, current) => prev + current.skuQuantity,
    0
  );

  const totalPickedItemsQuantity = pickingConfirmation?.skus.reduce(
    (prev, current) => prev + current.pickedQuantity,
    0
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isPickingDiscrepancy = totalPickedItemsQuantity! !== totalItemsQuantity;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pickingDiscrepancyAmount =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    totalItemsQuantity - totalPickedItemsQuantity!;
  const isNegativePickingDiscrepancy = pickingDiscrepancyAmount > 0;

  const totalShippedItemsQuantity = shipmentNote?.skus.reduce(
    (prev, current) => prev + current.skuQuantity,
    0
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isShippingDiscrepancy =
    totalShippedItemsQuantity !== totalItemsQuantity;
  const shippingDiscrepancyAmount =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    totalItemsQuantity - totalShippedItemsQuantity!;
  const isNegativeShippingDiscrepancy = shippingDiscrepancyAmount > 0;

  const totalPODItemsQuantity = goodsReceivedNote?.skus.reduce(
    (prev, current) => prev + current.receivedSkuQuantity,
    0
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isPODDiscrepancy =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    totalPODItemsQuantity! !== totalItemsQuantity;
  const podDiscrepancyAmount =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    totalItemsQuantity - totalPODItemsQuantity!;
  const isNegativePODDiscrepancy = podDiscrepancyAmount > 0;

  const hasIssues = (notices?.length ?? 0) > 0;
  const isProcessComplete = !!goodsReceivedNote?.finalisedAt;
  const hasIssuesBeforeProcessIsComplete = hasIssues && !isProcessComplete;

  const onClose = () => {
    setIsOpen(false);
    setSelectedOutboundProcess(null);
    setSelectedProcessChildType(null);
  };

  return (
    <Drawer
      hideBackdrop
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={onClose}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: isDesktop ? '32rem' : '100%',
          p: 16,
        }}
      >
        <IconButton
          aria-label='close'
          onClick={onClose}
          size='medium'
          sx={{
            height: '2.5rem',
            color: (theme) => theme.palette.grey[500],
            mb: '1rem',
            ...(!isDesktop && {
              alignSelf: 'flex-end',
            }),
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ p: 8, width: '100%' }}>
          <Heading as='h2' size='sm' css={{ mb: '$space32' }}>
            Outbound Despatch Note
          </Heading>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Status: </strong>
            </Text>
            <Text as='span' size='md'>
              {status}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Customer (deliver to): </strong>
            </Text>
            <Text as='span' size='md'>
              {despatchNote.customerName}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Purchase Order #: </strong>
            </Text>
            <Text as='span' size='md'>
              {despatchNote.purchaseOrderNumber}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Creation date: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(createdAt).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Despatch Note #: </strong>
            </Text>
            <Text as='span' size='md'>
              {despatchNote.despatchNoteNumber}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>RTA: </strong>
            </Text>
            <Text as='span' size='md'>
              {dayjs(despatchNote.requiredTimeOfArrival).format('DD/MM/YYYY')}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>SKUs: </strong>
            </Text>
            <Text as='span' size='md'>
              {despatchNote.skus.length}
            </Text>
          </Stack>
          <Stack direction='row' justifyContent='space-between' sx={{ mb: 16 }}>
            <Text as='span' size='md'>
              <strong>Total Quantity (Items): </strong>
            </Text>
            <Text as='span' size='md'>
              {totalItemsQuantity}
            </Text>
          </Stack>
          {selectedOutboundProcessHasDescription && (
            <Stack gap={8} sx={{ mb: hasIssues ? 16 : 32 }}>
              <Text as='span' size='md'>
                <strong>Description</strong>
              </Text>
              <Text as='span' size='md'>
                {description}
              </Text>
            </Stack>
          )}
          {hasIssues && (
            <>
              <Text as='h3' size='md' css={{ mb: 16 }}>
                <strong>Issues</strong>
              </Text>
              <Stack
                gap={8}
                sx={{
                  mb: 32,
                }}
              >
                {notices?.map((notice) => (
                  <Stack
                    key={notice}
                    direction='row'
                    alignItems='center'
                    gap={8}
                    sx={{
                      backgroundColor: getNoticeColors(
                        hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                      ).backgroundColor,
                      p: 8,
                      borderRadius: 2,
                    }}
                  >
                    <ErrorIcon
                      sx={{
                        width: 16,
                        height: 16,
                        fill: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).fill,
                      }}
                    />
                    <Text
                      size='sm'
                      css={{
                        color: getNoticeColors(
                          hasIssuesBeforeProcessIsComplete ? 'warning' : 'error'
                        ).color,
                      }}
                    >
                      {notice}
                    </Text>
                  </Stack>
                ))}
              </Stack>
            </>
          )}
          <Button
            variant='contained'
            size='large'
            startIcon={<Search />}
            fullWidth
            sx={{ textTransform: 'none', mb: 16 }}
            onClick={() => {
              setIsOpen(false);
              setIsViewDespatchNoteDialogOpen(true);
            }}
          >
            View Despatch Note
          </Button>
          {pickingConfirmation && (
            <SidebarAccordionContainer>
              <SidebarAccordion
                expanded={selectedProcessChildType === 'pickingConfirmation'}
                setSelectedProcessChildType={setSelectedProcessChildType}
              >
                <SidebarAccordionSummary
                  title='Picking Confirmation'
                  ariaControls='picking-confirmation-content'
                  id='picking-confirmation-header'
                />
                <SidebarAccordionDetails>
                  <Stack gap={8}>
                    <PickingConfirmationSummary
                      selectedOutboundProcess={selectedOutboundProcess}
                      totalPickedItemsQuantity={totalPickedItemsQuantity}
                      isPickingDiscrepancy={isPickingDiscrepancy}
                      pickingDiscrepancyAmount={pickingDiscrepancyAmount}
                      isNegativePickingDiscrepancy={
                        isNegativePickingDiscrepancy
                      }
                    />
                    <Button
                      variant='contained'
                      size='large'
                      startIcon={<Search />}
                      fullWidth
                      sx={{ textTransform: 'none', mt: '0.5rem' }}
                      onClick={() => {
                        setIsOpen(false);
                        setIsViewPickingConfirmationDialogOpen(true);
                      }}
                    >
                      View Picking Confirmation
                    </Button>
                  </Stack>
                </SidebarAccordionDetails>
              </SidebarAccordion>
              {shipmentNote && (
                <>
                  <SidebarAccordionDivider />
                  <SidebarAccordion
                    expanded={selectedProcessChildType === 'shipmentNote'}
                    setSelectedProcessChildType={setSelectedProcessChildType}
                  >
                    <SidebarAccordionSummary
                      title='Shipment Note'
                      ariaControls='shipment-note-content'
                      id='shipment-note-header'
                    />
                    <SidebarAccordionDetails>
                      <Stack gap={8}>
                        <OutboundShipmentNoteSummary
                          selectedOutboundProcess={selectedOutboundProcess}
                          totalShippedItemsQuantity={totalShippedItemsQuantity}
                          isShippingDiscrepancy={isShippingDiscrepancy}
                          shippingDiscrepancyAmount={shippingDiscrepancyAmount}
                          isNegativeShippingDiscrepancy={
                            isNegativeShippingDiscrepancy
                          }
                        />
                        <Button
                          variant='contained'
                          size='large'
                          startIcon={<Search />}
                          fullWidth
                          sx={{ textTransform: 'none', mt: '0.5rem' }}
                          onClick={() => {
                            setIsOpen(false);
                            setIsViewOutboundShipmentNoteDialogOpen(true);
                          }}
                        >
                          View Shipment Note
                        </Button>
                      </Stack>
                    </SidebarAccordionDetails>
                  </SidebarAccordion>
                </>
              )}
              {goodsReceivedNote && (
                <>
                  <SidebarAccordionDivider />
                  <SidebarAccordion
                    expanded={selectedProcessChildType === 'goodsReceivedNote'}
                    setSelectedProcessChildType={setSelectedProcessChildType}
                  >
                    <SidebarAccordionSummary
                      title='Proof Of Delivery'
                      ariaControls='proof-of-delivery-content'
                      id='proof-of-delivery-header'
                    />
                    <SidebarAccordionDetails>
                      <Stack gap={8}>
                        <ProofOfDeliverySummary
                          selectedOutboundProcess={selectedOutboundProcess}
                          totalPODItemsQuantity={totalPODItemsQuantity}
                          isPODDiscrepancy={isPODDiscrepancy}
                          podDiscrepancyAmount={podDiscrepancyAmount}
                          isNegativePODDiscrepancy={isNegativePODDiscrepancy}
                        />
                        <Button
                          variant='contained'
                          size='large'
                          startIcon={<Search />}
                          fullWidth
                          sx={{ textTransform: 'none', mt: '0.5rem' }}
                          onClick={() => {
                            setIsOpen(false);
                            setIsViewProofOfDeliveryDialogOpen(true);
                          }}
                        >
                          View Proof Of Delivery
                        </Button>
                      </Stack>
                    </SidebarAccordionDetails>
                  </SidebarAccordion>
                </>
              )}
            </SidebarAccordionContainer>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
