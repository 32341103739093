import LinearProgress from '@mui/material/LinearProgress';

interface ProgressBarProps {
  currentPage: number;
  totalPages: number;
}

function ProgressBar({ currentPage, totalPages }: ProgressBarProps) {
  const progress = (currentPage / totalPages) * 100;

  return (
    <div>
      <LinearProgress variant='determinate' value={progress} />
    </div>
  );
}

export default ProgressBar;
