import { createTheme } from '@mui/material/styles';
import { Localization } from '@mui/material/locale';
import { grayPalette } from './palettes';

const detectedLocale = navigator.language.replace('-', '') as Localization;

export const theme = createTheme(
  {
    spacing: (factor: number) => `${0.0625 * factor}rem`,
    breakpoints: {
      unit: 'em',
      values: {
        xs: 0,
        sm: 36,
        md: 48,
        lg: 62,
        xl: 75,
      },
    },
    palette: {
      ...grayPalette,
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
          disableElevation: true,
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    typography: {
      fontFamily: ['Poppins', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ','
      ),
    },
  },
  detectedLocale
);
