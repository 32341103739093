import { useState, useEffect, useRef } from 'react';
import { useAuthHttp, useDebouncedQuery } from '@hooks';
import { AVAILABLE_SKUS_URL } from '@constants';
import { AvailableSKU } from '../forms/client/CreateInboundShipmentNoteForm/types';

export const useAvailableSkus = () => {
  const authHttp = useAuthHttp();

  const [openAvailableSkuOptions, setOpenAvailableSkuOptions] = useState(false);

  const [availableSkuOptions, setAvailableSkuOptions] = useState<
    AvailableSKU[]
  >([]);

  const [openPalletisingIndex, setOpenPalletisingIndex] = useState<
    number | null
  >(null);

  const [openPalletisingOptions, setOpenPalletisingOptions] = useState(false);

  const {
    debouncedQuery: availableSkusQuery,
    setDebouncedQuery: setAvailableSkusQuery,
  } = useDebouncedQuery();

  const [loadingAvailableSkus, setLoadingAvailableSkus] = useState(false);

  const [openSkuIndex, setOpenSkuIndex] = useState<number | null>(null);

  const previousController = useRef<AbortController>();

  useEffect(() => {
    if (availableSkusQuery.length < 3) return;

    const getAvailableSkus = async () => {
      if (previousController.current) {
        previousController.current.abort();
      }
      const controller = new AbortController();
      const { signal } = controller;
      previousController.current = controller;

      setLoadingAvailableSkus(true);

      try {
        const response = await authHttp.post(
          AVAILABLE_SKUS_URL,
          {
            searchQuery: availableSkusQuery,
            isPalletisingRequired: true,
            pagination: {
              lastItem: null,
              itemsPerPage: 25,
            },
          },
          { signal }
        );

        const {
          data: { skus },
        }: { data: { skus: AvailableSKU[] } } = response;

        setAvailableSkuOptions(skus);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setLoadingAvailableSkus(false);
      }
    };

    getAvailableSkus();
  }, [availableSkusQuery]);

  return {
    availableSkuOptions,
    loadingAvailableSkus,
    openSkuIndex,
    setOpenSkuIndex,
    openAvailableSkuOptions,
    setOpenAvailableSkuOptions,
    availableSkusQuery,
    setAvailableSkusQuery,
    openPalletisingIndex,
    setOpenPalletisingIndex,
    openPalletisingOptions,
    setOpenPalletisingOptions,
  };
};
