export type UserImage = {
  id: string;
  title: string;
  image: File;
  imageLocation: string;
  remoteName: string;
};

export interface PaginationQuery {
  pagination: {
    pageNumber: string;
    itemsPerPage: string;
  } | null;
}
export interface ListInventoryResponse {
  skuItems: VehicleSummary[];
  currentQuery: PaginationQuery;
}

export interface VehicleSummary {
  averageAnnualMileage: number;
  averageAnnualMileageUnits: string;
  emissionStandard: string;
  fuelType: string;
  images: UserImage[];
  vehicleCapacity: {
    maxCapacityDimensions: {
      width: number;
      height: number;
      length: number;
      dimensionsUnitOfMeasure: string;
    };
    maxPallets: number;
    maxKilograms: number;
  };
  model: string;
  modifiedAt: string;
  registrationNumber: string;
  userBusinessId: string;
  userId: string;
  vehicleId: string;
  vehicleType: string;
  vehicleCategory: string;
  specialEquipment: string;
}

export type Fleet =
  | 'VanLessThan1AndHalft'
  | 'LgvLessThan3AndHalft'
  | 'TwoAxleRigidLessThan7AndHalf5t'
  | 'TwoAxleRigid18t'
  | 'TwoAxleRigidLessThan26t'
  | 'MultiAxleArticMoreThan26t'
  | 'SpecialOrOther';

export const fleetMapping = {
  VanLessThan1AndHalft: 'Van < 1.5t',
  LgvLessThan3AndHalft: 'LDVs < 3.5t',
  TwoAxleRigidLessThan7AndHalf5t: 'Rigids < 7.5t',
  TwoAxleRigid18t: 'Rigids 18t',
  TwoAxleRigidLessThan26t: 'Rigids < 26t',
  ThreeAxleRigidLessThan26t: 'Rigids < 26t',
  MultiAxleArticMoreThan26t: 'Artics > 26t',
  SpecialOrOther: 'Special/Other',
};
