import { useState } from 'react';
import { useAuth } from '@context';
import { clearSupplyChain } from '@features/supplyChain';
import { useAppDispatch } from '@store';
import { SelectChangeEvent } from '@mui/material/Select';
import { fetchLspWarehouses, fetchShipperWarehouses } from '@api';
import { UserRoleService, FilterService } from '@services';
import { useAuthHttp, WarehouseOption } from '@hooks';

export function useSelectWarehouse() {
  const allWarehousesOption: WarehouseOption = {
    warehouseId: 'All',
    warehouseName: 'All',
  };

  const authHttp = useAuthHttp();
  const { getFilterValue, setFilterValue } = FilterService();
  const { user } = useAuth();
  const { isWarehouseClient } = UserRoleService();
  const dispatch = useAppDispatch();

  const [isLoadingWarehouses, setIsLoadingWarehouses] = useState(false);

  const [isWarehousesError, setIsWarehousesError] = useState(false);

  const [warehousesOptions, setWarehousesOptions] = useState<WarehouseOption[]>(
    [allWarehousesOption]
  );

  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    getFilterValue('noticeboardWarehouseId')
  );

  const fetchWarehouses = async () => {
    let warehouses: WarehouseOption[];

    setIsLoadingWarehouses(true);
    try {
      if (isWarehouseClient(user)) {
        warehouses = await fetchShipperWarehouses(authHttp);
      } else {
        warehouses = await fetchLspWarehouses(authHttp);
      }

      setWarehousesOptions([allWarehousesOption, ...warehouses]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsWarehousesError(true);
    } finally {
      setIsLoadingWarehouses(false);
    }
  };

  const handleChangeWarehouse = async (event: SelectChangeEvent) => {
    dispatch(clearSupplyChain());
    const matchingOption = warehousesOptions.find(
      (option) => option.warehouseId === event.target.value
    );
    setFilterValue('noticeboardWarehouseId', event.target.value);
    if (matchingOption) {
      setSelectedWarehouseId(matchingOption.warehouseId);
    }
  };

  return {
    fetchWarehouses,
    warehousesOptions,
    selectedWarehouseId,
    isLoadingWarehouses: isLoadingWarehouses && !isWarehousesError,
    isWarehousesError: !isLoadingWarehouses && isWarehousesError,
    handleChangeWarehouse,
  };
}
